import React, { useState } from "react";
import { withRouter } from "react-router";
import ImageLogin from "app/assets/bg-login-page.png";
import ImageLogo from "app/assets/boom-logo@2x.png";
import "./style.scss";
import AuthenticationAPI from "app/apis/authentication";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";
import LocalStorageService from "app/services/localStorageService";
import TokenService from "app/services/tokenService";

const RegisterPart2 = ({ history, location }) => {
	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant, duration) => dispatch(commonActions.openAlertSnackbar(message, variant, duration));
	const logoutUserAndResetStates = () => dispatch(commonActions.logoutUserAndResetStates());

	const [inputData, setInputData] = useState({
		contact_number: "",
		company_name: "",
		website: "",
	});

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleSubmitCreateAccount = (event) => {
		event.preventDefault();

		const { registerInputData } = location.state;
		const { first_name, last_name, email, password } = registerInputData;

		const data = {
			first_name: first_name,
			last_name: last_name,
			email: email,
			password: password,
			phone_no: inputData.contact_number,
			company_name: inputData.company_name,
			website: inputData.website,
		};

		AuthenticationAPI.postRegister(data)
			.then((response) => {
				if (response.code === 200 && response.data.email_sent) {
					const { token, user } = response.data;
					
					// if no token found in response, then redirect to login page
					if (!token || !user) {
						history.push("/login");
						return;
					}

					// Clear all before cache the data
					logoutUserAndResetStates();
					LocalStorageService.clearAll();

					// Cache the access token and user profile
					TokenService.setAccessToken(token);
					LocalStorageService.setUserProfile(user);

					openAlertSnackbar(
						"Your organizer account has been successfully created. Please create an organization to continue.",
						"success",
						12000
					);
					history.push("/dashboard");
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	return (
		<div className="auth-page container-fluid vh-100">
			<div className="row vh-100">
				<div className="col-6 auth-left px-5">
					<section className="my-5">
						<div>
							<img src={ImageLogo} alt="logo" className="img-logo" />
						</div>
						<h5>Your global host for online and hybrid events.</h5>
					</section>
					<img src={ImageLogin} alt="Login" className="img-fluid" />
				</div>
				<div className="col-6 p-5 auth-right">
					<div className="d-flex flex-column align-items-center">
						<section className="mb-5 text-center">
							<div className="title">Almost there!</div>
							<div className="subtitle">Please tell us a little more about yourself.</div>
						</section>

						<form id="form-register" className="w-100" method="post" onSubmit={handleSubmitCreateAccount}>
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label htmlFor="contact_number">
											Contact Number <span className="text-danger">*</span>
										</label>
										<input
											id="contact_number"
											type="text"
											className="form-control"
											aria-describedby="contact_number"
											name="contact_number"
											value={inputData.contact_number}
											onChange={handleInputOnChange}
											required
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label htmlFor="company_name">
											Company Name <span className="text-danger">*</span>
										</label>
										<input
											id="company_name"
											type="text"
											className="form-control"
											aria-describedby="company_name"
											name="company_name"
											value={inputData.company_name}
											onChange={handleInputOnChange}
											required
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label htmlFor="website">
											Website <span className="text-danger">*</span>
										</label>
										<input
											id="website"
											type="url"
											className="form-control"
											aria-describedby="website"
											name="website"
											value={inputData.website}
											onChange={handleInputOnChange}
											required
										/>
									</div>
								</div>
							</div>

							<div className="mt-4 text-center mb-1">
								<button type="submit" className="btn btn-primary btn-auth px-5">
									Create Account
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(RegisterPart2);
