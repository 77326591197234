/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./style.scss";
import { withRouter, useLocation, Link } from "react-router-dom";
import Organization from "app/apis/organization";
import { useDispatch, useSelector } from "react-redux";
import { organizationActions } from "app/states/organization";
import ImageLogo from "app/assets/boom-logo-v2-blue.png";
import ImageDashboard from "app/assets/icon-menu-dashboard.png";
import ImageProfileSettings from "app/assets/icon-menu-organisation-profile.png";
import ImageEvents from "app/assets/icon-menu-organisation-event.png";
import ImageVisitor from "app/assets/icon-menu-organisation-visitor.png";
import ImageAccounts from "app/assets/icon-menu-organisation-accounts.png";
import ImageBillings from "app/assets/icon-menu-billings.png";
import ImageAdd from "app/assets/icon-add-grey.png";
import LocalStorageService from "app/services/localStorageService";
import IconArrowUp from "app/assets/icon-arrow-up.png";
import IconArrowDown from "app/assets/icon-arrow-down.png";
import IconDefaultUser from "app/assets/icon-default-user.png";
import ProfileModal from "app/views/components/ProfileModal/ProfileModal";
import { commonActions } from "app/states/common";
import SessionStorageService from "app/services/sessionStorageService";
import IconWarningTriangle from "app/assets/icon-alert-triangle-yellow.png";
import UsageAPI from "app/apis/usage";

const NavLink = ({ icon, title, link, rightIcon }) => {
	const location = useLocation();

	const isCurrentRoute = () => {
		if (location.pathname.includes(link)) {
			return true;
		}

		return false;
	};

	const handleLinkOnClick = () => {
		SessionStorageService.clearLastViewingPage();
		SessionStorageService.clearGamificationLeaderboardTop3();
	};

	return (
		<li className="nav-item admin-nav-item mb-2">
			<Link
				className={`nav-link admin-nav-link ${isCurrentRoute() && "active"}`}
				to={link}
				onClick={handleLinkOnClick}>
				<img src={icon} alt={title} className="nav-icon" /> {title}{" "}
				{rightIcon && <img src={rightIcon} alt="warning" className="nav-warning-icon" />}
			</Link>
		</li>
	);
};

const Sidebar = ({ history }) => {
	const userProfile = LocalStorageService.getUserProfile();

	const [organizationListing, setOrganizationListing] = useState([]);
	const [selectedOrganizationName, setSelectedOrganizationName] = useState("");
	const [selecteOrganizationLogo, setSelectedOrganizationLogo] = useState("");

	const [userProfileImage, setUserProfileImage] = useState("");
	const [userProfileName, setUserProfileName] = useState("");

	const [visitorUsage, setVisitorUsage] = useState(null);

	const dispatch = useDispatch();
	const setOrganizationId = (id) => dispatch(organizationActions.setOrganizationId(id));
	const refreshOrganizationListing = (refresh) => dispatch(organizationActions.refreshOrganizationListing(refresh));
	const openProfileModal = () => dispatch(commonActions.openProfileModal());
	const setSelectedOrganizationAccount = (organizationAccount) =>
		dispatch(organizationActions.setSelectedOrganizationAccount(organizationAccount));

	const isRefreshOrganizationListing = useSelector(({ organization }) => organization.isRefreshOrganizationListing);
	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);

	// Get organization listing
	useEffect(() => {
		getOrganizationListing();
	}, []);

	// Handle get latest organization listing
	useEffect(() => {
		if (isRefreshOrganizationListing) {
			getOrganizationListing();
			refreshOrganizationListing(false);
		}
	}, [isRefreshOrganizationListing]);

	// Listen for selected organization local storage changes
	useEffect(() => {
		// First load, get selectedOrganizationAccount from local storage
		if (selectedOrganizationAccount) {
			setSelectedOrganizationName(selectedOrganizationAccount.name);
			setSelectedOrganizationLogo(selectedOrganizationAccount.logo_url);
		}

		function checkSelectedOrganizationData() {
			const updatedSelectedOrganization = LocalStorageService.getSelectedOrganizationAccount();

			if (updatedSelectedOrganization) {
				setSelectedOrganizationName(updatedSelectedOrganization.name);
				setSelectedOrganizationLogo(updatedSelectedOrganization.logo_url);
			}
		}

		window.addEventListener("storage", checkSelectedOrganizationData);
	}, []);

	// Listen for user profile local storage changes
	useEffect(() => {
		// First load, get user profile from local storage
		if (userProfile) {
			setUserProfileImage(userProfile.profile_image_url);
			setUserProfileName(userProfile.first_name + " " + userProfile.last_name);
		}

		function checkUserProfileChanges() {
			const updatedUserProfile = LocalStorageService.getUserProfile();

			if (updatedUserProfile) {
				setUserProfileImage(updatedUserProfile.profile_image_url);
				setUserProfileName(updatedUserProfile.first_name + " " + updatedUserProfile.last_name);
			}
		}

		window.addEventListener("updateUserProfileData", checkUserProfileChanges);
	}, []);

	// Get visitor usage limit
	useEffect(() => {
		if (!selectedOrganizationAccount) return;

		UsageAPI.getVisitorUsage(selectedOrganizationAccount.id)
			.then((response) => {
				setVisitorUsage(response);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [selectedOrganizationAccount]);

	const getOrganizationListing = () => {
		const queryParams = {
			limit: 999999,
		};

		Organization.getOrganizationListing(queryParams)
			.then((res) => {
				const { organizations } = res.data;
				setOrganizationListing(organizations);

				if (organizations.length === 0) {
					history.push("/organization/add");
					return;
				}

				// Set first organiztion as selected
				if (organizations.length > 0 && !selectedOrganizationAccount) {
					LocalStorageService.setSelectedOrganizationAccount(organizations[0]);
					setSelectedOrganizationAccount(organizations[0]);

					setOrganizationId(organizations[0].id);
					setSelectedOrganizationName(organizations[0].name);
					setSelectedOrganizationLogo(organizations[0].logo_url);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleAddNewOrganization = () => {
		history.push("/organization/add");
	};

	const handleSelectOrganization = (organization) => {
		const { id, name, logo_url } = organization;

		LocalStorageService.setSelectedOrganizationAccount(organization);
		setSelectedOrganizationAccount(organization);

		setSelectedOrganizationName(name);
		setSelectedOrganizationLogo(logo_url);
		setOrganizationId(id);
		history.push("/dashboard");
	};

	return (
		<div className="pt-3 sidebar">
			<section className="px-3 mb-3">
				<img src={ImageLogo} alt="logo" className="img-fluid img-logo" />
			</section>

			<section className="px-3 mb-4">
				<div className="dropdown organization-selection">
					<button
						className="organization-dropdown-toggle dropdown-toggle w-100"
						type="button"
						id="orgDropdown"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false">
						<div className="row align-items-center mx-0 text-left p-3">
							<div className="col-3 pl-0 text-center">
								<img
									src={selecteOrganizationLogo || IconDefaultUser}
									alt="logo"
									className="img-fluid img-org-logo"
								/>
							</div>
							<div className="col-7 pl-2">
								<h6 className="org-name" title={selectedOrganizationName}>
									{selectedOrganizationName || "Organization name"}
								</h6>
								<span className="org-desc">Organization</span>
							</div>
							<div className="col-2">
								<img src={IconArrowUp} alt="up" className="icon-arrow-up" />
								<img src={IconArrowDown} alt="down" className="icon-arrow-down" />
							</div>
						</div>
					</button>
					<div className="dropdown-menu w-100 org-dropdown-menu py-0" aria-labelledby="orgDropdown">
						<div
							className="org-dropdown-item row align-items-center mx-0 text-left px-3"
							onClick={handleAddNewOrganization}>
							<div className="col-lg-2 px-0">
								<img src={ImageAdd} alt="logo" className="img-fluid" />
							</div>
							<div className="col-lg-10">
								<h6 className="text-add-new">Add New Organization</h6>
							</div>
						</div>
						{organizationListing.map((organization, index) => {
							const { name, logo_url } = organization;

							return (
								<div
									key={`${index}-${name}`}
									className="org-dropdown-item row align-items-center mx-0 text-left p-3"
									onClick={() => handleSelectOrganization(organization)}>
									<div className="col-3 pl-0 text-center">
										<img
											src={logo_url || IconDefaultUser}
											alt="logo"
											className="img-fluid img-org-logo"
										/>
									</div>
									<div className="col-9 pl-2">
										<h6 className="org-name" title={name}>
											{name}
										</h6>
										<span className="org-desc">Organization</span>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</section>

			<nav className="pr-3">
				<ul className="nav flex-column">
					<NavLink icon={ImageDashboard} title="Dashboard" link={"/dashboard"} />
					<NavLink icon={ImageProfileSettings} title="Profile & Settings" link={"/organization/profile"} />
					<NavLink icon={ImageEvents} title="Events" link={"/events"} />
					<NavLink
						icon={ImageVisitor}
						title="Visitor Database"
						link={"/visitors"}
						rightIcon={
							visitorUsage && visitorUsage.available <= 0 && visitorUsage.limit !== -1
								? IconWarningTriangle
								: null
						}
					/>
					<NavLink icon={ImageAccounts} title="Accounts" link={"/accounts"} />
					{/* TODO: reopen in next code drop */}
					{/* <NavLink icon={ImageBillings} title="Billings" link={"/billings"} /> */}
				</ul>
			</nav>

			<section className="event-organizer pr-3">
				<div className="d-flex align-items-center" onClick={openProfileModal}>
					<img src={userProfileImage || IconDefaultUser} alt="logo" className="img-fluid mr-2" />
					<div>
						{/* <div className="organizer-name">{userProfile ? userProfile[0].business_name : "Organizer"}</div> */}
						<span className="organizer-desc">{userProfileName || "Event Organizer"} (me)</span>
					</div>
				</div>
			</section>

			<ProfileModal />
		</div>
	);
};

export default withRouter(Sidebar);
