/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import { commonActions } from "app/states/common";
import { useDispatch } from "react-redux";
import SessionStorageService from "app/services/sessionStorageService";
import IconCalendar from "app/assets/icon-calendar.png";
import IconArrowDown from "app/assets/icon-arrow-down.png";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatCurrencyNumbers } from "app/helper/common";
import EventInfo from "app/views/components/EventInfo/EventInfo";
import OrdersAPI from "app/apis/orders";
import { DELIVERY_METHODS, ORDER_STATUS } from "app/config/constants";

const MAX_RECORDS = 10;

const StoreTransactionListing = ({ history }) => {
	const { eventId } = useParams();
	const lastViewingPage = SessionStorageService.getLastViewingPage();

	const dispatch = useDispatch();
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());

	const [showDatePicker, setShowDatePicker] = useState(false);
	const [selectedStartDate, setSelectedStartDate] = useState(new Date());
	const [selectedEndDate, setSelectedEndDate] = useState(null);

	const [totalSuccess, setTotalSuccess] = useState(0);
	const [totalFailed, setTotalFailed] = useState(0);
	const [amountCollected, setAmountCollected] = useState(0);
	const [amountProfited, setAmountProfited] = useState(0);
	const [currency, setCurrency] = useState("USD");

	const [searchText, setSearchText] = useState("");
	const [page, setPage] = useState(lastViewingPage || 1);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [orderListing, setOrderListing] = useState([]);
	const [loadedOrderListing, setLoadedOrderListing] = useState(false);

	// Get order summary
	useEffect(() => {
		let params = {
			event_id: eventId,
			created_at: moment(selectedStartDate).toISOString(),
		};

		if (selectedEndDate) {
			params["created_at"] = `${moment(selectedStartDate).toISOString()},${moment(
				selectedEndDate
			).toISOString()}`;
		}

		OrdersAPI.getOrderSummary(params)
			.then((response) => {
				const { total_failed, total_success, amount_collected, amount_profited, currency } = response.data;

				setTotalSuccess(total_success);
				setTotalFailed(total_failed);
				setAmountCollected(amount_collected);
				setAmountProfited(amount_profited);
				setCurrency(currency);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [selectedStartDate, selectedEndDate]);

	// Get order listing
	useEffect(() => {
		let params = {
			page: page,
			limit: MAX_RECORDS,
			event_id: eventId,
		};

		getOrderListing(params);
	}, [page]);

	// Search order listing
	useEffect(() => {
		if (!loadedOrderListing) return;

		const timeout = setTimeout(() => {
			let params = {
				page: page,
				limit: MAX_RECORDS,
				event_id: eventId,
			};

			if (searchText) {
				params["search"] = searchText;
			}

			getOrderListing(params);
		}, 500);

		return () => clearTimeout(timeout);
	}, [searchText]);

	const getOrderListing = (params) => {
		if (selectedEndDate) {
			params["created_at"] = `${moment(selectedStartDate).toISOString()},${moment(
				selectedEndDate
			).toISOString()}`;
		} else {
			params["created_at"] = moment(selectedStartDate).toISOString();
		}

		showLoading();
		OrdersAPI.getOrderListing(params)
			.then((response) => {
				const { event_exhibitor_room_store_products, paging } = response.data;
				const { last_page } = paging;

				setTotalPageCount(last_page);
				setOrderListing(event_exhibitor_room_store_products);
				setLoadedOrderListing(true);
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	const handleViewDetails = (transactionId) => {
		SessionStorageService.setLastViewingPage(page);
		history.push(`/events/view/${eventId}/store-transactions/view/${transactionId}`);
	};

	const handleSelectDate = (dates) => {
		const [start, end] = dates;
		setSelectedStartDate(start);
		setSelectedEndDate(end);
	};

	return (
		<EventLayoutContainer>
			<div id="store-transaction-listing-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-5">
					<div className="d-flex align-items-center">
						<div>
							<h2 className="title">Store Transaction Listing</h2>
							<span className="subtitle">Store Transactions</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="datepicker-section mb-5">
					<div className="dropdown dropdown-selection">
						<button
							className="btn-dropdown-toggle"
							type="button"
							id="calendar_dropdown"
							style={selectedEndDate ? { minWidth: "434px" } : {}}
							onClick={() => setShowDatePicker(!showDatePicker)}>
							<div className="d-flex align-items-center mx-0 text-left px-3 py-2">
								<div className="col-lg-2 px-0 text-center">
									<img src={IconCalendar} alt="calendar" className="icon-calendar" />
								</div>
								<div className="col-lg-9">
									<h6 className="dropdown-name mb-0">
										{moment(selectedStartDate).isSame(new Date(), "day") && "Today, "}
										{moment(selectedStartDate).format("Do MMMM YYYY")}

										{selectedEndDate && (
											<>
												<span className="mx-2">-</span>
												{moment(selectedEndDate).isSame(new Date(), "day") && "Today, "}
												{moment(selectedEndDate).format("Do MMMM YYYY")}
											</>
										)}
									</h6>
								</div>
								<div className="col-1 pl-0">
									<img src={IconArrowDown} alt="down" className="icon-arrow-down" />
								</div>
							</div>
						</button>
						{showDatePicker && (
							<>
								<div
									className="custom-selection-wrapper-bgOverlay"
									onClick={() => setShowDatePicker(false)}></div>
								<div className="calendar-dropdown">
									<DatePicker
										selected={selectedStartDate}
										startDate={selectedStartDate}
										endDate={selectedEndDate}
										onChange={handleSelectDate}
										dateFormat="dd MMMM yyyy"
										inline
										selectsRange
									/>
								</div>
							</>
						)}
					</div>
				</section>

				<section className="transaction-summary-section mb-5">
					<h3>
						View Transactions{" "}
						<span className="summary-date ml-2">
							({moment(selectedStartDate).isSame(new Date(), "day") && "Today, "}{" "}
							{moment(selectedStartDate).format("Do MMMM YYYY")}
							{selectedEndDate && (
								<>
									<span className="mx-2">-</span>
									{moment(selectedEndDate).isSame(new Date(), "day") && "Today, "}
									{moment(selectedEndDate).format("Do MMMM YYYY")}
								</>
							)}
							)
						</span>
					</h3>
					<div className="row">
						<div className="col-3">
							<div className="summary-card">
								<div className="title">Successful Purchases</div>
								<div className="desc">Number of successfully checkouts</div>
								<div className="successful-purchase-count">{totalSuccess}</div>
							</div>
						</div>
						<div className="col-3">
							<div className="summary-card">
								<div className="title">Failed Purchases</div>
								<div className="desc">Number of failed checkouts</div>
								<div className="failed-purchase-count">{totalFailed}</div>
							</div>
						</div>
						<div className="col-3">
							<div className="summary-card">
								<div className="title">Amount Collected</div>
								<div className="desc">Total Amount Collected on Behalf of Exhibitors</div>
								<div className="number-values">
									<span className="currency">{currency === "-" ? "" : currency}</span>
									<span className="amount">{formatCurrencyNumbers(amountCollected)}</span>
								</div>
							</div>
						</div>
						<div className="col-3">
							<div className="summary-card">
								<div className="title">Profit Generated</div>
								<div className="desc">Profited Amount from Exhibitors’ Sales</div>
								<div className="number-values">
									<span className="currency">{currency === "-" ? "" : currency}</span>
									<span className="amount">{formatCurrencyNumbers(amountProfited)}</span>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="transaction-listing-section">
					<h3>View Transactions</h3>

					<div className="form-group">
						<input
							type="text"
							className="form-control"
							name="search"
							placeholder="Search"
							value={searchText}
							onChange={(event) => setSearchText(event.target.value)}
						/>
					</div>

					<table className="table table-hover">
						<thead>
							<tr>
								<th scope="col" className="table-header">
									Order Number
								</th>
								<th scope="col" className="table-header">
									Order Status
								</th>
								<th scope="col" className="table-header">
									Customer Name
								</th>
								<th scope="col" className="table-header">
									Email Address
								</th>
								<th scope="col" className="table-header">
									Exhibitor Name
								</th>
								<th scope="col" className="table-header">
									Delivery Method
								</th>
								<th scope="col" className="table-header">
									Total Purchase Amount
								</th>
							</tr>
						</thead>
						<tbody>
							{orderListing.map((order) => {
								const {
									id,
									order_no,
									status,
									customer_name,
									email,
									exhibitor_name,
									delivery_method,
									currency,
									amount,
								} = order;

								return (
									<tr key={`order-${id}`} onClick={() => handleViewDetails(id)} className="cursor-pointer">
										<td className="table-cell">
											<span className="order-number-link cursor-pointer">
												{order_no ?? "N/A"}
											</span>
										</td>
										<td className="table-cell">
											<span className="status-in-progress">
												{status !== null && ORDER_STATUS[status] ? ORDER_STATUS[status] : "N/A"}
											</span>
										</td>
										<td className="table-cell">{customer_name ?? "N/A"}</td>
										<td className="table-cell">{email ?? "N/A"}</td>
										<td className="table-cell">{exhibitor_name ?? "N/A"}</td>
										<td className="table-cell">
											{delivery_method !== null && DELIVERY_METHODS[delivery_method]
												? DELIVERY_METHODS[delivery_method]
												: "N/A"}
										</td>
										<td className="table-cell">
											{currency && amount ? `${currency}${amount}` : "N/A"}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					<div className="d-flex align-items-center justify-content-center w-100">
						<AppPagination
							pageCount={totalPageCount}
							handlePageChange={handlePageChange}
							initialPage={page - 1}
						/>
					</div>
				</section>
			</div>
		</EventLayoutContainer>
	);
};

export default withRouter(StoreTransactionListing);
