export const OPEN_UPGRADE_MONTHLY_PLAN_CONFIRMATION_MODAL = "billings/OPEN_UPGRADE_MONTHLY_PLAN_CONFIRMATION_MODAL";
export const CLOSE_UPGRADE_MONTHLY_PLAN_CONFIRMATION_MODAL = "billings/CLOSE_UPGRADE_MONTHLY_PLAN_CONFIRMATION_MODAL";

export const OPEN_UPGRADE_ANNUAL_PLAN_CONFIRMATION_MODAL = "billings/OPEN_UPGRADE_ANNUAL_PLAN_CONFIRMATION_MODAL";
export const CLOSE_UPGRADE_ANNUAL_PLAN_CONFIRMATION_MODAL = "billings/CLOSE_UPGRADE_ANNUAL_PLAN_CONFIRMATION_MODAL";

export const OPEN_PAYMENT_SUCCESS_MODAL = "billings/OPEN_PAYMENT_SUCCESS_MODAL";
export const CLOSE_PAYMENT_SUCCESS_MODAL = "billings/CLOSE_PAYMENT_SUCCESS_MODAL";

export const SET_CHOSEN_PLAN = "billings/SET_CHOSEN_PLAN";
export const SET_CURRENT_PLAN = "billings/SET_CURRENT_PLAN";

export const SET_SELECTED_PRICING_MODE = "billings/SET_SELECTED_PRICING_MODE";

export const REFRESH_BILLING_DASHBOARD = "billings/REFRESH_BILLING_DASHBOARD";

export const SET_IS_UPGRADING_PLAN = "billings/SET_IS_UPGRADING_PLAN";

export const OPEN_CONFIRM_PURCHASE_PLAN_MODAL = "billings/OPEN_PURCHASE_PLAN_MODAL";
export const CLOSE_CONFIRM_PURCHASE_PLAN_MODAL = "billings/CLOSE_PURCHASE_PLAN_MODAL";
export const SET_CONFIRM_PURCHASE_PRICE = "billings/SET_CONFIRM_PURCHASE_PRICE";

export const OPEN_UPGRADE_ENTERPRISE_PLAN_CONFIRMATION_MODAL = "billings/OPEN_UPGRADE_ENTERPRISE_PLAN_CONFIRMATION_MODAL";
export const CLOSE_UPGRADE_ENTERPRISE_PLAN_CONFIRMATION_MODAL = "billings/CLOSE_UPGRADE_ENTERPRISE_PLAN_CONFIRMATION_MODAL";

export const OPEN_ENTERPRISE_PLAN_REACHOUT_SUCCESS_MODAL = "billings/OPEN_ENTERPRISE_PLAN_REACHOUT_SUCCESS_MODAL";
export const CLOSE_ENTERPRISE_PLAN_REACHOUT_SUCCESS_MODAL = "billings/CLOSE_ENTERPRISE_PLAN_REACHOUT_SUCCESS_MODAL";

export const SET_PENDING_SUBSCRIPTION = "billings/SET_PENDING_SUBSCRIPTION";

export const REFRESH_CURRENT_SUBSCRIPTION_PLAN = "billings/REFRESH_CURRENT_SUBSCRIPTION_PLAN";

export const OPEN_REACTIVATE_PLAN_MODAL = "billings/OPEN_REACTIVATE_PLAN_MODAL";
export const CLOSE_REACTIVATE_PLAN_MODAL = "billings/CLOSE_REACTIVATE_PLAN_MODAL";

export const OPEN_DOWNGRADE_CONFIRMATION_MODAL = "billings/OPEN_DOWNGRADE_CONFIRMATION_MODAL";
export const CLOSE_DOWNGRADE_CONFIRMATION_MODAL = "billings/CLOSE_DOWNGRADE_CONFIRMATION_MODAL";