import React from "react";
import ImgSplash from "app/assets/mobile/admin-splash-image.png";
import ImgLogo from "app/assets/mobile/boom-logo-v2-blue.png";
import "./style.scss";

const MobileWarning = ({ history }) => {
	return (
		<div id="mobile-warning-page" className="d-flex flex-column justify-content-center align-items-center">
			<img src={ImgSplash} alt="splash" className="img-fluid mb-4" />

			<div className="warning-text-container px-4">
				<p className="text-sorry mb-1">Sorry!</p>
				<p className="text-desc">Organiser dashboard can only be accessed from a desktop.</p>
			</div>

			<img src={ImgLogo} alt="boom" className="img-logo" />
		</div>
	);
};

export default MobileWarning;
