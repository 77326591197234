import * as types from "./types";

/**
 * Create channel modal
 */
export const openCreateChannelModal = () => ({
	type: types.OPEN_CREATE_CHANNEL_MODAL,
});

export const closeCreateChannelModal = () => ({
	type: types.CLOSE_CREATE_CHANNEL_MODAL,
});

/**
 * QnA
 */
export const openCreateQnaModal = () => ({
	type: types.OPEN_CREATE_QNA_MODAL,
});

export const closeCreateQnaModal = () => ({
	type: types.CLOSE_CREATE_QNA_MODAL,
});

export const refreshQnaListing = (refresh) => ({
	type: types.REFRESH_QNA_LISTING,
	params: {
		refresh,
	},
});

export const openEditQnaModal = (questionId) => ({
	type: types.OPEN_EDIT_QNA_MODAL,
	params: {
		questionId,
	},
});

export const closeEditQnaModal = () => ({
	type: types.CLOSE_EDIT_QNA_MODAL,
});

/**
 * Poll
 */
export const openCreatePollModal = () => ({
	type: types.OPEN_CREATE_POLL_MODAL,
});

export const closeCreatePollModal = () => ({
	type: types.CLOSE_CREATE_POLL_MODAL,
});

export const refreshPollListing = (refresh) => ({
	type: types.REFRESH_POLL_LISTING,
	params: {
		refresh,
	},
});

export const openEditPollModal = (pollId) => ({
	type: types.OPEN_EDIT_POLL_MODAL,
	params: {
		pollId,
	},
});

export const closeEditPollModal = () => ({
	type: types.CLOSE_EDIT_POLL_MODAL,
});

/**
 * Create exhibitor category modal
 */
export const openCreateExhibitorCategoryModal = () => ({
	type: types.OPEN_CREATE_EXHIBITOR_CATEGORY_MODAL,
});

export const closeCreateExhibitorCategoryModal = () => ({
	type: types.CLOSE_CREATE_EXHIBITOR_CATEGORY_MODAL,
});

export const refreshExhibitorCategoryListing = (refresh) => ({
	type: types.REFRESH_EXHIBITOR_CATEGORY_LISTING,
	params: {
		refresh,
	},
});

export const openViewExhibitorCategoryModal = (exhibitorCategoryId) => ({
	type: types.OPEN_VIEW_EXHIBITOR_CATEGORY_MODAL,
	params: {
		exhibitorCategoryId,
	},
});

export const closeViewExhibitorCategoryModal = () => ({
	type: types.CLOSE_VIEW_EXHIBITOR_CATEGORY_MODAL,
});

export const refreshExhibitorRoomDetails = (refresh) => ({
	type: types.REFRESH_EXHIBITOR_ROOM_DETAILS,
	params: {
		refresh,
	},
});

/**
 * Set event details
 */
export const setEventDetails = (eventDetails) => ({
	type: types.SET_EVENT_DETAILS,
	params: {
		eventDetails,
	},
});

export const refreshEventDetails = () => ({
	type: types.REFRESH_EVENT_DETAILS,
});

/**
 * Selected channel for session sidebar
 */
export const setSelectedChannelForSessionSidebar = (selectedChannel) => ({
	type: types.SET_SELECTED_CHANNEL_FOR_SESSION_SIDEBAR,
	params: {
		selectedChannel,
	},
});

export const setSelectedDateForSessionSidebar = (selectedDate) => ({
	type: types.SET_SELECTED_DATE_FOR_SESSION_SIDEBAR,
	params: {
		selectedDate,
	},
});

/**
 * Landing page editor - speaker modal
 */
export const openAddSpeakerModal = () => ({
	type: types.OPEN_ADD_SPEAKER_MODAL,
});

export const closeAddSpeakerModal = () => ({
	type: types.CLOSE_ADD_SPEAKER_MODAL,
});

export const openEditSpeakerModal = () => ({
	type: types.OPEN_EDIT_SPEAKER_MODAL,
});

export const closeEditSpeakerModal = () => ({
	type: types.CLOSE_EDIT_SPEAKER_MODAL,
});

export const setViewingSpeaker = (speaker) => ({
	type: types.SET_VIEWING_SPEAKER,
	params: {
		speaker,
	},
});

export const refreshSpeakerList = () => ({
	type: types.REFRESH_SPEAKER_LIST,
});

/**
 * Landing page editor - event highlights modal
 */
export const openAddEventHighlightsModal = () => ({
	type: types.OPEN_ADD_EVENT_HIGHLIGHTS_MODAL,
});

export const closeAddEventHighlightsModal = () => ({
	type: types.CLOSE_ADD_EVENT_HIGHLIGHTS_MODAL,
});

export const openEditEventHighlightsModal = () => ({
	type: types.OPEN_EDIT_EVENT_HIGHLIGHTS_MODAL,
});

export const closeEditEventHighlightsModal = () => ({
	type: types.CLOSE_EDIT_EVENT_HIGHLIGHTS_MODAL,
});

export const setViewingEventHighlights = (eventHighlights) => ({
	type: types.SET_VIEWING_EVENT_HIGHLIGHTS,
	params: {
		eventHighlights,
	},
});

export const refreshEventHighlightsList = () => ({
	type: types.REFRESH_EVENT_HIGHLIGHTS_LIST,
});

/**
 * Landing page editor - event partner modal
 */
export const openAddEventPartnerModal = () => ({
	type: types.OPEN_ADD_EVENT_PARTNER_MODAL,
});

export const closeAddEventPartnerModal = () => ({
	type: types.CLOSE_ADD_EVENT_PARTNER_MODAL,
});

export const openEditEventPartnerModal = () => ({
	type: types.OPEN_EDIT_EVENT_PARTNER_MODAL,
});

export const closeEditEventPartnerModal = () => ({
	type: types.CLOSE_EDIT_EVENT_PARTNER_MODAL,
});

export const setViewingEventPartner = (eventPartner) => ({
	type: types.SET_VIEWING_EVENT_PARTNER,
	params: {
		eventPartner,
	},
});
export const refreshEventPartnerList = () => ({
	type: types.REFRESH_EVENT_PARTNERS_LIST,
});

export const setCurrentEventPartnerKey = (eventPartnerKey) => ({
	type: types.SET_CURRENT_EVENT_PARTNER_KEY,
	params: {
		eventPartnerKey,
	},
});

/**
 * Publish event modal
 */
export const setIsEventPublished = (isEventPublished) => ({
	type: types.SET_IS_EVENT_PUBLISHED,
	params: {
		isEventPublished,
	},
});

export const setEventPublishAction = (action) => ({
	type: types.SET_EVENT_PUBLISH_ACTION,
	params: {
		action,
	},
});

export const setCurrentEventStatus = (status) => ({
	type: types.SET_CURRENT_EVENT_STATUS,
	params: {
		status,
	},
});

export const openPublishEventModal = () => ({
	type: types.OPEN_PUBLISH_EVENT_MODAL,
});

export const closePublishEventModal = () => ({
	type: types.CLOSE_PUBLISH_EVENT_MODAL,
});

export const openPauseEndEventModal = () => ({
	type: types.OPEN_PAUSE_END_EVENT_MODAL,
});

export const closePauseEndEventModal = () => ({
	type: types.CLOSE_PAUSE_END_EVENT_MODAL,
});

export const openRepublishEventModal = () => ({
	type: types.OPEN_REPUBLISH_EVENT_MODAL,
});

export const closeRepublishEventModal = () => ({
	type: types.CLOSE_REPUBLISH_EVENT_MODAL,
});

/**
 * Session control create channel modal
 */
export const openSessionControlCreateChannelModal = () => ({
	type: types.OPEN_SESSION_CONTROL_CREATE_CHANNEL_MODAL,
});

export const closeSessionControlCreateChannelModal = () => ({
	type: types.CLOSE_SESSION_CONTROL_CREATE_CHANNEL_MODAL,
});

/**
 * Refresh session listing in sidebar
 */
 export const refreshSessionControlListingCount = () => ({
	type: types.REFRESH_SESSION_CONTROL_LISTING_COUNT,
});