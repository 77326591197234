import apiAction from "../services/apiAction"

const GamificationAPI = {

    /**
     * Get gamification listing
     * @param {object} queryParams 
     * {event_id, page, limit}
     */
    getGamificationListing: (queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/gamifications`;
            
            apiAction.get(url, queryParams)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get gamification details
     * @param {int} eventId
     * @param {int} visitor_id
     */
    getGamificationDetails: (eventId, visitor_id) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/${eventId}/gamifications/detail/${visitor_id}`;

            apiAction.get(url)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get gamification configurations
     * @param {int} eventId
     */
    getGamificationConfigurations: (eventId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/${eventId}/gamifications/config`;

            apiAction.get(url)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Update gamification configurations
     * @param {int} eventId
     * @param {object} data
     */
    postUpdateGamificationConfigurations: (eventId, data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/${eventId}/gamifications/config`;

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },
};

export default GamificationAPI;
