/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams, Link } from "react-router-dom";
import "./style.scss";
import AdsManagerAPI from "app/apis/adsManager";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import IconBack from "app/assets/icon-back-grey.png";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import SessionStorageService from "app/services/sessionStorageService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import IconWarningTriangle from "app/assets/icon-alert-triangle-yellow.png";
import { useSelector } from "react-redux";
import EventInfo from "app/views/components/EventInfo/EventInfo";
import UsageAPI from "app/apis/usage";

const MAX_TABLE_ROWS = 20;

const AdsSummary = ({ history }) => {
	const { eventId, adsTypeId } = useParams();
	const lastViewingPage = SessionStorageService.getLastViewingPage();

	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);

	const [viewingAdsTypesName, setViewingAdsTypesName] = useState("");
	const [viewingAdsTypeCode, setViewingAdsTypesCode] = useState("");
	const [adsSummary, setAdsSummary] = useState(null);

	const [loadedAdsListing, setLoadedAdsListing] = useState(false);
	const [adsListing, setAdsListing] = useState([]);
	const [page, setPage] = useState(lastViewingPage || 1);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [searchText, setSearchText] = useState("");

	const [sortBy, setSortBy] = useState("");
	const [sortOrdering, setSortOrdering] = useState({
		name: "asc",
		ad_owner: "asc",
	});

	const [advertisementUsage, setAdvertisementUsage] = useState(null);

	// Get ads summary
	useEffect(() => {
		let params = {
			ads_type_id: parseInt(adsTypeId),
		};

		AdsManagerAPI.getAdsSummary(params)
			.then((response) => {
				const { ad_type_name, ad_type_code } = response.data[0];
				setAdsSummary(response.data[0]);
				setViewingAdsTypesName(ad_type_name);
				setViewingAdsTypesCode(ad_type_code);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	// Get ads listing
	useEffect(() => {
		let params = {
			ads_type_id: parseInt(adsTypeId),
			page: page,
			limit: MAX_TABLE_ROWS,
		};

		if (searchText) {
			params["search_string"] = searchText;
		}

		if (sortBy) {
			params["sort_by"] = sortBy;
			params["sort_order"] = sortOrdering[sortBy];
		}

		AdsManagerAPI.getAdsListing(params)
			.then((response) => {
				const { advertisments, paging } = response.data;
				const { last_page } = paging;

				setAdsListing(advertisments);
				setTotalPageCount(last_page);
				setLoadedAdsListing(true);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [page]);

	// Search ad listing
	useEffect(() => {
		if (!loadedAdsListing) return;

		const timeout = setTimeout(() => {
			let params = {
				ads_type_id: parseInt(adsTypeId),
				page: 1,
				limit: MAX_TABLE_ROWS,
				search_string: searchText,
			};

			if (sortBy) {
				params["sort_by"] = sortBy;
				params["sort_order"] = sortOrdering[sortBy];
			}

			AdsManagerAPI.getAdsListing(params)
				.then((response) => {
					const { advertisments, paging } = response.data;
					const { last_page } = paging;

					setAdsListing(advertisments);
					setTotalPageCount(last_page);
				})
				.catch((error) => {
					console.error(error);
				});
		}, 500);

		return () => clearTimeout(timeout);
	}, [searchText]);

	// Get session usage limit
	useEffect(() => {
		UsageAPI.getAdvertisementUsage(selectedOrganizationAccount.id)
			.then((response) => {
				setAdvertisementUsage(response);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	const handleViewDetails = (ad_id) => {
		SessionStorageService.setLastViewingPage(page);
		history.push(`/events/view/${eventId}/ads/type/${adsTypeId}/view/${ad_id}`);
	};

	const handleOnChangeSearchText = (event) => {
		let text = event.target.value;
		setSearchText(text);
	};

	const handleSorting = (sortByColumn) => {
		let sortOrder = "asc";

		sortOrder = sortOrdering[sortByColumn] === "asc" ? "desc" : "asc";

		setSortOrdering({
			...sortOrdering,
			[sortByColumn]: sortOrder,
		});

		setSortBy(sortByColumn);

		let params = {
			ads_type_id: parseInt(adsTypeId),
			page: 1,
			limit: MAX_TABLE_ROWS,
			sort_by: sortByColumn,
			sort_order: sortOrder,
		};

		if (searchText) {
			params["search_string"] = searchText;
		}

		AdsManagerAPI.getAdsListing(params)
			.then((response) => {
				const { advertisments, paging } = response.data;
				const { last_page } = paging;

				setAdsListing(advertisments);
				setTotalPageCount(last_page);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<EventLayoutContainer>
			<div id="ads-summary-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-5">
					<div className="d-flex align-items-center">
						<div className="btn-back" onClick={() => history.goBack()}>
							<img src={IconBack} alt="back" className="img-fluid" />
						</div>
						<div>
							<h2 className="title">Manage {viewingAdsTypesName}</h2>
							<span className="subtitle">
								Ads Manager &gt; {`${viewingAdsTypesName} >`} View/Manage Ads
							</span>
						</div>
					</div>
					<EventInfo />
				</section>

				{adsSummary && (
					<section className="ads-summary-section px-5 py-3 mb-4">
						<h3>Ads Summary</h3>
						<div className="d-flex">
							<div className="ads-summary-card">
								<h4 className="ads-summary-card-title">Total Active Ads</h4>
								<div className="ads-summary-card-subtitle mb-3">
									Ads that are currently visible to visitors
								</div>
								<h1 className="active-ads-number">{adsSummary.total_active_ads}</h1>
							</div>
							<div className="ads-summary-card">
								<h4 className="ads-summary-card-title">Total Inactive Ads</h4>
								<div className="ads-summary-card-subtitle mb-3">
									Inactive ads that are no longer published
								</div>
								<h1 className="inactive-ads-number">{adsSummary.total_inactive_ads}</h1>
							</div>
							<div className="ads-summary-card">
								<h4 className="ads-summary-card-title mb-4">Ad Settings</h4>
								<div className="row w-100">
									<div className="col-7">
										<span className="setting-name">Max Ad Slots</span>
									</div>
									<div className="col-5 text-center">
										<span className="setting-value">{adsSummary.max_ad_slot}</span>
									</div>
								</div>
								<div className="row w-100">
									<div className="col-7">
										<span className="setting-name">Duration per Ad</span>
									</div>
									<div className="col-5 text-center">
										<span className="setting-value">
											{adsSummary.duration_per_ad_in_seconds} seconds
										</span>
									</div>
								</div>
							</div>
						</div>
					</section>
				)}

				<section className="ads-listing-section px-5 py-3 mb-4">
					<h3>Ad Listing</h3>
					<div className="d-flex justify-content-between align-items-center mb-3">
						<input
							name="search"
							placeholder="Search"
							value={searchText}
							onChange={handleOnChangeSearchText}
							className="form-control input-search"
						/>
						<button
							type="button"
							className="btn btn-primary btn-add-ad"
							disabled={advertisementUsage && advertisementUsage.available <= 0 && advertisementUsage.limit !== -1 ? true : false}
							onClick={() =>
								history.push(`/events/view/${eventId}/ads/type/${adsTypeId}/create`, {
									adsTypeName: viewingAdsTypesName,
									adsTypeCode: viewingAdsTypeCode,
								})
							}>
							Add Ads
						</button>
					</div>

					{advertisementUsage && advertisementUsage.available <= 0 && advertisementUsage.limit !== -1 && (
						<section className="entitlement-warning-container mt-3 mb-3">
							<img src={IconWarningTriangle} alt="warning" className="icon-entitlement-warning" />
							<div className="text-entitlement-warning">
								Ads limit reached! Upgrade your plan now to create more ads.
							</div>
							<Link className="text-manage-subscription" to="/dashboard">
								Manage Subscription
							</Link>
						</section>
					)}

					<div className="ads-listing-container">
						<table className="table table-hover">
							<thead>
								<tr>
									<th scope="col" className="table-header">
										Ad Banner
									</th>
									<th
										scope="col"
										className="table-header cursor-pointer"
										onClick={() => handleSorting("name")}>
										Ad Name{" "}
										<FontAwesomeIcon
											icon={sortOrdering["name"] === "desc" ? faChevronDown : faChevronUp}
										/>
									</th>
									<th
										scope="col"
										className="table-header cursor-pointer"
										onClick={() => handleSorting("ad_owner")}>
										Ad Owner (Account Name){" "}
										<FontAwesomeIcon
											icon={sortOrdering["ad_owner"] === "desc" ? faChevronDown : faChevronUp}
										/>
									</th>
									<th scope="col" className="table-header">
										Status
									</th>
								</tr>
							</thead>
							<tbody>
								{adsListing.map((advertisement) => {
									const { ad_id, name, ad_banner, ad_owner, status } = advertisement;

									let statusClass = status === 0 ? "text-inactive-ads" : "text-active-ads";
									let statusText = status === 0 ? "Inactive" : "Active";

									return (
										<tr
											key={ad_id}
											onClick={() => handleViewDetails(ad_id)}
											className="cursor-pointer">
											<td className="table-cell">
												<img src={ad_banner} alt={name} className="ad-thumbnail" />
											</td>
											<td className="table-cell">{name}</td>
											<td className="table-cell">{ad_owner}</td>
											<td className={`table-cell ${statusClass}`}>{statusText}</td>
										</tr>
									);
								})}
							</tbody>
						</table>

						<div className="d-flex align-items-center justify-content-center w-100">
							<AppPagination
								pageCount={totalPageCount}
								handlePageChange={handlePageChange}
								initialPage={page - 1}
							/>
						</div>
					</div>
				</section>
			</div>
		</EventLayoutContainer>
	);
};

export default withRouter(AdsSummary);
