import React from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/states/common";

const CustomModal = ({
	icon,
	subIcon,
	modalSubIconClass,
	title,
	desc,
	btnClass,
	textPrimary,
	textSecondary,
	onClickPrimary,
	onClickSecondary,
}) => {
	const dispatch = useDispatch();
	const closeCustomModal = () => dispatch(commonActions.closeCustomModal());

	const showCustomModal = useSelector(({ common }) => common.showCustomModal);

	return (
		<Modal
			show={showCustomModal}
			onHide={closeCustomModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="custom-modal"
			centered>
			<Modal.Body>
				<div className="custom-modal-body">
					{icon && (
						<div className="modal-icon-wrapper">
							<img src={icon} alt="icon" className="modal-icon" />
							{subIcon && 
							
							<img src={subIcon} alt="sub-icon" className={modalSubIconClass} />}
						</div>
					)}
					{title && <h4 className="modal-title">{title}</h4>}
					{desc && <p className="modal-desc">{desc}</p>}

					{textPrimary && onClickPrimary && (
						<button type="button" className={`btn ${btnClass} mb-3 px-4`} onClick={onClickPrimary}>
							{textPrimary}
						</button>
					)}

					{textSecondary && onClickSecondary && (
						<button type="button" className="btn btn-cancel px-4" onClick={onClickSecondary}>
							{textSecondary}
						</button>
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default CustomModal;
