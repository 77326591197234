/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import LayoutContainer from "../../../containers/LayoutContainer/LayoutContainer";
import "./style.scss";
import ImageAdd from "app/assets/icon-add-grey.png";
import { withRouter } from "react-router-dom";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import EventAPI from "app/apis/event";
import moment from "moment";
import { EVENT_STATUS } from "app/config/constants";
import { useSelector } from 'react-redux';

const MAX_TABLE_ROWS = 10;

const Events = ({ history }) => {
	const [recentEvents, setRecentEvents] = useState([]);
	const [events, setEvents] = useState([]);
	const [page, setPage] = useState(1);
	const [totalPageCount, setTotalPageCount] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);

	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);

	// First time get event listing
	useEffect(() => {
		if (!selectedOrganizationAccount) return;

		let params = {
			page: 1,
			limit: MAX_TABLE_ROWS,
			sort_order: "desc",
			organization_id: selectedOrganizationAccount.id,
		};

		EventAPI.getEventListing(params)
			.then((response) => {
				const { events, paging } = response.data;

				// Set events
				const first5RecentEvents = events.slice(0, 5);
				setRecentEvents(first5RecentEvents);
				setEvents(events);

				// Set paging
				const { records_total, last_page } = paging;
				setTotalRecords(records_total);
				setTotalPageCount(last_page);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [selectedOrganizationAccount]);

	// Handle event table pagination change
	useEffect(() => {
		if (totalRecords <= 0) return;

		let params = {
			page: page,
			limit: MAX_TABLE_ROWS,
			sort_order: "desc",
			organization_id: selectedOrganizationAccount.id,
		};

		EventAPI.getEventListing(params)
			.then((response) => {
				const { events } = response.data;
				setEvents(events);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [page]);

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	const goToEventDetailPage = (eventObj) => {
		const { id } = eventObj;
		history.push(`/events/view/${id}`);
	};

	return (
		<LayoutContainer>
			<div id="event-page">
				<section className="page-header">
					<h2 className="title">Events</h2>
					<span className="subtitle">List of events</span>
				</section>

				<section className="recent-events p-4">
					<h6 className="section-title">Recent Events</h6>
					<div className="row">
						<div className="col-3 mb-3">
							<div className="create-new-event" onClick={() => history.push("/events/add")}>
								<img src={ImageAdd} alt="event" className="img-fluid img-add" />
								<div className="text-create">Create New Event</div>
							</div>
						</div>
						{recentEvents.map((event) => {
							const { id, name, start_at, end_at, logo_url } = event;
							const startDate = moment(start_at).format("DD MMMM");
							const endDate = moment(end_at).format("DD MMMM");

							return (
								<div key={`recent-event-${id}`} className="col-3 mb-3">
									<div className="event-item" onClick={() => goToEventDetailPage(event)}>
										<div className="event-img-wrapper">
											<img src={logo_url} alt="event" className="img-fluid" />
										</div>
										<div className="event-name">{name}</div>
										<div className="event-date">
											{startDate} - {endDate}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</section>

				{totalRecords > 5 && (
					<section className="table-events p-4">
						<h6 className="table-listing-title">All Events</h6>
						<table className="table table-hover">
							<thead>
								<tr>
									<th scope="col" className="table-header">
										Event Name
									</th>
									<th scope="col" className="table-header">
										Date
									</th>
									<th scope="col" className="table-header">
										Time
									</th>
									<th scope="col" className="table-header">
										Status
									</th>
								</tr>
							</thead>
							<tbody>
								{events.map((event) => {
									const { id, name, start_at, end_at, event_status_code } = event;
									const startDate = moment(start_at).format("DD MMMM");
									const startTime = moment(start_at).format("h:mmA");
									const endDate = moment(end_at).format("DD MMMM");
									const endTime = moment(end_at).format("h:mmA");

									return (
										<tr
											key={`event-${id}`}
											onClick={() => goToEventDetailPage(event)}
											className="cursor-pointer">
											<td className="table-cell">{name}</td>
											<td className="table-cell">
												{startDate} - {endDate}
											</td>
											<td className="table-cell">
												{startTime} - {endTime}
											</td>
											<td className="table-cell">
												{event_status_code ? EVENT_STATUS[event_status_code]['display'] : "Draft"}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>

						<AppPagination pageCount={totalPageCount} handlePageChange={handlePageChange} />
					</section>
				)}
			</div>
		</LayoutContainer>
	);
};

export default withRouter(Events);
