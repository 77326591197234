import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import { ALPHABETS_LIST } from "app/config/alphabetList";
import { HashLink } from "react-router-hash-link";
import { useDispatch } from "react-redux";
import EventAPI from "app/apis/event";
import CreateChannelModal from "app/views/containers/CreateChannelModal/CreateChannelModal";
import { eventActions } from "app/states/event";
import EventInfo from "app/views/components/EventInfo/EventInfo";

const ChannelListing = ({ history }) => {
	const dispatch = useDispatch();
	const openCreateChannelModal = () => dispatch(eventActions.openCreateChannelModal());

	const { eventId } = useParams();
	
	const [searchText, setSearchText] = useState("");
	const [channels, setChannels] = useState([]);
	const [alphabets, setAlphabets] = useState([]);

	useEffect(() => {
		if (!eventId) return;

		let params = {
			limit: 750,
			event_id: eventId,
			sort_by: "name",
			sort_order: "asc"
		}

		if (searchText) {
			params["search_string"] = searchText;
		}

		EventAPI.getEventChannelListing(params)
			.then((response) => {
				const { event_channels } = response.data;

				// Set alphabets
				let alphabetsArray = [];
				event_channels.forEach((channel) => {
					const { name } = channel;
					const firstLetter = name.charAt(0).toUpperCase();
			
					if (!alphabetsArray.includes(firstLetter)) {
						alphabetsArray.push(firstLetter);
					}
				});

				alphabetsArray.sort();
				setAlphabets(alphabetsArray);
				setChannels(event_channels);
			})
			.catch((error) => {
				console.log(error);
			});

	}, [searchText, eventId]);

	const goToAlphabetSection = () => {
		history.push(`/events/view/${eventId}/channels`);
	};

	// Alphabet list component
	const AlphabetList = () => {
		return (
			<div className="alphabet-list">
				{ALPHABETS_LIST.map((alphabet) => {
					return (
						<HashLink key={alphabet} to={`#section-${alphabet}`} className="alphabet-letter" onClick={goToAlphabetSection}>
							{alphabet}
						</HashLink>
					);
				})}
			</div>
		);
	};

	return (
		<EventLayoutContainer>
			<div id="channel-listing-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-5">
					<div className="d-flex align-items-center">
						<div>
							<h2 className="title">Channel Room Listing</h2>
							<span className="subtitle">Channels &gt; Channel Room</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="p-3">
					<div className="row">
						<div className="col-10">
							<div className="form-group mb-0">
								<input
									type="text"
									className="form-control"
									name="search"
									placeholder="Search"
									value={searchText}
									onChange={(event) => setSearchText(event.target.value)}
								/>
							</div>
						</div>
						<div className="col-2">
							<button type="button" className="btn btn-primary h-100" onClick={openCreateChannelModal}>
								Add Channel
							</button>
						</div>
					</div>
				</section>

				<section id="channel-listing-container" className="p-3">
					<AlphabetList />

					{alphabets.map((alphabet) => {
						return (
							<section key={alphabet} id={`section-${alphabet}`} className="mb-3">
								<label className="alphabet-section-title">{alphabet}</label>
								<div id={`section-${alphabet}-channels`} className="row">
									{channels.map((channel) => {
										const { id, name, description, thumbnail_url } = channel;
										const firstLetter = name.charAt(0).toUpperCase();

										if (alphabet === firstLetter) {
											return (
												<div key={`${id}-${name}`} className="col-4 mb-3">
													<ChannelCard id={id} image={thumbnail_url} title={name} description={description} 
														onClick={() => history.push(`/events/view/${eventId}/channels/${id}`)}/>
												</div>
											);
										}

										return null;
									})}
								</div>
							</section>
						);
					})}
				</section>

				<CreateChannelModal />
			</div>
		</EventLayoutContainer>
	);
};

// Channel Card component
const ChannelCard = ({ image, title, description, onClick }) => {
	return (
		<div className="channel-card" onClick={onClick}>
			<div className="row align-items-center">
				<div className="col-3 pr-0">
					<img src={image} alt="channel" className="img-fluid" />
				</div>
				<div className="col-9">
					<div className="title">{title}</div>
					<div className="desc">{description}</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(ChannelListing);
