const LocalStorageService = {
	// Clear all
	clearAll: () => {
		localStorage.clear();
	},

	// User profile
	setUserProfile: (userProfile) => {
		localStorage.setItem("userProfile", JSON.stringify(userProfile));
	},

	getUserProfile: () => {
		const userProfile = localStorage.getItem("userProfile");
		return JSON.parse(userProfile);
	},

	clearUserProfile: () => {
		localStorage.removeItem("userProfile");
	},

	// Selected organization account
	setSelectedOrganizationAccount: (selectedOrganizationAccount) => {
		localStorage.setItem("selectedOrganizationAccount", JSON.stringify(selectedOrganizationAccount));
	},

	getSelectedOrganizationAccount: () => {
		const selectedOrganizationAccount = localStorage.getItem("selectedOrganizationAccount");
		return JSON.parse(selectedOrganizationAccount);
	},

	// Event details
	setEventDetails: (eventDetails) => {
		localStorage.setItem("eventDetails", JSON.stringify(eventDetails));
	},

	getEventDetails: () => {
		const eventDetails = localStorage.getItem("eventDetails");
		return JSON.parse(eventDetails);
	},

	// Exhibitor current room detail
	setExhibitorRoomDetails: (exhibitorRoomDetails) => {
		localStorage.setItem("exhibitorRoomDetails", JSON.stringify(exhibitorRoomDetails));
	},

	getExhibitorRoomDetails: () => {
		const exhibitorRoomDetails = localStorage.getItem("exhibitorRoomDetails");
		return JSON.parse(exhibitorRoomDetails);
	},

	// Current subscription plan
	setCurrentSubscriptionPlan: (subscriptionPlan) => {
		localStorage.setItem("currentSubscriptionPlan", JSON.stringify(subscriptionPlan));
	},

	getCurrentSubscriptionPlan: () => {
		const currentSubscriptionPlan = localStorage.getItem("currentSubscriptionPlan");
		return JSON.parse(currentSubscriptionPlan);
	},

	// Current subscription plan
	setPendingSubscriptionPlan: (subscriptionPlan) => {
		localStorage.setItem("pendingSubscriptionPlan", JSON.stringify(subscriptionPlan));
	},

	getPendingSubscriptionPlan: () => {
		const pendingSubscriptionPlan = localStorage.getItem("pendingSubscriptionPlan");
		return JSON.parse(pendingSubscriptionPlan);
	},

	removePendingSubscriptionPlan: () => {
		localStorage.removeItem("pendingSubscriptionPlan");
	},

	// Chosen plan (when user selected the plan to upgrade)
	setChosenPlan: (chosenPlan) => {
		localStorage.setItem("chosenPlan", JSON.stringify(chosenPlan));
	},

	getChosenPlan: () => {
		const chosenPlan = localStorage.getItem("chosenPlan");
		return JSON.parse(chosenPlan);
	},

	removeChosenPlan: () => {
		localStorage.removeItem("chosenPlan");
	},

	// QuickBlox session object
	setQbSession: (qbSession) => {
		localStorage.setItem("qbSession", qbSession);
	},

	getQbSession: () => {
		return JSON.parse(localStorage.getItem("qbSession"));
	},

	// User login secrets for Quickblox
	setQbUserSecret: (username, password) => {
		const userSecrets = {
			username, password
		}

		localStorage.setItem("qbUserSecrets", JSON.stringify(userSecrets));
	},

	getQbUserSecret: () => {
		return JSON.parse(localStorage.getItem("qbUserSecrets"));
	}
};

export default LocalStorageService;
