import * as types from "./types";

// Upgrade monthly plan confirmation modal
export const openUpgradeMonthlyPlanConfirmationModal = () => ({
	type: types.OPEN_UPGRADE_MONTHLY_PLAN_CONFIRMATION_MODAL,
});

export const closeUpgradeMonthlyPlanConfirmationModal = () => ({
	type: types.CLOSE_UPGRADE_MONTHLY_PLAN_CONFIRMATION_MODAL,
});

// Upgrade annual plan confirmation modal
export const openUpgradeAnnualPlanConfirmationModal = () => ({
	type: types.OPEN_UPGRADE_ANNUAL_PLAN_CONFIRMATION_MODAL,
});

export const closeUpgradeAnnualPlanConfirmationModal = () => ({
	type: types.CLOSE_UPGRADE_ANNUAL_PLAN_CONFIRMATION_MODAL,
});

// Payment success modal
export const openPaymentSuccessModal = () => ({
	type: types.OPEN_PAYMENT_SUCCESS_MODAL,
});

export const closePaymentSuccessModal = () => ({
	type: types.CLOSE_PAYMENT_SUCCESS_MODAL,
});

// Set chosen plan
export const setChosenPlan = (chosenPlan) => ({
	type: types.SET_CHOSEN_PLAN,
	params: {
		chosenPlan,
	},
});

// Set current plan
export const setCurrentPlan = (currentPlan) => ({
	type: types.SET_CURRENT_PLAN,
	params: {
		currentPlan,
	},
});

// Set selected pricing mode
export const setSelectedPricingMode = (pricingMode) => ({
	type: types.SET_SELECTED_PRICING_MODE,
	params: {
		pricingMode,
	},
});

// Refresh billing dashboard
export const refreshBillingDashboard = () => ({
	type: types.REFRESH_BILLING_DASHBOARD,
});

// Is upgrading plan
export const isUpgradingPlan = (isUpgradingPlan) => ({
	type: types.SET_IS_UPGRADING_PLAN,
	params: {
		isUpgradingPlan
	}
});

// Confirm purchase plan modal
export const openConfirmPurchasePlanModal = () => ({
	type: types.OPEN_CONFIRM_PURCHASE_PLAN_MODAL,
});

export const closeConfirmPurchasePlanModal = () => ({
	type: types.CLOSE_CONFIRM_PURCHASE_PLAN_MODAL,
});

export const setConfirmPurchasePrice = (confirmPurchasePrice) => ({
	type: types.SET_CONFIRM_PURCHASE_PRICE,
	params: {
		confirmPurchasePrice
	}
});

// Upgrade enterprise plan confirmation modal
export const openUpgradeEnterprisePlanConfirmationModal = () => ({
	type: types.OPEN_UPGRADE_ENTERPRISE_PLAN_CONFIRMATION_MODAL,
});

export const closeUpgradeEnterprisePlanConfirmationModal = () => ({
	type: types.CLOSE_UPGRADE_ENTERPRISE_PLAN_CONFIRMATION_MODAL,
});

// Enterprise plan reachout success modal
export const openEnterprisePlanReachoutSuccessModal = () => ({
	type: types.OPEN_ENTERPRISE_PLAN_REACHOUT_SUCCESS_MODAL,
});

export const closeEnterprisePlanReachoutSuccessModal = () => ({
	type: types.CLOSE_ENTERPRISE_PLAN_REACHOUT_SUCCESS_MODAL,
});

// pending subscription
export const setPendingSubscription = (pendingSubscription) => ({
	type: types.SET_PENDING_SUBSCRIPTION,
	params: {
		pendingSubscription
	}
});

// refresh current subscription plan
export const refreshCurrentSubscriptionPlan = () => ({
	type: types.REFRESH_CURRENT_SUBSCRIPTION_PLAN,
});

// Reactivate plan modal
export const openReactivatePlanModal = () => ({
	type: types.OPEN_REACTIVATE_PLAN_MODAL,
});

export const closeReactivatePlanModal = () => ({
	type: types.CLOSE_REACTIVATE_PLAN_MODAL,
});

// Downgrade Confirmation modal
export const openDowngradeConfirmationModal = () => ({
	type: types.OPEN_DOWNGRADE_CONFIRMATION_MODAL,
});

export const closeDowngradeConfirmationModal = () => ({
	type: types.CLOSE_DOWNGRADE_CONFIRMATION_MODAL,
});