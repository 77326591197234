import React, { useState } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { billingActions } from "app/states/billings";
import { withRouter } from "react-router-dom";
import { commonActions } from "app/states/common";
import BillingAPI from "app/apis/billings";
import LocalStorageService from "app/services/localStorageService";

const ConfirmPurchasePlanModal = ({ history }) => {
	const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();

	const dispatch = useDispatch();
	const closeConfirmPurchasePlanModal = () => dispatch(billingActions.closeConfirmPurchasePlanModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const closeUpgradeMonthlyPlanConfirmationModal = () =>
		dispatch(billingActions.closeUpgradeMonthlyPlanConfirmationModal());
	const refreshCurrentSubscriptionPlan = () => dispatch(billingActions.refreshCurrentSubscriptionPlan());
	const refreshBillingDashboard = () => dispatch(billingActions.refreshBillingDashboard());
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openPaymentSuccessModal = () => dispatch(billingActions.openPaymentSuccessModal());

	const showConfirmPurchasePlanModal = useSelector(({ billing }) => billing.showConfirmPurchasePlanModal);
	const confirmPurchasePrice = useSelector(({ billing }) => billing.confirmPurchasePrice);
	const chosenPlan = useSelector(({ billing }) => billing.chosenPlan);

	const handleCompletePurchase = (event) => {
		event.preventDefault();
		showLoading();

		const data = {
			subscription_plan_id: chosenPlan.id,
			term: 1,
		};

		BillingAPI.postChoosePlan(selectedOrganizationAccount.id, data)
			.then((response) => {
				if (response.code === 200) {
					setTimeout(() => {
						hideLoading();
						closeConfirmPurchasePlanModal();
						closeUpgradeMonthlyPlanConfirmationModal();
						
						// Open payment success modal
						openPaymentSuccessModal();
						
						refreshCurrentSubscriptionPlan();
						refreshBillingDashboard();
						history.push('/dashboard');
					}, 2000);
				} else {
					hideLoading();
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				hideLoading();
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
	};

	const handleBack = () => {
		closeConfirmPurchasePlanModal();
	};

	return (
		<Modal
			show={showConfirmPurchasePlanModal}
			onHide={closeConfirmPurchasePlanModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="confirm-purchase-plan-modal"
			centered>
			<Modal.Body>
				<div className="confirm-purchase-plan-modal-body">
					<h3 className="mb-5">Confirm Purchase?</h3>

					<p className="mb-5">
						By completing this purchase, ${confirmPurchasePrice} will be charged to your credit card.
					</p>

					<button
						className="btn btn-primary btn-complete-purchase mb-3"
						type="button"
						onClick={handleCompletePurchase}>
						Complete Purchase
					</button>

					<button className="btn btn-back" type="button" onClick={handleBack}>
						Back
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(ConfirmPurchasePlanModal);
