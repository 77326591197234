import React, { useState } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { billingActions } from "app/states/billings";
import IconClose from "app/assets/icon-close.png";
import { withRouter } from "react-router-dom";
import { commonActions } from "app/states/common";
import BillingAPI from "app/apis/billings";
import LocalStorageService from "app/services/localStorageService";

const EnterpriseReachoutSuccessModal = ({ history }) => {
	const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
	const userProfile = LocalStorageService.getUserProfile();

	const dispatch = useDispatch();
	const closeEnterprisePlanReachoutSuccessModal = () =>
		dispatch(billingActions.closeEnterprisePlanReachoutSuccessModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showEnterprisePlanReachoutSuccessModal = useSelector(
		({ billing }) => billing.showEnterprisePlanReachoutSuccessModal
	);
	const chosenPlan = useSelector(({ billing }) => billing.chosenPlan);
	const pricingMode = useSelector(({ billing }) => billing.selectedPricingMode);

	const [inputData, setInputData] = useState({
		country_code: "",
		mobile_number: "",
	});

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleSubmitPhoneNumber = (event) => {
		event.preventDefault();

		const data = {
			subscription_plan_id: chosenPlan.id,
			term: pricingMode === "monthly" ? 1 : 12,
			contact: inputData.mobile_number,
		};

		BillingAPI.postChoosePlan(selectedOrganizationAccount.id, data)
			.then((response) => {
				if (response.code === 200) {
					openAlertSnackbar("Our representative will get in touch with you shortly", "success");
					closeEnterprisePlanReachoutSuccessModal();
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	return (
		<Modal
			show={showEnterprisePlanReachoutSuccessModal}
			onHide={closeEnterprisePlanReachoutSuccessModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="enterprise-plan-reachout-success-modal"
			centered>
			<Modal.Body>
				<div className="enterprise-plan-reachout-success-modal-body">
					<div className="icon-close-wrapper">
						<img
							src={IconClose}
							alt="close"
							className="icon-close"
							onClick={closeEnterprisePlanReachoutSuccessModal}
						/>
					</div>

					<h3>Thank you for reaching out!</h3>
					<p className="mb-5">
						Our representative will get in touch with you at <strong>{userProfile.email}</strong> shortly.
					</p>

					<p className="mb-3">
						Alternatively, please leave your contact number if you prefer to be contacted via phone call:
					</p>

					<form method="POST" onSubmit={handleSubmitPhoneNumber}>
						<div className="row form-group">
							<div className="col-3">
								{/* <select
									className="form-control"
									aria-describedby="country_code"
									name="country_code"
									value={inputData.country_code}
									onChange={handleInputOnChange}>
									<option>+60</option>
								</select> */}
								<input
									type="text"
									className="form-control"
									aria-describedby="country_code"
									name="country_code"
									value={inputData.country_code}
									onChange={handleInputOnChange}
									placeholder="+60"
									required
								/>
							</div>
							<div className="col-9 pl-0">
								<input
									type="text"
									className="form-control"
									aria-describedby="mobile_number"
									name="mobile_number"
									value={inputData.mobile_number}
									onChange={handleInputOnChange}
									placeholder="012 3456 789"
									required
								/>
							</div>
						</div>
						<div className="text-right">
							<button className="btn btn-primary btn-submit" type="submit">
								Submit
							</button>
						</div>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(EnterpriseReachoutSuccessModal);
