import React, { useEffect, useState } from "react";
import "./style.scss";
import IconLiveRed from "app/assets/icon-session-live@2x.png";
import IconActiveVisitors from "app/assets/icon-dashboard-active-visitors.png";
import IconActiveExhibitors from "app/assets/icon-dashboard-active-exhibitors.png";
import { numberWithCommas } from "app/helper/common";
import DashboardAPI from "app/apis/dashboard";
import { useSelector } from "react-redux";
import moment from "moment";
import { EVENT_STATUS } from "app/config/constants";
import { withRouter } from "react-router-dom";
import { toFixedIfNecessary } from "./../../../../helper/common";

const CurrentLiveEventContainer = ({ history }) => {
	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);

	const [events, setEvents] = useState([]);

	useEffect(() => {
		if (!selectedOrganizationAccount) return;

		const queryParams = {
			organization_id: selectedOrganizationAccount.id,
		};

		DashboardAPI.getDashboardData(queryParams)
			.then((response) => {
				if (response.code === 200) {
					setEvents(response.data);
				} else {
					setEvents([]);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [selectedOrganizationAccount]);

	return (
		<div className="section-container current-live-event-container">
			<h3 className="section-title mb-3">Current Active Events</h3>
			<hr />

			{events.map((event) => {
				const {
					id,
					name,
					start_at,
					end_at,
					logo_url,
					event_status_code,
					advertisements_count,
					advertisements_percentage,
					exhibitors_count,
					exhibitors_percentage,
					sessions_count,
					sessions_percentage,
					visitors_count,
					visitors_percentage,
				} = event;

				let eventStatusKey = EVENT_STATUS[event_status_code] ? event_status_code : "unpublish";

				return (
					<div
						key={`event-${id}`}
						className="event-item-wrapper"
						onClick={() => history.push(`/events/view/${id}`)}>
						<div className="event-item d-flex align-items-start px-3">
							<div className="image-wrapper">
								<img src={logo_url ? logo_url.value : ""} alt="thumbnail" className="event-thumbnail" />
							</div>
							<div className="pt-2">
								<label className="event-title">{name}</label>
								<div className="event-datetime mb-2">
									{moment(start_at).format("DD MMMM")} - {moment(end_at).format("DD MMMM")} |{" "}
									{moment(start_at).format("h:mmA")} - {moment(end_at).format("h:mmA")}
								</div>
								<div className={`event-status event-status-${EVENT_STATUS[eventStatusKey]["value"]}`}>
									{EVENT_STATUS[eventStatusKey]["display"]}
								</div>
							</div>
						</div>
						<div className="event-stats d-flex align-items-center flex-wrap px-4">
							<div className="stat-item-wrapper d-flex align-items-start mr-5">
								<img src={IconActiveVisitors} alt="visitors" className="icon-stats" />
								<div>
									<div className="stat-label">REGISTERED VISITORS</div>
									<div className="stat-value">{numberWithCommas(visitors_count)}</div>
									<div
										className={`stat-quota ${
											visitors_percentage >= 100 ? "stat-quota-yellow" : ""
										}`}>
										{visitors_percentage === -1 ? (
											<span>Unlimited</span>
										) : (
											<span>{toFixedIfNecessary(visitors_percentage, 2)}% of quota</span>
										)}
									</div>
								</div>
							</div>
							<div className="stat-item-wrapper d-flex align-items-start mr-5">
								<img src={IconActiveExhibitors} alt="sessions" className="icon-stats" />
								<div>
									<div className="stat-label">SESSIONS</div>
									<div className="stat-value">{numberWithCommas(sessions_count)}</div>
									<div
										className={`stat-quota ${
											sessions_percentage >= 100 ? "stat-quota-yellow" : ""
										}`}>
										{sessions_percentage === -1 ? (
											<span>Unlimited</span>
										) : (
											<span>{toFixedIfNecessary(sessions_percentage, 2)}% of quota</span>
										)}
									</div>
								</div>
							</div>
							<div className="stat-item-wrapper d-flex align-items-start mr-5">
								<img src={IconActiveVisitors} alt="exhibitors" className="icon-stats" />
								<div>
									<div className="stat-label">EXHIBITORS</div>
									<div className="stat-value">{numberWithCommas(exhibitors_count)}</div>
									<div
										className={`stat-quota ${
											exhibitors_percentage >= 100 ? "stat-quota-yellow" : ""
										}`}>
										{exhibitors_percentage === -1 ? (
											<span>Unlimited</span>
										) : (
											<span>{toFixedIfNecessary(exhibitors_percentage, 2)}% of quota</span>
										)}
									</div>
								</div>
							</div>
							<div className="stat-item-wrapper d-flex align-items-start mr-5">
								<img src={IconActiveExhibitors} alt="advertisements" className="icon-stats" />
								<div>
									<div className="stat-label">ADVERTISEMENT SLOTS</div>
									<div className="stat-value">{numberWithCommas(advertisements_count)}</div>
									<div
										className={`stat-quota ${
											advertisements_percentage >= 100 ? "stat-quota-yellow" : ""
										}`}>
										{advertisements_percentage === -1 ? (
											<span>Unlimited</span>
										) : (
											<span>{toFixedIfNecessary(advertisements_percentage, 2)}% of quota</span>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default withRouter(CurrentLiveEventContainer);
