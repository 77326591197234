import React, { useState, useEffect } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "app/states/event";
import IconClose from "app/assets/icon-close.png";
import ChannelSessionAPI from "app/apis/channelSession";
import { withRouter } from "react-router-dom";
import IconAdd from "app/assets/icon-add-grey.png";
import IconDelete from "app/assets/icon-delete-grey.png";
import { commonActions } from "app/states/common";

const EditPollModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeEditPollModal = () => dispatch(eventActions.closeEditPollModal());
	const refreshPollListing = (refresh) => dispatch(eventActions.refreshPollListing(refresh));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showEditPollModal = useSelector(({ event }) => event.showEditPollModal);
	const pollId = useSelector(({ event }) => event.viewPollId);

	const [poll_title, setPollTitle] = useState("");
	const [originalChoicesDetail, setOriginalChoicesDetail] = useState([]);
	const [newChoices, setNewChoices] = useState([]);
	const [deletedChoiceIds, setDeletedChoiceIds] = useState([]);

	useEffect(() => {
		if (!pollId) return;

		ChannelSessionAPI.getPollDetail(pollId)
			.then((response) => {
				const { title, choices_detail } = response.data;

				setPollTitle(title);
				setOriginalChoicesDetail(choices_detail);
				setNewChoices([]);
				setDeletedChoiceIds([]);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [pollId]);

	const handleUpdatePoll = (event) => {
		event.preventDefault();

		let data = {
			title: poll_title,
		};

		if (newChoices.length > 0) {
			data[`new_choices`] = newChoices;
		}

		if (deletedChoiceIds.length > 0) {
			data[`delete_choices`] = deletedChoiceIds;
		}

		// Append existing updated choices
		data["update_choices"] = originalChoicesDetail.map((choiceDetail) => {
			return { id: choiceDetail.choice_id, text: choiceDetail.choices };
		});

		ChannelSessionAPI.postUpdatePoll(pollId, data)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					refreshPollListing(true);
					closeEditPollModal();

					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleAddOptions = () => {
		setNewChoices([...newChoices, ""]);
	};

	const handleDeleteOption = (choice_id) => {
		// Remove from original choice array
		const remainingChoices = originalChoicesDetail.filter((choiceDetail) => {
			return choiceDetail.choice_id !== choice_id;
		});

		setOriginalChoicesDetail(remainingChoices);
		setDeletedChoiceIds([...deletedChoiceIds, choice_id]);
	};

	const handleDeleteNewOption = (index) => {
		let newChoicesCopy = [...newChoices];
		newChoicesCopy.splice(index, 1);
		setNewChoices(newChoicesCopy);
	};

	const handleInputChange = (event, choiceId) => {
		let originalChoicesDetailCopy = [...originalChoicesDetail];

		if (choiceId) {
			originalChoicesDetailCopy.forEach((choiceDetail) => {
				if (choiceDetail.choice_id === choiceId) {
					choiceDetail.choices = event.target.value;
				}
			});

			setOriginalChoicesDetail(originalChoicesDetailCopy);
		} else {
			setNewChoices({
				...newChoices,
				[event.target.name]: event.target.value,
			});
		}
	};

	return (
		<Modal
			show={showEditPollModal}
			onHide={closeEditPollModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="edit-poll-modal"
			centered>
			<Modal.Body>
				<div className="edit-poll-modal-body">
					<div className="d-flex justify-content-end">
						<img src={IconClose} alt="close" className="icon-close" onClick={closeEditPollModal} />
					</div>

					<h3>Edit Poll</h3>

					<form method="post" onSubmit={handleUpdatePoll}>
						<div className="form-group">
							<label htmlFor="question">Poll</label>
							<input
								id="poll_title"
								type="text"
								className="form-control"
								aria-describedby="poll_title"
								name="poll_title"
								value={poll_title}
								onChange={(event) => setPollTitle(event.target.value)}
								required
							/>
						</div>

						{originalChoicesDetail.map((choiceDetail, index) => {
							const { choice_id, choices } = choiceDetail;
							const indexNum = index + 1;
							const optionKeyname = `option-${indexNum}`;

							return (
								<div key={indexNum} className="form-group">
									<label htmlFor={optionKeyname}>Option {indexNum}</label>
									<div className="d-flex align-items-center">
										<input
											id={optionKeyname}
											type="text"
											className="form-control"
											aria-describedby={optionKeyname}
											name={optionKeyname}
											value={choices}
											onChange={(event) => handleInputChange(event, choice_id)}
											required
										/>

										{index > 1 && (
											<img
												src={IconDelete}
												alt="Delete"
												className="icon-delete"
												onClick={() => handleDeleteOption(choice_id)}
											/>
										)}
									</div>
								</div>
							);
						})}

						{newChoices.map((newChoice, index) => {
							const indexNum = originalChoicesDetail.length + index + 1;
							const optionIndex = index;

							return (
								<div key={indexNum} className="form-group">
									<label htmlFor={optionIndex}>Option {indexNum}</label>
									<div className="d-flex align-items-center">
										<input
											id={optionIndex}
											type="text"
											className="form-control"
											aria-describedby={optionIndex}
											name={optionIndex}
											value={newChoice}
											onChange={(event) => {
												let newChoicesCopy = [...newChoices];
												newChoicesCopy[index] = event.target.value;

												setNewChoices(newChoicesCopy);
											}}
											required
										/>
										<img
											src={IconDelete}
											alt="Delete"
											className="icon-delete"
											onClick={() => handleDeleteNewOption(optionIndex)}
										/>
									</div>
								</div>
							);
						})}

						<button type="button" className="btn btn-add-options mt-4" onClick={handleAddOptions}>
							<img src={IconAdd} alt="add" /> Add Options
						</button>

						<button type="submit" className="btn btn-primary w-100 mt-5">
							Save Changes
						</button>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(EditPollModal);
