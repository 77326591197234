import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import GamificationAPI from "app/apis/gamification";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import { numberWithCommas, ordinal_suffix_of } from "app/helper/common";
import IconDefaultUser from "app/assets/icon-default-user.png";
import BgFirstPlace from "app/assets/bg-leaderboard-first-place-effect.png";
import IconCrown from "app/assets/icon-leaderboard-crown.png";
import { commonActions } from "app/states/common";
import { useDispatch } from "react-redux";
import SessionStorageService from "app/services/sessionStorageService";
import EventInfo from "app/views/components/EventInfo/EventInfo";

const GamificationLeaderboard = ({ history }) => {
	const { eventId } = useParams();
	const lastViewingPage = SessionStorageService.getLastViewingPage();

	const cachedFirstPlace = SessionStorageService.getFirstPlace();
	const cachedSecondPlace = SessionStorageService.getSecondPlace();
	const cachedThirdPlace = SessionStorageService.getThirdPlace();

	const dispatch = useDispatch();
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());

	const [gamificationListing, setGamificationListing] = useState([]);
	const [page, setPage] = useState(lastViewingPage || 1);
	const [totalPageCount, setTotalPageCount] = useState(0);

	const [firstPlace, setFirstPlace] = useState(cachedFirstPlace || null);
	const [secondPlace, setSecondPlace] = useState(cachedSecondPlace || null);
	const [thirdPlace, setThirdPlace] = useState(cachedThirdPlace || null);

	// Get gamification listing
	useEffect(() => {
		showLoading();

		let params = {
			event_id: eventId,
			page: page,
			limit: 10,
		};

		GamificationAPI.getGamificationListing(params)
			.then((response) => {
				hideLoading();

				const { gamifications, paging } = response.data;
				const { last_page } = paging;

				setGamificationListing(gamifications);
				setTotalPageCount(last_page);

				// Set first, second, third place
				if (!firstPlace && gamifications.length > 0) {
					setFirstPlace(gamifications[0]);
					SessionStorageService.setFirstPlace(gamifications[0]);
				}

				if (!secondPlace && gamifications.length > 1) {
					setSecondPlace(gamifications[1]);
					SessionStorageService.setSecondPlace(gamifications[1]);
				}

				if (!thirdPlace && gamifications.length > 2) {
					setThirdPlace(gamifications[2]);
					SessionStorageService.setThirdPlace(gamifications[2]);
				}
			})
			.catch((error) => {
				hideLoading();
				console.error(error);
			});
	}, [page]);

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	const handleViewDetails = (visitor_id, ranking) => {
		SessionStorageService.setLastViewingPage(page);
		history.push(`/events/view/${eventId}/gamification/leaderboard/view/${visitor_id}`, {
			ranking
		})
	}

	return (
		<EventLayoutContainer>
			<div id="gamification-leaderboard-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-5">
					<div className="d-flex align-items-center">
						<div>
							<h2 className="title">Gamification Leaderboard</h2>
							<span className="subtitle">Gamification &gt; Leaderboard</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="gamification-leaderboard-section">
					<h3>Leaderboard Listing</h3>

					<div className="top-rank-container">
						{secondPlace && (
							<div className="top-rank" onClick={() => handleViewDetails(secondPlace.visitor_id, 2)}>
								<div className="ranking-num">{ordinal_suffix_of(2)}</div>
								<img src={secondPlace.profile_image_url || IconDefaultUser} alt="second" className="thumbnail-rank-2" />
								<div className="name">
									{secondPlace.first_name} {secondPlace.last_name}
								</div>
								<div className="email">{secondPlace.email}</div>
								<div className="points">{numberWithCommas(secondPlace.counter)} points</div>
							</div>
						)}

						{firstPlace && (
							<div className="top-rank" onClick={() => handleViewDetails(firstPlace.visitor_id, 1)}>
								<div className="ranking-num">{ordinal_suffix_of(1)}</div>
								<img src={IconCrown} alt="crown" className="icon-crown" />
								<div className="position-relative">
									<img src={BgFirstPlace} alt="bg-first-place" className="bg-first-place" />
									<img src={firstPlace.profile_image_url || IconDefaultUser} alt="first" className="thumbnail-rank-1" />
								</div>
								<div className="name">
									{firstPlace.first_name} {firstPlace.last_name}
								</div>
								<div className="email">{firstPlace.email}</div>
								<div className="points">{numberWithCommas(firstPlace.counter)} points</div>
							</div>
						)}

						{thirdPlace && (
							<div className="top-rank" onClick={() => handleViewDetails(thirdPlace.visitor_id, 3)}>
								<div className="ranking-num">{ordinal_suffix_of(3)}</div>
								<img src={thirdPlace.profile_image_url || IconDefaultUser} alt="third" className="thumbnail-rank-3" />
								<div className="name">
									{thirdPlace.first_name} {thirdPlace.last_name}
								</div>
								<div className="email">{thirdPlace.email}</div>
								<div className="points">{numberWithCommas(thirdPlace.counter)} points</div>
							</div>
						)}
					</div>

					<div className="rank-listing-container">
						<table className="table table-hover">
							<thead>
								<tr>
									<th scope="col" className="table-header">
										Ranking
									</th>
									<th scope="col" className="table-header">
										Name
									</th>
									<th scope="col" className="table-header">
										Email
									</th>
									<th scope="col" className="table-header">
										Points
									</th>
								</tr>
							</thead>
							<tbody>
								{gamificationListing.map((gamification, index) => {
									const { visitor_id, first_name, last_name, email, counter } = gamification;

									let ranking = page > 1 ? page * 10 - 10 + index + 1 : index + 1;

									return (
										<tr
											key={ranking}
											onClick={() => handleViewDetails(visitor_id, ranking)}
											className="cursor-pointer">
											<td className="table-cell">{ordinal_suffix_of(ranking)}</td>
											<td className="table-cell">
												{first_name} {last_name}
											</td>
											<td className="table-cell">{email}</td>
											<td className="table-cell">{numberWithCommas(counter)}</td>
										</tr>
									);
								})}
							</tbody>
						</table>

						<div className="d-flex align-items-center justify-content-center w-100">
							<AppPagination pageCount={totalPageCount} handlePageChange={handlePageChange} initialPage={page - 1} />
						</div>
					</div>
				</section>
			</div>
		</EventLayoutContainer>
	);
};

export default withRouter(GamificationLeaderboard);
