import React, { useState, useRef } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "app/states/event";
import IconClose from "app/assets/icon-close.png";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import ExhibitorAPI from "app/apis/exhibitor";
import { withRouter, useParams } from "react-router-dom";
import { commonActions } from "app/states/common";

const CreateExhibitorCategoryModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeCreateExhibitorCategoryModal = () => dispatch(eventActions.closeCreateExhibitorCategoryModal());
	const refreshExhibitorCategoryListing = (refresh) =>
		dispatch(eventActions.refreshExhibitorCategoryListing(refresh));
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showCreateExhibitorCategoryModal = useSelector(({ event }) => event.showCreateExhibitorCategoryModal);
	const { eventId } = useParams();

	const logoRef = useRef();

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [uploadedLogo, setUploadedLogo] = useState(null);

	const handleChangeLogo = (event) => {
		setUploadedLogo(event.target.files[0]);
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleSubmitCreateExhibitorCategory = (event) => {
		event.preventDefault();
		showLoading();

		let formData = new FormData();
		formData.append("event_id", eventId);
		formData.append("name", name);
		formData.append("description", description);
		formData.append("thumbnail", uploadedLogo);

		ExhibitorAPI.postCreateExhibitorCategory(formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					refreshExhibitorCategoryListing(true);
					closeCreateExhibitorCategoryModal();
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	return (
		<Modal
			show={showCreateExhibitorCategoryModal}
			onHide={closeCreateExhibitorCategoryModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="create-exhibitor-category-modal"
			centered>
			<Modal.Body>
				<div className="create-exhibitor-category-modal-body">
					<div className="d-flex justify-content-end">
						<img
							src={IconClose}
							alt="close"
							className="icon-close"
							onClick={closeCreateExhibitorCategoryModal}
						/>
					</div>

					<h3>Add New Category</h3>

					<form method="post" onSubmit={handleSubmitCreateExhibitorCategory}>
						<div className="form-group">
							<label htmlFor="name">Category Name</label>
							<input
								id="name"
								type="text"
								className="form-control"
								aria-describedby="name"
								name="name"
								value={name}
								onChange={(event) => setName(event.target.value)}
								required
							/>
						</div>

						<div className="form-group">
							<label htmlFor="description">Description</label>
							<textarea
								id="description"
								className="form-control"
								aria-describedby="description"
								name="description"
								value={description}
								onChange={(event) => setDescription(event.target.value)}
								required
								rows={3}
							/>
						</div>

						<div className="form-group">
							<label htmlFor="image">Select Category Image</label>
							<img
								src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : IconUpload}
								alt="logo"
								className="img-fluid img-logo"
								onClick={browsePicture}
							/>
							<input
								id="image"
								type="file"
								name="logo"
								onChange={handleChangeLogo}
								ref={logoRef}
								className="d-none"
								accept="image/*"
							/>
						</div>

						<button type="submit" className="btn btn-primary w-100 mt-3">
							Create
						</button>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(CreateExhibitorCategoryModal);
