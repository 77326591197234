import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import AdsManagerAPI from "app/apis/adsManager";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import { ADS_TYPE_SIZE } from "app/config/settings";
import SessionStorageService from "app/services/sessionStorageService";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";
import EventInfo from "app/views/components/EventInfo/EventInfo";

const MIN_ADS_DURATION = 5;
const MIN_ADS_COUNT = 1;

const AdsManager = ({ history }) => {
	const { eventId } = useParams();

	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const [adsTypes, setAdsTypes] = useState([]);
	const [loadedAdsTypes, setLoadedAdsTypes] = useState(false);

	useEffect(() => {
		let params = {
			event_id: eventId,
		};

		AdsManagerAPI.getAdsTypeListing(params)
			.then((response) => {
				setAdsTypes(response.data);
				setLoadedAdsTypes(true);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	useEffect(() => {
		if (!loadedAdsTypes) return;

		let timeout;

		timeout = setTimeout(() => {
			adsTypes.forEach((adsType) => {
				const { id, per_ad_duration, max_ads } = adsType; 
				handleUpdateAdType(id, per_ad_duration, max_ads)
			});
		}, 2000);

		return () => {
			clearTimeout(timeout);
		}
	}, [adsTypes])

	const handleMinusAdsDuration = (id, code) => {
		let adsTypesCopy = [...adsTypes];

		adsTypesCopy.forEach((adsType) => {
			if (adsType.code === code && adsType.per_ad_duration > MIN_ADS_DURATION) {
				adsType.per_ad_duration -= 1;
			}
		});

		setAdsTypes(adsTypesCopy);
	};

	const handlePlusAdsDuration = (id, code) => {
		let adsTypesCopy = [...adsTypes];

		adsTypesCopy.forEach((adsType) => {
			if (adsType.code === code) {
				adsType.per_ad_duration += 1;
			}
		});

		setAdsTypes(adsTypesCopy);
	};

	const handleMinusMaxAds = (id, code) => {
		let adsTypesCopy = [...adsTypes];

		adsTypesCopy.forEach((adsType) => {
			if (adsType.code === code && adsType.max_ads > MIN_ADS_COUNT) {
				adsType.max_ads -= 1;
			}
		});

		setAdsTypes(adsTypesCopy);
	};

	const handlePlusMaxAds = (id, code) => {
		let adsTypesCopy = [...adsTypes];

		adsTypesCopy.forEach((adsType) => {
			if (adsType.code === code) {
				adsType.max_ads += 1;
			}
		});

		setAdsTypes(adsTypesCopy);
	};

	const handleManageAds = (id, code, name) => {
		// Store in session storage
		let viewingAdsType = {
			code, name
		}
		SessionStorageService.setViewingAdsType(viewingAdsType);

		// Go to the ads summary
		history.push(`/events/view/${eventId}/ads/type/${id}`);
	}

	const handleUpdateAdType = (adTypeId, per_ad_duration, max_ads) => {
		let data = {
			per_ad_duration: per_ad_duration,
			max_ads: max_ads,
		}

		AdsManagerAPI.postUpdateAdType(eventId, adTypeId, data)
			.then(response => {
				openAlertSnackbar("Updated", "success");
			})
			.catch(error => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
	}

	return (
		<EventLayoutContainer>
			<div id="ads-manager-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-5">
					<div className="d-flex align-items-center">
						<div>
							<h2 className="title">Ads Manager</h2>
							<span className="subtitle">Ads Manager</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="ads-manager-body px-5 py-3">
					<h3>List of Ads Type</h3>
					<div className="row">
						{adsTypes.map((adsType, index) => {
							const { id, code, name, per_ad_duration, max_ads } = adsType;

							// Default use
							let sizeClassName = "col-md-5";

							// The first column use "col-md-7"
							if (index % 2 === 0) {
								sizeClassName = "col-md-7";
							}

							return (
								<div key={id} className={`col-12 ${sizeClassName}`}>
									<div className="ads-card">
										<div className="ads-card-header">
											<h3 className="ads-card-title">{name}</h3>
											<span className="ads-card-size">{ADS_TYPE_SIZE[code]["display"]}</span>
										</div>
										<div className="ads-card-body">
											<div className="mb-4">
												<label>Per Ad Duration</label>
												<div className="d-flex align-items-center">
													<button
														className="btn btn-minus"
														onClick={() => handleMinusAdsDuration(id, code)}>
														-
													</button>
													<div className="value-wrapper">{per_ad_duration} Seconds</div>
													<button
														className="btn btn-plus"
														onClick={() => handlePlusAdsDuration(id, code)}>
														+
													</button>
												</div>
											</div>
											<div className="mb-4">
												<label>Max Ads</label>
												<div className="d-flex align-items-center">
													<button
														className="btn btn-minus"
														onClick={() => handleMinusMaxAds(id, code)}>
														-
													</button>
													<div className="value-wrapper">{max_ads}</div>
													<button
														className="btn btn-plus"
														onClick={() => handlePlusMaxAds(id, code)}>
														+
													</button>
												</div>
											</div>
											<button
												type="button"
												className="btn btn-primary btn-manage-ads"
												onClick={() => handleManageAds(id, code, name)}>
												View / Manage Ads
											</button>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</section>
			</div>
		</EventLayoutContainer>
	);
};

export default withRouter(AdsManager);
