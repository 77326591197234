/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { billingActions } from "app/states/billings";
import { withRouter } from "react-router-dom";
import IconCheckbox from "app/assets/icon-checkbox.png";

const UpgradeEnterprisePlanConfirmationModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeUpgradeEnterprisePlanConfirmationModal = () =>
		dispatch(billingActions.closeUpgradeEnterprisePlanConfirmationModal());
	const setChosenPlan = (chosenPlan) => dispatch(billingActions.setChosenPlan(chosenPlan));
	const setSelectedPricingMode = (pricingMode) => dispatch(billingActions.setSelectedPricingMode(pricingMode));

	const showUpgradeEnterprisePlanConfirmationModal = useSelector(
		({ billing }) => billing.showUpgradeEnterprisePlanConfirmationModal
	);
	const pendingSubscription = useSelector(({ billing }) => billing.pendingSubscription);

	const handleCheckout = () => {
		// Cache the plan details in order to show in the PaymentSuccesModal
		const chosenPlan = {
			name: pendingSubscription.subscription.subscription_plan.name,
			monthly_price: pendingSubscription.subscription.discounted_monthly_price
		}

		setChosenPlan(chosenPlan)
		setSelectedPricingMode("monthly");

		// Close modal and redirect to payment gateway
		window.location.href = pendingSubscription.payment_url;
		closeUpgradeEnterprisePlanConfirmationModal();
	};

	if (!pendingSubscription) {
		return <></>;
	}

	return (
		<Modal
			show={showUpgradeEnterprisePlanConfirmationModal}
			onHide={closeUpgradeEnterprisePlanConfirmationModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="upgrade-enterprise-plan-confirmation-modal"
			dialogClassName="upgrade-enterprise-plan-confirmation-dialog"
			centered>
			<Modal.Body className="p-0">
				<div className="upgrade-enterprise-plan-confirmation-modal-body">
					<div className="d-flex">
						<section className="upgrade-confirmation-wrapper">
							<h1>Upgrade Confirmation</h1>

							<div className="content-container">
								<div className="d-flex justify-content-between align-items-center mb-3">
									<div>
										<div className="text-16">
											{pendingSubscription.subscription.subscription_plan.name}
										</div>
										<div className="text-12">Billed Monthly</div>
									</div>
									<div className="plan-pricing">
										<span className="price mr-2">
											${pendingSubscription.subscription.monthly_price}
										</span>
										<span>/ month</span>
									</div>
								</div>

								<div className="d-flex justify-content-between align-items-center">
									<div>
										<div className="text-12 discount-text">
											Discount {pendingSubscription.subscription.monthly_discount_percentage}%
										</div>
									</div>
									<div className="discount-price">- ${Math.abs(pendingSubscription.subscription.discount_monthly_price)}</div>
								</div>

								<hr className={`mt-3 mb-3`} />

								<div className="d-flex justify-content-between align-items-center">
									<div className="text-12">Payable Amount</div>
									<div className="price">
										${pendingSubscription.subscription.discounted_monthly_price}
									</div>
								</div>
							</div>

							<p className="description">
								This is a monthly subscription plan. You can choose to opt out at any point of time at
								the billing panel.
							</p>

							<div className="d-flex justify-content-center align-items-center">
								<button
									className="btn btn-cancel"
									onClick={closeUpgradeEnterprisePlanConfirmationModal}>
									Cancel
								</button>
								<button className="btn btn-check-out" onClick={handleCheckout}>
									Check Out
								</button>
							</div>
						</section>
						<section className="plan-summary-wrapper">
							<div className="title mb-3">Plan Summary</div>
							<div className="features-container">
								{pendingSubscription.subscription.products.map((product, index) => {
									const { details } = product;

									return (
										<div key={`product-${index}`}>
											{details.map((detail, detailIndex) => {
												return (
													<div key={`detail-${detailIndex}`} className="plan-feature-text">
														<img
															src={IconCheckbox}
															alt="checkbox"
															className="icon-checkbox"
														/>
														<div
															className="d-inline-block"
															dangerouslySetInnerHTML={{ __html: detail }}></div>
													</div>
												);
											})}
										</div>
									);
								})}
							</div>
						</section>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(UpgradeEnterprisePlanConfirmationModal);
