/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import IconBack from "app/assets/icon-back-grey.png";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import IconUpload from "app/assets/icon-add-grey.png";
import IconRemove from "app/assets/icon-close.png";
import ExhibitorAPI from "app/apis/exhibitor";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import LocalStorageService from "app/services/localStorageService";
import { commonActions } from "app/states/common";
import IconDeleteRed from "app/assets/icon-delete-red.png";
import IconDeleteGrey from "app/assets/icon-delete-grey.png";
import CustomModal from "app/views/components/CustomModal/CustomModal";
import { PAYMENT_SETTINGS, PRODUCT_VARIANT_COLORS } from "app/config/settings";
import moment from "moment";

const ViewExhibitorStoreProduct = ({ history }) => {
	let { eventId, roomId, productId } = useParams();

	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openCustomModal = () => dispatch(commonActions.openCustomModal());
	const closeCustomModal = () => dispatch(commonActions.closeCustomModal());

	const eventDetails = useSelector(({ event }) => event.eventDetails);

	const exhibitorRoomDetails = LocalStorageService.getExhibitorRoomDetails();

	const [inputData, setInputData] = useState({
		name: "",
		description: "",
		external_product_link: "",
		currency: "",
		price: "",
	});

	const [variantMatrix, setVariantMatrix] = useState([]);

	const [uploadedProductImages, setUploadedProductImages] = useState([]);
	const [uploadedProductImagesUrl, setUploadedProductImagesUrl] = useState([]);
	const [productImages, setProductImages] = useState([]);
	const [refreshProductDetails, setRefreshProductDetails] = useState(0);
	const [skus, setSkus] = useState([]);
	const [sizes, setSizes] = useState([]);
	const [colors, setColors] = useState([]);
	const [options, setOptions] = useState([]);

	const [banInfo, setBanInfo] = useState({
		is_banned: false,
		banned_at: null,
		banned_by: null,
		banned_by_name: null,
	});

	useEffect(() => {
		setUploadedProductImages([]);
		setUploadedProductImagesUrl([]);

		ExhibitorAPI.getEventRoomStoreProducDetail(productId)
			.then((response) => {
				const {
					name,
					description,
					currency,
					price,
					event_exhibitor_room_store_product_medias,
					event_exhibitor_room_store_product_skus,
					is_banned,
					banned_at,
					banned_by,
					banned_by_name,
					external_product_url,
				} = response.data;

				setBanInfo({
					is_banned: is_banned,
					banned_at: banned_at,
					banned_by: banned_by,
					banned_by_name: banned_by_name,
				});

				setInputData({
					name: name,
					description: description,
					currency: currency,
					price: price,
					external_product_link: external_product_url ?? "",
				});

				setProductImages(event_exhibitor_room_store_product_medias);
				setSkus(event_exhibitor_room_store_product_skus);

				let sizeVariants = [];
				let colorVariants = [];
				let optionVariants = [];
				event_exhibitor_room_store_product_skus.forEach((sku) => {
					const { size_variant, color_variant, option_variant } = sku;

					if (!sizeVariants.includes(size_variant)) {
						sizeVariants.push(size_variant);
					}

					if (!colorVariants.includes(color_variant)) {
						colorVariants.push(color_variant);
					}

					if (!optionVariants.includes(option_variant)) {
						optionVariants.push(option_variant);
					}
				});

				setSizes(sizeVariants);
				setColors(colorVariants);
				setOptions(optionVariants);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [refreshProductDetails]);

	const handleInputOnChange = (event) => {
		if (event.target.name === "price") {
			// Restrict 2 decimal places only
			let t = event.target.value;
			event.target.value = t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3) : t;
			setInputData({ ...inputData, [event.target.name]: event.target.value });
		} else {
			setInputData({ ...inputData, [event.target.name]: event.target.value });
		}
	};

	const handleUpdateProduct = (event) => {
		event.preventDefault();
		showLoading();

		const storeId = exhibitorRoomDetails.event_exhibitor_room_stores.id;

		let formData = new FormData();
		formData.append("store_id", storeId);
		formData.append("name", inputData.name);
		formData.append("description", inputData.description);
		formData.append("price", inputData.price);

		productImages.forEach((productImage) => {
			formData.append(`event_exhibitor_room_store_product_medias[]`, JSON.stringify({ id: productImage.id }));
		});

		uploadedProductImages.forEach((uploadedProductImage) => {
			formData.append("photo", uploadedProductImage);
		});

		ExhibitorAPI.postUpdateEventRoomStoreProduct(productId, formData)
			.then((response) => {
				if (response.code === 200) {
					setRefreshProductDetails(refreshProductDetails + 1);
					openAlertSnackbar("Product details updated.", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleProductImageUpload = (event, index) => {
		setUploadedProductImages([...uploadedProductImages, event.target.files[0]]);
		setUploadedProductImagesUrl([...uploadedProductImagesUrl, URL.createObjectURL(event.target.files[0])]);
	};

	const handleProductImageRemove = (index, isUploadImage) => {
		if (isUploadImage) {
			let uploadedProductImagesCopy = [...uploadedProductImages];
			uploadedProductImagesCopy.splice(index, 1);
			setUploadedProductImages(uploadedProductImagesCopy);

			let uploadedProductImagesUrlCopy = [...uploadedProductImagesUrl];
			uploadedProductImagesUrlCopy.splice(index, 1);
			setUploadedProductImagesUrl(uploadedProductImagesUrlCopy);
		} else {
			let productImagesCopy = [...productImages];
			productImagesCopy.splice(index, 1);
			setProductImages(productImagesCopy);
		}
	};

	const handleConfirmDelete = () => {
		showLoading();

		ExhibitorAPI.deleteEventRoomStoreProduct(productId)
			.then((response) => {
				hideLoading();
				const { code } = response;

				if (code === 200) {
					closeCustomModal();
					history.replace(`/events/view/${eventId}/exhibitor/rooms/${roomId}`);
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				hideLoading();
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleOnChangeVariantMatrixInput = (event, index) => {
		let variantMatrixCopy = [...variantMatrix];

		if (event.target.name === "fragile" || event.target.name === "available") {
			variantMatrixCopy[index][event.target.name] = !variantMatrixCopy[index][event.target.name] ? 1 : 0;
		} else {
			variantMatrixCopy[index][event.target.name] = event.target.value;
		}

		setVariantMatrix(variantMatrixCopy);
	};

	const handleBanProduct = () => {
		showLoading();
		ExhibitorAPI.postBanProduct(productId)
			.then((response) => {
				if (response.code === 200) {
					setRefreshProductDetails(refreshProductDetails + 1);
					openAlertSnackbar("Product banned.", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleUnbanProduct = () => {
		showLoading();
		ExhibitorAPI.postUnbanProduct(productId)
			.then((response) => {
				if (response.code === 200) {
					setRefreshProductDetails(refreshProductDetails + 1);
					openAlertSnackbar("Product unbanned.", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	return (
		<EventLayoutContainer>
			<div id="exhibitor-view-product-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-4">
					<div className="d-flex align-items-center">
						<div className="btn-back" onClick={() => history.goBack()}>
							<img src={IconBack} alt="back" className="img-fluid" />
						</div>
						{exhibitorRoomDetails && (
							<img src={exhibitorRoomDetails.logo_url} alt="room logo" className="img-fluid room-logo" />
						)}
						<div>
							<h2 className="title">Product Details</h2>
							<span className="subtitle">Exhibitors &gt; Exhibitor Rooms &gt; View Product</span>
						</div>
					</div>
				</section>

				<div className="section-container mb-3">
					{banInfo.is_banned === 1 && (
						<div className="product-notice mb-3">
							This product has been disabled/banned by {banInfo.banned_by_name} at{" "}
							{moment(banInfo.banned_at).format("DD MMMM, h:mma")}
						</div>
					)}

					<form id="form-add-product" method="post" onSubmit={handleUpdateProduct}>
						<div className="row mb-3">
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="name">Product Name</label>
									<input
										id="name"
										type="text"
										className="form-control"
										aria-describedby="name"
										name="name"
										value={inputData.name}
										onChange={handleInputOnChange}
										required
										readOnly
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="description">Product Description</label>
									<textarea
										id="description"
										type="text"
										className="form-control"
										aria-describedby="description"
										name="description"
										value={inputData.description}
										onChange={handleInputOnChange}
										rows={3}
										readOnly
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="description">
										External Store's Product Link{" "}
										{eventDetails.event_settings.payment_type !== PAYMENT_SETTINGS.ecommerce && (
											<span className="text-warning">
												(not used in checkout flow that is currently selected)
											</span>
										)}
									</label>
									<input
										id="external_product_link"
										type="url"
										className={`form-control ${
											eventDetails.event_settings.payment_type !== PAYMENT_SETTINGS.ecommerce
												? "text-secondary"
												: ""
										}`}
										aria-describedby="external_product_link"
										name="external_product_link"
										value={inputData.external_product_link}
										onChange={handleInputOnChange}
										readOnly
										disabled={
											eventDetails.event_settings.payment_type !== PAYMENT_SETTINGS.ecommerce
										}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<label htmlFor="price">Product Price</label>
									<div className="d-flex align-items-center product-price-input-wrapper">
										{/* <div className="select-wrapper mr-3 currency-select">
											<select className="form-control" disabled value={inputData.currency}>
												<option value="MYR">MYR</option>
											</select>
										</div> */}
										<div className="mr-3 currency-select">
											<input
												type="text"
												className="form-control"
												value={inputData.currency}
												readOnly
											/>
										</div>
										<input
											id="price"
											type="number"
											className="form-control"
											aria-describedby="price"
											name="price"
											value={inputData.price}
											onChange={handleInputOnChange}
											required
											step="0.01"
											min="0"
											readOnly
										/>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<label>Photo Gallery</label>
									<div className="d-flex align-items-center flex-wrap">
										{productImages.map((productImage, index) => {
											const { content_url } = productImage;

											return (
												<GalleryItem
													key={index}
													thumbnail={content_url}
													index={index}
													onUpload={handleProductImageUpload}
													onRemove={handleProductImageRemove}
												/>
											);
										})}

										{uploadedProductImagesUrl.map((productImageUrl, index) => {
											return (
												<GalleryItem
													key={index}
													thumbnail={productImageUrl}
													index={index}
													isUploaded
													onUpload={handleProductImageUpload}
													onRemove={handleProductImageRemove}
												/>
											);
										})}

										{/* Disabled: do not allow edit product */}
										{/* {productImages.length - 1 < 10 && (
											<GalleryItem
												onUpload={handleProductImageUpload}
												onRemove={handleProductImageRemove}
												index={productImages.length - 1}
											/>
										)} */}
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<label>Variants</label>
									<div className="section-container variant-container mb-3">
										<div className="row mb-4">
											<div className="col-md-2 col-lg-1">
												<span className="variant-label">Sizes:</span>
											</div>
											<div className="col-md-10 col-lg-11">
												{sizes.map((size, index) => {
													return (
														<span
															key={`size-${index}`}
															className="variant-pill mr-2"
															style={{ backgroundColor: PRODUCT_VARIANT_COLORS[0] }}>
															{size}
														</span>
													);
												})}
											</div>
										</div>
										<div className="row mb-4">
											<div className="col-md-2 col-lg-1">
												<span className="variant-label">Colors:</span>
											</div>
											<div className="col-md-10 col-lg-11">
												{colors.map((color, index) => {
													return (
														<span
															key={`color-${index}`}
															className="variant-pill mr-2"
															style={{ backgroundColor: PRODUCT_VARIANT_COLORS[1] }}>
															{color}
														</span>
													);
												})}
											</div>
										</div>
										<div className="row mb-4">
											<div className="col-md-2 col-lg-1">
												<span className="variant-label">Options:</span>
											</div>
											<div className="col-md-10 col-lg-11">
												{options.map((option, index) => {
													return (
														<span
															key={`option-${index}`}
															className="variant-pill mr-2"
															style={{ backgroundColor: PRODUCT_VARIANT_COLORS[2] }}>
															{option}
														</span>
													);
												})}
											</div>
										</div>

										<div className="variant-matrix-container mt-5 mb-3">
											<table className="table">
												<thead>
													<tr>
														<th
															scope="col"
															className="table-header text-left"
															style={{ minWidth: "146px" }}>
															Variants Matrix
														</th>
														<th
															scope="col"
															className="table-header"
															style={{ minWidth: "162px" }}>
															SKU Code
														</th>
														<th
															scope="col"
															className="table-header"
															style={{ minWidth: "141px" }}>
															Price (RM)
														</th>
														<th
															scope="col"
															className="table-header"
															style={{ minWidth: "93px" }}>
															Stock (Qty)
														</th>
														<th
															scope="col"
															className="table-header"
															style={{ minWidth: "93px" }}>
															Weight (kg)
														</th>
														<th
															scope="col"
															className="table-header"
															style={{ minWidth: "93px" }}>
															Width (cm)
														</th>
														<th
															scope="col"
															className="table-header"
															style={{ minWidth: "93px" }}>
															Height (cm)
														</th>
														<th
															scope="col"
															className="table-header"
															style={{ minWidth: "93px" }}>
															Length (cm)
														</th>
														<th scope="col" className="table-header">
															Fragile
														</th>
														<th scope="col" className="table-header">
															Availability
														</th>
													</tr>
												</thead>
												<tbody>
													{/* {variantMatrix.map((variantMatrixRow, index) => {
														const {
															combination,
															sku_code,
															price,
															quantity,
															weight,
															width,
															height,
															length,
															fragile,
															available,
														} = variantMatrixRow;

														let displayCombination = "";
														combination.forEach((comboKey, index) => {
															displayCombination += `<span style="color: ${PRODUCT_VARIANT_COLORS[index]}">${comboKey}</span>`;

															if (combination.length - 1 !== index) {
																displayCombination += `<span class="mx-1 font-normal">+</span>`;
															}
														});

														return (
															<tr key={index}>
																<td className="variant-matrix-label">
																	<div
																		dangerouslySetInnerHTML={{
																			__html: displayCombination,
																		}}></div>
																</td>
																<td>
																	<input
																		className="input-box"
																		type="text"
																		name="sku_code"
																		value={sku_code}
																		onChange={(event) =>
																			handleOnChangeVariantMatrixInput(
																				event,
																				index
																			)
																		}
																	/>
																</td>
																<td>
																	<input
																		className="input-box"
																		type="number"
																		name="price"
																		value={price}
																		onChange={(event) =>
																			handleOnChangeVariantMatrixInput(
																				event,
																				index
																			)
																		}
																	/>
																</td>
																<td>
																	<input
																		className="input-box"
																		type="number"
																		name="quantity"
																		value={quantity}
																		onChange={(event) =>
																			handleOnChangeVariantMatrixInput(
																				event,
																				index
																			)
																		}
																	/>
																</td>
																<td>
																	<input
																		className="input-box"
																		type="number"
																		name="weight"
																		value={weight}
																		onChange={(event) =>
																			handleOnChangeVariantMatrixInput(
																				event,
																				index
																			)
																		}
																	/>
																</td>
																<td>
																	<input
																		className="input-box"
																		type="number"
																		name="width"
																		value={width}
																		onChange={(event) =>
																			handleOnChangeVariantMatrixInput(
																				event,
																				index
																			)
																		}
																	/>
																</td>
																<td>
																	<input
																		className="input-box"
																		type="number"
																		name="height"
																		value={height}
																		onChange={(event) =>
																			handleOnChangeVariantMatrixInput(
																				event,
																				index
																			)
																		}
																	/>
																</td>
																<td>
																	<input
																		className="input-box"
																		type="number"
																		name="length"
																		value={length}
																		onChange={(event) =>
																			handleOnChangeVariantMatrixInput(
																				event,
																				index
																			)
																		}
																	/>
																</td>
																<td className="text-center">
																	<input
																		type="checkbox"
																		className="app-custom-checkbox"
																		name="fragile"
																		checked={fragile === 1 ? true : false}
																		onChange={(event) =>
																			handleOnChangeVariantMatrixInput(
																				event,
																				index
																			)
																		}
																	/>
																</td>
																<td className="text-center">
																	<input
																		type="checkbox"
																		className="app-custom-checkbox"
																		name="available"
																		checked={available === 1 ? true : false}
																		onChange={(event) =>
																			handleOnChangeVariantMatrixInput(
																				event,
																				index
																			)
																		}
																	/>
																</td>
															</tr>
														);
													})} */}

													{skus.map((sku, index) => {
														const {
															size_variant,
															color_variant,
															option_variant,
															sku_code,
															price,
															quantity,
															weight,
															width,
															height,
															length,
															fragile,
															available,
														} = sku;

														return (
															<tr key={index}>
																<td className="table-cell variant-matrix-label">
																	<div
																		dangerouslySetInnerHTML={{
																			__html: `${size_variant} + ${color_variant} + ${option_variant}`,
																		}}></div>
																</td>
																<td className="table-cell">{sku_code}</td>
																<td className="table-cell">{price}</td>
																<td className="table-cell">{quantity}</td>
																<td className="table-cell">{weight}</td>
																<td className="table-cell">{width}</td>
																<td className="table-cell">{height}</td>
																<td className="table-cell">{length}</td>
																<td className="table-cell text-center">
																	<input
																		type="checkbox"
																		className="custom-outline-checkbox mt-0"
																		name="fragile"
																		readOnly
																		checked={fragile === 1 ? true : false}
																		// onChange={(event) =>
																		// 	handleOnChangeVariantMatrixInput(event, index)
																		// }
																	/>
																</td>
																<td className="table-cell text-center">
																	<input
																		type="checkbox"
																		className="custom-outline-checkbox mt-0"
																		name="available"
																		readOnly
																		checked={available === 1 ? true : false}
																		// onChange={(event) =>
																		// 	handleOnChangeVariantMatrixInput(event)
																		// }
																	/>
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>

				<div className="row mt-4">
					<div className="col-9"></div>
					<div className="col-3 text-right">
						{banInfo.is_banned ? (
							<button type="button" className="btn btn-unban w-100" onClick={handleUnbanProduct}>
								Enable / Unban Product
							</button>
						) : (
							<button type="button" className="btn btn-danger w-100" onClick={handleBanProduct}>
								Disable / Ban Product
							</button>
						)}
					</div>
					{/* Disabled: do not allow edit product */}
					{/* <div className="col-3">
						<button type="button" className="btn btn-outline-danger w-100" onClick={openCustomModal}>
							<img src={IconDeleteRed} alt="delete" className="icon-btn" /> Delete
						</button>
					</div>
					<div className="col-3">
						<button type="submit" className="btn btn-primary w-100" form="form-add-product">
							Save
						</button>
					</div> */}
				</div>
			</div>

			<CustomModal
				icon={IconDeleteRed}
				title="Are you sure you want to delete this ad?"
				btnClass="btn-danger"
				textPrimary={
					<>
						<img src={IconDeleteGrey} alt="delete" className="icon-btn" /> Delete
					</>
				}
				textSecondary="Cancel"
				onClickPrimary={handleConfirmDelete}
				onClickSecondary={closeCustomModal}
			/>
		</EventLayoutContainer>
	);
};

const GalleryItem = ({ thumbnail, isUploaded, onUpload, onRemove, index }) => {
	const imageRef = useRef();

	const browsePicture = () => {
		imageRef.current.click();
	};

	const handleImageChange = (event) => {
		if (typeof onUpload === "function") {
			onUpload(event, index);
		}
	};

	const handleRemoveImage = () => {
		if (typeof onRemove === "function") {
			onRemove(index, isUploaded || false);
		}
	};

	// Show image thumbnail
	if (thumbnail) {
		return (
			<div className="gallery-item mb-3 mr-3">
				<img src={thumbnail} alt="thumbnail" className="img-fluid img-product" />
				{/* Disabled: do not allow edit product */}
				{/* <img src={IconRemove} alt="remove" className="icon-remove" onClick={handleRemoveImage} /> */}
			</div>
		);
	}

	// No image thumbnail, show add icon
	return (
		<div className="gallery-item-add mb-3 mr-3">
			<img src={IconUpload} alt="upload" className="img-fluid img-upload" onClick={browsePicture} />
			<input
				type="file"
				name="image"
				onChange={handleImageChange}
				value={""}
				ref={imageRef}
				className="d-none"
				accept="image/*"
			/>
		</div>
	);
};

export default withRouter(ViewExhibitorStoreProduct);
