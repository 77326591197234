import React, { useState } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/states/common";
import IconLiveRed from "app/assets/icon-go-live-red.png";
import IconLiveGrey from "app/assets/icon-go-live-grey.png";
import moment from "moment";

const LiveToggleModal = ({
	isLive,
	onBehalfLogo,
	title,
	streamingUrl,
	streamingStartAt,
	streamingEndAt,
	streamingCover,
	textConfirm,
	textCancel,
	onConfirm,
	onCancel,
}) => {
	const dispatch = useDispatch();
	const closeLiveToggleModal = () => dispatch(commonActions.closeLiveToggleModal());

	const showLiveToggleModal = useSelector(({ common }) => common.showLiveToggleModal);

	// Format streaming schedule
	let streamingSchedule = "";

	if (streamingStartAt && streamingEndAt) {
		let date = moment(streamingStartAt).format("ddd, DD MMM");
		let startTime = moment(streamingStartAt).format("hh:mmA");
		let endTime = moment(streamingEndAt).format("hh:mmA");

		streamingSchedule = `${date} from ${startTime}-${endTime}`;
	}

	const handleConfirm = () => {
		if (typeof onConfirm === "function") {
			onConfirm();
		}

		closeLiveToggleModal();
	};

	const handleCancel = () => {
		if (typeof onCancel === "function") {
			onCancel();
		}

		closeLiveToggleModal();
	};

	return (
		<Modal
			show={showLiveToggleModal}
			onHide={closeLiveToggleModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="live-toggle-modal"
			centered>
			<Modal.Body>
				<div className="live-toggle-modal-body">
					<div className="d-flex justify-content-center align-items-center mb-4">
						{onBehalfLogo && <img src={onBehalfLogo} alt="icon" className="modal-behalf-logo mr-3" />}

						{isLive ? (
							<img src={IconLiveGrey} alt="icon" className="live-off-logo" />
						) : (
							<img src={IconLiveRed} alt="icon" className="live-on-logo" />
						)}
					</div>

					{title && <h4 className="modal-title">{title}</h4>}

					<div className="text-center">
						<label className="text-label">Streaming URL</label>
						{streamingUrl ? (
							<p className="text-value">{streamingUrl}</p>
						) : (
							<p className="text-value no-streaming-url">(no streaming link is provided)</p>
						)}
					</div>

					{streamingSchedule && (
						<div className="text-center">
							<label className="text-label">Streaming Schedule</label>
							<p className="text-value">{streamingSchedule}</p>
						</div>
					)}

					{streamingCover && (
						<div className="text-center mb-5">
							<label className="text-label">Cover Image</label>
							<img src={streamingCover} alt="cover" className="streaming-cover" />
						</div>
					)}

					{textConfirm && onConfirm && (
						<button
							type="button"
							className={`btn btn-primary btn-confirm mb-2 px-5 ${!streamingUrl ? "btn-disabled" : ""}`}
							onClick={handleConfirm}
							disabled={!streamingUrl}>
							{textConfirm}
						</button>
					)}

					{textCancel && onCancel && (
						<button type="button" className="btn btn-cancel px-5" onClick={handleCancel}>
							{textCancel}
						</button>
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default LiveToggleModal;
