/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import AccountAPI from "app/apis/account";
import AdsManagerAPI from "app/apis/adsManager";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import "./style.scss";
import { withRouter, useParams } from "react-router-dom";
import IconBack from "app/assets/icon-back-grey.png";
import LocalStorageService from "app/services/localStorageService";
import { ADS_TYPE_SIZE, DROPDOWN_RECORDS_PER_PAGE } from "app/config/settings";
import IconDefaultUser from "app/assets/icon-default-user.png";
import CustomModal from "app/views/components/CustomModal/CustomModal";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";
import IconSuccess from "app/assets/icon-success-tick.png";
import IconWarningTriangle from "app/assets/icon-alert-triangle-red.png";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import SessionStorageService from "app/services/sessionStorageService";
import EventInfo from "app/views/components/EventInfo/EventInfo";

const CreateAd = ({ history, location }) => {
	const { eventId, adsTypeId } = useParams();
	const viewingAdsType = SessionStorageService.getViewingAdsType();
	const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();

	const dispatch = useDispatch();
	const openCustomModal = () => dispatch(commonActions.openCustomModal());
	const closeCustomModal = () => dispatch(commonActions.closeCustomModal());
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const [inputData, setInputData] = useState({
		ad_name: "",
		ad_description: "",
		ad_url: "",
		ad_status: 1,
	});

	const [uploadedLogo, setUploadedLogo] = useState(null);
	const [adTypeCodeName, setAdTypeCodeName] = useState("");
	const [adTypeName, setAdTypeName] = useState("");

	const [searchAccountText, setSearchAccountText] = useState("");
	const [accountsFiltered, setAccountsFiltered] = useState([]);
	const [selectedAccount, setSelectedAccount] = useState(null);
	const [showAccountSelection, setShowAccountSelection] = useState(false);

	const [newCreatedAdId, setNewCreatedAdId] = useState(null);

	const [page, setPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [loadedNextPage, setLoadedNextPage] = useState(true);
	const [triggerSearch, setTriggerSearch] = useState(0);

	const logoRef = useRef();

	useEffect(() => {
		if (location.state) {
			const { adsTypeName, adsTypeCode } = location.state;
			setAdTypeName(adsTypeName);
			setAdTypeCodeName(adsTypeCode);
		}
	}, []);

	// Get organization account listing
	useEffect(() => {
		let params = {
			page: page,
			limit: DROPDOWN_RECORDS_PER_PAGE,
		};

		if (searchAccountText) {
			params["name"] = searchAccountText;
		}

		AccountAPI.getOrganizationAccountListing(selectedOrganizationAccount.id, params)
			.then((response) => {
				const { organization_accounts, paging } = response.data;
				const { last_page } = paging;

				setAccountsFiltered([...accountsFiltered, ...organization_accounts]);
				setTotalPage(last_page);
				setIsFirstLoad(false);
				setLoadedNextPage(true);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [page, triggerSearch]);

	// Search organization account listing
	useEffect(() => {
		if (isFirstLoad) {
			return;
		}

		// Reset state and trigger search
		setAccountsFiltered([]);
		setPage(1);
		setTriggerSearch(triggerSearch + 1);
	}, [searchAccountText]);

	const handleSelectionScroll = (event) => {
		let offset = 20;
		let isBottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + offset;

		if (isBottom && page < totalPage && loadedNextPage) {
			setLoadedNextPage(false);
			setPage(page + 1);
		}
	};

	const removeAccount = () => {
		setSelectedAccount(null);
	};

	const selectAccount = (account) => {
		setSelectedAccount(account);
	};

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleChangeLogo = (event) => {
		let img = new Image();
		img.onload = function () {
			const expectedRatio = ADS_TYPE_SIZE[adTypeCodeName]["width"] / ADS_TYPE_SIZE[adTypeCodeName]["height"];
			const actualRatio = this.width / this.height;

			if (!(expectedRatio - 0.03 <= actualRatio) || !(actualRatio <= expectedRatio + 0.03)) {
				openAlertSnackbar(
					`Image resolution must be ${ADS_TYPE_SIZE[adTypeCodeName]["width"]} x ${ADS_TYPE_SIZE[adTypeCodeName]["height"]}px or similar ratio`,
					"error"
				);
				return false;
			}

			// If uploaded image size valid, only allow upload
			setUploadedLogo(this.srcFile);
			return true;
		};
		img.src = URL.createObjectURL(event.target.files[0]);
		img.srcFile = event.target.files[0];
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleCreateAd = (event) => {
		event.preventDefault();
		showLoading();

		if (!uploadedLogo) {
			openAlertSnackbar("Ad Banner Image is required", "error");
			return;
		}

		let formData = new FormData();
		formData.append("event_id", eventId);
		if (selectedAccount) {
			formData.append("org_account_id", selectedAccount.id);
		}
		formData.append("name", inputData.ad_name);
		formData.append("description", inputData.ad_description);
		formData.append("banner_image", uploadedLogo);
		formData.append("banner_url", inputData.ad_url);
		formData.append("ads_type_id", adsTypeId);
		formData.append("status", parseInt(inputData.ad_status));

		AdsManagerAPI.postCreateAd(formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					const { id, warning } = response.data;
					setNewCreatedAdId(id);

					// When ads limit exceeded
					if (warning && warning.ads_limit_exceed) {
						openCustomModal();
					} else {
						history.replace(`/events/view/${eventId}/ads/type/${adsTypeId}/view/${id}`);
					}

					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleModalButtonPress = () => {
		closeCustomModal();
		history.replace(`/events/view/${eventId}/ads/type/${adsTypeId}/view/${newCreatedAdId}`);
	};

	return (
		<EventLayoutContainer>
			<section className="page-header d-flex justify-content-between align-items-center mb-4">
				<div className="d-flex align-items-center">
					<div className="btn-back" onClick={() => history.goBack()}>
						<img src={IconBack} alt="back" className="img-fluid" />
					</div>
					<div>
						<h2 className="title">Create a New Ad</h2>
						<span className="subtitle">Ads Manager &gt; View/Manage Ads &gt; Add New Ad</span>
					</div>
				</div>
				<EventInfo />
			</section>

			<section className="create-ad-container p-4">
				<form id="form-create-ad" method="post" onSubmit={handleCreateAd}>
					<div className="row">
						<section className="w-100 custom-selection-container">
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="accounts">Link to Account</label>
									<div className="custom-selection-wrapper">
										{selectedAccount ? (
											<div
												className="selected-account"
												onClick={() => setShowAccountSelection(!showAccountSelection)}>
												<div className="d-flex align-items-center">
													<div className="selected-account-logo">
														<img
															src={selectedAccount.logo_url || IconDefaultUser}
															alt="logo"
															className="img-fluid"
														/>
													</div>
													<div>
														<div className="selected-account-name">
															{selectedAccount.name}
														</div>
														<div className="selected-account-organization">
															Organization
														</div>
													</div>
												</div>
											</div>
										) : (
											<input
												id="accounts"
												type="text"
												className="form-control"
												aria-describedby="accounts"
												name="accounts"
												value={searchAccountText}
												onChange={(event) => setSearchAccountText(event.target.value)}
												placeholder="Search Account"
												list="accounts"
												onClick={() => setShowAccountSelection(!showAccountSelection)}
												autoComplete={"off"}
												required={!selectedAccount ? true : false}
											/>
										)}
										{showAccountSelection && (
											<>
												<div
													className="custom-selection-wrapper-bgOverlay"
													onClick={() =>
														setShowAccountSelection(!showAccountSelection)
													}></div>
												<div className="custom-dropdown" onScroll={handleSelectionScroll}>
													{accountsFiltered.map((account) => {
														const { id, logo_url, name } = account;

														return (
															<div
																key={`custom-option-${id}`}
																className="custom-option row align-items-center">
																<div className="col-1">
																	<img
																		src={logo_url || IconDefaultUser}
																		alt="logo"
																		className="img-fluid"
																	/>
																</div>
																<div className="col-9">
																	<div className="custom-name">{name}</div>
																	<div className="custom-organization">
																		Organization
																	</div>
																</div>
																<div className="col-2">
																	{selectedAccount && selectedAccount.id === id ? (
																		<button
																			type="button"
																			className="btn btn-outline-primary w-100"
																			onClick={() => removeAccount()}>
																			Remove
																		</button>
																	) : (
																		<button
																			type="button"
																			className="btn btn-primary w-100"
																			onClick={() => selectAccount(account)}>
																			Select
																		</button>
																	)}
																</div>
															</div>
														);
													})}
												</div>
											</>
										)}
									</div>
								</div>
							</div>
						</section>

						<div className="col-12">
							<div className="form-group">
								<label htmlFor="ad_name">Ad Name</label>
								<input
									id="ad_name"
									type="text"
									className="form-control"
									aria-describedby="ad_name"
									name="ad_name"
									value={inputData.ad_name}
									onChange={handleInputOnChange}
									required
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="ad_description">Ad Description</label>
								<textarea
									id="ad_description"
									type="text"
									className="form-control"
									aria-describedby="ad_description"
									name="ad_description"
									value={inputData.ad_description}
									onChange={handleInputOnChange}
									rows={3}
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="ad_url">Ad URL</label>
								<input
									id="ad_url"
									type="url"
									className="form-control"
									aria-describedby="ad_url"
									name="ad_url"
									value={inputData.ad_url}
									onChange={handleInputOnChange}
									required
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label>
									Ad Banner Image <span>({ADS_TYPE_SIZE[viewingAdsType.code]["display"]})</span>
								</label>
								<div className="d-flex align-items-start">
									<img
										src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : IconUpload}
										alt="logo"
										className={`img-fluid ${!uploadedLogo ? "img-logo" : ""}`}
										onClick={browsePicture}
									/>
									<input
										type="file"
										name="logo"
										onChange={handleChangeLogo}
										value=""
										ref={logoRef}
										className="d-none"
										accept="image/*"
									/>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="ad_status">Ad Status</label>
								<div className="select-wrapper">
									<select
										id="ad_status"
										type="text"
										className="form-control"
										aria-describedby="ad_status"
										name="ad_status"
										value={inputData.ad_status}
										onChange={handleInputOnChange}
										required>
										<option value="1">Active</option>
										<option value="0">Inactive</option>
									</select>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="ad_type">Ad Type</label>
								<input
									id="ad_type"
									type="text"
									className="form-control"
									aria-describedby="ad_type"
									name="ad_type"
									value={adTypeName}
									readOnly
								/>
							</div>
						</div>
					</div>

					<div className="row mt-4">
						<div className="col-9"></div>
						<div className="col-3">
							<button type="submit" className="btn btn-primary w-100">
								Create
							</button>
						</div>
					</div>
				</form>
			</section>

			<CustomModal
				icon={IconSuccess}
				subIcon={IconWarningTriangle}
				modalSubIconClass="icon-create-ad-warning"
				title="Ad successfully created with warning"
				desc="Ad status cannot be activated because ad slot limit is reached."
				btnClass="btn-primary btn-modal-ok"
				textPrimary="OK"
				onClickPrimary={handleModalButtonPress}
			/>
		</EventLayoutContainer>
	);
};

export default withRouter(CreateAd);
