export const LANDING_PAGE_BG_THEME_COLORS = [
	"#887949",
	"#884953",
	"#538849",
	"#496188",
	"#1A2232",
	"#754988",
	"#497E88",
	"#886949",
	"#884977",
	"#321A1A",
];

export const FEATURED_PARTNER_TYPES = {
	event_partners: {
		type: 1,
		name: "Event Partners",
		name_lc: "event partner",
	},
	sponsors: {
		type: 2,
		name: "Sponsors",
		name_lc: "sponsor",
	},
	featured_exhibitor: {
		type: 3,
		name: "Featured Exhibitors",
		name_lc: "featured exhibitor",
	},
};

export const EVENT_STATUS = {
	unpublish: {
		value: "unpublish",
		display: "Not Published",
		color: "#fa5353",
	},
	publish: {
		value: "publish",
		display: "Published",
		color: "#34a853",
	},
	pause: {
		value: "pause",
		display: "Paused",
		color: "#f5891c",
	},
	resume: {
		value: "resume",
		display: "Published",
		color: "#34a853",
	},
	end: {
		value: "end",
		display: "Ended",
		color: "#fa5353",
	},
	republish: {
		value: "republish",
		display: "Published",
		color: "#34a853",
	},
};

export const EVENT_ACTION = {
	unpublish: "unpublish",
	publish: "publish",
	pause: "pause",
	resume: "resume",
	end: "end",
	republish: "republish",
};

export const EVENT_TICKET_TRANSACTION_STATUS = {
	0: "Failed",
	1: "Processing",
	2: "Successful",
	3: "Failed",
};

export const ORGANIZATION_ACCOUNT_TYPE = {
	1: "Company",
	2: "Individual",
};

export const PAYMENT_METHODS = {
	1: "COD",
	2: "Bank Transfer",
	3: "Online Payment",
};

export const ORDER_STATUS = {
	0: "Unpaid",
	1: "Processing",
	2: "Paid",
	3: "Failed",
};

export const DELIVERY_METHODS = {
	1: "Self Pickup",
	2: "Meetup",
	3: "Delivery",
};
