import apiAction from "../services/apiAction"

const EventTicketAPI = {

    /**
     * Get event tickets
     * @param {int} event_id
     */
    getEventTickets: (event_id) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/${event_id}/tickets`;
            
            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get event ticket summaries
     * @param {int} event_id
     */
    getEventTicketSummaries: (event_id) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/${event_id}/tickets/summaries`;
            
            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Create/update ticket
     * @param {int} event_id 
     * @param {object} data
     */
    postCreateUpdateTicket: (event_id, data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/${event_id}/tickets/charge`;

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get event ticket transactions
     * @param {int} event_id
     */
    getTicketTransactions: (event_id, queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/${event_id}/tickets/transactions`;
            
            apiAction.get(url, queryParams)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },
};

export default EventTicketAPI;
