import React, { useState, useRef } from "react";
import LayoutContainer from "app/views/containers/LayoutContainer/LayoutContainer";
import AccountAPI from "app/apis/account";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import "./style.scss";
import { withRouter } from "react-router-dom";
import IconBack from "app/assets/icon-back-grey.png";
import IconEditWhite from "app/assets/icon-edit-white.png";
import IconDeleteGrey from "app/assets/icon-delete-grey.png";
import InviteMemberRow from "app/views/components/InviteMemberRow/InviteMemberRow";
import LocalStorageService from "app/services/localStorageService";
import { ACCOUNT_TYPES } from "app/config/settings";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";

const AddAccount = ({ history }) => {
	const dispatch = useDispatch();
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();

	const [inputData, setInputData] = useState({
		account_type: ACCOUNT_TYPES["company"]["value"],
		logo: "",
		company_name: "",
		company_email: "",
		company_phone_no: "",
		website: "",
		address: "",
		pic_name: "",
		pic_email: "",
		pic_phone_no: "",
	});

	const [uploadedLogo, setUploadedLogo] = useState(null);
	const [emailInvite, setEmailInvite] = useState("");
	const [teamMembers, setTeamMembers] = useState([]);

	const logoRef = useRef();

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleChangeLogo = (event) => {
		setUploadedLogo(event.target.files[0]);
	};

	const handleDeleteLogo = () => {
		setUploadedLogo(null);
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleCreateOrganization = (event) => {
		event.preventDefault();
		showLoading();

		if (!selectedOrganizationAccount) {
			hideLoading();
			openAlertSnackbar("Organization is required", "error");
			return;
		}

		let formData = new FormData();

		if (uploadedLogo) {
			formData.append("thumbnail", uploadedLogo);
		}

		formData.append("organization_id", selectedOrganizationAccount.id);
		formData.append("name", inputData.company_name);
		formData.append("type", inputData.account_type);
		formData.append("email", inputData.company_email);
		formData.append("phone_no", inputData.company_phone_no);
		formData.append("website", inputData.website);
		formData.append("address", inputData.address);

		if (inputData.account_type === ACCOUNT_TYPES["company"]["value"]) {
			formData.append("pic_name", inputData.pic_name);
			formData.append("pic_phone_no", inputData.pic_phone_no);
			formData.append("pic_email", inputData.pic_email);
		}

		teamMembers.forEach((teamMemberEmail) => {
			formData.append("organization_account_user_invitations[]", JSON.stringify({ email: teamMemberEmail }));
		});

		AccountAPI.postCreateOrganizationAccount(formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					const { id } = response.data;
					history.push(`/accounts/view/${id}`);
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleSubmitInvite = (event) => {
		// When hit enter, add to the array
		if (event.key === "Enter") {
			// Prevent default submit form
			event.preventDefault();

			setTeamMembers([...teamMembers, emailInvite]);
			setEmailInvite("");
		}
	};

	const handleRemoveTeamMember = (index) => {
		let teamMembersCopy = [...teamMembers];
		teamMembersCopy.splice(index, 1);
		setTeamMembers(teamMembersCopy);
	};

	return (
		<LayoutContainer>
			<section className="page-header d-flex justify-content-between align-items-center mb-4">
				<div className="d-flex align-items-center">
					<div className="btn-back" onClick={() => history.goBack()}>
						<img src={IconBack} alt="back" className="img-fluid" />
					</div>
					<div>
						<h2 className="title">Add Account</h2>
						<span className="subtitle">New account setup</span>
					</div>
				</div>
			</section>

			<section className="add-account-container p-4">
				<form id="form-add-account" method="post" onSubmit={handleCreateOrganization}>
					<div className="row">
						<div className="col-6">
							<div className="form-group">
								<label>Account Type</label>
								<div className="select-wrapper">
									<select
										className="form-control"
										name="account_type"
										value={inputData.account_type}
										onChange={handleInputOnChange}>
										{Object.keys(ACCOUNT_TYPES).map((accountTypeKey) => {
											return (
												<option
													key={accountTypeKey}
													value={ACCOUNT_TYPES[accountTypeKey]["value"]}>
													{ACCOUNT_TYPES[accountTypeKey]["display"]}
												</option>
											);
										})}
									</select>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label>
									{parseInt(inputData.account_type) === ACCOUNT_TYPES["company"]["value"]
										? "Company Logo"
										: "Account Logo"}
								</label>
								<div className="d-flex align-items-start">
									<img
										src={
											uploadedLogo
												? URL.createObjectURL(uploadedLogo)
												: inputData.logo
												? inputData.logo
												: IconUpload
										}
										alt="event-logo"
										className="img-fluid img-logo"
										onClick={browsePicture}
									/>
									<input
										type="file"
										name="logo"
										onChange={handleChangeLogo}
										value=""
										ref={logoRef}
										className="d-none"
										accept="image/*"
									/>
									<div className="ml-3">
										<div className="d-flex align-items-start mb-1">
											<button
												type="button"
												className="btn btn-primary d-flex align-items-center"
												onClick={browsePicture}>
												Replace <img src={IconEditWhite} alt="edit" className="icon-action" />
											</button>
											<button
												type="button"
												className="btn btn-outline-secondary ml-3 d-flex align-items-center"
												onClick={handleDeleteLogo}
												disabled={uploadedLogo ? false : true}>
												Delete <img src={IconDeleteGrey} alt="edit" className="icon-action" />
											</button>
										</div>
										<div className="text-info">Suggested size: 100x100px, 2MB and below only</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="company_name">
									{parseInt(inputData.account_type) === ACCOUNT_TYPES["company"]["value"]
										? "Company Name"
										: "Name"}
								</label>
								<input
									id="company_name"
									type="text"
									className="form-control"
									aria-describedby="company_name"
									name="company_name"
									value={inputData.company_name}
									onChange={handleInputOnChange}
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="website">Website</label>
								<input
									id="website"
									type="url"
									className="form-control"
									aria-describedby="website"
									name="website"
									value={inputData.website}
									onChange={handleInputOnChange}
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="company_phone_no">Contact Number</label>
								<input
									id="company_phone_no"
									type="text"
									className="form-control"
									aria-describedby="company_phone_no"
									name="company_phone_no"
									value={inputData.company_phone_no}
									onChange={handleInputOnChange}
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="company_email">Email Address</label>
								<input
									id="company_email"
									type="email"
									className="form-control"
									aria-describedby="company_email"
									name="company_email"
									value={inputData.company_email}
									onChange={handleInputOnChange}
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="address">Address</label>
								<input
									id="address"
									type="text"
									className="form-control"
									aria-describedby="address"
									name="address"
									value={inputData.address}
									onChange={handleInputOnChange}
								/>
							</div>
						</div>

						{parseInt(inputData.account_type) === ACCOUNT_TYPES["company"]["value"] && (
							<>
								<div className="col-6">
									<div className="form-group">
										<label htmlFor="pic_name">PIC Name</label>
										<input
											id="pic_name"
											type="text"
											className="form-control"
											aria-describedby="pic_name"
											name="pic_name"
											value={inputData.pic_name}
											onChange={handleInputOnChange}
										/>
									</div>
								</div>
								<div className="col-6">
									<div className="form-group">
										<label htmlFor="pic_phone_no">PIC Contact Number</label>
										<input
											id="pic_phone_no"
											type="text"
											className="form-control"
											aria-describedby="pic_phone_no"
											name="pic_phone_no"
											value={inputData.pic_phone_no}
											onChange={handleInputOnChange}
										/>
									</div>
								</div>
								<div className="col-6">
									<div className="form-group">
										<label htmlFor="pic_email">PIC Email Address</label>
										<input
											id="pic_email"
											type="text"
											className="form-control"
											aria-describedby="pic_email"
											name="pic_email"
											value={inputData.pic_email}
											onChange={handleInputOnChange}
										/>
									</div>
								</div>
							</>
						)}

						<div className="invite-team-members col-12 form-group">
							<label>Invite Staff/Team Members</label>
							{teamMembers.map((teamMember, index) => {
								return (
									<InviteMemberRow
										value={teamMember}
										allowRemove
										onRemove={() => handleRemoveTeamMember(index)}
										readOnly
									/>
								);
							})}

							<InviteMemberRow
								value={emailInvite}
								onChange={(event) => setEmailInvite(event.target.value)}
								onKeyDown={handleSubmitInvite}
							/>
						</div>
					</div>

					<div className="row">
						<div className="col-10"></div>
						<div className="col-2">
							<button type="submit" className="btn btn-primary w-100">
								Create
							</button>
						</div>
					</div>
				</form>
			</section>
		</LayoutContainer>
	);
};

export default withRouter(AddAccount);
