import apiAction from "../services/apiAction"

const ChannelSessionAPI = {

    /**
     * Get speaker listing
     * @param {object} queryParams 
     * {page, limit, sort_order}
     */
    getSpeakerListing: (queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/speakers`;
            
            apiAction.get(url, queryParams)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get channel session listing
     * @param {object} queryParams 
     * {channel_id, page, limit}
     */
    getChannelSessionListing: (queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/sessions`;
            
            apiAction.get(url, queryParams)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },


    /**
     * Get channel session detail
     * @param {int} sessionId 
     */
    getChannelSessionDetail: (sessionId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/sessions/${sessionId}`;
            
            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Create channel session
     * @param {FormData} formData 
     */
    postCreateChannelSession: (formData) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/sessions`;

            apiAction.post(url, formData)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Update channel session
     * @param {int} sessionId
     * @param {FormData} formData 
     */
    postUpdateChannelSession: (sessionId, formData) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/sessions/${sessionId}`;

            apiAction.post(url, formData)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Delete channel session
     * @param {int} channelId
     */
    deleteChannelSession: (sessionId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/sessions/${sessionId}`;

            apiAction.delete(url)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get active audience listing
     * @param {object} queryParams 
     * {session_id, page, limit, sort_by, sort_order}
     */
    getActiveAudienceListing: (queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/audience/active`;
            
            apiAction.get(url, queryParams)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get history audience listing
     * @param {object} queryParams 
     * {session_id, page, limit, sort_by, sort_order}
     */
    getHistoryAudienceListing: (queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/audience/history`;
            
            apiAction.get(url, queryParams)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get qna listing
     * @param {object} queryParams 
     * {session_id, page, limit, sort_by, sort_order}
     */
    getQnaListing: (queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/questions`;
            
            apiAction.get(url, queryParams)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Post add answer to question
     * @param {int} questionId
     * @param {object} data 
     */
    postAddAnswer: (questionId, data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/questions/${questionId}/answers`;

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Post add question
     * @param {object} data
     */
    postAddQuestion: (data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/questions`;

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get qna details
     * @param {int} question_id 
     */
    getQnaDetails: (question_id) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/questions/${question_id}`;
            
            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Post update question
     * @param {int} questionId
     * @param {object} data 
     */
    postUpdateQuestion: (questionId, data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/questions/${questionId}`;

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Post update answer
     * @param {object} data
     */
    postUpdateAnswer: (questionId, answerId, data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/questions/${questionId}/answers/${answerId}`;

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Delete qna
     * @param {int} questionId
     */
    deleteQna: (questionId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/questions/${questionId}`;

            apiAction.delete(url)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get poll listing
     * @param {object} queryParams 
     * {session_id, page, limit, sort_by, sort_order}
     */
     getPollListing: (queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/polls`;
            
            apiAction.get(url, queryParams)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Post add poll
     * @param {object} data 
     */
    postAddPoll: (data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/polls`;

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Delete poll
     * @param {int} pollId
     */
    deletePoll: (pollId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/polls/${pollId}`;

            apiAction.delete(url)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get poll detail
     */
    getPollDetail: (pollId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/polls/${pollId}`;
            
            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Post update poll
     * @param {object} data 
     */
    postUpdatePoll: (pollId, data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/session/polls/${pollId}`;

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Post toggle live
     * @param {object} data 
     */
    postToggleLive: (sessionId, data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/channel/sessions/${sessionId}/live`;

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },
};

export default ChannelSessionAPI;
