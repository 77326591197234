import apiAction from "../services/apiAction"

const AdsManagerAPI = {

    /**
     * Get ads type listing
     * @param {object} queryParams 
     * {event_id}
     */
    getAdsTypeListing: (queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/ad_manager/types`;
            
            apiAction.get(url, queryParams)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get ads listing
     * @param {object} queryParams 
     */
    getAdsListing: (queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/ad_manager`;
            
            apiAction.get(url, queryParams)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get ads summary
     * @param {object} queryParams 
     */
    getAdsSummary: (queryParams) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/ad_manager/summary`;

            apiAction.get(url, queryParams)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Create new ad
     * @param {FormData} formData 
     */
    postCreateAd: (formData) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/ad_manager`;

            apiAction.post(url, formData)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Update ad
     * @param {int} adId
     * @param {FormData} formData 
     */
    postUpdateAd: (adId, formData) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/ad_manager/${adId}`;

            apiAction.post(url, formData)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Delete ad
     * @param {int} adId
     */
    deleteAd: (adId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/ad_manager/${adId}`;

            apiAction.delete(url)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get ad details
     * @param {int} adId
     */
    getAdDetails: (adId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/ad_manager/${adId}`;

            apiAction.get(url)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get ad details performance breakdown
     * @param {int} adId
     * @param {object} queryParams
     */
    getAdDetailsPeformanceBreakdown: (adId, queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/ad_manager/${adId}/performance`;

            apiAction.get(url, queryParams)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get ad details performance breakdown export
     * @param {int} adId
     */
    getAdDetailsPeformanceBreakdownExport: (adId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/ad_manager/${adId}/performance/download`;

            apiAction.get(url)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Update ad
     * @param {int} adId
     * @param {object} data 
     */
    postUpdateAdType: (eventId, adTypeId, data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/event/${eventId}/ad_manager/types/${adTypeId}`;

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },
};

export default AdsManagerAPI;
