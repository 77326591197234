import apiAction from "../services/apiAction";

const UsageAPI = {
	/**
	 * Get visitor usage
	 * @param {int} organization_id
	 */
	getVisitorUsage: (organization_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/organization/${organization_id}/usages/visitors`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get session usage
	 * @param {int} organization_id
	 */
	getSessionUsage: (organization_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/organization/${organization_id}/usages/sessions`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get exhibitor usage
	 * @param {int} organization_id
	 */
	getExhibitorUsage: (organization_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/organization/${organization_id}/usages/exhibitors`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get advertisements usage
	 * @param {int} organization_id
	 */
	getAdvertisementUsage: (organization_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/organization/${organization_id}/usages/advertisements`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get administrator usage
	 * @param {int} organization_id
	 */
	getAdministratorUsage: (organization_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/organization/${organization_id}/usages/administrators`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},
};

export default UsageAPI;
