/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { withRouter, useParams, Link } from "react-router-dom";
import "./style.scss";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import { useDispatch, useSelector } from "react-redux";
import EventAPI from "app/apis/event";
import ChannelSessionAPI from "app/apis/channelSession";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import IconBack from "app/assets/icon-back-grey.png";
import IconDelete from "app/assets/icon-delete-red.png";
import IconDeleteGrey from "app/assets/icon-delete-grey.png";
import CustomModal from "app/views/components/CustomModal/CustomModal";
import { commonActions } from "app/states/common";
import { eventActions } from "app/states/event";
import moment from "moment";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import SessionStorageService from "app/services/sessionStorageService";
import IconWarningTriangle from "app/assets/icon-alert-triangle-yellow.png";
import EventInfo from "app/views/components/EventInfo/EventInfo";
import UsageAPI from "app/apis/usage";

const MAX_TABLE_ROWS = 10;

const ViewChannel = ({ history }) => {
	const lastViewingPage = SessionStorageService.getLastViewingPage();

	const dispatch = useDispatch();
	const openCustomModal = () => dispatch(commonActions.openCustomModal());
	const closeCustomModal = () => dispatch(commonActions.closeCustomModal());
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setSelectedDateForSessionSidebar = (selectedDate) =>
		dispatch(eventActions.setSelectedDateForSessionSidebar(selectedDate));

	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);

	const { eventId, channelId } = useParams();

	const logoRef = useRef();

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [uploadedLogo, setUploadedLogo] = useState(null);
	const [logoUrl, setLogoUrl] = useState(null);
	const [channelSessions, setChannelSessions] = useState([]);

	const [page, setPage] = useState(lastViewingPage || 1);
	const [totalPageCount, setTotalPageCount] = useState(0);

	const [sessionUsage, setSessionUsage] = useState(null);

	// Get event channel details
	useEffect(() => {
		EventAPI.getEventChannelDetails(channelId)
			.then((response) => {
				const { name, description, thumbnail_url } = response.data;

				setName(name);
				setDescription(description || "");
				setLogoUrl(thumbnail_url);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	// Get channel session listing based on pagination
	useEffect(() => {
		getChannelSessionListing();
	}, [page]);

	// Get session usage limit
	useEffect(() => {
		UsageAPI.getSessionUsage(selectedOrganizationAccount.id)
			.then((response) => {
				setSessionUsage(response);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	const getChannelSessionListing = () => {
		let params = {
			channel_id: channelId,
			page: page,
			limit: MAX_TABLE_ROWS,
		};

		ChannelSessionAPI.getChannelSessionListing(params)
			.then((response) => {
				const { event_channel_sessions, paging } = response.data;
				const { last_page } = paging;

				setChannelSessions(event_channel_sessions);
				setTotalPageCount(last_page);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleChangeLogo = (event) => {
		setUploadedLogo(event.target.files[0]);
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleSubmitUpdateChannel = (event) => {
		event.preventDefault();
		showLoading();

		let formData = new FormData();
		formData.append("name", name);
		formData.append("description", description);

		if (uploadedLogo) {
			formData.append("thumbnail", uploadedLogo);
		}

		EventAPI.postUpdateEventChannel(channelId, formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					openAlertSnackbar("Channel Updated", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleConfirmDelete = () => {
		showLoading();

		// Delete event
		EventAPI.deleteEventChannel(channelId)
			.then((response) => {
				hideLoading();
				const { code } = response;

				if (code === 200) {
					closeCustomModal();
					history.replace(`/events/view/${eventId}/channels`);
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				hideLoading();
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	const handleViewChannelSession = (channelSession) => {
		const { id, start_at } = channelSession;
		setSelectedDateForSessionSidebar(start_at);
		SessionStorageService.setLastViewingPage(page);
		history.push(`/events/view/${eventId}/channels/${channelId}/session/${id}`);
	};

	return (
		<EventLayoutContainer>
			<div id="channel-view-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-5">
					<div className="d-flex align-items-center">
						<div>
							<img
								src={IconBack}
								alt="back"
								className="img-fluid img-back"
								onClick={() => history.replace(`/events/view/${eventId}/channels`)}
							/>
						</div>
						<div>
							<img src={logoUrl} alt="logo" className="img-fluid img-channel-logo" />
						</div>
						<div>
							<h2 className="title">{name}</h2>
							<span className="subtitle">Channel Room</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="section-container channel-details-container mb-5">
					<form className="form-channel" method="post" onSubmit={handleSubmitUpdateChannel}>
						<div className="form-group">
							<label htmlFor="name">Name</label>
							<input
								id="name"
								type="text"
								className="form-control"
								aria-describedby="name"
								name="name"
								value={name}
								onChange={(event) => setName(event.target.value)}
								required
							/>
						</div>

						<div className="form-group">
							<label htmlFor="description">Description</label>
							<textarea
								id="description"
								className="form-control"
								aria-describedby="description"
								name="description"
								value={description}
								onChange={(event) => setDescription(event.target.value)}
								required
								rows={3}
							/>
						</div>

						<div className="form-group">
							<label htmlFor="image">Logo Image</label>
							<img
								src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : logoUrl ? logoUrl : IconUpload}
								alt="logo"
								className="img-fluid img-logo"
								onClick={browsePicture}
							/>
							<input
								id="image"
								type="file"
								name="logo"
								onChange={handleChangeLogo}
								ref={logoRef}
								className="d-none"
								accept="image/*"
							/>
						</div>

						<div className="row mt-5">
							<div className="col-6"></div>
							<div className="col-3">
								<button
									type="button"
									className="btn btn-outline-danger w-100"
									onClick={openCustomModal}>
									<img src={IconDelete} alt="delete" className="icon-btn" /> Delete
								</button>
							</div>
							<div className="col-3">
								<button type="submit" className="btn btn-primary w-100">
									Save
								</button>
							</div>
						</div>
					</form>
				</section>

				<section className="section-container channel-sessions-container">
					<div className="row">
						<div className="col-9"></div>
						<div className="col-3">
							<button
								type="button"
								className="btn btn-primary w-100"
								onClick={() =>
									history.push(`/events/view/${eventId}/channels/${channelId}/session/add`)
								}
								disabled={
									sessionUsage && sessionUsage.available <= 0 && sessionUsage.limit !== -1
										? true
										: false
								}>
								Add New Session
							</button>
						</div>
					</div>

					{sessionUsage && sessionUsage.available <= 0 && sessionUsage.limit !== -1 && (
						<div className="entitlement-warning-container mt-4 mb-3">
							<img src={IconWarningTriangle} alt="warning" className="icon-entitlement-warning" />
							<div className="text-entitlement-warning">
								Sessions limit reached! Upgrade your plan now to create more sessions.
							</div>
							<Link className="text-manage-subscription" to="/billings">
								Manage Subscription
							</Link>
						</div>
					)}

					<div className="row mt-4">
						<table className="table table-hover">
							<thead>
								<tr>
									<th scope="col" className="table-header">
										Date
									</th>
									<th scope="col" className="table-header">
										Time
									</th>
									<th scope="col" className="table-header text-left">
										Session Title
									</th>
								</tr>
							</thead>
							<tbody>
								{channelSessions.map((channelSession) => {
									const { id, name, start_at, end_at, live } = channelSession;

									const date = moment(start_at).format("Do MMM YYYY");
									const startTime = moment(start_at).format("h:mma");
									const endTime = moment(end_at).format("h:mma");

									return (
										<tr
											key={id}
											onClick={() => handleViewChannelSession(channelSession)}
											className="cursor-pointer">
											<td className="table-cell">{date}</td>
											<td className="table-cell">
												{startTime} - {endTime}
											</td>
											<td className="table-cell text-left">{name}</td>
										</tr>
									);
								})}
							</tbody>
						</table>

						<div className="d-flex align-items-center justify-content-center w-100">
							<AppPagination
								pageCount={totalPageCount}
								handlePageChange={handlePageChange}
								initialPage={page - 1}
							/>
						</div>
					</div>
				</section>

				<CustomModal
					icon={IconDelete}
					title="Are you sure you want to delete?"
					desc="You are about to delete this channel. All sessions in this channel will also be removed. This action cannot be undone."
					btnClass="btn-danger"
					textPrimary={
						<>
							<img src={IconDeleteGrey} alt="delete" className="icon-btn" /> Delete Channel
						</>
					}
					textSecondary="Cancel"
					onClickPrimary={handleConfirmDelete}
					onClickSecondary={closeCustomModal}
				/>
			</div>
		</EventLayoutContainer>
	);
};

export default withRouter(ViewChannel);
