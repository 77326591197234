import apiAction from "../services/apiAction";

const OrdersAPI = {
	/**
	 * Get order listing
	 * @param {object} queryParams
	 * {type}
	 */
	getOrderListing: (queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/store/orders`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get order details
	 * @param {int} orderId
	 */
	getOrderDetails: (orderId) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/store/orders/${orderId}`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get order summary
	 * @param {object} queryParams
	 */
	getOrderSummary: (queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/store/orders/summary`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},
};

export default OrdersAPI;
