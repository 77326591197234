import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/states/common";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import IconEditWhite from "app/assets/icon-edit-white.png";
import IconDeleteGrey from "app/assets/icon-delete-grey.png";
import IconLogout from "app/assets/icon-logout.png";
import { withRouter } from "react-router";
import LocalStorageService from "app/services/localStorageService";
import ProfileAPI from "app/apis/profile";

const ProfileModal = ({ history }) => {
	const userProfile = LocalStorageService.getUserProfile();

	const dispatch = useDispatch();
	const closeProfileModal = () => dispatch(commonActions.closeProfileModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const logoutUserAndResetStates = () => dispatch(commonActions.logoutUserAndResetStates());

	const showProfileModal = useSelector(({ common }) => common.showProfileModal);

	const [triggerUpdateUserProfileData, setTriggerUpdateUserProfileData] = useState(0);
	const [showPasswordSection, setShowPasswordSection] = useState(false);
	const [profileDetails, setProfileDetails] = useState({
		photo: "",
		first_name: "",
		last_name: "",
		email: "",
		phone_no: "",
		company_name: "",
		website: "",
		current_password: "",
		new_password: "",
		confirm_password: "",
	});

	const [uploadedPhoto, setUploadedPhoto] = useState(null);
	const photoRef = useRef();

	// Get user profile
	useEffect(() => {
		if (!showProfileModal) return;

		ProfileAPI.getUserProfile(userProfile.id)
			.then((response) => {
				const { email, first_name, last_name, phone_no, company_name, website, profile_image_url } =
					response.data;

				setProfileDetails({
					...profileDetails,
					email: email,
					first_name: first_name,
					last_name: last_name,
					phone_no: phone_no,
					company_name: company_name,
					website: website,
					photo: profile_image_url,
				});

				// Update user profile data in local storage
				if (triggerUpdateUserProfileData > 0) {
					const updatedUserProfile = {
						...userProfile,
						first_name: first_name,
						last_name: last_name,
						profile_image_url: profile_image_url,
					};
					LocalStorageService.setUserProfile(updatedUserProfile);
					window.dispatchEvent(new Event("updateUserProfileData"));
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [showProfileModal, triggerUpdateUserProfileData]);

	const handleInputOnChange = (event) => {
		setProfileDetails({ ...profileDetails, [event.target.name]: event.target.value });
	};

	const handleChangePhoto = (event) => {
		setUploadedPhoto(event.target.files[0]);
	};

	const browsePicture = () => {
		photoRef.current.click();
	};

	const handleDeletePhoto = () => {
		setUploadedPhoto(null);
	};

	const handleSaveProfile = (event) => {
		event.preventDefault();
		showLoading();

		let formData = new FormData();

		if (profileDetails.first_name) {
			formData.append("first_name", profileDetails.first_name);
		}
		if (profileDetails.last_name) {
			formData.append("last_name", profileDetails.last_name);
		}
		if (profileDetails.company_name) {
			formData.append("company_name", profileDetails.company_name);
		}
		if (profileDetails.website) {
			formData.append("website", profileDetails.website);
		}
		if (profileDetails.phone_no) {
			formData.append("phone_no", profileDetails.phone_no);
		}
		if (uploadedPhoto) {
			formData.append("profile_image_url", uploadedPhoto);
		}

		// Change password if all 3 password fields filled
		if (profileDetails.current_password && profileDetails.new_password && profileDetails.confirm_password) {
			if (profileDetails.new_password !== profileDetails.confirm_password) {
				openAlertSnackbar("New Password and Confirm Password are not same.", "error");
				return;
			}

			formData.append("current_password", profileDetails.current_password);
			formData.append("new_password", profileDetails.new_password);
		}

		ProfileAPI.postUpdateUserProfile(userProfile.id, formData)
			.then((response) => {
				if (response.code === 200 && response.data.updated) {
					setTriggerUpdateUserProfileData(triggerUpdateUserProfileData + 1);
					openAlertSnackbar("Profile Updated", "success");
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleLogout = () => {
		logoutUserAndResetStates();
		LocalStorageService.clearAll();
		closeProfileModal();
		history.push("/");
	};

	const handleCloseModal = () => {
		setShowPasswordSection(false);
		setUploadedPhoto(null);
		closeProfileModal();
	};

	return (
		<Modal
			show={showProfileModal}
			onHide={handleCloseModal}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="profile-modal"
			centered>
			<Modal.Body>
				<div className="profile-modal-header">
					<h3>User Profile</h3>
					<span className="btn-logout" onClick={handleLogout}>
						<img src={IconLogout} alt="logout" className="icon-action mr-1" />
						Logout
					</span>
				</div>
				<div className="profile-modal-body">
					<form id="form-profile" method="post" onSubmit={handleSaveProfile}>
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<label>Profile Photo</label>
									<div className="row">
										<div className="col-2 pr-0">
											<img
												src={
													uploadedPhoto
														? URL.createObjectURL(uploadedPhoto)
														: profileDetails.photo
														? profileDetails.photo
														: IconUpload
												}
												alt="profile"
												className="img-fluid img-photo"
												onClick={browsePicture}
											/>
										</div>
										<div className="col-8">
											<button type="button" className="btn btn-primary" onClick={browsePicture}>
												Replace <img src={IconEditWhite} alt="edit" className="icon-action" />
											</button>
											<button
												type="button"
												className="btn btn-outline-secondary mx-3"
												onClick={handleDeletePhoto}>
												Delete <img src={IconDeleteGrey} alt="edit" className="icon-action" />
											</button>
											<div>
												<small>Suggested size: 100 x 100px, 2MB and below only</small>
											</div>
										</div>
										<input
											type="file"
											name="logo"
											onChange={handleChangePhoto}
											ref={photoRef}
											className="d-none"
											accept="image/*"
											value=""
										/>
									</div>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="first_name">
										First Name <span className="text-danger">*</span>
									</label>
									<input
										id="first_name"
										type="text"
										className="form-control"
										aria-describedby="first_name"
										name="first_name"
										value={profileDetails.first_name}
										onChange={handleInputOnChange}
										required
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="last_name">
										Last Name <span className="text-danger">*</span>
									</label>
									<input
										id="last_name"
										type="text"
										className="form-control"
										aria-describedby="last_name"
										name="last_name"
										value={profileDetails.last_name}
										onChange={handleInputOnChange}
										required
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="email">
										Email Address <span className="text-danger">*</span>
									</label>
									<div className="email-input-wrapper">
										<input
											id="email"
											type="email"
											className="form-control"
											aria-describedby="email"
											name="email"
											value={profileDetails.email}
											onChange={handleInputOnChange}
											readOnly
										/>
										{!showPasswordSection && (
											<span
												className="text-change-password"
												onClick={() => setShowPasswordSection(true)}>
												CHANGE PASSWORD
											</span>
										)}
									</div>
								</div>
							</div>

							{showPasswordSection && (
								<>
									<div className="col-12">
										<div className="form-group">
											<label htmlFor="current_password">
												Current Password <span className="text-danger">*</span>
											</label>
											<input
												id="current_password"
												type="password"
												className="form-control"
												aria-describedby="current_password"
												name="current_password"
												value={profileDetails.current_password}
												onChange={handleInputOnChange}
												required
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="form-group">
											<label htmlFor="new_password">
												New Password <span className="text-danger">*</span>
											</label>
											<input
												id="new_password"
												type="password"
												className="form-control"
												aria-describedby="new_password"
												name="new_password"
												value={profileDetails.new_password}
												onChange={handleInputOnChange}
												required
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="form-group">
											<label htmlFor="confirm_password">
												Confirm Password <span className="text-danger">*</span>
											</label>
											<input
												id="confirm_password"
												type="password"
												className="form-control"
												aria-describedby="confirm_password"
												name="confirm_password"
												value={profileDetails.confirm_password}
												onChange={handleInputOnChange}
												required
											/>
										</div>
									</div>
								</>
							)}

							<div className="col-12">
								<div className="form-group">
									<label htmlFor="phone_no">
										Contact Number <span className="text-danger">*</span>
									</label>
									<input
										id="phone_no"
										type="phone_no"
										className="form-control"
										aria-describedby="phone_no"
										name="phone_no"
										value={profileDetails.phone_no}
										onChange={handleInputOnChange}
										required
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="company_name">
										Company Name <span className="text-danger">*</span>
									</label>
									<input
										id="company_name"
										type="company_name"
										className="form-control"
										aria-describedby="company_name"
										name="company_name"
										value={profileDetails.company_name}
										onChange={handleInputOnChange}
										required
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="website">
										Website <span className="text-danger">*</span>
									</label>
									<input
										id="website"
										type="url"
										className="form-control"
										aria-describedby="website"
										name="website"
										value={profileDetails.website}
										onChange={handleInputOnChange}
										required
									/>
								</div>
							</div>
						</div>
						<div className="row mt-4">
							<div className="col-9"></div>
							<div className="col-3">
								<button type="submit" className="btn btn-primary w-100">
									Save
								</button>
							</div>
						</div>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(ProfileModal);
