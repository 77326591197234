/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import "./style.scss";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import LocalStorageService from "app/services/localStorageService";
import OrdersAPI from "app/apis/orders";
import { PAYMENT_METHODS } from "app/config/constants";
import moment from "moment";

const MAX_RECORDS = 10;

const StoreOrdersContainer = ({ history }) => {
	let { eventId } = useParams();

	const exhibitorRoomDetails = LocalStorageService.getExhibitorRoomDetails();
	const storeId = exhibitorRoomDetails.event_exhibitor_room_stores[0].id;

	const [page, setPage] = useState(1);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [orderListing, setOrderListing] = useState([]);

	useEffect(() => {
		let params = {
			page: page,
			store_id: storeId,
			limit: MAX_RECORDS,
			event_id: eventId,
		};

		getOrderListing(params);
	}, [page]);

	const getOrderListing = (params) => {
		OrdersAPI.getOrderListing(params)
			.then((response) => {
				const { event_exhibitor_room_store_products, paging } = response.data;
				const { last_page } = paging;

				setTotalPageCount(last_page);
				setOrderListing(event_exhibitor_room_store_products);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	const handleViewDetails = (transactionId) => {
		history.push(`/events/view/${eventId}/store-transactions/view/${transactionId}`);
	};

	return (
		<section className="tab-panel-container store-products-container mb-4">
			<div className="row align-items-center mb-3">
				<div className="col-12">
					<h4 className="tab-content-title">View Orders</h4>
				</div>
			</div>

			<table className="table table-hover">
				<thead>
					<tr>
						<th scope="col" className="table-header">
							Order Date
						</th>
						<th scope="col" className="table-header">
							Order Number
						</th>
						<th scope="col" className="table-header">
							Customer Name
						</th>
						<th scope="col" className="table-header">
							Exhibitor Name
						</th>
						<th scope="col" className="table-header">
							Purchased Amount
						</th>
						<th scope="col" className="table-header">
							Payment Method
						</th>
					</tr>
				</thead>
				<tbody>
					{orderListing.map((order) => {
						const {
							id,
							created_at,
							order_no,
							customer_name,
							exhibitor_name,
							currency,
							amount,
							payment_method,
						} = order;

						return (
							<tr key={`order-${id}`} className="cursor-pointer" onClick={() => handleViewDetails(id)}>
								<td className="table-cell">
									{created_at ? moment(created_at).format("DD MMMM YYYY") : "N/A"}
								</td>
								<td className="table-cell">{order_no ?? "N/A"}</td>
								<td className="table-cell">{customer_name ?? "N/A"}</td>
								<td className="table-cell">{exhibitor_name ?? "N/A"}</td>
								<td className="table-cell">{currency && amount ? `${currency}${amount}` : "N/A"}</td>
								<td className="table-cell">
									{payment_method && PAYMENT_METHODS[payment_method]
										? PAYMENT_METHODS[payment_method]
										: "N/A"}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>

			<AppPagination pageCount={totalPageCount} handlePageChange={handlePageChange} />
		</section>
	);
};

export default withRouter(StoreOrdersContainer);
