import React, { useState } from "react";
import "./style.scss";
import ReactPaginate from "react-paginate";

const AppPagination = ({ pageCount, handlePageChange, initialPage, className }) => {
	
    const [currPageNum, setCurrPageNum] = useState(0);
    
    const onPageChange = ({ selected: selectedPage }) => {
        setCurrPageNum(selectedPage);

		if (typeof handlePageChange === 'function') {
			handlePageChange(selectedPage + 1);
		}
    }

	return (
		<ReactPaginate
			initialPage={initialPage || 0}
			previousLabel={"<"}
			nextLabel={">"}
			breakLabel={"..."}
			breakClassName={"break-me"}
			pageCount={pageCount}
			marginPagesDisplayed={0}
			pageRangeDisplayed={9}
			onPageChange={onPageChange}
			containerClassName={`pagination-container ${className ? className : ''}`}
			activeClassName={"active"}
            previousClassName={currPageNum === 0 ? "disabled" : ""}
            nextClassName={currPageNum === pageCount ? "disabled" : ""}
		/>
	);
};

export default AppPagination;
