import axios from 'axios';
import history from './../routes/history';
import TokenService from './tokenService';
import LocalStorageService from './localStorageService';
import { commonActions } from 'app/states/common';
import reduxStore from "app/services/redux/reduxStore";
import SessionStorageService from './sessionStorageService';
 

export const InitAxiosInterceptor = () => {
    const { dispatch } = reduxStore;

    // Request
    axios.interceptors.request.use(
        // 
    );

    // Response
    axios.interceptors.response.use((response) => {
        const { code, errors } = response.data;
        
        if (code === 400 && (errors.includes("jwt expired") || errors.includes("invalid token") || errors.includes("invalid algorithm"))) {
            SessionStorageService.setTokenExpired(true);
            dispatch(commonActions.logoutUserAndResetStates());

            TokenService.clearTokens();
            LocalStorageService.clearAll();

            history.push('/');

            throw errors;
        }

        return response;
    }, (error) => {
        throw error
    });
};