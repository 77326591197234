/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import IconBack from "app/assets/icon-back-grey.png";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import IconRemove from "app/assets/icon-close.png";
import ExhibitorAPI from "app/apis/exhibitor";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import IconDefaultUser from "app/assets/icon-default-user.png";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";
import { DROPDOWN_RECORDS_PER_PAGE } from "app/config/settings";
import EventInfo from "app/views/components/EventInfo/EventInfo";

const AddExhibitorRoom = ({ history }) => {
	let { eventId } = useParams();

	const dispatch = useDispatch();
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const [inputData, setInputData] = useState({
		name: "",
		short_description: "",
		full_description: "",
		logo: "",
		product_slots: 10,
		agent_slots: 3,
		room_breakouts: 5,
		website: "",
		linkedin: "",
		facebook: "",
		instagram: "",
		youtube: "",
		twitter: "",
	});

	const [uploadedLogo, setUploadedLogo] = useState(null);

	const [searchAccountText, setSearchAccountText] = useState("");
	const [accounts, setAccounts] = useState([]);
	const [accountsFiltered, setAccountsFiltered] = useState([]);
	const [selectedAccount, setSelectedAccount] = useState(null);
	const [showAccountSelection, setShowAccountSelection] = useState(false);
	const [accountPage, setAccountPage] = useState(1);
	const [accountTotalPage, setAccountTotalPage] = useState(1);
	const [isAccountListFirstLoad, setIsAccountListFirstLoad] = useState(true);
	const [loadedAccountNextPage, setLoadedAccountNextPage] = useState(true);
	const [triggerAccountSearch, setTriggerAccountSearch] = useState(0);

	const [searchCategoryText, setSearchCategoryText] = useState("");
	const [exhibitorCategories, setExhibitorCategories] = useState([]);
	const [exhibitorCategoriesFiltered, setExhibitorCategoriesFiltered] = useState([]);
	const [selectedExhibitorCategories, setSelectedExhibitorCategories] = useState([]);
	const [showExhibitorCategoriesSelection, setShowExhibitorCategoriesSelection] = useState(false);
	const [categoryPage, setCategoryPage] = useState(1);
	const [categoryTotalPage, setCategoryTotalPage] = useState(1);
	const [isCategoryListFirstLoad, setIsCategoryListFirstLoad] = useState(true);
	const [loadedCategoryNextPage, setLoadedCategoryNextPage] = useState(true);
	const [triggerCategorySearch, setTriggerCategorySearch] = useState(0);

	const logoRef = useRef();

	// Get exhibitor category listings
	useEffect(() => {
		let params = {
			page: categoryPage,
			limit: DROPDOWN_RECORDS_PER_PAGE,
			event_id: eventId,
			sort_by: "name",
			sort_order: "asc",
		};

		if (searchCategoryText) {
			params["name"] = searchCategoryText;
		}

		ExhibitorAPI.getExhibitorCategoryListing(params)
			.then((response) => {
				const { exhibitor_categories, paging } = response.data;
				const { last_page } = paging;

				setExhibitorCategories([...exhibitorCategories, ...exhibitor_categories]);
				setExhibitorCategoriesFiltered([...exhibitorCategories, ...exhibitor_categories]);

				setCategoryTotalPage(last_page);
				setIsCategoryListFirstLoad(false);
				setLoadedCategoryNextPage(true);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [categoryPage, triggerCategorySearch]);

	// Search exhibitor category listing
	useEffect(() => {
		if (isCategoryListFirstLoad) {
			return;
		}

		// Reset state and trigger search
		setExhibitorCategories([]);
		setExhibitorCategoriesFiltered([]);
		setCategoryPage(1);
		setTriggerCategorySearch(triggerCategorySearch + 1);
	}, [searchCategoryText]);

	const handleCategorySelectionScroll = (event) => {
		let offset = 20;
		let isBottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + offset;

		if (isBottom && categoryPage < categoryTotalPage && loadedCategoryNextPage) {
			setLoadedCategoryNextPage(false);
			setCategoryPage(categoryPage + 1);
		}
	};

	// Get event account listing
	useEffect(() => {
		let params = {
			page: accountPage,
			limit: DROPDOWN_RECORDS_PER_PAGE,
			event_id: eventId,
		};

		if (searchAccountText) {
			params["search_string"] = searchAccountText;
		}

		ExhibitorAPI.getEventAccountListing(params)
			.then((response) => {
				const { event_accounts, paging } = response.data;
				const { last_page } = paging;

				setAccounts([...accounts, ...event_accounts]);
				setAccountsFiltered([...accounts, ...event_accounts]);

				setAccountTotalPage(last_page);
				setIsAccountListFirstLoad(false);
				setLoadedAccountNextPage(true);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [accountPage, triggerAccountSearch]);

	// Search event account listing
	useEffect(() => {
		if (isAccountListFirstLoad) {
			return;
		}

		// Reset state and trigger search
		setAccounts([]);
		setAccountsFiltered([]);
		setAccountPage(1);
		setTriggerAccountSearch(triggerAccountSearch + 1);
	}, [searchAccountText]);

	const handleEventAccountSelectionScroll = (event) => {
		let offset = 20;
		let isBottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + offset;

		if (isBottom && accountPage < accountTotalPage && loadedAccountNextPage) {
			setLoadedAccountNextPage(false);
			setAccountPage(accountPage + 1);
		}
	};

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleChangeLogo = (event) => {
		setUploadedLogo(event.target.files[0]);
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleCreateRoom = async (event) => {
		event.preventDefault();
		showLoading();

		// Ensure event account is selected
		if (!selectedAccount) {
			hideLoading();
			openAlertSnackbar("Event account is required", "error");
			return;
		}

		// Ensure at least one category is selected
		if (selectedExhibitorCategories.length <= 0) {
			hideLoading();
			openAlertSnackbar("Category is required", "error");
			return;
		}

		// Ensure logo is uploaded
		if (!uploadedLogo) {
			hideLoading();
			openAlertSnackbar("Logo is required", "error");
			return;
		}

		let formData = new FormData();

		formData.append("event_id", eventId);
		formData.append("event_account_id", selectedAccount.event_account_id);
		formData.append("title", inputData.name);
		formData.append("max_store_slots", inputData.product_slots);
		formData.append("max_private_chat", inputData.room_breakouts);
		formData.append("max_agents", inputData.agent_slots);
		formData.append("logo", uploadedLogo);

		if (inputData.short_description) {
			formData.append("short_description", inputData.short_description);
		}
		if (inputData.full_description) {
			formData.append("description", inputData.full_description);
		}

		if (inputData.website) {
			formData.append("website", inputData.website);
		}
		if (inputData.linkedin) {
			formData.append("linkedin_url", inputData.linkedin);
		}
		if (inputData.facebook) {
			formData.append("facebook_url", inputData.facebook);
		}
		if (inputData.instagram) {
			formData.append("instagram_url", inputData.instagram);
		}
		if (inputData.youtube) {
			formData.append("youtube_url", inputData.youtube);
		}
		if (inputData.twitter) {
			formData.append("twitter_url", inputData.twitter);
		}

		// Categories
		if (selectedExhibitorCategories.length > 0) {
			selectedExhibitorCategories.forEach((category) => {
				formData.append("event_exhibitor_room_categories[]", JSON.stringify({ category_id: category.id }));
			});
		}

		ExhibitorAPI.postCreateExhibitorRoom(formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					const { event_exhibitor_rooms } = response.data;
					const { id } = event_exhibitor_rooms;
					history.push(`/events/view/${eventId}/exhibitor/rooms/${id}`);
					return;
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const removeAccount = () => {
		setSelectedAccount(null);
	};

	const selectAccount = (account) => {
		setSelectedAccount(account);
	};

	const removeCategory = (category) => {
		let selectedCategoriesCopy = [...selectedExhibitorCategories];

		let removeIndex = null;
		for (let i = 0; i < selectedCategoriesCopy.length; i++) {
			const { id } = selectedCategoriesCopy[i];

			if (id === category.id) {
				removeIndex = i;
				break;
			}
		}

		selectedCategoriesCopy.splice(removeIndex, 1);
		setSelectedExhibitorCategories(selectedCategoriesCopy);
	};

	const addCategory = (category) => {
		setSelectedExhibitorCategories([...selectedExhibitorCategories, category]);
	};

	return (
		<EventLayoutContainer>
			<div id="add-exhibitor-room-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-4">
					<div className="d-flex align-items-center">
						<div className="btn-back" onClick={() => history.goBack()}>
							<img src={IconBack} alt="back" className="img-fluid" />
						</div>
						<div>
							<h2 className="title">Add New Room</h2>
							<span className="subtitle">Exhibitors &gt; Exhibitor Rooms &gt; Add New Room</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="section-container">
					<form id="form-add-channel-session" method="post" onSubmit={handleCreateRoom}>
						<div className="row mb-3">
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="name">Room Name</label>
									<input
										id="name"
										type="text"
										className="form-control"
										aria-describedby="name"
										name="name"
										value={inputData.name}
										onChange={handleInputOnChange}
										required
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="short_description">Short Description</label>
									<textarea
										id="short_description"
										type="text"
										className="form-control"
										aria-describedby="short_description"
										name="short_description"
										value={inputData.short_description}
										onChange={handleInputOnChange}
										rows={3}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="full_description">Full Description</label>
									<textarea
										id="full_description"
										type="text"
										className="form-control"
										aria-describedby="full_description"
										name="full_description"
										value={inputData.full_description}
										onChange={handleInputOnChange}
										rows={5}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="row">
									<div className="col-3">
										<div className="form-group">
											<label>Select Room Logo Image</label>
											<img
												src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : IconUpload}
												alt="event-logo"
												className="img-fluid img-logo"
												onClick={browsePicture}
											/>
											<input
												type="file"
												name="logo"
												onChange={handleChangeLogo}
												ref={logoRef}
												className="d-none"
												accept="image/*"
											/>
										</div>
									</div>
								</div>
							</div>

							<section className="w-100 custom-selection-container">
								<div className="col-12">
									<div className="form-group">
										<label htmlFor="accounts">Select Exhibitor's Account</label>
										<div className="custom-selection-wrapper">
											{selectedAccount ? (
												<div
													className="selected-account"
													onClick={() => setShowAccountSelection(!showAccountSelection)}>
													<div className="d-flex align-items-center">
														<div className="selected-account-logo">
															<img
																src={selectedAccount.logo_url || IconDefaultUser}
																alt="logo"
																className="img-fluid"
															/>
														</div>
														<div>
															<div className="selected-account-name">
																{selectedAccount.organization_account_name}
															</div>
															<div className="selected-account-organization">
																Organization
															</div>
														</div>
													</div>
												</div>
											) : (
												<input
													id="accounts"
													type="text"
													className="form-control"
													aria-describedby="accounts"
													name="accounts"
													value={searchAccountText}
													onChange={(event) => setSearchAccountText(event.target.value)}
													placeholder="Search"
													list="accounts"
													onClick={() => setShowAccountSelection(!showAccountSelection)}
													autoComplete={"off"}
												/>
											)}
											{showAccountSelection && (
												<>
													<div
														className="custom-selection-wrapper-bgOverlay"
														onClick={() =>
															setShowAccountSelection(!showAccountSelection)
														}></div>
													<div
														className="custom-dropdown"
														onScroll={handleEventAccountSelectionScroll}>
														{accountsFiltered.map((account) => {
															const {
																event_account_id,
																logo_url,
																organization_account_name,
															} = account;

															return (
																<div
																	key={`custom-option-${event_account_id}`}
																	className="custom-option row align-items-center">
																	<div className="col-1">
																		<img
																			src={logo_url || IconDefaultUser}
																			alt="logo"
																			className="img-fluid"
																		/>
																	</div>
																	<div className="col-9">
																		<div className="custom-name">
																			{organization_account_name}
																		</div>
																		<div className="custom-organization">
																			Organization
																		</div>
																	</div>
																	<div className="col-2">
																		{selectedAccount &&
																		selectedAccount.event_account_id ===
																			event_account_id ? (
																			<button
																				type="button"
																				className="btn btn-outline-primary w-100"
																				onClick={() => removeAccount()}>
																				Remove
																			</button>
																		) : (
																			<button
																				type="button"
																				className="btn btn-primary w-100"
																				onClick={() => selectAccount(account)}>
																				Select
																			</button>
																		)}
																	</div>
																</div>
															);
														})}
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</section>

							<section className="w-100 custom-selection-container">
								<div className="col-12">
									<div className="form-group">
										<label htmlFor="category">Select Exhibitor Category</label>
										<div className="custom-selection-wrapper">
											<input
												id="category"
												type="text"
												className="form-control"
												aria-describedby="category"
												name="category"
												value={searchCategoryText}
												onChange={(event) => setSearchCategoryText(event.target.value)}
												placeholder="Search"
												list="category"
												onClick={() =>
													setShowExhibitorCategoriesSelection(
														!showExhibitorCategoriesSelection
													)
												}
												autoComplete={"off"}
											/>
											{showExhibitorCategoriesSelection && (
												<>
													<div
														className="custom-selection-wrapper-bgOverlay"
														onClick={() =>
															setShowExhibitorCategoriesSelection(
																!showExhibitorCategoriesSelection
															)
														}></div>
													<div
														className="custom-dropdown"
														onScroll={handleCategorySelectionScroll}>
														{exhibitorCategoriesFiltered.map((category) => {
															const { id, name, thumbnail_url } = category;

															let categoryAdded = selectedExhibitorCategories.filter(
																(selectedCategory) => {
																	return selectedCategory.id === id;
																}
															);

															return (
																<div
																	key={`custom-option-${id}`}
																	className="custom-option row align-items-center">
																	<div className="col-1">
																		<img
																			src={thumbnail_url}
																			alt="profile"
																			className="img-fluid"
																		/>
																	</div>
																	<div className="col-9">
																		<div className="custom-name">{name}</div>
																	</div>
																	<div className="col-2">
																		{categoryAdded.length > 0 ? (
																			<button
																				type="button"
																				className="btn btn-outline-primary w-100"
																				onClick={() =>
																					removeCategory(category)
																				}>
																				Remove
																			</button>
																		) : (
																			<button
																				type="button"
																				className="btn btn-primary w-100"
																				onClick={() => addCategory(category)}>
																				Select
																			</button>
																		)}
																	</div>
																</div>
															);
														})}
													</div>
												</>
											)}
										</div>
									</div>
								</div>

								<div className="col-12">
									<div className="selected-custom-container row">
										{selectedExhibitorCategories.map((selectedCategory) => {
											const { id, name, thumbnail_url } = selectedCategory;

											return (
												<div key={`selected-custom-${id}`} className="col-4 mb-3">
													<div className="custom-item">
														<div className="row align-items-center">
															<div className="col-2 pr-0">
																<img
																	src={thumbnail_url}
																	alt="thumnail"
																	className="img-fluid"
																/>
															</div>
															<div className="col-8">
																<div className="custom-name">{name}</div>
															</div>
															<div className="col-2 pl-0 text-right">
																<img
																	src={IconRemove}
																	alt="remove"
																	className="img-fluid icon-remove"
																	onClick={() => removeCategory(selectedCategory)}
																/>
															</div>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</section>

							<div className="col-12">
								<div className="form-group">
									<label>Room Entitlements</label>
									<div className="room-entitlements-container">
										<div className="row">
											<div className="col-6 border__right">
												<div className="row align-items-center mb-3">
													<div className="col-8">
														<label htmlFor="product_slots">Allocated Product Slots</label>
													</div>
													<div className="col-3">
														<input
															id="product_slots"
															type="number"
															className="form-control"
															aria-describedby="product_slots"
															name="product_slots"
															value={inputData.product_slots}
															onChange={handleInputOnChange}
														/>
													</div>
												</div>
												<div className="row align-items-center">
													<div className="col-8">
														<label htmlFor="room_breakouts">Allocated Breakout Rooms</label>
													</div>
													<div className="col-3">
														<input
															id="room_breakouts"
															type="number"
															className="form-control"
															aria-describedby="room_breakouts"
															name="room_breakouts"
															value={inputData.room_breakouts}
															onChange={handleInputOnChange}
														/>
													</div>
												</div>
											</div>
											<div className="col-6">
												<div className="row align-items-center">
													<div className="col-1"></div>
													<div className="col-8">
														<label htmlFor="agent_slots">Allocated Room Agent Logins</label>
													</div>
													<div className="col-3">
														<input
															id="agent_slots"
															type="number"
															className="form-control"
															aria-describedby="agent_slots"
															name="agent_slots"
															value={inputData.agent_slots}
															onChange={handleInputOnChange}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<label>Social Links</label>
									<input
										id="website"
										type="url"
										className="form-control mb-3"
										aria-describedby="website"
										name="website"
										value={inputData.website}
										onChange={handleInputOnChange}
										placeholder="Website"
									/>
									<input
										id="linkedin"
										type="url"
										className="form-control mb-3"
										aria-describedby="linkedin"
										name="linkedin"
										value={inputData.linkedin}
										onChange={handleInputOnChange}
										placeholder="Linkedin"
									/>
									<input
										id="facebook"
										type="url"
										className="form-control mb-3"
										aria-describedby="facebook"
										name="facebook"
										value={inputData.facebook}
										onChange={handleInputOnChange}
										placeholder="Facebook"
									/>
									<input
										id="instagram"
										type="url"
										className="form-control mb-3"
										aria-describedby="instagram"
										name="instagram"
										value={inputData.instagram}
										onChange={handleInputOnChange}
										placeholder="Instagram"
									/>
									<input
										id="youtube"
										type="url"
										className="form-control mb-3"
										aria-describedby="youtube"
										name="youtube"
										value={inputData.youtube}
										onChange={handleInputOnChange}
										placeholder="Youtube"
									/>
									{/* <input
										id="twitter"
										type="url"
										className="form-control mb-3"
										aria-describedby="twitter"
										name="twitter"
										value={inputData.twitter}
										onChange={handleInputOnChange}
										placeholder="Twitter"
									/> */}
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-10"></div>
							<div className="col-2">
								<button type="submit" className="btn btn-primary w-100">
									Create
								</button>
							</div>
						</div>
					</form>
				</section>
			</div>
		</EventLayoutContainer>
	);
};

export default withRouter(AddExhibitorRoom);
