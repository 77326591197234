export const OPEN_CUSTOM_MODAL = 'common/OPEN_CUSTOM_MODAL'
export const CLOSE_CUSTOM_MODAL = 'common/CLOSE_CUSTOM_MODAL'

export const OPEN_LIVE_TOGGLE_MODAL = 'common/OPEN_LIVE_TOGGLE_MODAL'
export const CLOSE_LIVE_TOGGLE_MODAL = 'common/CLOSE_LIVE_TOGGLE_MODAL'

export const SHOW_LOADING = 'common/SHOW_LOADING'
export const HIDE_LOADING = 'common/HIDE_LOADING'

export const OPEN_PROFILE_MODAL = 'common/OPEN_PROFILE_MODAL'
export const CLOSE_PROFILE_MODAL = 'common/CLOSE_PROFILE_MODAL'

export const OPEN_FORGOT_PASSWORD_MODAL = 'common/OPEN_FORGOT_PASSWORD_MODAL'
export const CLOSE_FORGOT_PASSWORD_MODAL = 'common/CLOSE_FORGOT_PASSWORD_MODAL'

export const OPEN_ALERT_SNACKBAR = 'common/OPEN_ALERT_SNACKBAR'
export const CLOSE_ALERT_SNACKBAR = 'common/CLOSE_ALERT_SNACKBAR'