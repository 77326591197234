import React, { useEffect, useState } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "app/states/event";
import { withRouter } from "react-router-dom";
import { commonActions } from "app/states/common";
import moment from "moment";
import { EVENT_ACTION, EVENT_STATUS } from "app/config/constants";
import EventAPI from "app/apis/event";

const PublishEventModal = ({ history }) => {
	const dispatch = useDispatch();
	const closePublishEventModal = () => dispatch(eventActions.closePublishEventModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setIsEventPublished = (isEventPublished) => dispatch(eventActions.setIsEventPublished(isEventPublished));
	const setCurrentEventStatus = (status) => dispatch(eventActions.setCurrentEventStatus(status));
	const refreshEventDetails = () => dispatch(eventActions.refreshEventDetails());

	const showPublishEventModal = useSelector(({ event }) => event.showPublishEventModal);
	const eventPublishAction = useSelector(({ event }) => event.eventPublishAction);
	const eventDetails = useSelector(({ event }) => event.eventDetails);

	const [eventLogo, setEventLogo] = useState(null);

	// Get the event logo
	useEffect(() => {
		if (eventDetails) {
			const logoObject = eventDetails.event_web_configs.find((config) => config.key === "logo_url");
			if (logoObject) {
				setEventLogo(logoObject.value);
			}
		}
	}, [eventDetails]);

	const handlePublishEvent = () => {
		EventAPI.postUpdateEventPublishStatus(EVENT_STATUS["publish"]["value"])
			.then((response) => {
				if (response.code === 200) {
					refreshEventDetails();
					setIsEventPublished(true);
					setCurrentEventStatus(EVENT_STATUS["publish"]["value"]);
					closePublishEventModal();
					openAlertSnackbar("Published event successfully.", "success");
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleEndEvent = () => {
		EventAPI.postUpdateEventPublishStatus(EVENT_STATUS["end"]["value"])
			.then((response) => {
				if (response.code === 200) {
					refreshEventDetails();
					setIsEventPublished(false);
					setCurrentEventStatus(EVENT_STATUS["end"]["value"]);
					closePublishEventModal();
					openAlertSnackbar("Ended event successfully.", "success");
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handlePauseEvent = () => {
		EventAPI.postUpdateEventPublishStatus(EVENT_STATUS["pause"]["value"])
			.then((response) => {
				if (response.code === 200) {
					refreshEventDetails();
					setIsEventPublished(false);
					setCurrentEventStatus(EVENT_STATUS["pause"]["value"]);
					closePublishEventModal();
					openAlertSnackbar("Paused event successfully.", "success");
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleResumeEvent = () => {
		EventAPI.postUpdateEventPublishStatus(EVENT_STATUS["resume"]["value"])
			.then((response) => {
				if (response.code === 200) {
					refreshEventDetails();
					setIsEventPublished(true);
					setCurrentEventStatus(EVENT_STATUS["resume"]["value"]);
					closePublishEventModal();
					openAlertSnackbar("Resumed event successfully.", "success");
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	return (
		<Modal
			show={showPublishEventModal}
			onHide={closePublishEventModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="publish-event-modal"
			dialogClassName="publish-event-dialog"
			centered>
			<Modal.Body>
				<div className="publish-event-modal-body">
					{eventDetails && (
						<>
							<img src={eventLogo} alt="event" className="img-event mb-4" />
							<section className="event-details mb-5">
								<h4 className="event-title">{eventDetails.event_name}</h4>
								<span className="event-datetime">
									{moment(eventDetails.start_at).format("DD MMMM YYYY, hh:mmA")}
								</span>
							</section>
						</>
					)}

					{eventPublishAction === EVENT_ACTION["pause"] && (
						<section>
							<div className="text-confirmation mb-3">Are you sure you want to pause the event?</div>
							<div className="text-confirmation-description mb-4">
								By pausing your event, attendees will not be able to access to the event during the
								event period.
							</div>
							<div className="d-flex justify-content-center align-items-center">
								<button
									className="btn btn-outline-primary mx-2"
									type="button"
									onClick={closePublishEventModal}>
									Cancel
								</button>
								<button className="btn btn-pause mx-2" type="button" onClick={handlePauseEvent}>
									Pause Event
								</button>
							</div>
						</section>
					)}

					{eventPublishAction === EVENT_ACTION["resume"] && (
						<section>
							<div className="text-confirmation mb-3">Are you sure you want to resume the event now?</div>
							<div className="text-confirmation-description mb-4">
								By resuming your event, attendees will be able to register for the event, or access to
								the event during the event period.
							</div>
							<div className="d-flex justify-content-center align-items-center">
								<button
									className="btn btn-outline-primary mx-2"
									type="button"
									onClick={closePublishEventModal}>
									Cancel
								</button>
								<button className="btn btn-success mx-2" type="button" onClick={handleResumeEvent}>
									Resume Event
								</button>
							</div>
						</section>
					)}

					{eventPublishAction === EVENT_ACTION["end"] && (
						<section>
							<div className="text-confirmation mb-3">Are you sure you want to end the event now?</div>
							<div className="text-confirmation-description mb-4">
								By ending your event, attendees will no longer be able to register for the event, or
								access to the event during the event period.
							</div>
							<div className="d-flex justify-content-center align-items-center">
								<button
									className="btn btn-outline-primary mx-2"
									type="button"
									onClick={closePublishEventModal}>
									No
								</button>
								<button className="btn btn-danger mx-2" type="button" onClick={handleEndEvent}>
									End Event
								</button>
							</div>
						</section>
					)}

					{(eventPublishAction === EVENT_ACTION["publish"] || !eventPublishAction) && (
						<section>
							<div className="text-confirmation mb-3">
								Are you sure you want to publish the event now?
							</div>
							<div className="text-confirmation-description mb-4">
								By publishing your event, attendees will be able to view your landing page, register for
								the event, or access to the event during the event period.
							</div>
							<div className="d-flex justify-content-center align-items-center">
								<button
									className="btn btn-outline-primary mx-2"
									type="button"
									onClick={closePublishEventModal}>
									No
								</button>
								<button className="btn btn-success mx-2" type="button" onClick={handlePublishEvent}>
									Publish Now
								</button>
							</div>
						</section>
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(PublishEventModal);
