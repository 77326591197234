import apiAction from "../services/apiAction";

const ExhibitorAPI = {
	/**
	 * Get exhibitor category listing
	 * @param {object} queryParams
	 * {page, limit, event_id, sort_by, sort_order}
	 */
	getExhibitorCategoryListing: (queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/exhibitors`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get exhibitor category detail
	 * @param {int} exhibitor_category_id
	 */
	getExhibitorCategoryDetail: (exhibitor_category_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/exhibitors/${exhibitor_category_id}`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Create exhibitor category
	 * @param {FormData} formData
	 */
	postCreateExhibitorCategory: (formData) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/exhibitors`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Update exhibitor category
	 * @param {int} exhibitor_category_id
	 * @param {FormData} formData
	 */
	postUpdateExhibitorCategory: (exhibitor_category_id, formData) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/exhibitors/${exhibitor_category_id}`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Delete exhibitor category
	 * @param {int} exhibitor_category_id
	 */
	deleteExhibitorCategory: (exhibitor_category_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/exhibitors/${exhibitor_category_id}`;

			apiAction
				.delete(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get exhibitor room listing
	 * @param {object} queryParams
	 * {page, limit, event_id, sort_by, sort_order}
	 */
	getExhibitorRoomListing: (eventId, queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/${eventId}/rooms`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get exhibitor room detail
	 * @param {int} room_id
	 */
	getExhibitorRoomDetail: (room_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/rooms/${room_id}`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Create exhibitor room
	 * @param {FormData} formData
	 */
	postCreateExhibitorRoom: (formData) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/rooms`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Update exhibitor room
	 * @param {int} room_id
	 * @param {FormData} formData
	 */
	postUpdateExhibitorRoom: (room_id, formData) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/rooms/${room_id}`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Delete exhibitor room
	 * @param {int} room_id
	 */
	deleteExhibitorRoom: (room_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/rooms/${room_id}`;

			apiAction
				.delete(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get room agents listing
	 * @param {object} queryParams
	 * {event_account_id, page, limit, sort_order}
	 */
	getRoomAgentListing: (queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/agents`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get event account listing
	 * @param {object} queryParams
	 * {event_id, page, limit, sort_order}
	 */
	getEventAccountListing: (queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/accounts`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get event room streaming
	 * @param {int} room_id
	 */
	getEventRoomStreaming: (room_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/streamings/${room_id}`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Update event room streaming
	 * @param {int} room_id
	 * @param {FormData} formData
	 */
	postUpdateEventRoomStreaming: (room_id, formData) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/streamings/${room_id}`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Update event room streaming
	 * @param {int} room_id
	 */
	postToggleLive: (room_id, data) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/streamings/${room_id}/live`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get event room attendance history listing
	 * @param {int} room_id
	 */
	getRoomAttendanceHistoryListing: (room_id, queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/attendance/history/${room_id}`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get event room attendance current listing
	 * @param {int} room_id
	 */
	getRoomAttendanceCurrentListing: (room_id, queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/attendance/current/${room_id}`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get event room store
	 * @param {int} store_id
	 */
	getEventRoomStore: (store_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/stores/${store_id}`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Update event room store
	 * @param {int} store_id
	 */
	postUpdateEventRoomStore: (store_id, data) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/stores/${store_id}`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get event room store product listing
	 * @param {object} queryParams
	 */
	getEventRoomStoreProductListing: (queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/store/products`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get Event Room Product Store Product Detail
	 * @param {int}
	 */
	getEventRoomStoreProducDetail: (product_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/store/products/${product_id}`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Create Event Room Store Product
	 * @param {FormData} formData
	 */
	postCreateEventRoomStoreProduct: (formData) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/store/products`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Update Event Room Store Product
	 * @param {int} product_id
	 * @param {FormData} formData
	 */
	postUpdateEventRoomStoreProduct: (product_id, formData) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/store/products/${product_id}`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Delete Event Room Store Product
	 * @param {int} product_id
	 */
	deleteEventRoomStoreProduct: (product_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/store/products/${product_id}`;

			apiAction
				.delete(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

    /**
	 * Ban Product
	 * @param {int} product_id
	 */
	postBanProduct: (product_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/store/products/${product_id}/ban`;

			apiAction
				.post(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

    /**
	 * Unban Product
	 * @param {int} product_id
	 */
	postUnbanProduct: (product_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/room/store/products/${product_id}/unban`;

			apiAction
				.post(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},
};

export default ExhibitorAPI;
