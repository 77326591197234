import LocalStorageService from "app/services/localStorageService";
import apiAction from "../services/apiAction";

const EventAPI = {
	/**
	 * Get event listing
	 * @param {object} queryParams
	 * {page, limit}
	 */
	getEventListing: (queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/events`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get event details
	 * @param {int} event_id
	 */
	getEventDetails: (event_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/events/${parseInt(event_id)}`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Create event
	 * @param {FormData} formData
	 */
	postCreateEvent: (formData) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/events`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Update event
	 * @param {int} eventId
	 * @param {FormData} formData
	 */
	postUpdateEvent: (eventId, formData) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/events/${eventId}`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Delete event
	 * @param {int} eventId
	 */
	deleteEvent: (eventId) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/events/${eventId}`;

			apiAction
				.delete(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get event visitors
	 * @param {object} queryParams
	 */
	getEventVisitors: (queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/visitors`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get event channel listing
	 * @param {object} queryParams
	 */
	getEventChannelListing: (queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/channels`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get event channel details
	 * @param {int} channelId
	 */
	getEventChannelDetails: (channelId) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/channels/${channelId}`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Create event channel
	 * @param {FormData} formData
	 */
	postCreateEventChannel: (formData) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/channels`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Update event channel
	 * @param {int} channelId
	 * @param {FormData} formData
	 */
	postUpdateEventChannel: (channelId, formData) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/channels/${channelId}`;

			apiAction
				.post(url, formData)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Delete event channel
	 * @param {int} channelId
	 */
	deleteEventChannel: (channelId) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/channels/${channelId}`;

			apiAction
				.delete(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Update Event Publish Status
	 * @param {string} status_code
	 */
	postUpdateEventPublishStatus: (status_code) => {
		return new Promise((resolve, reject) => {
			const eventDetails = LocalStorageService.getEventDetails();
			const url = `/events/${eventDetails.id}/event_status/${status_code}`;

			apiAction
				.post(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get republish event quote details
	 */
	getRepublishEventQuotaDetails: (eventId) => {
		return new Promise((resolve, reject) => {
			const url = `/events/${eventId}/republish/quota_balance`;

			apiAction
				.get(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},
};

export default EventAPI;
