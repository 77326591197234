import React, { useState } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/states/common";
import IconClose from "app/assets/icon-close.png";
import AuthenticationAPI from "app/apis/authentication";

const ForgotPasswordModal = () => {
	const dispatch = useDispatch();
	const closeForgotPasswordModal = () => dispatch(commonActions.closeForgotPasswordModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showForgotPasswordModal = useSelector(({ common }) => common.showForgotPasswordModal);

	const [email, setEmail] = useState("");
	const [submitted, setSubmitted] = useState(false);

	const handleCloseModal = () => {
		setEmail("");
		setSubmitted(false);
		closeForgotPasswordModal();
	};

	const handleSubmitForgotPassword = (event) => {
		event.preventDefault();

		AuthenticationAPI.postForgotPassword(email)
			.then((response) => {
				if (response.code === 200 && response.data.sent) {
					setSubmitted(true);
				}
				else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	return (
		<Modal
			show={showForgotPasswordModal}
			onHide={handleCloseModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="forgot-password-modal"
			centered>
			<Modal.Body>
				<div className="forgot-password-modal-body">
					<div className="d-flex justify-content-end w-100">
						<img src={IconClose} alt="close" className="icon-close" onClick={handleCloseModal} />
					</div>

					<h1 className="modal-title">Forget your password?</h1>

					{submitted ? (
						<div className="submitted-container px-3 w-100">
							<div className="text-instruction mb-3">
								Instructions to reset your password has been sent to:
							</div>
							<div className="text-email mb-4">{email}</div>
							<div className="text-instruction mb-4">Please check your mailbox.</div>

							<button type="button" className="btn btn-primary w-100 mb-4" onClick={handleCloseModal}>
								OK
							</button>
						</div>
					) : (
						<form
							id="form-forgot-password"
							className="px-3 w-100"
							method="POST"
							onSubmit={handleSubmitForgotPassword}>
							<div className="form-group mb-4">
								<label htmlFor="email">Please enter the email address you use to sign in.</label>
								<input
									id="email"
									type="email"
									className="form-control"
									aria-describedby="email"
									name="email"
									value={email}
									onChange={(event) => setEmail(event.target.value)}
									required
								/>
							</div>

							<button type="submit" className="btn btn-primary w-100 mb-4">
								Confirm
							</button>
						</form>
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ForgotPasswordModal;
