import React, { useState, useRef } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "app/states/event";
import IconClose from "app/assets/icon-close.png";
import { withRouter, useParams } from "react-router-dom";
import { commonActions } from "app/states/common";
import IconAddGrey from "app/assets/icon-add-grey.png";
import { FEATURED_PARTNER_TYPES } from "app/config/constants";
import LandingPageAPI from "app/apis/landingPage";

const AddEventPartnerModal = ({ history }) => {
	const { eventId } = useParams();

	const dispatch = useDispatch();
	const closeAddEventPartnerModal = () => dispatch(eventActions.closeAddEventPartnerModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const refreshEventPartnerList = () => dispatch(eventActions.refreshEventPartnerList());

	const showAddEventPartnerModal = useSelector(({ event }) => event.showAddEventPartnerModal);
	const currentEventPartnerKey = useSelector(({ event }) => event.currentEventPartnerKey);

	const [uploadedPhoto, setUploadedPhoto] = useState(null);
	const photoRef = useRef();

	const [inputData, setInputData] = useState({
		photo: "",
		company_name: "",
		company_url: "",
	});

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleCloseAddEventPartnerModal = () => {
		// Clear inputs
		setInputData({
			photo: "",
			company_name: "",
			company_url: "",
		});
		setUploadedPhoto(null);

		closeAddEventPartnerModal();
	};

	const handleAddEventPartner = (event) => {
		event.preventDefault();

		// Validation
		if (!uploadedPhoto) {
			openAlertSnackbar("Photo is required.", "error");
			return;
		}

		// Form Data
		let formData = new FormData();
		formData.append("featured_partner_logo", uploadedPhoto);
		formData.append("company_name", inputData.company_name);

		if (inputData.company_url) {
			formData.append("company_url_link", inputData.company_url);
		}

		LandingPageAPI.postCreateUpdateFeaturedPartners(
			eventId,
			FEATURED_PARTNER_TYPES[currentEventPartnerKey]["type"],
			"",
			formData
		)
			.then((response) => {
				if (response.code === 200) {
					refreshEventPartnerList();
					openAlertSnackbar(
						`${FEATURED_PARTNER_TYPES[currentEventPartnerKey]["name"]} added successfully.`,
						"success"
					);
					handleCloseAddEventPartnerModal();
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleChangePhoto = (event) => {
		setUploadedPhoto(event.target.files[0]);
	};

	const browsePhoto = () => {
		photoRef.current.click();
	};

	return (
		<Modal
			show={showAddEventPartnerModal}
			onHide={handleCloseAddEventPartnerModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="add-event-partner-modal"
			centered>
			<Modal.Body>
				<div className="add-event-partner-modal-body">
					<div className="d-flex justify-content-end">
						<img
							src={IconClose}
							alt="close"
							className="icon-close"
							onClick={handleCloseAddEventPartnerModal}
						/>
					</div>

					<h3>Add {FEATURED_PARTNER_TYPES[currentEventPartnerKey]["name"]}</h3>

					<form method="post" onSubmit={handleAddEventPartner}>
						<div className="form-group">
							<label htmlFor="photo">Upload Photo *</label>
							{uploadedPhoto || inputData.photo ? (
								<img
									src={uploadedPhoto ? URL.createObjectURL(uploadedPhoto) : inputData.photo}
									alt="cover"
									className="img-fluid img-photo"
									onClick={browsePhoto}
								/>
							) : (
								<div className="add-photo-wrapper" onClick={browsePhoto}>
									<img src={IconAddGrey} alt="add" className="img-add-photo" />
								</div>
							)}
							<input
								type="file"
								name="photo"
								value={""}
								onChange={handleChangePhoto}
								ref={photoRef}
								className="d-none"
								accept="image/*"
							/>
						</div>
						<div className="form-group">
							<label htmlFor="company_name">Company Name *</label>
							<input
								id="company_name"
								type="text"
								className="form-control"
								aria-describedby="company_name"
								name="company_name"
								value={inputData.company_name}
								onChange={handleInputOnChange}
								required
							/>
						</div>
						<div className="form-group">
							<label htmlFor="company_url">Company Website URL</label>
							<input
								id="company_url"
								type="url"
								className="form-control"
								aria-describedby="company_url"
								name="company_url"
								value={inputData.company_url}
								onChange={handleInputOnChange}
							/>
						</div>

						<button type="submit" className="btn btn-primary w-100 mt-4">
							Add
						</button>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(AddEventPartnerModal);
