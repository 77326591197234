import apiAction from "../services/apiAction"

const Organization = {

    /**
     * Get organization listing
     * @param {object} queryParams 
     * {page, limit}
     */
    getOrganizationListing: (queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/organizations`;
            
            apiAction.get(url, queryParams)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get organization detail
     * @param {int} organizationId 
     * {page, limit}
     */
    getOrganizationDetail: (organizationId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/organizations/${organizationId}`;
            
            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Create organization
     * @param {FormData} formData 
     */
    postCreateOrganization: (formData) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/organizations`;

            apiAction.post(url, formData)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Update organization
     * @param {FormData} formData 
     */
    postUpdateOrganization: (organizationId, formData) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/organizations/${organizationId}`;

            apiAction.post(url, formData)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Invite team members
     * @param {object} data 
     */
    postInviteTeamMembers: (organizationId, data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/organizations/${organizationId}/invites`;

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Delete team members
     * @param {object} data 
     */
    deleteTeamMembers: (organizationId, data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/organizations/${organizationId}/invite`;

            apiAction.delete(url, {}, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },
};

export default Organization;
