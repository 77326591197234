/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import IconBack from "app/assets/icon-back-grey.png";
import { withRouter, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import IconRemove from "app/assets/icon-close.png";
import moment from "moment";
import ChannelSessionAPI from "app/apis/channelSession";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import { PLATFORM_TYPES, DROPDOWN_RECORDS_PER_PAGE } from "app/config/settings";
import IconDefaultUser from "app/assets/icon-default-user.png";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/states/common";
import EventInfo from "app/views/components/EventInfo/EventInfo";

const AddChannelSession = ({ history }) => {
	let { channelId } = useParams();

	const dispatch = useDispatch();
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);

	const [inputData, setInputData] = useState({
		title: "",
		video_url: "",
		platform_type: "",
		description: "",
		cover_image: "",
		live: false,
		start_date: new Date(),
		start_time: new Date(),
		end_date: new Date(),
		end_time: new Date(),
		featured: true,
		speaker: "",
	});

	const [uploadedLogo, setUploadedLogo] = useState(null);
	const [speakersFiltered, setSpeakersFiltered] = useState([]);
	const [selectedSpeakers, setSelectedSpeakers] = useState([]);
	const [showSpeakerSelection, setShowSpeakerSelection] = useState(false);

	const [speakerPage, setSpeakerPage] = useState(1);
	const [totalSpeakerPage, setTotalSpeakerPage] = useState(1);
	const [loadMore, setLoadMore] = useState(false);
	const [loadingMoreSpeakers, setLoadingMoreSpeakers] = useState(false);

	const logoRef = useRef();

	// Get speaker listing
	useEffect(() => {
		if (!loadMore) return;

		// Flag to indicate loading more speakers to ensure every page is loaded when scroll
		setLoadingMoreSpeakers(true);

		let params = {
			page: speakerPage,
			limit: DROPDOWN_RECORDS_PER_PAGE,
			sort_order: "asc",
			organization_id: selectedOrganizationAccount.id,
		};

		ChannelSessionAPI.getSpeakerListing(params)
			.then((response) => {
				const { speakers: speakerList, paging } = response.data;
				const { last_page } = paging;

				setSpeakersFiltered([...speakersFiltered, ...speakerList]);
				setTotalSpeakerPage(last_page);
				setLoadingMoreSpeakers(false);
				setLoadMore(false);
			})
			.catch((response) => {
				console.error(response);
			});
	}, [loadMore]);

	// Search speaker listing
	useEffect(() => {
		let params = {
			page: 1,
			limit: DROPDOWN_RECORDS_PER_PAGE,
			sort_order: "asc",
			organization_id: selectedOrganizationAccount.id,
		};

		if (inputData.speaker) {
			params["search_string"] = inputData.speaker;
		}

		let timeout = setTimeout(() => {
			ChannelSessionAPI.getSpeakerListing(params)
				.then((response) => {
					const { speakers: speakerList, paging } = response.data;
					const { last_page } = paging;

					setSpeakerPage(1);
					setSpeakersFiltered(speakerList);
					setTotalSpeakerPage(last_page);
				})
				.catch((response) => {
					console.error(response);
				});
		}, 500);

		return () => {
			clearTimeout(timeout);
		};
	}, [inputData.speaker]);

	const handleSpeakerSelectionScroll = (event) => {
		let offset = 20;
		let isBottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + offset;

		if (isBottom && speakerPage <= totalSpeakerPage && !loadingMoreSpeakers) {
			setSpeakerPage(speakerPage + 1);
			setLoadMore(true);
		}
	};

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleChangeLogo = (event) => {
		setUploadedLogo(event.target.files[0]);
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleCreateSession = async (event) => {
		event.preventDefault();
		showLoading();

		let formData = new FormData();

		// Process start/end time
		let formattedStartDate = moment(inputData.start_date).format("DD MMMM YYYY");
		let formattedStartTime = moment(inputData.start_time).format("h:mma");
		let startDateTime = moment(formattedStartDate + " " + formattedStartTime, "DD MMMM YYYY h:mma");
		let startAt = startDateTime.format("YYYY-MM-DD HH:mm:ss");

		let formattedEndDate = moment(inputData.end_date).format("DD MMMM YYYY");
		let formattedEndTime = moment(inputData.end_time).format("h:mma");
		let endDateTime = moment(formattedEndDate + " " + formattedEndTime, "DD MMMM YYYY h:mma");
		let endAt = endDateTime.format("YYYY-MM-DD HH:mm:ss");

		// Check end datetime and start datetime
		if (!endDateTime.isAfter(startDateTime)) {
			openAlertSnackbar("Start date must be earlier than end date.", "error");
			hideLoading();
			return;
		}

		formData.append("channel_id", channelId);
		formData.append("name", inputData.title);
		formData.append("video_url", inputData.video_url);
		formData.append("thumbnail", uploadedLogo);
		formData.append("live", inputData.live);
		formData.append("start_at", startAt);
		formData.append("end_at", endAt);
		formData.append("featured", inputData.featured);

		if (inputData.description) {
			formData.append("description", inputData.description);
		}

		if (selectedSpeakers.length > 0) {
			selectedSpeakers.forEach((speaker) => {
				const speakerObject = {
					user_id: speaker.user_id,
					account_id: speaker.account_id,
				};

				formData.append("speakers[]", JSON.stringify(speakerObject));
			});
		}

		ChannelSessionAPI.postCreateChannelSession(formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					const { id } = response.data;
					openAlertSnackbar("Channel Session Created", "success");

					history.goBack();
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const removeSpeaker = (speaker) => {
		let speakersCopy = [...selectedSpeakers];

		let removeIndex = null;
		for (let i = 0; i < speakersCopy.length; i++) {
			const { user_id } = speakersCopy[i];

			if (user_id === speaker.user_id) {
				removeIndex = i;
				break;
			}
		}

		speakersCopy.splice(removeIndex, 1);
		setSelectedSpeakers(speakersCopy);
	};

	const addSpeaker = (speaker) => {
		setSelectedSpeakers([...selectedSpeakers, speaker]);
	};

	return (
		<EventLayoutContainer>
			<div id="add-channel-session-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-4">
					<div className="d-flex align-items-center">
						<div className="btn-back" onClick={() => history.goBack()}>
							<img src={IconBack} alt="back" className="img-fluid" />
						</div>
						<div>
							<h2 className="title">Add New Session</h2>
							<span className="subtitle">Channels &gt; Channel Room &gt; Add New Session</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="section-container">
					<form id="form-add-channel-session" method="post" onSubmit={handleCreateSession}>
						<div className="row mb-3">
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="title">Session Title</label>
									<input
										id="title"
										type="text"
										className="form-control"
										aria-describedby="title"
										name="title"
										value={inputData.title}
										onChange={handleInputOnChange}
										required
									/>
								</div>
							</div>
							<div className="col-9">
								<div className="form-group">
									<label htmlFor="video_url">Video URL (e.g. Youtube link, FB Live)</label>
									<input
										id="video_url"
										type="text"
										className="form-control"
										aria-describedby="video_url"
										name="video_url"
										value={inputData.video_url}
										onChange={handleInputOnChange}
										// required
									/>
								</div>
							</div>
							<div className="col-3">
								<div className="form-group">
									<label htmlFor="platform_type">Platform Type</label>
									<div className="select-wrapper">
										<select
											id="platform_type"
											className="form-control"
											aria-describedby="platform_type"
											name="platform_type"
											value={inputData.platform_type}
											onChange={handleInputOnChange}>
											{Object.keys(PLATFORM_TYPES).map((platform) => {
												return (
													<option key={platform} value={PLATFORM_TYPES[platform]["value"]}>
														{PLATFORM_TYPES[platform]["display"]}
													</option>
												);
											})}
										</select>
									</div>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="description">Description</label>
									<input
										id="description"
										type="text"
										className="form-control"
										aria-describedby="description"
										name="description"
										value={inputData.description}
										onChange={handleInputOnChange}
										required
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="row">
									<div className="col-5">
										<div className="form-group">
											<label>Preview / Cover Image</label>
											<img
												src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : IconUpload}
												alt="event-logo"
												className="img-logo"
												onClick={browsePicture}
											/>
											<input
												type="file"
												name="logo"
												onChange={handleChangeLogo}
												ref={logoRef}
												className="d-none"
												accept="image/*"
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="col-6">
								<div className="form-group">
									<label htmlFor="start_date">Start Date</label>
									<DatePicker
										selected={inputData.start_date}
										onChange={(date) => setInputData({ ...inputData, start_date: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										dateFormat="dd MMMM yyyy"
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="start_time">Start Time</label>
									<DatePicker
										selected={inputData.start_time}
										onChange={(date) => setInputData({ ...inputData, start_time: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										showTimeInput
										showTimeSelectOnly
										timeCaption="Time"
										dateFormat="h:mm aa"
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="end_date">End Date</label>
									<DatePicker
										selected={inputData.end_date}
										onChange={(date) => setInputData({ ...inputData, end_date: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										dateFormat="dd MMMM yyyy"
										// minDate={inputData.start_date}
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="end_time">End Time</label>
									<DatePicker
										selected={inputData.end_time}
										onChange={(date) => setInputData({ ...inputData, end_time: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										showTimeInput
										showTimeSelectOnly
										timeCaption="Time"
										dateFormat="h:mm aa"
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="form-check form-group">
									<input
										className="form-check-input custom-outline-checkbox"
										type="checkbox"
										id="featured"
										onChange={() => setInputData({ ...inputData, featured: !inputData.featured })}
										checked={inputData.featured}
									/>
									<label className="form-check-label custom-checkbox-label" htmlFor="featured">
										Featured
									</label>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<label htmlFor="speaker">Add / Remove Speakers</label>
									<div className="speakers-selection-wrapper">
										<input
											id="speaker"
											type="text"
											className="form-control"
											aria-describedby="speaker"
											name="speaker"
											value={inputData.speaker}
											onChange={handleInputOnChange}
											placeholder="Search"
											list="speakers"
											onClick={() => setShowSpeakerSelection(!showSpeakerSelection)}
											autoComplete="off"
											// onFocus={() => setShowSpeakerSelection(!showSpeakerSelection)}
											// onBlur={() => setShowSpeakerSelection(false)}
										/>
										{showSpeakerSelection && (
											<>
												<div
													className="custom-selection-wrapper-bgOverlay"
													onClick={() =>
														setShowSpeakerSelection(!showSpeakerSelection)
													}></div>
												<div
													className="speakers-dropdown"
													onScroll={handleSpeakerSelectionScroll}>
													{speakersFiltered.map((speaker) => {
														const {
															user_id,
															profile_image_url,
															first_name,
															last_name,
															organization_name,
														} = speaker;

														let speakerAdded = selectedSpeakers.filter(
															(selectedSpeaker) => {
																return selectedSpeaker.user_id === user_id;
															}
														);

														if (speakerAdded.length > 0) {
															return <div key={`speaker-option-${user_id}`}></div>;
														}

														return (
															<div
																key={`speaker-option-${user_id}`}
																className="speaker-option row align-items-center">
																<div className="col-1">
																	<img
																		src={profile_image_url || IconDefaultUser}
																		alt="profile"
																		className="img-fluid"
																	/>
																</div>
																<div className="col-9">
																	<div className="speaker-name">
																		{last_name} {first_name}
																	</div>
																	<div className="speaker-organization">
																		{organization_name}
																	</div>
																</div>
																<div className="col-2">
																	{speakerAdded.length > 0 ? (
																		<button
																			type="button"
																			className="btn btn-outline-primary w-100"
																			onClick={() => removeSpeaker(speaker)}>
																			Remove
																		</button>
																	) : (
																		<button
																			type="button"
																			className="btn btn-primary w-100"
																			onClick={() => addSpeaker(speaker)}>
																			Add
																		</button>
																	)}
																</div>
															</div>
														);
													})}
												</div>
											</>
										)}
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className="selected-speaker-container row">
									{selectedSpeakers.map((speaker) => {
										const { user_id, profile_image_url, first_name, last_name, organization_name } =
											speaker;

										return (
											<div key={`selected-speaker-${user_id}`} className="col-4 mb-3">
												<div className="speaker-item">
													<div className="row align-items-center">
														<div className="col-2 pr-0">
															<img
																src={profile_image_url || IconDefaultUser}
																alt="profile"
																className="img-fluid"
															/>
														</div>
														<div className="col-8">
															<div className="speaker-name">
																{last_name} {first_name}
															</div>
															<div className="speaker-organization">
																{organization_name}
															</div>
														</div>
														<div className="col-2 pl-0 text-right">
															<img
																src={IconRemove}
																alt="remove"
																className="img-fluid icon-remove"
																onClick={() => removeSpeaker(speaker)}
															/>
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-9"></div>
							<div className="col-3">
								<button type="submit" className="btn btn-primary w-100">
									Create Session
								</button>
							</div>
						</div>
					</form>
				</section>
			</div>
		</EventLayoutContainer>
	);
};

export default withRouter(AddChannelSession);
