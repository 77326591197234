import React, { useState, useRef } from "react";
import LayoutContainer from "../../containers/LayoutContainer/LayoutContainer";
import OrganizationAPI from "app/apis/organization";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import "./style.scss";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";
import { organizationActions } from "app/states/organization";
import LocalStorageService from "app/services/localStorageService";

const AddOrganization = ({ history }) => {
	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setOrganizationId = (id) => dispatch(organizationActions.setOrganizationId(id));
	const setSelectedOrganizationAccount = (organizationAccount) =>
		dispatch(organizationActions.setSelectedOrganizationAccount(organizationAccount));

	const [organizationDetail, setOrganizationDetail] = useState({
		name: "",
		email: "",
		logo_url: "",
		pic_name: "",
		pic_email: "",
		pic_phone_no: "",
	});

	const [uploadedLogo, setUploadedLogo] = useState(null);

	const logoRef = useRef();

	const handleInputOnChange = (event) => {
		setOrganizationDetail({ ...organizationDetail, [event.target.name]: event.target.value });
	};

	const handleChangeLogo = (event) => {
		setUploadedLogo(event.target.files[0]);
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleCreateOrganization = (event) => {
		event.preventDefault();

		let formData = new FormData();

		if (uploadedLogo) {
			formData.append("logo", uploadedLogo);
		}

		formData.append("name", organizationDetail.name);
		formData.append("email", organizationDetail.email);
		formData.append("pic_name", organizationDetail.pic_name);
		formData.append("pic_phone_no", organizationDetail.pic_phone_no);
		formData.append("pic_email", organizationDetail.pic_email);
		formData.append("event_organizer_id", 1);

		OrganizationAPI.postCreateOrganization(formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					const newOrganizationId = response.data.id;
					getNewOrganizationDetailsAndRedirect(newOrganizationId);

					openAlertSnackbar("Organization Created", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const getNewOrganizationDetailsAndRedirect = (newOrganizationId) => {
		OrganizationAPI.getOrganizationDetail(newOrganizationId)
			.then((response) => {
				const { name, logo_url } = response.data;

				const newOrganization = {
					id: newOrganizationId,
					name: name,
					logo_url: logo_url,
				};

				setOrganizationId(newOrganizationId);
				LocalStorageService.setSelectedOrganizationAccount(newOrganization);
				setSelectedOrganizationAccount(newOrganization);

				// Redirect to view organization page
				history.replace("/dashboard");
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<LayoutContainer>
			<section className="page-header">
				<h2 className="title">Add New Organization</h2>
				<span className="subtitle">Add New</span>
			</section>

			<section className="profile-and-settings p-4">
				<form id="form-profile-settings" method="post" onSubmit={handleCreateOrganization}>
					<div className="row">
						<div className="col-7">
							<div className="form-group">
								<label>Organization Logo</label>
								<div className="row">
									<div className="col-2">
										<img
											src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : IconUpload}
											alt="org-logo"
											className="img-fluid icon-upload"
											onClick={browsePicture}
										/>
									</div>
									<input
										type="file"
										name="logo"
										onChange={handleChangeLogo}
										ref={logoRef}
										className="d-none"
										accept="image/*"
									/>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="organization-name">Organization Name</label>
								<input
									id="organization-name"
									type="text"
									className="form-control"
									aria-describedby="organization-name"
									name="name"
									value={organizationDetail.name}
									onChange={handleInputOnChange}
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="organization-email">Organization Email Address</label>
								<input
									id="organization-email"
									type="text"
									className="form-control"
									aria-describedby="organization-email"
									name="email"
									value={organizationDetail.email}
									onChange={handleInputOnChange}
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="pic_name">PIC Name</label>
								<input
									id="pic_name"
									type="text"
									className="form-control"
									aria-describedby="pic_name"
									name="pic_name"
									value={organizationDetail.pic_name}
									onChange={handleInputOnChange}
									required
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="pic_contact_number">PIC Contact Number</label>
								<input
									id="pic_contact_number"
									type="text"
									className="form-control"
									aria-describedby="pic_contact_number"
									name="pic_phone_no"
									value={organizationDetail.pic_phone_no}
									onChange={handleInputOnChange}
									required
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="pic_email">PIC Email Address</label>
								<input
									id="pic_email"
									type="text"
									className="form-control"
									aria-describedby="pic_email"
									name="pic_email"
									value={organizationDetail.pic_email}
									onChange={handleInputOnChange}
									required
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-10"></div>
						<div className="col-2">
							<button type="submit" className="btn btn-primary w-100">
								Create
							</button>
						</div>
					</div>
				</form>
			</section>
		</LayoutContainer>
	);
};

export default withRouter(AddOrganization);
