/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./style.scss";
import { withRouter } from "react-router-dom";
import BillingAPI from "app/apis/billings";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from 'axios';

const InvoiceListContainer = ({ history }) => {
	const [invoices, setInvoices] = useState([]);

	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);
	const refreshBillingDashboardCount = useSelector(({ billing }) => billing.refreshBillingDashboardCount);

	// Get invoices
	useEffect(() => {
		if (!selectedOrganizationAccount) return;

		BillingAPI.getInvoices(selectedOrganizationAccount.id)
			.then((response) => {
				setInvoices(response);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [refreshBillingDashboardCount]);

	const handleViewInvoice = async (invoicePdf) => {
		try {
			await axios
				.get(invoicePdf, {
					responseType: "blob",
				})
				.then((response) => {
					//Create a Blob from the PDF Stream
					const file = new Blob([response.data], { type: "application/pdf" });
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);
					//Open the URL on new Window
					const pdfWindow = window.open();
					pdfWindow.location.href = fileURL;
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			return { error };
		}

		// window.open(invoicePdf, "_blank");
	};

	return (
		<div className="section-container invoice-list-container">
			<h3 className="section-title">My Invoices</h3>
			<table className="table table-hover">
				<thead>
					<tr>
						<th scope="col" className="table-header">
							Invoice Number
						</th>
						<th scope="col" className="table-header">
							Invoice Date
						</th>
						<th scope="col" className="table-header">
							Due Date
						</th>
						<th scope="col" className="table-header">
							Amount
						</th>
						<th scope="col" className="table-header">
							Payment Status
						</th>
					</tr>
				</thead>
				<tbody>
					{invoices.map((invoice, index) => {
						const { no, paid, created, due_date, amount, pdf } = invoice;

						return (
							<tr key={`invoice-${index}`}>
								<td className="table-cell">
									<span className="invoice-number-link" onClick={() => handleViewInvoice(pdf)}>
										{no || "-"}
									</span>
								</td>
								<td className="table-cell">{created ? moment(created).format("DD MMM YYYY") : "-"}</td>
								<td className="table-cell">
									{due_date ? moment(due_date).format("DD MMM YYYY") : "-"}
								</td>
								<td className="table-cell">{`$${amount}`}</td>
								<td className="table-cell">
									{paid ? (
										<span className="status-paid">Paid</span>
									) : (
										<span className="status-unpaid">Unpaid</span>
									)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default withRouter(InvoiceListContainer);
