import React, { useState } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "app/states/event";
import IconClose from "app/assets/icon-close.png";
import ChannelSessionAPI from "app/apis/channelSession";
import { withRouter, useParams } from 'react-router-dom';
import LocalStorageService from "app/services/localStorageService";
import { commonActions } from "app/states/common";

const CreateQnaModal = ({ history }) => {
	const userProfile = LocalStorageService.getUserProfile();

	const dispatch = useDispatch();
	const closeCreateQnaModal = () => dispatch(eventActions.closeCreateQnaModal());
	const refreshQnaListing = (refresh) => dispatch(eventActions.refreshQnaListing(refresh));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showCreateQnaModal = useSelector(({ event }) => event.showCreateQnaModal);
	const { sessionId } = useParams();
	
	const [question, setQuestion] = useState("");

	const handleCloseCreateQnaModal = () => {
		setQuestion("");
		closeCreateQnaModal();
	}

	const handleCreateQna = (event) => {
		event.preventDefault();

		const data = {
			session_id: sessionId,
			question: question,
			question_created_by: userProfile.id
		}

		ChannelSessionAPI.postAddQuestion(data)
		.then((response) => {
			const { code } = response;

			if (code === 200) {
				refreshQnaListing(true);
				handleCloseCreateQnaModal();

				return;
			}

			openAlertSnackbar(response.errors.join("\n"), "error");
		})
		.catch((error) => {
			openAlertSnackbar(error.data.errors.join("\n"), "error");
		});
	};

	return (
		<Modal
			show={showCreateQnaModal}
			onHide={handleCloseCreateQnaModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="create-qna-modal"
			centered>
			<Modal.Body>
				<div className="create-qna-modal-body">
					<div className="d-flex justify-content-end">
						<img src={IconClose} alt="close" className="icon-close" onClick={handleCloseCreateQnaModal} />
					</div>

					<h3>Add Question</h3>

					<form method="post" onSubmit={handleCreateQna}>
						<div className="form-group">
							<label htmlFor="question">Question</label>
							<input
								id="question"
								type="text"
								className="form-control"
								aria-describedby="question"
								name="question"
								value={question}
								onChange={(event) => setQuestion(event.target.value)}
								required
							/>
						</div>

						<button type="submit" className="btn btn-primary w-100 mt-3">
							Create
						</button>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(CreateQnaModal);
