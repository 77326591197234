/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import IconBack from "app/assets/icon-back-grey.png";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ExhibitorAPI from "app/apis/exhibitor";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import LocalStorageService from "app/services/localStorageService";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ExhibitorRoomDetailsContainer from "app/views/containers/Exhibitor/ExhibitorRoomDetailsContainer/ExhibitorRoomDetailsContainer";
import { commonActions } from "app/states/common";
import IconLive from "app/assets/icon-live.png";
import LiveToggleModal from "app/views/components/LiveToggleModal/LiveToggleModal";
import StreamingContainer from "app/views/containers/Exhibitor/StreamingContainer/StreamingContainer";
import StoreContainer from "app/views/containers/Exhibitor/StoreContainer/StoreContainer";
import AudienceContainer from "app/views/containers/Exhibitor/AudienceContainer/AudienceContainer";
import StoreProductsContainer from "app/views/containers/Exhibitor/StoreProductsContainer/StoreProductsContainer";
import { eventActions } from "app/states/event";
import StoreOrdersContainer from "app/views/containers/Exhibitor/StoreOrdersContainer/StoreOrdersContainer";
import EventInfo from "app/views/components/EventInfo/EventInfo";
import SessionStorageService from "app/services/sessionStorageService";

const ViewExhibitorRoom = ({ history, location }) => {
	let { roomId } = useParams();

	const dispatch = useDispatch();
	const openLiveToggleModal = () => dispatch(commonActions.openLiveToggleModal());
	const closeLiveToggleModal = () => dispatch(commonActions.openLiveToggleModal());
	const refreshExhibitorRoomDetails = (refresh) => dispatch(eventActions.refreshExhibitorRoomDetails(refresh));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const isRefreshExhibitorRoomDetails = useSelector(({ event }) => event.isRefreshExhibitorRoomDetails);
	const eventDetails = useSelector(({ event }) => event.eventDetails);

	const [exhibitorRoomDetails, setExhibitorRoomDetails] = useState(null);
	const [streamingDetails, setStreamingDetails] = useState(null);
	const [storeDetails, setStoreDetails] = useState(null);
	const [maxStoreProductSlots, setMaxStoreProductSlots] = useState(0);
	const [roomSessionId, setRoomSessionId] = useState(null);
	const [tabKey, setTabKey] = useState(SessionStorageService.getExhibitorRoomViewingTab() || "details");

	const [roomLogo, setRoomLogo] = useState();
	const [roomName, setRoomName] = useState("");
	const [roomCategory, setRoomCategory] = useState("");
	const [liveInfo, setLiveInfo] = useState({
		isLive: false,
		video_url: "",
		start_date: moment(),
		end_date: moment(),
		cover_image: "",
	});

	// Get the default tab key if any
	useEffect(() => {
		if (location.state && location.state.defaultTabKey) {
			setTabKey(location.state.defaultTabKey);
		}
	}, []);

	// Get exhibitor room details
	useEffect(() => {
		getExhibitorRoomDetails();
	}, []);

	// Get latest exhibitor room details after update
	useEffect(() => {
		if (isRefreshExhibitorRoomDetails) {
			refreshExhibitorRoomDetails(false);
			getExhibitorRoomDetails();
			getStreamingDetails();
		}
	}, [isRefreshExhibitorRoomDetails]);

	useEffect(() => {
		if (roomSessionId) {
			getStreamingDetails();
		}
	}, [roomSessionId]);

	const getExhibitorRoomDetails = () => {
		ExhibitorAPI.getExhibitorRoomDetail(roomId)
			.then((response) => {
				const {
					title,
					logo_url,
					event_exhibitor_room_stores,
					max_store_slots,
					event_exhibitor_room_categories,
					event_exhibitor_room_sessions,
				} = response.data;

				setRoomLogo(logo_url);
				setRoomName(title);

				LocalStorageService.setExhibitorRoomDetails(response.data);

				setExhibitorRoomDetails(response.data);

				if (event_exhibitor_room_stores.length > 0) {
					setStoreDetails(event_exhibitor_room_stores[0]);
				}

				setMaxStoreProductSlots(max_store_slots);

				// Set category for page header
				if (event_exhibitor_room_categories.length > 0) {
					setRoomCategory(event_exhibitor_room_categories[0].name);
				}

				// Set the room session id
				if (event_exhibitor_room_sessions) {
					setRoomSessionId(event_exhibitor_room_sessions.id);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const getStreamingDetails = () => {
		ExhibitorAPI.getEventRoomStreaming(roomSessionId)
			.then((response) => {
				const { content_url, thumbnail_url, live, start_at, end_at } = response.data;
				setStreamingDetails(response.data);
				setLiveInfo({
					isLive: live ? true : false,
					video_url: content_url,
					start_date: start_at,
					end_date: end_at,
					cover_image: thumbnail_url,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleToggleLiveSwitch = () => {
		openLiveToggleModal();
	};

	const handleToggleLiveConfirm = () => {
		const data = {
			is_live: !liveInfo.isLive ? 1 : 0,
		};

		ExhibitorAPI.postToggleLive(roomSessionId, data)
			.then((response) => {
				if (response.code === 200) {
					setLiveInfo({ ...liveInfo, isLive: !liveInfo.isLive });
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleTabChange = (selectedKey) => {
		setTabKey(selectedKey);
		SessionStorageService.setExhibitorRoomViewingTab(selectedKey);
	};

	const handleBack = () => {
		SessionStorageService.clearExhibitorRoomViewingTab();
		history.goBack();
	};

	return (
		<EventLayoutContainer>
			<div id="view-exhibitor-room-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-4">
					<div className="d-flex align-items-center">
						<div className="btn-back" onClick={handleBack}>
							<img src={IconBack} alt="back" className="img-fluid" />
						</div>
						<img src={roomLogo} alt="room logo" className="img-fluid room-logo" />
						<div>
							<h2 className="title">{roomName}</h2>
							<span className="subtitle">{roomCategory}</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<div className="view-exhibitor-room-content-wrapper">
					<Tabs activeKey={tabKey} onSelect={handleTabChange} className="custom-tabs">
						<Tab eventKey="details" title="Details">
							<ExhibitorRoomDetailsContainer exhibitorRoomDetails={exhibitorRoomDetails} />
						</Tab>
						<Tab eventKey="streaming" title="Streaming">
							<StreamingContainer streamingDetails={streamingDetails} />
							<AudienceContainer />
						</Tab>
						{eventDetails && eventDetails.event_settings.ecommerce_enabled === 1 && (
							<Tab eventKey="store" title="Store">
								<StoreContainer storeDetails={storeDetails} />
								{storeDetails && <StoreProductsContainer maxStoreProductSlots={maxStoreProductSlots} />}
								{storeDetails && <StoreOrdersContainer />}
							</Tab>
						)}
					</Tabs>

					{roomSessionId && (
						<section className="live-settings d-flex justify-content-end align-items-center mb-3">
							<img src={IconLive} alt="live" className="icon-live" />
							<div className="custom-control custom-switch">
								<input
									type="checkbox"
									className="custom-control-input"
									id="live"
									name="live"
									checked={liveInfo.isLive}
									onChange={handleToggleLiveSwitch}
								/>
								<label className="custom-control-label" htmlFor="live"></label>
							</div>
						</section>
					)}
				</div>

				<LiveToggleModal
					isLive={liveInfo.isLive}
					streamingUrl={liveInfo.video_url}
					streamingStartAt={liveInfo.start_date}
					streamingEndAt={liveInfo.end_date}
					streamingCover={liveInfo.cover_image}
					title={liveInfo.isLive ? "Turn Off LIVE?" : `Go LIVE ?`}
					textConfirm={liveInfo.isLive ? "Turn Off Live" : "Go Live Now!"}
					onConfirm={handleToggleLiveConfirm}
					textCancel={liveInfo.isLivee ? "Stay on Live" : "Cancel"}
					onCancel={closeLiveToggleModal}
				/>
			</div>
		</EventLayoutContainer>
	);
};

export default withRouter(ViewExhibitorRoom);
