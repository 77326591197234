import React, { useState, useRef } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "app/states/event";
import IconClose from "app/assets/icon-close.png";
import { withRouter, useParams } from "react-router-dom";
import { commonActions } from "app/states/common";
import IconAddGrey from "app/assets/icon-add-grey.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LandingPageAPI from "app/apis/landingPage";
import moment from "moment";

const AddEventHighlightsModal = ({ history }) => {
	const { eventId } = useParams();

	const dispatch = useDispatch();
	const closeAddEventHighlightsModal = () => dispatch(eventActions.closeAddEventHighlightsModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const refreshEventHighlightsList = () => dispatch(eventActions.refreshEventHighlightsList());

	const showAddEventHighlightsModal = useSelector(({ event }) => event.showAddEventHighlightsModal);

	const [uploadedPhoto, setUploadedPhoto] = useState(null);
	const photoRef = useRef();

	const [inputData, setInputData] = useState({
		photo: "",
		title: "",
		subtitle: "",
		start_date: "",
		start_time: "",
		end_date: "",
		end_time: "",
		description: "",
		highlighted_name: "",
	});

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleCloseAddEventHighlightsModal = () => {
		// Clear inputs
		setInputData({
			photo: "",
			title: "",
			subtitle: "",
			start_date: "",
			start_time: "",
			end_date: "",
			end_time: "",
			description: "",
			highlighted_name: "",
		});
		setUploadedPhoto(null);

		closeAddEventHighlightsModal();
	};

	const handleAddEventHighlights = (event) => {
		event.preventDefault();

		// Validation
		if (!uploadedPhoto) {
			openAlertSnackbar("Photo is required.", "error");
			return;
		}

		// Form Data
		let formData = new FormData();
		formData.append("event_highlight_logo", uploadedPhoto);
		formData.append("title", inputData.title);
		formData.append("description", inputData.description);

		if (inputData.subtitle) {
			formData.append("subtitle", inputData.subtitle);
		}
		if (inputData.start_date) {
			let formattedStartDate = moment(inputData.start_date).format("DD MMMM YYYY");
			let formattedStartTime = inputData.start_time ? moment(inputData.start_time).format("h:mma") : "12:00am";
			let startDateTime = moment(formattedStartDate + " " + formattedStartTime, "DD MMMM YYYY h:mma");
			let startAt = startDateTime.format("YYYY-MM-DD HH:mm:ss");
			formData.append("start_at", startAt);
		}
		if (inputData.end_date) {
			let formattedEndDate = moment(inputData.end_date).format("DD MMMM YYYY");
			let formattedEndTime = inputData.end_time ? moment(inputData.end_time).format("h:mma") : "12:00am";
			let endDateTime = moment(formattedEndDate + " " + formattedEndTime, "DD MMMM YYYY h:mma");
			let endAt = endDateTime.format("YYYY-MM-DD HH:mm:ss");
			formData.append("end_at", endAt);
		}
		if (inputData.highlighted_name) {
			formData.append("highlight_name", inputData.highlighted_name);
		}

		// Check end datetime and start datetime
		if (inputData.start_date && inputData.end_date) {
			let formattedStartDate = moment(inputData.start_date).format("DD MMMM YYYY");
			let formattedStartTime = inputData.start_time ? moment(inputData.start_time).format("h:mma") : "12:00am";
			let startDateTime = moment(formattedStartDate + " " + formattedStartTime, "DD MMMM YYYY h:mma");

			let formattedEndDate = moment(inputData.end_date).format("DD MMMM YYYY");
			let formattedEndTime = inputData.end_time ? moment(inputData.end_time).format("h:mma") : "12:00am";
			let endDateTime = moment(formattedEndDate + " " + formattedEndTime, "DD MMMM YYYY h:mma");

			// Check end datetime and start datetime
			if (!endDateTime.isAfter(startDateTime)) {
				openAlertSnackbar("Start date must be earlier than end date.", "error");
				return;
			}
		}

		LandingPageAPI.postCreateUpdateEventHighlights(eventId, "", formData)
			.then((response) => {
				if (response.code === 200) {
					refreshEventHighlightsList();
					openAlertSnackbar("Event highlights added successfully.", "success");
					handleCloseAddEventHighlightsModal();
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleChangePhoto = (event) => {
		setUploadedPhoto(event.target.files[0]);
	};

	const browsePhoto = () => {
		photoRef.current.click();
	};

	return (
		<Modal
			show={showAddEventHighlightsModal}
			onHide={handleCloseAddEventHighlightsModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="add-event-highlights-modal"
			centered>
			<Modal.Body>
				<div className="add-event-highlights-modal-body">
					<div className="d-flex justify-content-end">
						<img
							src={IconClose}
							alt="close"
							className="icon-close"
							onClick={handleCloseAddEventHighlightsModal}
						/>
					</div>

					<h3>Add Event Highlights</h3>

					<form method="post" onSubmit={handleAddEventHighlights}>
						<div className="form-group">
							<label htmlFor="photo">Upload Photo *</label>
							{uploadedPhoto || inputData.photo ? (
								<img
									src={uploadedPhoto ? URL.createObjectURL(uploadedPhoto) : inputData.photo}
									alt="cover"
									className="img-fluid img-photo"
									onClick={browsePhoto}
								/>
							) : (
								<div className="add-photo-wrapper" onClick={browsePhoto}>
									<img src={IconAddGrey} alt="add" className="img-add-photo" />
								</div>
							)}
							<input
								type="file"
								name="photo"
								value={""}
								onChange={handleChangePhoto}
								ref={photoRef}
								className="d-none"
								accept="image/*"
							/>
						</div>
						<div className="form-group">
							<label htmlFor="title">Title *</label>
							<input
								id="title"
								type="text"
								className="form-control"
								aria-describedby="title"
								name="title"
								value={inputData.title}
								onChange={handleInputOnChange}
								required
							/>
						</div>
						<div className="form-group">
							<label htmlFor="subtitle">Subtitle</label>
							<input
								id="subtitle"
								type="text"
								className="form-control"
								aria-describedby="subtitle"
								name="subtitle"
								value={inputData.subtitle}
								onChange={handleInputOnChange}
							/>
						</div>
						<div className="row">
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="start_date">Start Date</label>
									<DatePicker
										selected={inputData.start_date}
										onChange={(date) =>
											setInputData({ ...inputData, start_date: date })
										}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										dateFormat="dd MMMM yyyy"
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="start_time">Start Time</label>
									<DatePicker
										selected={inputData.start_time}
										onChange={(date) => setInputData({ ...inputData, start_time: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										showTimeInput
										showTimeSelectOnly
										timeCaption="Time"
										dateFormat="h:mm aa"
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="end_date">End Date</label>
									<DatePicker
										selected={inputData.end_date}
										onChange={(date) => setInputData({ ...inputData, end_date: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										dateFormat="dd MMMM yyyy"
										// minDate={inputData.start_date}
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="end_time">End Time</label>
									<DatePicker
										selected={inputData.end_time}
										onChange={(date) => setInputData({ ...inputData, end_time: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										showTimeInput
										showTimeSelectOnly
										timeCaption="Time"
										dateFormat="h:mm aa"
									/>
								</div>
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="description">Description *</label>
							<textarea
								id="description"
								type="text"
								className="form-control"
								aria-describedby="description"
								name="description"
								value={inputData.description}
								onChange={handleInputOnChange}
								required
								rows={5}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="highlighted_name">Highlighted Name</label>
							<input
								id="highlighted_name"
								type="text"
								className="form-control"
								aria-describedby="highlighted_name"
								name="highlighted_name"
								value={inputData.v}
								onChange={handleInputOnChange}
							/>
						</div>

						<button type="submit" className="btn btn-primary w-100 mt-4">
							Add
						</button>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(AddEventHighlightsModal);
