/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import AccountAPI from "app/apis/account";
import LayoutContainer from "app/views/containers/LayoutContainer/LayoutContainer";
import { ACCOUNT_TYPES } from "app/config/settings";
import SessionStorageService from "app/services/sessionStorageService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const MAX_TABLE_ROWS = 20;

const Accounts = ({ history }) => {
	const lastViewingPage = SessionStorageService.getLastViewingPage();

	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);

	const [searchText, setSearchText] = useState("");
	const [page, setPage] = useState(lastViewingPage || 1);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [accounts, setAccounts] = useState([]);
	const [loadedAccountListing, setLoadedAccountListing] = useState(false);

	const [sortBy, setSortBy] = useState("");
	const [sortOrdering, setSortOrdering] = useState({
		name: "asc",
		type: "asc",
		email: "asc",
	});

	// Get account listing
	useEffect(() => {
		if (!selectedOrganizationAccount) return;

		let params = {
			page: page,
			limit: MAX_TABLE_ROWS,
		};

		if (searchText) {
			params["name"] = searchText;
		}

		if (sortBy) {
			params["sort_by"] = sortBy;
			params["sort_order"] = sortOrdering[sortBy];
		}

		AccountAPI.getOrganizationAccountListing(selectedOrganizationAccount.id, params)
			.then((response) => {
				const { organization_accounts, paging } = response.data;

				// Set event visitors
				setAccounts(organization_accounts);

				// Set paging
				const { last_page } = paging;
				setTotalPageCount(last_page);

				setLoadedAccountListing(true);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [page]);

	// Search account listing
	useEffect(() => {
		if (!loadedAccountListing) return;

		const timeout = setTimeout(() => {
			let params = {
				page: page,
				limit: MAX_TABLE_ROWS,
			};

			if (searchText) {
				params["name"] = searchText;
			}

			if (sortBy) {
				params["sort_by"] = sortBy;
				params["sort_order"] = sortOrdering[sortBy];
			}

			AccountAPI.getOrganizationAccountListing(selectedOrganizationAccount.id, params)
				.then((response) => {
					const { organization_accounts, paging } = response.data;

					// Set event visitors
					setAccounts(organization_accounts);

					// Set paging
					const { last_page } = paging;
					setTotalPageCount(last_page);
				})
				.catch((error) => {
					console.error(error);
				});
		}, 500);

		return () => clearTimeout(timeout);
	}, [searchText]);

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	const handleSearchChange = (event) => {
		setSearchText(event.target.value);
		setPage(1);
	};

	const handleViewDetails = (id) => {
		SessionStorageService.setLastViewingPage(page);
		history.push(`/accounts/view/${id}`);
	};

	const handleSorting = (sortByColumn) => {
		let sortOrder = "asc";

		sortOrder = sortOrdering[sortByColumn] === "asc" ? "desc" : "asc";

		setSortOrdering({
			...sortOrdering,
			[sortByColumn]: sortOrder,
		});

		setSortBy(sortByColumn);

		if (!selectedOrganizationAccount) return;

		let params = {
			page: 1,
			limit: MAX_TABLE_ROWS,
			sort_by: sortByColumn,
			sort_order: sortOrder,
		};

		if (searchText) {
			params["name"] = searchText;
		}

		AccountAPI.getOrganizationAccountListing(selectedOrganizationAccount.id, params)
			.then((response) => {
				const { organization_accounts, paging } = response.data;

				// Set event visitors
				setAccounts(organization_accounts);

				// Set paging
				const { last_page } = paging;
				setTotalPageCount(last_page);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<LayoutContainer>
			<div id="accounts-page">
				<section className="page-header d-flex justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						<div>
							<h2 className="title">Accounts</h2>
							<span className="subtitle">List of partner accounts</span>
						</div>
					</div>
				</section>

				<section className="px-5 py-4">
					<div className="row">
						<div className="col-9">
							<div className="form-group mb-0">
								<input
									type="text"
									className="form-control"
									name="search"
									placeholder="Search"
									value={searchText}
									onChange={handleSearchChange}
								/>
							</div>
						</div>
						<div className="col-3">
							<button
								type="button"
								className="btn btn-primary w-100 h-100"
								onClick={() => history.push("/accounts/add")}>
								Add Account
							</button>
						</div>
					</div>
				</section>

				<section>
					<table className="table table-hover">
						<thead>
							<tr>
								<th
									scope="col"
									className="table-header cursor-pointer"
									style={{ width: "20%" }}
									onClick={() => handleSorting("type")}>
									Account Type{" "}
									<FontAwesomeIcon
										icon={sortOrdering["type"] === "desc" ? faChevronDown : faChevronUp}
									/>
								</th>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("name")}>
									Name{" "}
									<FontAwesomeIcon
										icon={sortOrdering["name"] === "desc" ? faChevronDown : faChevronUp}
									/>
								</th>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("email")}>
									Email Address{" "}
									<FontAwesomeIcon
										icon={sortOrdering["email"] === "desc" ? faChevronDown : faChevronUp}
									/>
								</th>
								<th scope="col" className="table-header">
									Phone Number
								</th>
								<th scope="col" className="table-header">
									Website
								</th>
							</tr>
						</thead>
						<tbody>
							{accounts.map((account) => {
								const { id, type, name, email, phone_no, website } = account;

								return (
									<tr key={id} className="cursor-pointer" onClick={() => handleViewDetails(id)}>
										<td className="table-cell">
											{type === ACCOUNT_TYPES["company"]["value"] ? "Company" : "Individual"}
										</td>
										<td className="table-cell">{name || "-"}</td>
										<td className="table-cell">{email || "-"}</td>
										<td className="table-cell">{phone_no || "-"}</td>
										<td className="table-cell">{website || "-"}</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					<AppPagination
						pageCount={totalPageCount}
						handlePageChange={handlePageChange}
						initialPage={page - 1}
					/>
				</section>
			</div>
		</LayoutContainer>
	);
};

export default withRouter(Accounts);
