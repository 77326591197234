import VisitorAPI from "app/apis/visitor";
import React, { useState, useEffect, useRef } from "react";
import LayoutContainer from "app/views/containers/LayoutContainer/LayoutContainer";
import "./style.scss";
import IconRemove from "app/assets/icon-close.png";
import { withRouter, useParams } from "react-router-dom";
import IconBack from "app/assets/icon-back-grey.png";
import IconDefaultUser from "app/assets/icon-default-user.png";
import moment from "moment";
import COUNTRIES from "app/config/country_region.json";

const ViewVisitorDetail = ({ history }) => {
	const { visitorId } = useParams();

	// Get "Malaysia" regions
	const MsiaRegions = COUNTRIES.filter((countries) => {
		return countries.countryShortCode === "MY";
	});

	const [regions, setRegions] = useState(MsiaRegions[0].regions);

	const [visitorDetail, setVisitorDetail] = useState({
		photo: "",
		first_name: "",
		last_name: "",
		email: "",
		phone_no: "",
		dob: "",
		gender: "",
		city: "",
		country: "",
		about: "",
		company_name: "",
		industry: "",
		job_title: "",
	});
	const [interests, setInterests] = useState([]);

	useEffect(() => {
		getVisitorDetail();
	}, []);

	const getVisitorDetail = () => {
		VisitorAPI.getVisitorDetail(visitorId)
			.then((response) => {
				console.log(response);
				const {
					first_name,
					last_name,
					email,
					profile_image_url,
					phone_no,
					dob,
					gender,
					country,
					city,
					about,
					extra_info,
				} = response.data.visitor;

				const jobTitle = extra_info.find((info) => {
					return info.key === "jobTitle";
				});

				const companyName = extra_info.find((info) => {
					return info.key === "companyName";
				});

				const industry = extra_info.find((info) => {
					return info.key === "industry";
				});

				const interest = extra_info.find((info) => {
					return info.key === "interest";
				});

				setVisitorDetail({
					...visitorDetail,
					photo: profile_image_url,
					first_name: first_name,
					last_name: last_name,
					email: email,
					phone_no: phone_no,
					dob: moment(dob).format("DD.MM.YYYY"),
					gender: gender,
					country: country,
					city: city,
					about: about,
					job_title: jobTitle.value,
					company_name: companyName.value,
					industry: industry.value,
				});

				// parse interest json if is json string
				let interestArray = [];
				try {
					interestArray = JSON.parse(interest.value);
				} catch (e) {
					// If is not json string, split the string by comma
					interestArray = interest.value.split(",");
				}

				setInterests(interestArray);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleInputOnChange = (event) => {
		setVisitorDetail({ ...visitorDetail, [event.target.name]: event.target.value });
	};

	return (
		<LayoutContainer>
			<section className="page-header">
				<div className="d-flex align-items-center">
					<div className="btn-back" onClick={() => history.goBack()}>
						<img src={IconBack} alt="back" className="img-fluid" />
					</div>
					<div>
						<h2 className="title">Visitor Database</h2>
						<span className="subtitle">Visitor Database &gt; Visitor</span>
					</div>
				</div>
			</section>

			<section className="visitor-detail p-4">
				<form id="form-visitor-detail">
					<div className="row">
						<div className="col-12">
							<div className="form-group">
								<label>Visitor Photo</label>
								<div className="row">
									<div className="col-3">
										<div className="photo-wrapper">
											<img
												src={visitorDetail.photo || IconDefaultUser}
												alt="logo"
												className="img-fluid img-user"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="first_name">First Name</label>
								<input
									id="first_name"
									type="text"
									className="form-control"
									aria-describedby="first_name"
									name="first_name"
									value={visitorDetail.first_name}
									onChange={handleInputOnChange}
									readOnly
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="last_name">Last Name</label>
								<input
									id="last_name"
									type="text"
									className="form-control"
									aria-describedby="last_name"
									name="last_name"
									value={visitorDetail.last_name}
									onChange={handleInputOnChange}
									readOnly
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="email">Email Address</label>
								<input
									id="email"
									type="email"
									className="form-control"
									aria-describedby="email"
									name="email"
									value={visitorDetail.email}
									onChange={handleInputOnChange}
									readOnly
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="phone_no">Phone Number</label>
								<input
									id="phone_no"
									type="text"
									className="form-control"
									aria-describedby="phone_no"
									name="phone_no"
									value={visitorDetail.phone_no}
									onChange={handleInputOnChange}
									readOnly
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="dob">Date of Birth</label>
								<input
									id="dob"
									type="text"
									className="form-control"
									aria-describedby="dob"
									name="dob"
									value={visitorDetail.dob}
									onChange={handleInputOnChange}
									readOnly
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="gender">Gender</label>
								<div className="select-wrapper">
									<select
										id="gender"
										type="email"
										className="form-control"
										aria-describedby="gender"
										name="gender"
										value={visitorDetail.gender}
										onChange={handleInputOnChange}
										disabled>
										<option value="1">Male</option>
										<option value="2">Female</option>
										<option value="0">Others</option>
									</select>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="city">City</label>
								<div className="select-wrapper">
									<select
										id="city"
										type="email"
										className="form-control"
										aria-describedby="city"
										name="city"
										value={visitorDetail.city}
										onChange={handleInputOnChange}
										disabled>
										{regions.map((item) => (
											<option key={item.name} value={item.name}>
												{item.name}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="country">Country</label>
								<div className="select-wrapper">
									<select
										id="country"
										type="email"
										className="form-control"
										aria-describedby="country"
										name="country"
										value={visitorDetail.country}
										onChange={handleInputOnChange}
										disabled>
										{COUNTRIES.map((item) => (
											<option key={item.countryShortCode} value={item.countryShortCode}>
												{item.countryName}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="about">About This Person</label>
								<textarea
									id="about"
									type="email"
									className="form-control"
									aria-describedby="about"
									name="about"
									value={visitorDetail.about}
									onChange={handleInputOnChange}
									readOnly
									rows={5}
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="interest">Interest</label>
								<div className="interest-wrapper">
									{interests.map((interest, index) => {
										return (
											<span key={index} className="interest-pill">
												<span>{interest}</span>
												<img src={IconRemove} alt="remove" className="btn-remove-interest" />
											</span>
										);
									})}
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="company_name">Company Name</label>
								<input
									id="company_name"
									type="text"
									className="form-control"
									aria-describedby="company_name"
									name="company_name"
									value={visitorDetail.company_name}
									onChange={handleInputOnChange}
									readOnly
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="industry">Industry</label>
								<input
									id="industry"
									type="text"
									className="form-control"
									aria-describedby="industry"
									name="industry"
									value={visitorDetail.industry}
									onChange={handleInputOnChange}
									readOnly
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="job_title">Job Title</label>
								<input
									id="job_title"
									type="text"
									className="form-control"
									aria-describedby="job_title"
									name="job_title"
									value={visitorDetail.job_title}
									onChange={handleInputOnChange}
									readOnly
								/>
							</div>
						</div>
					</div>
				</form>
			</section>
		</LayoutContainer>
	);
};

export default withRouter(ViewVisitorDetail);
