import apiAction from "../services/apiAction";

const StatisticAPI = {
	/**
	 * Get report analytics
	 * @param {integer} eventId
	 */
	getReportAnalytics: (eventId) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/${eventId}/reports/analytics`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get report session analytics
	 * @param {integer} eventId
	 */
	getReportSessionAnalytics: (eventId) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/${eventId}/reports/sessions/analytics`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get report session performance
	 * @param {integer} eventId
	 * @param {object} queryParams
	 */
	getReportSessionPerformance: (eventId, queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/${eventId}/reports/sessions/performance`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get report session performance csv
	 * @param {integer} eventId
	 */
	getReportSessionPerformanceCSV: (eventId) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/${eventId}/reports/sessions/performance/csv`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get report exhibitor analytics
	 * @param {integer} eventId
	 */
	getReportExhibitorAnalytics: (eventId) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/${eventId}/reports/exhibitors/analytics`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get report exhibitor performance
	 * @param {integer} eventId
	 * @param {object} queryParams
	 */
	getReportExhibitorPerformance: (eventId, queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/${eventId}/reports/exhibitors/performance`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

    /**
	 * Get report exhibitor performance csv
	 * @param {integer} eventId
	 */
	getReportExhibitorPerformanceCSV: (eventId) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/event/${eventId}/reports/exhibitors/performance/csv`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},
};

export default StatisticAPI;
