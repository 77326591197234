const SessionStorageService = {
	// Ads
	setViewingAdsType: (adsType) => {
		sessionStorage.setItem("viewingAdsType", JSON.stringify(adsType));
	},

	getViewingAdsType: () => {
		const viewingAdsType = sessionStorage.getItem("viewingAdsType");
		return JSON.parse(viewingAdsType);
	},

	// pagination cache
	setLastViewingPage: (lastViewingPage) => {
		sessionStorage.setItem("lastViewingPage", lastViewingPage);
	},

	getLastViewingPage: () => {
		return sessionStorage.getItem("lastViewingPage");
	},

	clearLastViewingPage: () => {
		sessionStorage.removeItem("lastViewingPage");
	},

	// Gamification leaderboard top 3
	setFirstPlace: (firstPlace) => {
		sessionStorage.setItem("firstPlace", JSON.stringify(firstPlace));
	},

	getFirstPlace: () => {
		return JSON.parse(sessionStorage.getItem("firstPlace"));
	},

	setSecondPlace: (secondPlace) => {
		sessionStorage.setItem("secondPlace", JSON.stringify(secondPlace));
	},

	getSecondPlace: () => {
		return JSON.parse(sessionStorage.getItem("secondPlace"));
	},

	setThirdPlace: (thirdPlace) => {
		sessionStorage.setItem("thirdPlace", JSON.stringify(thirdPlace));
	},

	getThirdPlace: () => {
		return JSON.parse(sessionStorage.getItem("thirdPlace"));
	},

	clearGamificationLeaderboardTop3: () => {
		sessionStorage.removeItem("firstPlace");
		sessionStorage.removeItem("secondPlace");
		sessionStorage.removeItem("thirdPlace");
	},

	// exhibitor room tab cache
	setExhibitorRoomViewingTab: (tab) => {
		sessionStorage.setItem("exhibitorRoomViewingTab", tab);
	},

	getExhibitorRoomViewingTab: () => {
		return sessionStorage.getItem("exhibitorRoomViewingTab");
	},

	clearExhibitorRoomViewingTab: () => {
		sessionStorage.removeItem("exhibitorRoomViewingTab");
	},

	// token expired flag
	setTokenExpired: (tokenExpired) => {
		sessionStorage.setItem("tokenExpired", tokenExpired);
	},

	getTokenExpired: () => {
		return sessionStorage.getItem("tokenExpired");
	},

	clearTokenExpired: () => {
		sessionStorage.removeItem("tokenExpired");
	},
};

export default SessionStorageService;
