import React, { useState, useRef } from "react";
import LayoutContainer from "../../../containers/LayoutContainer/LayoutContainer";
import IconBack from "app/assets/icon-back-grey.png";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import { HexColorPicker } from "react-colorful";
import IconExhibitionButtonInactive from "app/assets/icon-exhibition-button-inactive.png";
import IconExhibitionButtonActive from "app/assets/icon-exhibition-button-active.png";
import IconEventButtonInactive from "app/assets/icon-event-button-inactive.png";
import IconEventButtonActive from "app/assets/icon-event-button-active.png";
import IconConferenceButtonInactive from "app/assets/icon-conference-button-inactive.png";
import IconConferenceButtonActive from "app/assets/icon-conference-button-active.png";
import IconSeminarButtonInactive from "app/assets/icon-seminar-button-inactive.png";
import IconSeminarButtonActive from "app/assets/icon-seminar-button-active.png";
import IconWorkshopButtonInactive from "app/assets/icon-workshop-button-inactive.png";
import IconWorkshopButtonActive from "app/assets/icon-workshop-button-active.png";
import IconChannelModule from "app/assets/icon-module-channels.png";
import IconExhibitorModule from "app/assets/icon-module-exhibitor.png";
import IconEcommerceModule from "app/assets/icon-module-ecommerce.png";
import EventAPI from "app/apis/event";
import { useDispatch } from "react-redux";
import { EVENT_TYPE, PAYMENT_SETTINGS } from "app/config/settings";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import moment from "moment-timezone";
import { commonActions } from "app/states/common";
import LocalStorageService from "app/services/localStorageService";
import { useSelector } from "react-redux";

const AddEvent = ({ history }) => {
	const dispatch = useDispatch();
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);

	const [eventInput, setEventInput] = useState({
		name: "",
		tagline: "",
		sub_tagline: "",
		logo_url: "",
		event_type: "",
		theme_color: "#3E51F1",
		start_date: new Date(),
		start_time: new Date(),
		end_date: new Date(),
		end_time: new Date(),
		requires_mobile_num_verification: false,
		payment_type: PAYMENT_SETTINGS["ecommerce"],
		privacy_policy: "",
		terms_of_use: "",
	});

	const [modulesEnabled, setModulesEnabled] = useState([]);

	const [uploadedLogo, setUploadedLogo] = useState(null);
	const [uploadedCoverImage, setUploadedCoverImage] = useState(null);

	const logoRef = useRef();
	const coverImageRef = useRef();

	const handleInputOnChange = (event) => {
		if (event.target.name === "requires_mobile_num_verification") {
			setEventInput({ ...eventInput, [event.target.name]: !eventInput.requires_mobile_num_verification });
		} else {
			setEventInput({ ...eventInput, [event.target.name]: event.target.value });
		}
	};

	const handleModulesChange = (event) => {
		let value = event.target.value;

		// If already enabled, remove from array to disable it
		if (modulesEnabled.includes(value)) {
			let updatedModuleArray = modulesEnabled.filter((module) => {
				return module !== value;
			});
			setModulesEnabled(updatedModuleArray);
		} else {
			setModulesEnabled([...modulesEnabled, value]);
		}
	};

	const handleChangeLogo = (event) => {
		setUploadedLogo(event.target.files[0]);
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleChangeCoverImage = (event) => {
		setUploadedCoverImage(event.target.files[0]);
	};

	const browseCoverImage = () => {
		coverImageRef.current.click();
	};

	const handleCreateEvent = (event) => {
		event.preventDefault();
		showLoading();

		// Ensure there is organization selected
		if (!selectedOrganizationAccount) {
			hideLoading();
			openAlertSnackbar("Organization is required", "error");
			return;
		}

		let formData = new FormData();

		if (uploadedLogo) {
			formData.append("logo", uploadedLogo);
		}

		if (uploadedCoverImage) {
			formData.append("cover", uploadedCoverImage);
		}

		// Process start/end time
		let formattedStartDate = moment(eventInput.start_date).format("DD MMMM YYYY");
		let formattedStartTime = moment(eventInput.start_time).format("h:mma");
		let startDateTime = moment(formattedStartDate + " " + formattedStartTime, "DD MMMM YYYY h:mma");
		let startAt = startDateTime.format("YYYY-MM-DD HH:mm");

		let formattedEndDate = moment(eventInput.end_date).format("DD MMMM YYYY");
		let formattedEndTime = moment(eventInput.end_time).format("h:mma");
		let endDateTime = moment(formattedEndDate + " " + formattedEndTime, "DD MMMM YYYY h:mma");
		let endAt = endDateTime.format("YYYY-MM-DD HH:mm");

		// Check end datetime and start datetime
		if (!endDateTime.isAfter(startDateTime)) {
			openAlertSnackbar("Start date must be earlier than end date.", "error");
			hideLoading();
			return;
		}

		// let date = moment();
		// let utcOffset = moment.parseZone(date).utcOffset() / 60;
		// let timezone = utcOffset > 0 ? `GMT+${utcOffset}` : `GMT${utcOffset}`;
		// formData.append("timezone", timezone);

		formData.append("organization_id", selectedOrganizationAccount.id);
		formData.append("name", eventInput.name);
		formData.append("type", EVENT_TYPE[eventInput.event_type]);
		formData.append("start_at", startAt);
		formData.append("end_at", endAt);
		formData.append("channel_enabled", modulesEnabled.includes("channel") ? 1 : 0);
		formData.append("exhibitor_enabled", modulesEnabled.includes("exhibitor") ? 1 : 0);
		formData.append("ecommerce_enabled", modulesEnabled.includes("ecommerce") ? 1 : 0);
		formData.append("payment_type", eventInput.payment_type);
		formData.append("timezone", moment.tz.guess());

		formData.append("event_web_configs[0][config_id]", 1); // Slogan
		formData.append("event_web_configs[0][value]", eventInput.tagline);

		formData.append("event_web_configs[1][config_id]", 2); // Sub Slogan
		formData.append("event_web_configs[1][value]", eventInput.sub_tagline);

		// formData.append("event_web_configs[2][config_id]", 4); // Theme color
		// formData.append("event_web_configs[2][value]", eventInput.theme_color);

		formData.append("event_web_configs[2][config_id]", 5); // REGISTRATION_PHONE_NO_MANDATORY
		formData.append("event_web_configs[2][value]", eventInput.requires_mobile_num_verification ? 1 : 0);

		formData.append("event_web_configs[3][config_id]", 6); // PRIVACY_POLICY
		formData.append("event_web_configs[3][value]", eventInput.privacy_policy);

		formData.append("event_web_configs[4][config_id]", 7); // TERM_OF_USE
		formData.append("event_web_configs[4][value]", eventInput.terms_of_use);

		EventAPI.postCreateEvent(formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					const { id } = response.data;
					openAlertSnackbar("Event Created", "success");
					history.replace("/events");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	return (
		<LayoutContainer>
			<div id="add-event-page">
				<section className="page-header d-flex align-items-center">
					<div className="btn-back" onClick={() => history.goBack()}>
						<img src={IconBack} alt="back" className="img-fluid" />
					</div>
					<div>
						<h2 className="title">Add New Event</h2>
						<span className="subtitle">New event setup</span>
					</div>
				</section>

				<section className="p-5">
					<form id="form-add-event" method="post" onSubmit={handleCreateEvent}>
						<div className="row mb-3">
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="event-name">Event Name</label>
									<input
										id="event-name"
										type="text"
										className="form-control"
										aria-describedby="event-name"
										name="name"
										value={eventInput.name}
										onChange={handleInputOnChange}
										required
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="tagline">Tagline</label>
									<input
										id="tagline"
										type="text"
										className="form-control"
										aria-describedby="tagline"
										name="tagline"
										value={eventInput.tagline}
										onChange={handleInputOnChange}
										required
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="sub_tagline">Sub Tagline</label>
									<input
										id="sub_tagline"
										type="text"
										className="form-control"
										aria-describedby="sub_tagline"
										name="sub_tagline"
										value={eventInput.sub_tagline}
										onChange={handleInputOnChange}
										required
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<label>Upload Event Logo</label>
									<img
										src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : IconUpload}
										alt="event-logo"
										className="img-fluid img-logo"
										onClick={browsePicture}
									/>
									<input
										type="file"
										name="logo"
										onChange={handleChangeLogo}
										ref={logoRef}
										className="d-none"
										accept="image/*"
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<label>Upload Event Cover Image</label>
									<img
										src={uploadedCoverImage ? URL.createObjectURL(uploadedCoverImage) : IconUpload}
										alt="event-logo"
										className="img-fluid img-cover"
										onClick={browseCoverImage}
									/>
									<input
										type="file"
										name="cover_image"
										onChange={handleChangeCoverImage}
										ref={coverImageRef}
										className="d-none"
										accept="image/*"
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<label>Event Type</label>
									<div className="d-flex align-items-start">
										<div className="event-type-item-wrapper mb-3">
											<img
												src={
													eventInput.event_type === "Exhibition"
														? IconExhibitionButtonActive
														: IconExhibitionButtonInactive
												}
												alt="exhibition"
												className="img-fluid img-event-type img-exhibition"
												onClick={() =>
													setEventInput({ ...eventInput, event_type: "Exhibition" })
												}
											/>
										</div>
										<div className="event-type-item-wrapper mb-3">
											<img
												src={
													eventInput.event_type === "Event"
														? IconEventButtonActive
														: IconEventButtonInactive
												}
												alt="event"
												className="img-fluid img-event-type"
												onClick={() => setEventInput({ ...eventInput, event_type: "Event" })}
											/>
										</div>
										<div className="event-type-item-wrapper mb-3">
											<img
												src={
													eventInput.event_type === "Conference"
														? IconConferenceButtonActive
														: IconConferenceButtonInactive
												}
												alt="conference"
												className="img-fluid img-event-type"
												onClick={() =>
													setEventInput({ ...eventInput, event_type: "Conference" })
												}
											/>
										</div>
										<div className="event-type-item-wrapper mb-3">
											<img
												src={
													eventInput.event_type === "Seminar"
														? IconSeminarButtonActive
														: IconSeminarButtonInactive
												}
												alt="seminar"
												className="img-fluid img-event-type"
												onClick={() => setEventInput({ ...eventInput, event_type: "Seminar" })}
											/>
										</div>
										<div className="event-type-item-wrapper mb-3">
											<img
												src={
													eventInput.event_type === "Workshop"
														? IconWorkshopButtonActive
														: IconWorkshopButtonInactive
												}
												alt="workshop"
												className="img-fluid img-event-type"
												onClick={() => setEventInput({ ...eventInput, event_type: "Workshop" })}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 modules-container mb-3">
								<div className="form-group">
									<label>Module(s) Enabled</label>
									<div className="modules-wrapper">
										<div className="row">
											<div className="col-6 mb-3">
												<div
													className={`module-item p-3 ${
														modulesEnabled.includes("channel") ? "module-enabled" : ""
													}`}>
													<div className="row">
														<div className="col-2 d-flex align-items-center">
															<img
																src={IconChannelModule}
																alt="Channel"
																className="img-fluid"
															/>
														</div>
														<div className="col-9 d-flex flex-column justify-content-center">
															<label>Channels</label>
															<span>Host conferences, workshops, etc.</span>
														</div>
														<div className="col-1 d-flex align-items-center">
															<input
																className="form-check-input mt-0"
																type="checkbox"
																value="channel"
																onChange={handleModulesChange}
															/>
														</div>
													</div>
												</div>
											</div>
											<div className="col-6 mb-3">
												<div
													className={`module-item p-3 ${
														modulesEnabled.includes("exhibitor") ? "module-enabled" : ""
													}`}>
													<div className="row">
														<div className="col-2 d-flex align-items-center">
															<img
																src={IconExhibitorModule}
																alt="Channel"
																className="img-fluid"
															/>
														</div>
														<div className="col-9 d-flex flex-column justify-content-center">
															<label>Exhibitors</label>
															<span>Exhibitor room to exhibit</span>
														</div>
														<div className="col-1 d-flex align-items-center">
															<input
																className="form-check-input mt-0"
																type="checkbox"
																value="exhibitor"
																onChange={handleModulesChange}
															/>
														</div>
													</div>
												</div>
											</div>
											<div className="col-6 mb-3">
												<div
													className={`module-item p-3 ${
														modulesEnabled.includes("ecommerce") ? "module-enabled" : ""
													} ${
														!modulesEnabled.includes("exhibitor") ? "module-disabled" : ""
													}`}>
													<div className="row">
														<div className="col-2 d-flex align-items-center">
															<img
																src={IconEcommerceModule}
																alt="Channel"
																className="img-fluid"
															/>
														</div>
														<div className="col-9 d-flex flex-column justify-content-center">
															<label>
																E-commerce{" "}
																<span className="text-require-exhibitor">
																	(require Exhibitor modules)
																</span>
															</label>
															<span>Product browsing and purchase in exhibitor room</span>
														</div>
														<div className="col-1 d-flex align-items-center">
															<input
																className="form-check-input mt-0"
																type="checkbox"
																value="ecommerce"
																onChange={handleModulesChange}
																disabled={!modulesEnabled.includes("exhibitor")}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 check-out-type-container mb-3">
								<div className="form-group">
									<label>Check Out Flow Type</label>
									<div className={`check-out-type-wrapper p-3 ${!modulesEnabled.includes("ecommerce") ? "disabled" : ""}`}>
										<div className="form-check">
											<input
												className="form-check-input"
												type="radio"
												name="payment_type"
												id="checkout_flow_1"
												value={PAYMENT_SETTINGS["ecommerce"]}
												checked={
													parseInt(eventInput.payment_type) === PAYMENT_SETTINGS["ecommerce"]
												}
												onChange={handleInputOnChange}
												disabled={!modulesEnabled.includes("ecommerce")}
											/>
											<label className="form-check-label" htmlFor="checkout_flow_1">
												Product on-click, open e-commerce link
											</label>
										</div>
										<div className="form-check">
											<input
												className="form-check-input"
												type="radio"
												name="payment_type"
												id="checkout_flow_2"
												value={PAYMENT_SETTINGS["payment_gateway"]}
												checked={
													parseInt(eventInput.payment_type) ===
													PAYMENT_SETTINGS["payment_gateway"]
												}
												onChange={handleInputOnChange}
												disabled={!modulesEnabled.includes("ecommerce")}
											/>
											<label className="form-check-label" htmlFor="checkout_flow_2">
												Checkout through payment gateway
											</label>
										</div>
										<div className="form-check">
											<input
												className="form-check-input"
												type="radio"
												name="payment_type"
												id="checkout_flow_3"
												value={PAYMENT_SETTINGS["credit"]}
												checked={
													parseInt(eventInput.payment_type) === PAYMENT_SETTINGS["credit"]
												}
												onChange={handleInputOnChange}
												disabled={!modulesEnabled.includes("ecommerce")}
											/>
											<label className="form-check-label" htmlFor="checkout_flow_3">
												Checkout with credits/token. Purchase credits through payment gateway
											</label>
										</div>
										<div className="form-check">
											<input
												className="form-check-input"
												type="radio"
												name="payment_type"
												id="checkout_flow_4"
												value={PAYMENT_SETTINGS["place_booking"]}
												checked={
													parseInt(eventInput.payment_type) ===
													PAYMENT_SETTINGS["place_booking"]
												}
												onChange={handleInputOnChange}
												disabled={!modulesEnabled.includes("ecommerce")}
											/>
											<label className="form-check-label" htmlFor="checkout_flow_4">
												Place booking only
											</label>
										</div>
									</div>
								</div>
							</div>

							<div className="col-6">
								<div className="form-group">
									<label htmlFor="start_date">Start Date</label>
									<DatePicker
										selected={eventInput.start_date}
										onChange={(date) => setEventInput({ ...eventInput, start_date: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										dateFormat="dd MMMM yyyy"
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="start_time">Start Time</label>
									<DatePicker
										selected={eventInput.start_time}
										onChange={(date) => setEventInput({ ...eventInput, start_time: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										showTimeInput
										showTimeSelectOnly
										timeCaption="Time"
										dateFormat="h:mm aa"
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="end_date">End Date</label>
									<DatePicker
										selected={eventInput.end_date}
										onChange={(date) => setEventInput({ ...eventInput, end_date: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										dateFormat="dd MMMM yyyy"
										// minDate={eventInput.start_date}
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="end_time">End Time</label>
									<DatePicker
										selected={eventInput.end_time}
										onChange={(date) => setEventInput({ ...eventInput, end_time: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										showTimeInput
										showTimeSelectOnly
										timeCaption="Time"
										dateFormat="h:mm aa"
									/>
								</div>
							</div>

							<div className="col-12 mb-3">
								<div className="row">
									<div className="col-6">
										<div className="form-group">
											<label htmlFor="privacy_policy">Set Legal Details</label>
											<input
												id="privacy_policy"
												type="text"
												className="form-control"
												aria-describedby="privacy_policy"
												name="privacy_policy"
												value={eventInput.privacy_policy}
												onChange={handleInputOnChange}
												required
												placeholder="Privacy Policy Content URL"
											/>
										</div>
										<div className="form-group">
											<input
												id="terms_of_use"
												type="text"
												className="form-control"
												aria-describedby="terms_of_use"
												name="terms_of_use"
												value={eventInput.terms_of_use}
												onChange={handleInputOnChange}
												required
												placeholder="Term of use Content URL"
											/>
										</div>
									</div>
									{/* No longer needed for release cd 2.5 onwards */}
									{/* <div className="col-6">
										<div className="form-group mb-0">
											<label>Event Theme Colour</label>
										</div>
										<div className="row">
											<div className="col-6 mb-3">
												<HexColorPicker
													color={eventInput.theme_color}
													onChange={(color) =>
														setEventInput({ ...eventInput, theme_color: color })
													}
												/>
											</div>
											<div className="col-6">
												<input
													type="text"
													className="form-control"
													name="theme_color"
													value={eventInput.theme_color}
													style={{
														backgroundColor: eventInput.theme_color,
														color: "#ffffff",
													}}
													onChange={(event) =>
														setEventInput({
															...eventInput,
															theme_color: event.target.value,
														})
													}
												/>
											</div>
										</div>
									</div> */}
								</div>
							</div>

							{/* TODO: reopen in next code drop */}
							{/* <div className="col-12">
								<div className="mobile-num-verification p-3">
									<div>
										<label>Requires Mobile Number Verification.</label>
										<p className="px-4">
											During user account registration, users will be prompted to verify their
											mobile number with a one-time pin.
										</p>
									</div>
									<div>
										<div className="custom-control custom-switch">
											<input
												type="checkbox"
												className="custom-control-input"
												id="mobile_num_verification"
												name="requires_mobile_num_verification"
												value={1}
												checked={eventInput.requires_mobile_num_verification}
												onChange={handleInputOnChange}
											/>
											<label className="custom-control-label" htmlFor="mobile_num_verification"></label>
										</div>
									</div>
								</div>
							</div> */}
						</div>

						<div className="row">
							<div className="col-10"></div>
							<div className="col-2">
								<button type="submit" className="btn btn-primary w-100">
									Create
								</button>
							</div>
						</div>
					</form>
				</section>
			</div>
		</LayoutContainer>
	);
};

export default withRouter(AddEvent);
