/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams, Link } from "react-router-dom";
import "./style.scss";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import { ALPHABETS_LIST } from "app/config/alphabetList";
import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import ExhibitorAPI from "app/apis/exhibitor";
import IconAddBlue from "app/assets/icon-add-blue.png";
import IconLive from "app/assets/icon-live.png";
import IconWarningTriangle from "app/assets/icon-alert-triangle-yellow.png";
import EventInfo from "app/views/components/EventInfo/EventInfo";
import UsageAPI from "app/apis/usage";

const ExhibitorRoomListing = ({ history }) => {
	const { eventId } = useParams();

	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);

	const [searchText, setSearchText] = useState("");
	const [exhibitorRoomListing, setExhibitorRoomListing] = useState([]);
	const [alphabets, setAlphabets] = useState([]);

	const [exhibitorUsage, setExhibitorUsage] = useState(null);

	// Get current subscription plan
	useEffect(() => {
		UsageAPI.getExhibitorUsage(selectedOrganizationAccount.id)
			.then((response) => {
				setExhibitorUsage(response.data);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	useEffect(() => {
		let params = {
			limit: 750,
			sort_by: "title",
			sort_order: "asc",
		};

		if (searchText) {
			params["title"] = searchText;
		}

		getExhibitorRoomListing(params);
	}, [searchText]);

	const getExhibitorRoomListing = (params) => {
		ExhibitorAPI.getExhibitorRoomListing(eventId, params)
			.then((response) => {
				const { event_exhibitor_rooms } = response.data;

				// Set alphabets
				let alphabetsArray = [];

				event_exhibitor_rooms.forEach((room) => {
					const { title } = room;
					const firstLetter = title.charAt(0).toUpperCase();

					if (!alphabetsArray.includes(firstLetter)) {
						alphabetsArray.push(firstLetter);
					}
				});

				alphabetsArray.sort();

				setAlphabets(alphabetsArray);
				setExhibitorRoomListing(event_exhibitor_rooms);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const goToAlphabetSection = () => {
		history.push(`/events/view/${eventId}/exhibitor/rooms`);
	};

	const handleAddExhibitor = () => {
		if (exhibitorUsage && exhibitorUsage.available <= 0 && exhibitorUsage.limit !== -1) {
			return;
		}

		history.push(`/events/view/${eventId}/exhibitor/rooms/add`);
	};

	// Alphabet list component
	const AlphabetList = () => {
		return (
			<div className="alphabet-list">
				{ALPHABETS_LIST.map((alphabet) => {
					return (
						<HashLink
							key={alphabet}
							to={`#section-${alphabet}`}
							className="alphabet-letter"
							onClick={goToAlphabetSection}>
							{alphabet}
						</HashLink>
					);
				})}
			</div>
		);
	};

	return (
		<EventLayoutContainer>
			<div id="exhibitor-room-listing-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-5">
					<div className="d-flex align-items-center">
						<div>
							<h2 className="title">Exhibitor Room Listing</h2>
							<span className="subtitle">Exhibitors &gt; Exhibitor Rooms</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="p-3">
					<div className="row">
						<div className="col-11">
							<div className="form-group mb-0">
								<label htmlFor="room_listing_search">Exhibitor Room Listing</label>
								<input
									id="room_listing_search"
									type="text"
									className="form-control"
									name="search"
									placeholder="Search"
									value={searchText}
									onChange={(event) => setSearchText(event.target.value)}
								/>
							</div>
						</div>
						{/* <div className="col-1">
							<button
								type="button"
								className="btn btn-primary h-100">
								Filter
							</button>
						</div> */}
					</div>
				</section>

				<section id="listing-container" className="p-3">
					<AlphabetList />

					<div className="row">
						<div className="col-4 mb-3">
							<div className={`add-listing-card mb-2 disabled`} onClick={handleAddExhibitor}>
								<img src={IconAddBlue} alt={"Add"} className="img-fluid icon-add" />
								{exhibitorUsage && exhibitorUsage.available <= 0 && exhibitorUsage.limit !== -1 ? (
									<div className="warning-container">
										<img src={IconWarningTriangle} alt="warning" className="icon-warning" />
										<div>
											Exhibitors limit reached! Upgrade your plan now to create more exhibitors.{" "}
											<Link to="/dashboard" className="text-manage-subscription" onClick={(e) => e.stopPropagation()}>
												Manage Subscription
											</Link>
										</div>
									</div>
								) : (
									<div className="listing-card-title">Add New Exhibitor Room</div>
								)}
							</div>
						</div>
					</div>

					{alphabets.map((alphabet) => {
						return (
							<section key={alphabet} id={`section-${alphabet}`} className="mb-3">
								<label className="alphabet-section-title">{alphabet}</label>
								<div id={`section-${alphabet}-exhibitorRoomListing`} className="row">
									{exhibitorRoomListing.map((exhibitorRoom) => {
										const { id, title, logo_url, live } = exhibitorRoom;
										const firstLetter = title.charAt(0).toUpperCase();

										if (alphabet === firstLetter) {
											return (
												<div key={`${id}-${title}`} className="col-4 mb-3">
													<ListingCard
														id={id}
														image={logo_url}
														title={title}
														isLive={live ? true : false}
														onClick={() =>
															history.push(
																`/events/view/${eventId}/exhibitor/rooms/${id}`
															)
														}
													/>
												</div>
											);
										}

										return null;
									})}
								</div>
							</section>
						);
					})}
				</section>
			</div>
		</EventLayoutContainer>
	);
};

// Listing Card component
const ListingCard = ({ image, title, onClick, isLive }) => {
	return (
		<>
			<div className="listing-card mb-2" onClick={onClick}>
				<img src={image} alt={title} className="img-fluid" />
				{isLive && <img src={IconLive} alt="live" className="icon-live" />}
			</div>
			<div className="listing-card-title">{title}</div>
		</>
	);
};

export default withRouter(ExhibitorRoomListing);
