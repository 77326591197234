/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import IconBack from "app/assets/icon-back-grey.png";
import IconCheckbox from "app/assets/icon-checkbox.png";
import IconCheckboxWhite from "app/assets/icon-check-circle-white.png";
import LayoutContainer from "app/views/containers/LayoutContainer/LayoutContainer";
import Switch from "react-switch";
import UpgradeMonthlyPlanConfirmationModal from "app/views/containers/Billings/UpgradeMontlyPlanConfirmationModal/UpgradeMonthlyPlanConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { billingActions } from "app/states/billings";
import UpgradeAnnualPlanConfirmationModal from "app/views/containers/Billings/UpgradeAnnualPlanConfirmationModal/UpgradeAnnualPlanConfirmationModal";
import BillingAPI from "app/apis/billings";
import LocalStorageService from "app/services/localStorageService";
import { commonActions } from "app/states/common";
import EnterpriseReachoutSuccessModal from "app/views/containers/Billings/EnterpriseReachoutSuccessModal/EnterpriseReachoutSuccessModal";
import ConfirmPurchasePlanModal from "app/views/containers/Billings/ConfirmPurchasePlanModal/ConfirmPurchasePlanModal";
import DowngradeConfirmationModal from "app/views/containers/Billings/DowngradeConfirmationModal/DowngradeConfirmationModal";

const ChoosePlan = ({ history }) => {
	const dispatch = useDispatch();
	const openUpgradeMonthlyPlanConfirmationModal = () =>
		dispatch(billingActions.openUpgradeMonthlyPlanConfirmationModal());
	const openUpgradeAnnualPlanConfirmationModal = () =>
		dispatch(billingActions.openUpgradeAnnualPlanConfirmationModal());
	const setChosenPlan = (chosenPlan) => dispatch(billingActions.setChosenPlan(chosenPlan));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setSelectedPricingMode = (pricingMode) => dispatch(billingActions.setSelectedPricingMode(pricingMode));
	const setIsUpgradingPlan = (isUpgrade) => dispatch(billingActions.isUpgradingPlan(isUpgrade));
	const openEnterprisePlanReachoutSuccessModal = () =>
		dispatch(billingActions.openEnterprisePlanReachoutSuccessModal());
	const openDowngradeConfirmationModal = () => dispatch(billingActions.openDowngradeConfirmationModal());

	const pricingMode = useSelector(({ billing }) => billing.selectedPricingMode);
	const refreshCurrentSubscriptionPlanCount = useSelector(
		({ billing }) => billing.refreshCurrentSubscriptionPlanCount
	);
	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);

	const [subscriptionPlans, setSubscriptionPlans] = useState([]);
	const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState(null);
	const [discountPercentage, setDiscountPercentage] = useState(null);

	// Get subscription plan summaries
	useEffect(() => {
		const params = {
			type: 1, // type 1 is paid, 0 is free
		};

		BillingAPI.getSubscriptionPlanSummaries(params)
			.then((response) => {
				setSubscriptionPlans(response.data);

				if (response.data.length > 0) {
					setDiscountPercentage(response.data[0].yearly_discount_percentage);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [refreshCurrentSubscriptionPlanCount]);

	// Get current subscription plan
	useEffect(() => {
		BillingAPI.getCurrentSubscription(selectedOrganizationAccount.id)
			.then((response) => {
				setCurrentSubscriptionPlan(response.data);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [refreshCurrentSubscriptionPlanCount]);

	const handleOnChangePricingMode = (checked) => {
		if (checked) {
			setSelectedPricingMode("yearly");
		} else {
			setSelectedPricingMode("monthly");
		}
	};

	const handleChoosePlan = (subscriptionPlan) => {
		setChosenPlan(subscriptionPlan);
		LocalStorageService.setChosenPlan(subscriptionPlan);

		// If current plan id is not trial and is larger than the chosen plan, it means downgrading
		if (
			currentSubscriptionPlan.subscription_plan.id !== 4 &&
			currentSubscriptionPlan.subscription_plan.order > subscriptionPlan.order
		) {
			openDowngradeConfirmationModal();
			return;
		}

		// If current subscription is not free, and user trying to upgrade plan
		if (currentSubscriptionPlan.term > 0) {
			setIsUpgradingPlan(true);
		}

		// Enterprise plan - Contact us
		if (subscriptionPlan.name === "ENTERPRISE") {
			contactEnterprisePlan(subscriptionPlan);
			return;
		}

		if (pricingMode === "monthly") {
			openUpgradeMonthlyPlanConfirmationModal();
		} else if (pricingMode === "yearly") {
			openUpgradeAnnualPlanConfirmationModal();
		}
	};

	const contactEnterprisePlan = (subscriptionPlan) => {
		const data = {
			subscription_plan_id: subscriptionPlan.id,
			term: pricingMode === "monthly" ? 1 : 12,
		};

		BillingAPI.postChoosePlan(selectedOrganizationAccount.id, data)
			.then((response) => {
				openEnterprisePlanReachoutSuccessModal();
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	return (
		<LayoutContainer>
			<div id="choose-plan-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-5">
					<div className="d-flex align-items-center">
						<div className="btn-back" onClick={() => history.goBack()}>
							<img src={IconBack} alt="back" className="img-fluid" />
						</div>
						<div>
							<h2 className="title">Choose Your Plan</h2>
							<span className="subtitle">Upgrade your plan to enjoy more</span>
						</div>
					</div>
				</section>

				<section className="pricing-mode-wrapper d-flex align-items-center justify-content-center mb-4">
					<div className="pricing-label mx-3">Monthly Pricing</div>
					<Switch
						checkedIcon={false}
						uncheckedIcon={false}
						checked={pricingMode === "yearly"}
						onChange={handleOnChangePricingMode}
						width={90}
						height={40}
						handleDiameter={32}
						offColor="#34A853"
						onColor="#34A853"
					/>
					<div className="pricing-label mx-3">Annual Pricing</div>
					{discountPercentage && <div className="text-discount">Save {discountPercentage}%</div>}
				</section>

				<section className="row">
					{subscriptionPlans.map((subscriptionPlan) => {
						const { id, name, monthly_price, discounted_monthly_price, products } = subscriptionPlan;

						const mainFeatures = products.find((product) => product.type === "main");
						const addOnFeatures = products.find((product) => product.type === "add-on");

						return (
							<div key={id} className="col-4 mb-3">
								<div
									className={`plan-wrapper ${
										currentSubscriptionPlan?.subscription_plan?.id === id ? "current-plan" : ""
									}`}>
									<div className="plan-header d-flex flex-column align-items-center">
										<div className="plan-title">{name}</div>
										{name === "ENTERPRISE" ? (
											<>
												<div className="plan-price">
													<span className="price">Let's Talk</span>
												</div>
											</>
										) : (
											<div className="plan-price">
												<span
													className={`price ${
														pricingMode === "yearly" ? "strikethrough" : ""
													}`}>
													${monthly_price}
												</span>
												{pricingMode === "yearly" && (
													<span className="discounted-price">
														${discounted_monthly_price}
													</span>
												)}
												<span className="slash">/</span>
												<span>month</span>
											</div>
										)}

										{currentSubscriptionPlan?.subscription_plan?.id === id ? (
											<div className="current-plan-badge">
												<img src={IconCheckboxWhite} alt="checkbox" className="icon-checkbox" />{" "}
												Your current plan
											</div>
										) : (
											<button
												className="btn btn-primary btn-choose-plan"
												onClick={() => handleChoosePlan(subscriptionPlan)}>
												{name === "ENTERPRISE" ? "Contact Us" : "Choose Plan"}
											</button>
										)}
									</div>
									<div className="features-container mb-5">
										{mainFeatures &&
											mainFeatures.details.map((feature, index) => {
												return (
													<div key={`feature-${index}`} className="plan-feature-text">
														<img
															src={IconCheckbox}
															alt="checkbox"
															className="icon-checkbox"
														/>
														<div
															className="d-inline-block"
															dangerouslySetInnerHTML={{ __html: feature }}></div>
													</div>
												);
											})}
									</div>
									{addOnFeatures && (
										<div className="optional-addon-container">
											<h5>Optional Add-ons</h5>
											{addOnFeatures.details.map((feature, index) => {
												return (
													<div
														key={`addon-${index}`}
														className="d-inline-block plan-feature-text"
														dangerouslySetInnerHTML={{ __html: feature }}></div>
												);
											})}
										</div>
									)}
								</div>
							</div>
						);
					})}
				</section>
			</div>

			<UpgradeMonthlyPlanConfirmationModal />
			<UpgradeAnnualPlanConfirmationModal />
			<EnterpriseReachoutSuccessModal />
			<ConfirmPurchasePlanModal />
			<DowngradeConfirmationModal />
		</LayoutContainer>
	);
};

export default withRouter(ChoosePlan);
