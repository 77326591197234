/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import IconBack from "app/assets/icon-back-grey.png";
import { withRouter, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import IconRemove from "app/assets/icon-close.png";
import moment from "moment";
import ChannelSessionAPI from "app/apis/channelSession";
import IconDelete from "app/assets/icon-delete-red.png";
import IconDeleteGrey from "app/assets/icon-delete-grey.png";
import CustomModal from "app/views/components/CustomModal/CustomModal";
import { commonActions } from "app/states/common";
import { eventActions } from "app/states/event";
import { PLATFORM_TYPES, DROPDOWN_RECORDS_PER_PAGE } from "app/config/settings";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import IconLikeBlue from "app/assets/icon-like-blue@2x.png";
import IconLive from "app/assets/icon-live.png";
import AudienceContainer from "../../../containers/ChannelSession/AudienceContainer/AudienceContainer";
import QnAContainer from "../../../containers/ChannelSession/QnAContainer/QnAContainer";
import PollContainer from "app/views/containers/ChannelSession/PollContainer/PollContainer";
import LiveToggleModal from "app/views/components/LiveToggleModal/LiveToggleModal";
import IconDefaultUser from "app/assets/icon-default-user.png";
import SessionLayoutContainer from "app/views/containers/SessionLayoutContainer/SessionLayoutContainer";
import EventInfo from "app/views/components/EventInfo/EventInfo";

const ViewChannelSession = ({ history }) => {
	let { eventId, channelId, sessionId } = useParams();

	const dispatch = useDispatch();
	const openCustomModal = () => dispatch(commonActions.openCustomModal());
	const closeCustomModal = () => dispatch(commonActions.closeCustomModal());
	const openLiveToggleModal = () => dispatch(commonActions.openLiveToggleModal());
	const closeLiveToggleModal = () => dispatch(commonActions.openLiveToggleModal());
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setSelectedDateForSessionSidebar = (selectedDate) =>
		dispatch(eventActions.setSelectedDateForSessionSidebar(selectedDate));

	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);

	const [sessionTitle, setSessionTitle] = useState("");
	const [inputData, setInputData] = useState({
		title: "",
		video_url: "",
		platform_type: "",
		recorded_video_url: "",
		recorded_video_platform_type: "",
		description: "",
		cover_image: "",
		live: false,
		start_date: new Date(),
		start_time: new Date(),
		end_date: new Date(),
		end_time: new Date(),
		featured: true,
		speaker: "",
		show_recorded_video: true,
	});

	const [streamingUrl, setStreamingUrl] = useState("");

	const [uploadedLogo, setUploadedLogo] = useState(null);
	const [openModalName, setOpenModalName] = useState("");
	const [totalLikes, setTotalLikes] = useState(0);
	const [openLiveModal, setOpenLiveModal] = useState(false);

	const [speakersFiltered, setSpeakersFiltered] = useState([]);
	const [selectedSpeakers, setSelectedSpeakers] = useState([]);
	const [showSpeakerSelection, setShowSpeakerSelection] = useState(false);

	const [speakerPage, setSpeakerPage] = useState(1);
	const [totalSpeakerPage, setTotalSpeakerPage] = useState(1);
	const [loadMore, setLoadMore] = useState(false);
	const [loadingMoreSpeakers, setLoadingMoreSpeakers] = useState(false);

	const logoRef = useRef();
	const speakerInputRef = useRef();

	// Get speaker listing
	useEffect(() => {
		if (!loadMore) return;

		// Flag to indicate loading more speakers to ensure every page is loaded when scroll
		setLoadingMoreSpeakers(true);

		let params = {
			page: speakerPage,
			limit: DROPDOWN_RECORDS_PER_PAGE,
			sort_order: "asc",
			organization_id: selectedOrganizationAccount.id,
		};

		if (inputData.speaker) {
			params["search_string"] = inputData.speaker;
		}

		ChannelSessionAPI.getSpeakerListing(params)
			.then((response) => {
				const { speakers: speakerList, paging } = response.data;
				const { last_page } = paging;

				setSpeakersFiltered([...speakersFiltered, ...speakerList]);
				setTotalSpeakerPage(last_page);
				setLoadingMoreSpeakers(false);
				setLoadMore(false);
			})
			.catch((response) => {
				console.error(response);
			});
	}, [sessionId, loadMore]);

	// Search speaker listing
	useEffect(() => {
		let params = {
			page: 1,
			limit: DROPDOWN_RECORDS_PER_PAGE,
			sort_order: "asc",
			organization_id: selectedOrganizationAccount.id,
		};

		if (inputData.speaker) {
			params["search_string"] = inputData.speaker;
		}

		let timeout = setTimeout(() => {
			ChannelSessionAPI.getSpeakerListing(params)
				.then((response) => {
					const { speakers: speakerList, paging } = response.data;
					const { last_page } = paging;

					setSpeakerPage(1);
					setSpeakersFiltered(speakerList);
					setTotalSpeakerPage(last_page);
				})
				.catch((response) => {
					console.error(response);
				});
		}, 500);

		return () => {
			clearTimeout(timeout);
		};
	}, [inputData.speaker]);

	const handleSpeakerSelectionScroll = (event) => {
		let offset = 20;
		let isBottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + offset;

		if (isBottom && speakerPage <= totalSpeakerPage && !loadingMoreSpeakers) {
			setSpeakerPage(speakerPage + 1);
			setLoadMore(true);
		}
	};

	useEffect(() => {
		getChannelSessionDetail();
	}, [sessionId]);

	const getChannelSessionDetail = () => {
		ChannelSessionAPI.getChannelSessionDetail(sessionId)
			.then((response) => {
				const {
					name,
					thumbnail_url,
					content_url,
					live,
					start_at,
					end_at,
					speakers,
					like_total_count,
					description,
					featured,
					type,
					recorded_url,
					recorded_type,
					show_recorded,
				} = response.data;

				// Process start/end datetime
				let isStartAtValid = moment(start_at, moment.ISO_8601, true).isValid();
				let isEndAtValid = moment(end_at, moment.ISO_8601, true).isValid();

				let startDateTime = isStartAtValid ? new Date(start_at) : new Date();
				let endDateTime = isEndAtValid ? new Date(end_at) : new Date();

				setSessionTitle(name);
				setInputData({
					...inputData,
					title: name,
					video_url: content_url,
					platform_type: type,
					description: description,
					cover_image: thumbnail_url,
					live: live === 1 ? true : false,
					start_date: startDateTime,
					start_time: startDateTime,
					end_date: endDateTime,
					end_time: endDateTime,
					featured: featured === 1 ? true : false,
					recorded_video_url: recorded_url,
					recorded_video_platform_type: recorded_type,
					show_recorded_video: show_recorded === 1 ? true : false,
				});

				setStreamingUrl(content_url);
				setTotalLikes(like_total_count);
				setSelectedDateForSessionSidebar(startDateTime);

				// Map speakers array to conform the data structure as speaker listing
				const mappedSelectedSpeakers = speakers.map((speaker) => {
					return {
						id: speaker.id,
						user_id: speaker.user_id,
						account_id: speaker.account_user_id,
						organization_name: speaker.company_name,
						name: speaker.name,
						profile_image_url: speaker.thumbnail_url,
					};
				});

				setSelectedSpeakers(mappedSelectedSpeakers);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleChangeLogo = (event) => {
		setUploadedLogo(event.target.files[0]);
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleUpdateSession = (event) => {
		event.preventDefault();
		showLoading();

		let formData = new FormData();

		if (uploadedLogo) {
			formData.append("thumbnail", uploadedLogo);
		}

		// Process start/end time
		let formattedStartDate = moment(inputData.start_date).format("DD MMMM YYYY");
		let formattedStartTime = moment(inputData.start_time).format("h:mma");
		let startDateTime = moment(formattedStartDate + " " + formattedStartTime, "DD MMMM YYYY h:mma");
		let startAt = startDateTime.format("YYYY-MM-DD HH:mm:ss");

		let formattedEndDate = moment(inputData.end_date).format("DD MMMM YYYY");
		let formattedEndTime = moment(inputData.end_time).format("h:mma");
		let endDateTime = moment(formattedEndDate + " " + formattedEndTime, "DD MMMM YYYY h:mma");
		let endAt = endDateTime.format("YYYY-MM-DD HH:mm:ss");

		// Check end datetime and start datetime
		if (!endDateTime.isAfter(startDateTime)) {
			openAlertSnackbar("Start date must be earlier than end date.", "error");
			hideLoading();
			return;
		}

		formData.append("name", inputData.title);
		formData.append("video_url", inputData.video_url);
		formData.append("live", inputData.live);
		formData.append("start_at", startAt);
		formData.append("end_at", endAt);
		formData.append("featured", inputData.featured);
		formData.append("show_recorded_video", inputData.show_recorded_video ? 1 : 0);

		if (inputData.platform_type) {
			formData.append("video_type", inputData.platform_type);
		}

		if (inputData.recorded_video_platform_type) {
			formData.append("recorded_video_type", inputData.recorded_video_platform_type);
		}

		if (inputData.recorded_video_url) {
			formData.append("recorded_video_url", inputData.recorded_video_url);
		}

		if (inputData.description) {
			formData.append("description", inputData.description);
		}

		// Existing speakers
		if (selectedSpeakers.length > 0) {
			// Take the selected speakers ids
			const selectedSpeakerUserIds = selectedSpeakers
				.filter((speaker) => {
					return speaker.id;
				})
				.map((speaker) => {
					return speaker.id;
				});

			if (selectedSpeakerUserIds.length > 0) {
				formData.append("existing_speaker_ids", JSON.stringify(selectedSpeakerUserIds));
			}
		}

		// Add new speakers
		if (selectedSpeakers.length > 0) {
			selectedSpeakers
				.filter((speaker) => {
					return !speaker.id;
				})
				.forEach((speaker) => {
					const speakerObject = {
						user_id: speaker.user_id,
						account_id: speaker.account_id,
					};

					formData.append("speakers[]", JSON.stringify(speakerObject));
				});
		}

		ChannelSessionAPI.postUpdateChannelSession(sessionId, formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					const { id } = response.data;
					getChannelSessionDetail();
					openAlertSnackbar("Channel Session Updated", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const removeSpeaker = (speaker) => {
		let speakersCopy = [...selectedSpeakers];

		let removeIndex = null;
		for (let i = 0; i < speakersCopy.length; i++) {
			const { user_id } = speakersCopy[i];

			if (user_id === speaker.user_id) {
				removeIndex = i;
				break;
			}
		}

		speakersCopy.splice(removeIndex, 1);
		setSelectedSpeakers(speakersCopy);
	};

	const addSpeaker = (speaker) => {
		setSelectedSpeakers([...selectedSpeakers, speaker]);
	};

	const handleConfirmDelete = () => {
		showLoading();

		ChannelSessionAPI.deleteChannelSession(sessionId)
			.then((response) => {
				hideLoading();
				const { code } = response;

				if (code === 200) {
					closeCustomModal();
					history.replace(`/events/view/${eventId}/channels/${channelId}`);
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				hideLoading();
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const openDeleteSessionModal = () => {
		setOpenModalName("delete_session");
		openCustomModal();
	};

	const handleToggleLiveSwitch = () => {
		setOpenLiveModal(true);
		openLiveToggleModal();
	};

	const handleCloseLiveModal = () => {
		setOpenLiveModal(false);
		closeLiveToggleModal();
	};

	const handleToggleLiveConfirm = () => {
		showLoading();

		const data = {
			is_live: !inputData.live ? 1 : 0,
		};

		ChannelSessionAPI.postToggleLive(sessionId, data)
			.then((response) => {
				hideLoading();
				const { code } = response;

				if (code === 200) {
					setInputData({ ...inputData, live: !inputData.live });
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				hideLoading();
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	return (
		<SessionLayoutContainer>
			<div id="view-channel-session-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-4">
					<div className="d-flex align-items-center">
						{/* <div className="btn-back" onClick={() => history.goBack()}>
							<img src={IconBack} alt="back" className="img-fluid" />
						</div> */}
						<div>
							<h2 className="title">{sessionTitle}</h2>
							<span className="subtitle">Channels &gt; Channel Room &gt; View Session</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="live-settings d-flex justify-content-end align-items-center mb-3">
					<img src={IconLive} alt="live" className="icon-live" />
					<div className="custom-control custom-switch">
						<input
							type="checkbox"
							className="custom-control-input"
							id="live"
							name="live"
							checked={inputData.live}
							onChange={handleToggleLiveSwitch}
						/>
						<label className="custom-control-label" htmlFor="live"></label>
					</div>
				</section>

				<section className="section-container mb-5">
					<form id="form-update-channel-session" method="post" onSubmit={handleUpdateSession}>
						<div className="row mb-3">
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="title">Session Title</label>
									<input
										id="title"
										type="text"
										className="form-control"
										aria-describedby="title"
										name="title"
										value={inputData.title}
										onChange={handleInputOnChange}
										required
									/>
								</div>
							</div>
							<div className="col-9">
								<div className="form-group">
									<label htmlFor="video_url">Video URL (e.g. Youtube link, FB Live)</label>
									<input
										id="video_url"
										type="text"
										className="form-control"
										aria-describedby="video_url"
										name="video_url"
										value={inputData.video_url}
										onChange={handleInputOnChange}
										// required
									/>
								</div>
							</div>
							<div className="col-3">
								<div className="form-group">
									<label htmlFor="platform_type">Platform Type</label>
									<div className="select-wrapper">
										<select
											id="platform_type"
											className="form-control"
											aria-describedby="platform_type"
											name="platform_type"
											value={inputData.platform_type}
											onChange={handleInputOnChange}>
											{Object.keys(PLATFORM_TYPES).map((platform) => {
												return (
													<option key={platform} value={PLATFORM_TYPES[platform]["value"]}>
														{PLATFORM_TYPES[platform]["display"]}
													</option>
												);
											})}
										</select>
									</div>
								</div>
							</div>
							<div className="col-9">
								<div className="form-group">
									<label htmlFor="recorded_video_url">
										Recorded Video URL (e.g. Youtube link, FB link)
									</label>
									<small>
										<i>Recorded Video URL (e.g. Youtube link, FB link)</i>
									</small>
									<input
										id="recorded_video_url"
										type="text"
										className="form-control"
										aria-describedby="recorded_video_url"
										name="recorded_video_url"
										value={inputData.recorded_video_url}
										onChange={handleInputOnChange}
									/>
								</div>
							</div>
							<div className="col-3">
								<div className="form-group">
									<label htmlFor="recorded_video_platform_type">Platform Type</label>
									<div className="select-wrapper">
										<select
											id="recorded_video_platform_type"
											className="form-control"
											aria-describedby="recorded_video_platform_type"
											name="recorded_video_platform_type"
											value={inputData.recorded_video_platform_type}
											onChange={handleInputOnChange}>
											{Object.keys(PLATFORM_TYPES).map((platform) => {
												return (
													<option key={platform} value={PLATFORM_TYPES[platform]["value"]}>
														{PLATFORM_TYPES[platform]["display"]}
													</option>
												);
											})}
										</select>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className="form-check form-group">
									<input
										className="form-check-input custom-outline-checkbox"
										type="checkbox"
										checked={inputData.show_recorded_video}
										name="show_recorded_video"
										id="show_recorded_video"
										onChange={() =>
											setInputData({
												...inputData,
												show_recorded_video: !inputData.show_recorded_video,
											})
										}
									/>
									<label
										className="form-check-label custom-checkbox-label"
										htmlFor="show_recorded_video">
										Show recorded video once live session has ended
									</label>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<label htmlFor="description">Description</label>
									<input
										id="description"
										type="text"
										className="form-control"
										aria-describedby="description"
										name="description"
										value={inputData.description}
										onChange={handleInputOnChange}
										required
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="row">
									<div className="col-5">
										<div className="form-group">
											<label>Preview / Cover Image</label>
											{uploadedLogo || inputData.cover_image ? (
												<img
													src={
														uploadedLogo
															? URL.createObjectURL(uploadedLogo)
															: inputData.cover_image
													}
													alt="cover"
													className="img-cover"
													onClick={browsePicture}
												/>
											) : (
												<img
													src={IconUpload}
													alt="upload cover"
													className="img-upload"
													onClick={browsePicture}
												/>
											)}
											<input
												type="file"
												name="logo"
												onChange={handleChangeLogo}
												ref={logoRef}
												className="d-none"
												accept="image/*"
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="col-6">
								<div className="form-group">
									<label htmlFor="start_date">Start Date</label>
									<DatePicker
										selected={inputData.start_date}
										onChange={(date) => setInputData({ ...inputData, start_date: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										dateFormat="dd MMMM yyyy"
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="start_time">Start Time</label>
									<DatePicker
										selected={inputData.start_time}
										onChange={(date) => setInputData({ ...inputData, start_time: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										showTimeInput
										showTimeSelectOnly
										timeCaption="Time"
										dateFormat="h:mm aa"
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="end_date">End Date</label>
									<DatePicker
										selected={inputData.end_date}
										onChange={(date) => setInputData({ ...inputData, end_date: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										dateFormat="dd MMMM yyyy"
										// minDate={inputData.start_date}
									/>
								</div>
							</div>
							<div className="col-6">
								<div className="form-group">
									<label htmlFor="end_time">End Time</label>
									<DatePicker
										selected={inputData.end_time}
										onChange={(date) => setInputData({ ...inputData, end_time: date })}
										wrapperClassName="d-block"
										className="form-control input-datetime"
										showTimeInput
										showTimeSelectOnly
										timeCaption="Time"
										dateFormat="h:mm aa"
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="form-check form-group">
									<input
										className="form-check-input custom-outline-checkbox"
										type="checkbox"
										checked={inputData.featured}
										id="featured"
										onChange={() => setInputData({ ...inputData, featured: !inputData.featured })}
									/>
									<label className="form-check-label custom-checkbox-label" htmlFor="featured">
										Featured
									</label>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<label htmlFor="speaker">Add / Remove Speakers</label>
									<div className="speakers-selection-wrapper">
										<input
											ref={speakerInputRef}
											id="speaker"
											type="text"
											className="form-control"
											aria-describedby="speaker"
											name="speaker"
											value={inputData.speaker}
											onChange={handleInputOnChange}
											placeholder="Search"
											list="speakers"
											onClick={() => setShowSpeakerSelection(!showSpeakerSelection)}
											autoComplete="off"
										/>
										{showSpeakerSelection && (
											<>
												<div
													className="custom-selection-wrapper-bgOverlay"
													onClick={() =>
														setShowSpeakerSelection(!showSpeakerSelection)
													}></div>
												<div
													className="speakers-dropdown"
													onScroll={handleSpeakerSelectionScroll}>
													{speakersFiltered.map((speaker) => {
														const {
															user_id,
															profile_image_url,
															first_name,
															last_name,
															organization_name,
														} = speaker;

														let speakerAdded = selectedSpeakers.filter(
															(selectedSpeaker) => {
																return selectedSpeaker.user_id === user_id;
															}
														);

														if (speakerAdded.length > 0) {
															return <div key={`speaker-option-${user_id}`}></div>;
														}

														return (
															<div
																key={`speaker-option-${user_id}`}
																className="speaker-option row align-items-center">
																<div className="col-1">
																	<img
																		src={profile_image_url || IconDefaultUser}
																		alt="profile"
																		className="img-fluid"
																	/>
																</div>
																<div className="col-9">
																	<div className="speaker-name">
																		{speaker.name
																			? speaker.name
																			: `${last_name || ""} ${first_name || ""}`}
																	</div>
																	<div className="speaker-organization">
																		{organization_name}
																	</div>
																</div>
																<div className="col-2">
																	{speakerAdded.length > 0 ? (
																		<button
																			type="button"
																			className="btn btn-outline-primary w-100"
																			onClick={() => removeSpeaker(speaker)}>
																			Remove
																		</button>
																	) : (
																		<button
																			type="button"
																			className="btn btn-primary w-100"
																			onClick={() => addSpeaker(speaker)}>
																			Add
																		</button>
																	)}
																</div>
															</div>
														);
													})}
												</div>
											</>
										)}
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className="selected-speaker-container row">
									{selectedSpeakers.map((speaker) => {
										const { user_id, profile_image_url, first_name, last_name, organization_name } =
											speaker;

										return (
											<div key={`selected-speaker-${user_id}`} className="col-4 mb-3">
												<div className="speaker-item">
													<div className="row align-items-center">
														<div className="col-2 pr-0">
															<img
																src={profile_image_url || IconDefaultUser}
																alt="profile"
																className="img-fluid"
															/>
														</div>
														<div className="col-8">
															<div className="speaker-name">
																{speaker.name
																	? speaker.name
																	: `${last_name || ""} ${first_name || ""}`}
															</div>
															<div className="speaker-organization">
																{organization_name}
															</div>
														</div>
														<div className="col-2 pl-0 text-right">
															<img
																src={IconRemove}
																alt="remove"
																className="img-fluid icon-remove"
																onClick={() => removeSpeaker(speaker)}
															/>
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-8"></div>
							<div className="col-2">
								<button
									type="button"
									className="btn btn-outline-danger w-100"
									onClick={openDeleteSessionModal}>
									<img src={IconDelete} alt="delete" className="icon-btn" /> Delete
								</button>
							</div>
							<div className="col-2">
								<button type="submit" className="btn btn-primary w-100">
									Save
								</button>
							</div>
						</div>
					</form>
				</section>

				<Tabs defaultActiveKey={"qna"} className="custom-tabs">
					<Tab eventKey="qna" title="Q&A">
						<QnAContainer />
					</Tab>
					<Tab eventKey="polls" title="Polls">
						<PollContainer />
					</Tab>
					<Tab eventKey="audiences" title="Audiences">
						<AudienceContainer />
					</Tab>
					<Tab eventKey="likes" title="Likes">
						<section className="tab-panel-container likes-container">
							<h4 className="tab-content-title mb-3">Engagement Panel</h4>
							<div className="total-like-card d-inline-flex justify-content-center align-items-center">
								<div className="text-center mr-4">
									<h4 className="label">Total Likes Count</h4>
									<h1 className="text-count">{totalLikes}</h1>
								</div>
								<div>
									<img src={IconLikeBlue} alt="likes" className="like-icon" />
								</div>
							</div>
						</section>
					</Tab>
				</Tabs>

				{openModalName === "delete_session" && (
					<CustomModal
						icon={IconDelete}
						title="Are you sure you want to delete this session?"
						btnClass="btn-danger"
						textPrimary={
							<>
								<img src={IconDeleteGrey} alt="delete" className="icon-btn" /> Delete
							</>
						}
						textSecondary="Cancel"
						onClickPrimary={handleConfirmDelete}
						onClickSecondary={closeCustomModal}
					/>
				)}

				{openLiveModal && (
					<LiveToggleModal
						isLive={inputData.live}
						streamingUrl={streamingUrl}
						streamingStartAt={inputData.start_date}
						streamingEndAt={inputData.end_date}
						streamingCover={inputData.cover_image}
						title={inputData.live ? "Turn Off LIVE?" : `Go LIVE ?`}
						textConfirm={inputData.live ? "Turn Off Live" : "Go Live Now!"}
						onConfirm={handleToggleLiveConfirm}
						textCancel={inputData.live ? "Stay on Live" : "Cancel"}
						onCancel={handleCloseLiveModal}
					/>
				)}
			</div>
		</SessionLayoutContainer>
	);
};

export default withRouter(ViewChannelSession);
