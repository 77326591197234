import apiAction from "../services/apiAction"

const ProfileAPI = {

    /**
     * Get user profile
     * @param {int} userId 
     */
    getUserProfile: (userId) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/${userId}/profile`;
            
            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Update user profile
     * @param {int} userId
     * @param {FormData} formData
     */
    postUpdateUserProfile: (userId, formData) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/users/${userId}/profile`;

            apiAction.post(url, formData)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },
};

export default ProfileAPI;
