/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import IconRemove from "app/assets/icon-close.png";
import ExhibitorAPI from "app/apis/exhibitor";
import IconEditWhite from "app/assets/icon-edit-white.png";
import IconDeleteGrey from "app/assets/icon-delete-grey.png";
import { useDispatch } from "react-redux";
import { eventActions } from "app/states/event";
import { AGENT_TYPE } from "app/config/settings";
import CustomModal from "app/views/components/CustomModal/CustomModal";
import IconDelete from "app/assets/icon-delete-red.png";
import { commonActions } from "app/states/common";
import IconDefaultUser from "app/assets/icon-default-user.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { DROPDOWN_RECORDS_PER_PAGE } from "app/config/settings";
import { validateEmail } from "app/helper/common";

const ExhibitorRoomDetailsContainer = ({ history, exhibitorRoomDetails }) => {
	let { eventId, roomId } = useParams();

	const dispatch = useDispatch();
	const refreshExhibitorRoomDetails = (refresh) => dispatch(eventActions.refreshExhibitorRoomDetails(refresh));
	const openCustomModal = () => dispatch(commonActions.openCustomModal());
	const closeCustomModal = () => dispatch(commonActions.closeCustomModal());
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const [inputData, setInputData] = useState({
		name: "",
		short_description: "",
		full_description: "",
		logo: "",
		product_slots: 10,
		agent_slots: 3,
		room_breakouts: 5,
		website: "",
		linkedin: "",
		facebook: "",
		instagram: "",
		youtube: "",
		twitter: "",
	});

	const [uploadedLogo, setUploadedLogo] = useState(null);

	const [searchAgentText, setSearchAgentText] = useState("");
	const [agents, setAgents] = useState([]);
	const [agentsFiltered, setAgentsFiltered] = useState([]);
	const [selectedAgents, setSelectedAgents] = useState([]);
	const [newInvitedAgents, setNewInvitedAgents] = useState([]);
	const [showAgentSelection, setShowAgentSelection] = useState(false);
	const [showAgentRoleDropdownByUserId, setShowAgentRoleDropdownByUserId] = useState(false);
	const [agentPage, setAgentPage] = useState(1);
	const [agentTotalPage, setAgentTotalPage] = useState(1);
	const [isAgentListFirstLoad, setIsAgentListFirstLoad] = useState(true);
	const [loadedAgentNextPage, setLoadedAgentNextPage] = useState(true);
	const [triggerAgentSearch, setTriggerAgentSearch] = useState(0);
	const [agentsCanBeInvited, setAgentsCanBeInvited] = useState([]);

	const [searchCategoryText, setSearchCategoryText] = useState("");
	const [exhibitorCategories, setExhibitorCategories] = useState([]);
	const [exhibitorCategoriesFiltered, setExhibitorCategoriesFiltered] = useState([]);
	const [selectedExhibitorCategories, setSelectedExhibitorCategories] = useState([]);
	const [showExhibitorCategoriesSelection, setShowExhibitorCategoriesSelection] = useState(false);
	const [categoryPage, setCategoryPage] = useState(1);
	const [categoryTotalPage, setCategoryTotalPage] = useState(1);
	const [isCategoryListFirstLoad, setIsCategoryListFirstLoad] = useState(true);
	const [loadedCategoryNextPage, setLoadedCategoryNextPage] = useState(true);
	const [triggerCategorySearch, setTriggerCategorySearch] = useState(0);

	const [searchAccountText, setSearchAccountText] = useState("");
	const [accounts, setAccounts] = useState([]);
	const [accountsFiltered, setAccountsFiltered] = useState([]);
	const [selectedAccount, setSelectedAccount] = useState(null);
	const [showAccountSelection, setShowAccountSelection] = useState(false);
	const [currentEventAccountId, setCurrentEventAccountId] = useState(null);
	const [accountPage, setAccountPage] = useState(1);
	const [accountTotalPage, setAccountTotalPage] = useState(1);
	const [isAccountListFirstLoad, setIsAccountListFirstLoad] = useState(true);
	const [loadedAccountNextPage, setLoadedAccountNextPage] = useState(true);
	const [triggerAccountSearch, setTriggerAccountSearch] = useState(0);

	const logoRef = useRef();

	// Get exhibitor room details
	useEffect(() => {
		if (!exhibitorRoomDetails) return;

		const {
			event_account_id,
			title,
			short_description,
			description,
			logo_url,
			website,
			facebook_url,
			instagram_url,
			youtube_url,
			twitter_url,
			linkedin_url,
			max_store_slots,
			max_private_chat,
			max_agents,
			event_exhibitor_room_agents,
			event_exhibitor_room_categories,
			organization_account_id,
			organization_account_name,
			organization_logo_url,
		} = exhibitorRoomDetails;

		setCurrentEventAccountId(event_account_id);
		setInputData({
			name: title,
			short_description: short_description || "",
			full_description: description || "",
			logo: logo_url,
			website: website || "",
			linkedin: linkedin_url || "",
			facebook: facebook_url || "",
			instagram: instagram_url || "",
			youtube: youtube_url || "",
			twitter: twitter_url || "",
			product_slots: max_store_slots || 0,
			room_breakouts: max_private_chat || 0,
			agent_slots: max_agents || 0,
		});

		// Do mapping on the agents data
		const mappedAgents = event_exhibitor_room_agents.map((agent) => {
			const jobTitle = agent.extra_infos.find((info) => {
				return info.key === "jobTitle";
			});

			const companyName = agent.extra_infos.find((info) => {
				return info.key === "companyName";
			});

			return {
				id: agent.id,
				account_user_id: agent.account_user_id,
				type: agent.type,
				user_id: agent.user_id,
				profile_image_url: agent.visitor_profile_url,
				name: agent.visitor_user_name,
				organization_name: companyName ? companyName.value : null,
				job_title: jobTitle ? jobTitle.value : null,
				email: agent.email ?? null,
				source: agent.source ?? null,
			};
		});
		setSelectedAgents(mappedAgents);

		// Do mapping on the room categories so that the data structure match with the Get Category API returned result structure
		const mappedRoomCategories = event_exhibitor_room_categories.map((category) => {
			return {
				id: category.category_id,
				name: category.name,
				thumbnail_url: category.thumbnail,
			};
		});
		setSelectedExhibitorCategories(mappedRoomCategories);

		// Set the selected event account
		setSelectedAccount({
			event_account_id: organization_account_id,
			logo_url: organization_logo_url,
			organization_account_name: organization_account_name,
		});
	}, [exhibitorRoomDetails]);

	// Get agent listing
	useEffect(() => {
		if (!currentEventAccountId) return;

		let params = {
			event_account_id: currentEventAccountId,
			page: 1,
			limit: DROPDOWN_RECORDS_PER_PAGE,
			sort_order: "asc",
		};

		if (searchAgentText) {
			params["search_string"] = searchAgentText;
		}

		ExhibitorAPI.getRoomAgentListing(params)
			.then((response) => {
				if (response.code === 200) {
					const { agents: agentListing, paging } = response.data;
					const { last_page } = paging;

					setAgents(agentListing);
					setAgentsFiltered(agentListing);

					setAgentTotalPage(last_page);
					setIsAgentListFirstLoad(false);
					setLoadedAgentNextPage(true);
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [currentEventAccountId]);

	// Handle agent listing on search and pagination
	useEffect(() => {
		if (!currentEventAccountId || isAgentListFirstLoad) return;

		let params = {
			event_account_id: currentEventAccountId,
			page: agentPage,
			limit: DROPDOWN_RECORDS_PER_PAGE,
			sort_order: "asc",
		};

		if (searchAgentText) {
			params["search_string"] = searchAgentText;
		}

		ExhibitorAPI.getRoomAgentListing(params)
			.then((response) => {
				const { agents: agentListing, paging } = response.data;
				const { last_page } = paging;

				setAgents([...agents, ...agentListing]);
				setAgentsFiltered([...agents, ...agentListing]);

				setAgentTotalPage(last_page);
				setIsAgentListFirstLoad(false);
				setLoadedAgentNextPage(true);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [agentPage, triggerAgentSearch]);

	// Search agent listing
	useEffect(() => {
		if (isAgentListFirstLoad) {
			return;
		}

		// Reset state and trigger search
		setAgents([]);
		setAgentsFiltered([]);
		setAgentPage(1);
		setTriggerAgentSearch(triggerAgentSearch + 1);
	}, [searchAgentText]);

	const handleAgentSelectionScroll = (event) => {
		let offset = 20;
		let isBottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + offset;

		if (isBottom && agentPage < agentTotalPage && loadedAgentNextPage) {
			setLoadedAgentNextPage(false);
			setAgentPage(agentPage + 1);
		}
	};

	// Get exhibitor category listings
	useEffect(() => {
		let params = {
			page: categoryPage,
			limit: DROPDOWN_RECORDS_PER_PAGE,
			event_id: eventId,
			sort_by: "name",
			sort_order: "asc",
		};

		if (searchCategoryText) {
			params["name"] = searchCategoryText;
		}

		ExhibitorAPI.getExhibitorCategoryListing(params)
			.then((response) => {
				const { exhibitor_categories, paging } = response.data;
				const { last_page } = paging;

				setExhibitorCategories([...exhibitorCategories, ...exhibitor_categories]);
				setExhibitorCategoriesFiltered([...exhibitorCategories, ...exhibitor_categories]);

				setCategoryTotalPage(last_page);
				setIsCategoryListFirstLoad(false);
				setLoadedCategoryNextPage(true);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [categoryPage, triggerCategorySearch]);

	// Search exhibitor category listing
	useEffect(() => {
		if (isCategoryListFirstLoad) {
			return;
		}

		// Reset state and trigger search
		setExhibitorCategories([]);
		setExhibitorCategoriesFiltered([]);
		setCategoryPage(1);
		setTriggerCategorySearch(triggerCategorySearch + 1);
	}, [searchCategoryText]);

	const handleCategorySelectionScroll = (event) => {
		let offset = 20;
		let isBottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + offset;

		if (isBottom && categoryPage < categoryTotalPage && loadedCategoryNextPage) {
			setLoadedCategoryNextPage(false);
			setCategoryPage(categoryPage + 1);
		}
	};

	// Get event account listing
	useEffect(() => {
		let params = {
			page: accountPage,
			limit: DROPDOWN_RECORDS_PER_PAGE,
			event_id: eventId,
		};

		if (searchAccountText) {
			params["search_string"] = searchAccountText;
		}

		ExhibitorAPI.getEventAccountListing(params)
			.then((response) => {
				const { event_accounts, paging } = response.data;
				const { last_page } = paging;

				setAccounts([...accounts, ...event_accounts]);
				setAccountsFiltered([...accounts, ...event_accounts]);

				setAccountTotalPage(last_page);
				setIsAccountListFirstLoad(false);
				setLoadedAccountNextPage(true);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [accountPage, triggerAccountSearch]);

	// Search event account listing
	useEffect(() => {
		if (isAccountListFirstLoad) {
			return;
		}

		// Reset state and trigger search
		setAccounts([]);
		setAccountsFiltered([]);
		setAccountPage(1);
		setTriggerAccountSearch(triggerAccountSearch + 1);
	}, [searchAccountText]);

	const handleEventAccountSelectionScroll = (event) => {
		let offset = 20;
		let isBottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + offset;

		if (isBottom && accountPage < accountTotalPage && loadedAccountNextPage) {
			setLoadedAccountNextPage(false);
			setAccountPage(accountPage + 1);
		}
	};

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleChangeLogo = (event) => {
		setUploadedLogo(event.target.files[0]);
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleUpdateRoom = async (event) => {
		event.preventDefault();
		showLoading();

		// Ensure event account is selected
		if (!selectedAccount) {
			openAlertSnackbar("Event account is required.", "error");
			return;
		}

		let formData = new FormData();
		formData.append("event_id", eventId);
		formData.append("event_account_id", selectedAccount.event_account_id);
		formData.append("title", inputData.name);
		formData.append("max_store_slots", inputData.product_slots);
		formData.append("max_private_chat", inputData.room_breakouts);
		formData.append("max_agents", inputData.agent_slots);

		if (!uploadedLogo && inputData.logo) {
			formData.append("logo_url", inputData.logo);
		}
		if (uploadedLogo) {
			formData.append("logo", uploadedLogo);
		}
		if (inputData.short_description) {
			formData.append("short_description", inputData.short_description);
		}
		if (inputData.full_description) {
			formData.append("description", inputData.full_description);
		}
		if (inputData.website) {
			formData.append("website", inputData.website);
		}
		if (inputData.linkedin) {
			formData.append("linkedin_url", inputData.linkedin);
		}
		if (inputData.facebook) {
			formData.append("facebook_url", inputData.facebook);
		}
		if (inputData.instagram) {
			formData.append("instagram_url", inputData.instagram);
		}
		if (inputData.youtube) {
			formData.append("youtube_url", inputData.youtube);
		}
		if (inputData.twitter) {
			formData.append("twitter_url", inputData.twitter);
		}

		if (selectedExhibitorCategories.length > 0) {
			selectedExhibitorCategories.forEach((category) => {
				formData.append("event_exhibitor_room_categories[]", JSON.stringify({ category_id: category.id }));
			});
		}

		// Existing agents
		if (selectedAgents.length > 0) {
			selectedAgents.forEach((agent) => {
				// Add new agent
				let agentObject = {
					account_user_id: agent.account_user_id,
					user_id: agent.user_id,
					type: agent.type,
					email: agent.email,
					source: agent.source || "existing",
				};

				// If update existing agent
				if (agent.id) {
					agentObject["agent_id"] = agent.id;
				}

				formData.append("event_exhibitor_room_agents[]", JSON.stringify(agentObject));
			});
		}

		// New invited agents
		if (newInvitedAgents.length > 0) {
			newInvitedAgents.forEach((email) => {
				let agentObject = {
					email: email,
					source: "invited",
				};

				formData.append("event_exhibitor_room_agents[]", JSON.stringify(agentObject));
			});
		}

		ExhibitorAPI.postUpdateExhibitorRoom(roomId, formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					setNewInvitedAgents([]);
					refreshExhibitorRoomDetails(true);
					openAlertSnackbar("Exhibitor Room Updated", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const removeAgent = (agent) => {
		let agentsCopy = [...selectedAgents];

		let removeIndex = null;
		for (let i = 0; i < agentsCopy.length; i++) {
			const { id } = agentsCopy[i];

			if (id === agent.id) {
				removeIndex = i;
				break;
			}
		}

		agentsCopy.splice(removeIndex, 1);
		setSelectedAgents(agentsCopy);
	};

	const addAgent = (agent) => {
		setSelectedAgents([...selectedAgents, agent]);
	};

	const addNewAgentInvitation = (agentEmail) => {
		setNewInvitedAgents([...newInvitedAgents, agentEmail]);
		setAgentsCanBeInvited([]);
		setShowAgentSelection(false);
		setSearchAgentText("");
	};

	const removeNewAgentInvitation = (agentEmail) => {
		const newInvitedAgentsCopy = [...newInvitedAgents];
		const updatedNewInvitedAgents = newInvitedAgentsCopy.filter((email) => email !== agentEmail);
		setNewInvitedAgents(updatedNewInvitedAgents);
	};

	const removeCategory = (category) => {
		let selectedCategoriesCopy = [...selectedExhibitorCategories];

		let removeIndex = null;
		for (let i = 0; i < selectedCategoriesCopy.length; i++) {
			const { id } = selectedCategoriesCopy[i];

			if (id === category.id) {
				removeIndex = i;
				break;
			}
		}

		selectedCategoriesCopy.splice(removeIndex, 1);
		setSelectedExhibitorCategories(selectedCategoriesCopy);
	};

	const addCategory = (category) => {
		setSelectedExhibitorCategories([...selectedExhibitorCategories, category]);
	};

	const removeAccount = () => {
		setSelectedAccount(null);
	};

	const selectAccount = (account) => {
		setSelectedAccount(account);

		// To get the agent listing based on selected event account id
		setCurrentEventAccountId(account.event_account_id);
		setIsAgentListFirstLoad(true);
		setAgentPage(1);
	};

	const handleDeleteLogo = () => {
		setUploadedLogo(null);
	};

	const handleConfirmDelete = () => {
		showLoading();

		ExhibitorAPI.deleteExhibitorRoom(roomId)
			.then((response) => {
				hideLoading();
				const { code } = response;

				if (code === 200) {
					closeCustomModal();
					history.replace(`/events/view/${eventId}/exhibitor/rooms`);
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				hideLoading();
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleChangeAgentRole = (userId, changeToRole) => {
		let newRoleType = AGENT_TYPE[changeToRole];
		let selectedAgentsCopy = [...selectedAgents];

		let updatedSelectedAgents = selectedAgentsCopy.map((selectedAgents) => {
			// Find the user id to update new selected role type
			if (selectedAgents.user_id === userId) {
				selectedAgents.type = newRoleType;
			}

			return selectedAgents;
		});

		setSelectedAgents(updatedSelectedAgents);
	};

	const handleSearchAgentTextChange = (event) => {
		const searchText = event.target.value;
		setSearchAgentText(searchText);

		if (validateEmail(searchText)) {
			searchAgent(searchText);
		} else {
			setAgentsCanBeInvited([]);
		}
	};

	const searchAgent = (searchText) => {
		if (!currentEventAccountId) return;

		let params = {
			event_account_id: currentEventAccountId,
			page: agentPage,
			limit: DROPDOWN_RECORDS_PER_PAGE,
			sort_order: "asc",
		};

		if (searchText) {
			params["search_string"] = searchText;
		}

		ExhibitorAPI.getRoomAgentListing(params)
			.then((response) => {
				const { agents: agentListing } = response.data;

				// If no matching found, allow user to add/invite the new agent's email
				if (agentListing.length === 0) {
					setShowAgentSelection(true);
					setAgentsCanBeInvited([searchText]);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	if (!exhibitorRoomDetails) {
		return <></>;
	}

	return (
		<section className="tab-panel-container exhibitor-details-container">
			<form method="post" onSubmit={handleUpdateRoom}>
				<div className="row mb-3">
					<div className="col-12">
						<div className="form-group">
							<label htmlFor="name">Room Name</label>
							<input
								id="name"
								type="text"
								className="form-control"
								aria-describedby="name"
								name="name"
								value={inputData.name}
								onChange={handleInputOnChange}
								required
							/>
						</div>
					</div>
					<div className="col-12">
						<div className="form-group">
							<label htmlFor="short_description">Short Description</label>
							<textarea
								id="short_description"
								type="text"
								className="form-control"
								aria-describedby="short_description"
								name="short_description"
								value={inputData.short_description}
								onChange={handleInputOnChange}
								rows={3}
							/>
						</div>
					</div>
					<div className="col-12">
						<div className="form-group">
							<label htmlFor="full_description">Full Description</label>
							<textarea
								id="full_description"
								type="text"
								className="form-control"
								aria-describedby="full_description"
								name="full_description"
								value={inputData.full_description}
								onChange={handleInputOnChange}
								rows={5}
							/>
						</div>
					</div>

					<div className="col-12">
						<div className="row">
							<div className="col-3">
								<div className="form-group">
									<label>Select Room Logo Image</label>
									<div className="d-flex align-items-start">
										<img
											src={
												uploadedLogo
													? URL.createObjectURL(uploadedLogo)
													: inputData.logo
													? inputData.logo
													: IconUpload
											}
											alt="event-logo"
											className="img-fluid img-logo"
											onClick={browsePicture}
										/>
										<input
											type="file"
											name="logo"
											onChange={handleChangeLogo}
											ref={logoRef}
											className="d-none"
											accept="image/*"
										/>

										<button
											type="button"
											className="btn btn-primary ml-3 d-flex align-items-center"
											onClick={browsePicture}>
											Replace <img src={IconEditWhite} alt="edit" className="icon-action" />
										</button>
										<button
											type="button"
											className="btn btn-outline-secondary ml-3 d-flex align-items-center"
											onClick={handleDeleteLogo}
											disabled={uploadedLogo ? false : true}>
											Delete <img src={IconDeleteGrey} alt="edit" className="icon-action" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<section className="w-100 custom-selection-container">
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="category">Select Exhibitor Category</label>
								<div className="custom-selection-wrapper">
									<input
										id="category"
										type="text"
										className="form-control"
										aria-describedby="category"
										name="category"
										value={searchCategoryText}
										onChange={(event) => setSearchCategoryText(event.target.value)}
										placeholder="Search"
										list="category"
										onClick={() =>
											setShowExhibitorCategoriesSelection(!showExhibitorCategoriesSelection)
										}
										autoComplete={"off"}
									/>
									{showExhibitorCategoriesSelection && (
										<>
											<div
												className="custom-selection-wrapper-bgOverlay"
												onClick={() =>
													setShowExhibitorCategoriesSelection(
														!showExhibitorCategoriesSelection
													)
												}></div>
											<div className="custom-dropdown" onScroll={handleCategorySelectionScroll}>
												{exhibitorCategoriesFiltered.map((category) => {
													const { id, name, thumbnail_url } = category;

													let categoryAdded = selectedExhibitorCategories.filter(
														(selectedCategory) => {
															return selectedCategory.id === id;
														}
													);

													if (categoryAdded.length > 0) {
														return <div key={`category-option-${id}`}></div>;
													}

													return (
														<div
															key={`category-option-${id}`}
															className="custom-option row align-items-center">
															<div className="col-1">
																<img
																	src={thumbnail_url}
																	alt="profile"
																	className="img-fluid"
																/>
															</div>
															<div className="col-9">
																<div className="custom-name">{name}</div>
															</div>
															<div className="col-2">
																{categoryAdded.length > 0 ? (
																	<button
																		type="button"
																		className="btn btn-outline-primary w-100"
																		onClick={() => removeCategory(category)}>
																		Remove
																	</button>
																) : (
																	<button
																		type="button"
																		className="btn btn-primary w-100"
																		onClick={() => addCategory(category)}>
																		Select
																	</button>
																)}
															</div>
														</div>
													);
												})}
											</div>
										</>
									)}
								</div>
							</div>
						</div>

						<div className="col-12">
							<div className="selected-custom-container row">
								{selectedExhibitorCategories.map((selectedCategory) => {
									const { id, name, thumbnail_url } = selectedCategory;

									return (
										<div key={`selected-custom-${id}`} className="col-4 mb-3">
											<div className="custom-item">
												<div className="row align-items-center">
													<div className="col-2 pr-0">
														<img src={thumbnail_url} alt="thumnail" className="img-fluid" />
													</div>
													<div className="col-8">
														<div className="custom-name">{name}</div>
													</div>
													<div className="col-2 pl-0 text-right">
														<img
															src={IconRemove}
															alt="remove"
															className="img-fluid icon-remove"
															onClick={() => removeCategory(selectedCategory)}
														/>
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</section>

					<div className="col-12">
						<div className="form-group">
							<label>Room Entitlements</label>
							<div className="room-entitlements-container">
								<div className="row">
									<div className="col-6 border__right">
										<div className="row align-items-center mb-3">
											<div className="col-8">
												<label htmlFor="product_slots">Allocated Product Slots</label>
											</div>
											<div className="col-3">
												<input
													id="product_slots"
													type="number"
													className="form-control"
													aria-describedby="product_slots"
													name="product_slots"
													value={inputData.product_slots}
													onChange={handleInputOnChange}
													min={0}
												/>
											</div>
										</div>
										<div className="row align-items-center">
											<div className="col-8">
												<label htmlFor="room_breakouts">Allocated Breakout Rooms</label>
											</div>
											<div className="col-3">
												<input
													id="room_breakouts"
													type="number"
													className="form-control"
													aria-describedby="room_breakouts"
													name="room_breakouts"
													value={inputData.room_breakouts}
													onChange={handleInputOnChange}
													min={0}
												/>
											</div>
										</div>
									</div>
									<div className="col-6">
										<div className="row align-items-center">
											<div className="col-1"></div>
											<div className="col-8">
												<label htmlFor="agent_slots">Allocated Room Agent Logins</label>
											</div>
											<div className="col-3">
												<input
													id="agent_slots"
													type="number"
													className="form-control"
													aria-describedby="agent_slots"
													name="agent_slots"
													value={inputData.agent_slots}
													onChange={handleInputOnChange}
													min={0}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<section className="w-100 custom-selection-container">
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="accounts">Select Account</label>
								<div className="custom-selection-wrapper">
									{selectedAccount ? (
										<div
											className="selected-account"
											onClick={() => setShowAccountSelection(!showAccountSelection)}>
											<div className="d-flex align-items-center">
												<div className="selected-account-logo">
													<img
														src={selectedAccount.logo_url || IconDefaultUser}
														alt="logo"
														className="img-fluid"
													/>
												</div>
												<div>
													<div className="selected-account-name">
														{selectedAccount.organization_account_name}
													</div>
													<div className="selected-account-organization">Organization</div>
												</div>
											</div>
										</div>
									) : (
										<input
											id="accounts"
											type="text"
											className="form-control"
											aria-describedby="accounts"
											name="accounts"
											value={searchAccountText}
											onChange={(event) => setSearchAccountText(event.target.value)}
											placeholder="Search"
											list="accounts"
											onClick={() => setShowAccountSelection(!showAccountSelection)}
											autoComplete={"off"}
										/>
									)}
									{showAccountSelection && (
										<>
											<div
												className="custom-selection-wrapper-bgOverlay"
												onClick={() => setShowAccountSelection(!showAccountSelection)}></div>
											<div
												className="custom-dropdown"
												onScroll={handleEventAccountSelectionScroll}>
												{accountsFiltered.map((account) => {
													const { event_account_id, logo_url, organization_account_name } =
														account;

													return (
														<div
															key={`account-option-${event_account_id}`}
															className="custom-option row align-items-center">
															<div className="col-1">
																<img
																	src={logo_url || IconDefaultUser}
																	alt="logo"
																	className="img-fluid"
																/>
															</div>
															<div className="col-9">
																<div className="custom-name">
																	{organization_account_name}
																</div>
																<div className="custom-organization">Organization</div>
															</div>
															<div className="col-2">
																{selectedAccount &&
																selectedAccount.event_account_id ===
																	event_account_id ? (
																	<button
																		type="button"
																		className="btn btn-outline-primary w-100"
																		onClick={() => removeAccount()}>
																		Remove
																	</button>
																) : (
																	<button
																		type="button"
																		className="btn btn-primary w-100"
																		onClick={() => selectAccount(account)}>
																		Select
																	</button>
																)}
															</div>
														</div>
													);
												})}
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</section>

					<section className="w-100 custom-selection-container">
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="agents">
									Select Room Agent{" "}
									<span
										className={`selected-agent-count ${
											selectedAgents.length > inputData.agent_slots ? "agent-count-over" : ""
										}`}>
										( {selectedAgents.length} of {inputData.agent_slots} selected )
									</span>
								</label>
								<div className="custom-selection-wrapper">
									<input
										id="agents"
										type="text"
										className="form-control"
										aria-describedby="agents"
										name="agents"
										value={searchAgentText}
										onChange={handleSearchAgentTextChange}
										placeholder="Search"
										list="agents"
										onClick={() => setShowAgentSelection(!showAgentSelection)}
										autoComplete={"off"}
									/>
									{showAgentSelection && (
										<>
											<div
												className="custom-selection-wrapper-bgOverlay"
												onClick={() => setShowAgentSelection(!showAgentSelection)}></div>
											{agentsCanBeInvited.length > 0 ? (
												<div className="custom-dropdown">
													{agentsCanBeInvited.map((newAgentEmail, index) => {
														let agentsAdded = selectedAgents.filter((selectedAgent) => {
															return selectedAgent.email === newAgentEmail;
														});

														if (agentsAdded.length > 0) {
															return (
																<div
																	key={`invite-agent-${index}`}
																	className="custom-option row align-items-center">
																	<div className="col-1">
																		<img
																			src={IconDefaultUser}
																			alt="profile"
																			className="img-fluid"
																		/>
																	</div>
																	<div className="col-9">
																		<div className="custom-name">
																			{newAgentEmail}
																		</div>
																	</div>
																	<div className="col-2 text-center">
																		<span className="text-added">Added</span>
																	</div>
																</div>
															);
														}

														return (
															<div
																key={`invite-agent-${index}`}
																className="custom-option row align-items-center">
																<div className="col-1">
																	<img
																		src={IconDefaultUser}
																		alt="profile"
																		className="img-fluid"
																	/>
																</div>
																<div className="col-9">
																	<div className="custom-name">{newAgentEmail}</div>
																	<div className="custom-organization">
																		User not found in this account.
																	</div>
																</div>
																<div className="col-2">
																	<button
																		type="button"
																		className="btn btn-primary w-100"
																		onClick={() =>
																			addNewAgentInvitation(newAgentEmail)
																		}>
																		Add / Invite
																	</button>
																</div>
															</div>
														);
													})}
												</div>
											) : (
												<div className="custom-dropdown" onScroll={handleAgentSelectionScroll}>
													{agentsFiltered.map((agent) => {
														const {
															user_id,
															profile_image_url,
															first_name,
															last_name,
															organization_name,
															email,
														} = agent;

														let agentsAdded = selectedAgents.filter((selectedAgent) => {
															return (
																parseInt(selectedAgent.user_id) === parseInt(user_id) ||
																selectedAgent.email === email
															);
														});

														if (agentsAdded.length > 0) {
															return (
																<div
																	key={`agent-option-${user_id}`}
																	className="custom-option row align-items-center">
																	<div className="col-1">
																		<img
																			src={profile_image_url || IconDefaultUser}
																			alt="profile"
																			className="img-fluid"
																		/>
																	</div>
																	<div className="col-9">
																		<div className="custom-name">
																			{first_name} {last_name}
																		</div>
																		<div className="custom-organization">
																			{organization_name}
																		</div>
																	</div>
																	<div className="col-2 text-center">
																		<span className="text-added">Added</span>
																	</div>
																</div>
															);
														}

														return (
															<div
																key={`agent-option-${user_id}`}
																className="custom-option row align-items-center">
																<div className="col-1">
																	<img
																		src={profile_image_url || IconDefaultUser}
																		alt="profile"
																		className="img-fluid"
																	/>
																</div>
																<div className="col-9">
																	<div className="custom-name">
																		{first_name} {last_name}
																	</div>
																	<div className="custom-organization">
																		{organization_name}
																	</div>
																</div>
																<div className="col-2">
																	{agentsAdded.length > 0 ? (
																		<button
																			type="button"
																			className="btn btn-outline-primary w-100"
																			onClick={() => removeAgent(agent)}>
																			Remove
																		</button>
																	) : (
																		<button
																			type="button"
																			className="btn btn-primary w-100"
																			onClick={() => addAgent(agent)}>
																			Select
																		</button>
																	)}
																</div>
															</div>
														);
													})}
												</div>
											)}
										</>
									)}
								</div>
							</div>
						</div>

						{(selectedAgents.length > 0 || newInvitedAgents.length > 0) && (
							<div className="col-12 mb-3">
								<div className="selected-agent-container">
									{selectedAgents.map((agent) => {
										const {
											user_id,
											profile_image_url,
											first_name,
											last_name,
											job_title,
											type,
											email,
											source,
										} = agent;

										let selectedClass =
											type === AGENT_TYPE["admin"]
												? "admin-role-selected"
												: "agent-role-selected";

										if (source === "invited") {
											return (
												<div key={`selected-agent-${user_id}`} className="agent-item">
													<div className="row align-items-center">
														<div className="col-9 d-flex align-items-center">
															<img
																src={profile_image_url || IconDefaultUser}
																alt="profile"
																className="agent-logo"
															/>
															<div>
																<div className="agent-name">{email || "-"}</div>
															</div>
														</div>
														<div className="col-2">
															<div className="status-invited">Invited</div>
														</div>
														<div className="col-1 pl-0 text-right">
															<img
																src={IconRemove}
																alt="remove"
																className="img-fluid icon-remove"
																onClick={() => removeAgent(agent)}
															/>
														</div>
													</div>
												</div>
											);
										}

										return (
											<div key={`selected-agent-${user_id}`} className="agent-item">
												<div className="row align-items-center">
													<div className="col-9 d-flex align-items-center">
														<img
															src={profile_image_url || IconDefaultUser}
															alt="profile"
															className="agent-logo"
														/>
														<div>
															<div className="agent-name">
																{agent.name
																	? agent.name
																	: `${first_name || ""} ${last_name || ""}`}
															</div>
															<div className="agent-role">{job_title}</div>
														</div>
													</div>
													<div className="col-2">
														<div className="role-selection-wrapper">
															<div
																className="role-selection"
																onClick={() =>
																	setShowAgentRoleDropdownByUserId(
																		showAgentRoleDropdownByUserId === user_id
																			? false
																			: user_id
																	)
																}>
																<div className={`option ${selectedClass}`}>
																	<span className="text-role">
																		{type === AGENT_TYPE["admin"]
																			? "Room Admin"
																			: "Room Agent"}
																	</span>{" "}
																	<FontAwesomeIcon icon={faChevronDown} />
																</div>
																{showAgentRoleDropdownByUserId === user_id && (
																	<div className="selection-dropdown">
																		<div
																			className="option admin-option"
																			onClick={() =>
																				handleChangeAgentRole(user_id, "admin")
																			}>
																			Room Admin
																		</div>
																		<div
																			className="option agent-option"
																			onClick={() =>
																				handleChangeAgentRole(user_id, "agent")
																			}>
																			Room Agent
																		</div>
																	</div>
																)}
															</div>
														</div>
													</div>
													<div className="col-1 pl-0 text-right">
														<img
															src={IconRemove}
															alt="remove"
															className="img-fluid icon-remove"
															onClick={() => removeAgent(agent)}
														/>
													</div>
												</div>
											</div>
										);
									})}

									{newInvitedAgents.map((email, index) => {
										return (
											<div key={`new-invited-agent-${index}`} className="agent-item">
												<div className="row align-items-center">
													<div className="col-9 d-flex align-items-center">
														<img
															src={IconDefaultUser}
															alt="profile"
															className="agent-logo"
														/>
														<div>
															<div className="agent-name">{email || "-"}</div>
														</div>
													</div>
													<div className="col-2">
														<div className="status-invited">Invited</div>
													</div>
													<div className="col-1 pl-0 text-right">
														<img
															src={IconRemove}
															alt="remove"
															className="img-fluid icon-remove"
															onClick={() => removeNewAgentInvitation(email)}
														/>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</section>

					<div className="col-12">
						<div className="form-group">
							<label>Social Links</label>
							<input
								id="website"
								type="url"
								className="form-control mb-3"
								aria-describedby="website"
								name="website"
								value={inputData.website}
								onChange={handleInputOnChange}
								placeholder="Website"
							/>
							<input
								id="linkedin"
								type="url"
								className="form-control mb-3"
								aria-describedby="linkedin"
								name="linkedin"
								value={inputData.linkedin}
								onChange={handleInputOnChange}
								placeholder="Linkedin"
							/>
							<input
								id="facebook"
								type="url"
								className="form-control mb-3"
								aria-describedby="facebook"
								name="facebook"
								value={inputData.facebook}
								onChange={handleInputOnChange}
								placeholder="Facebook"
							/>
							<input
								id="instagram"
								type="url"
								className="form-control mb-3"
								aria-describedby="instagram"
								name="instagram"
								value={inputData.instagram}
								onChange={handleInputOnChange}
								placeholder="Instagram"
							/>
							<input
								id="youtube"
								type="url"
								className="form-control mb-3"
								aria-describedby="youtube"
								name="youtube"
								value={inputData.youtube}
								onChange={handleInputOnChange}
								placeholder="Youtube"
							/>
							{/* <input
								id="twitter"
								type="url"
								className="form-control mb-3"
								aria-describedby="twitter"
								name="twitter"
								value={inputData.twitter}
								onChange={handleInputOnChange}
								placeholder="Twitter"
							/> */}
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-8"></div>
					<div className="col-2">
						<button type="button" className="btn btn-outline-danger w-100" onClick={openCustomModal}>
							Delete
						</button>
					</div>
					<div className="col-2">
						<button type="submit" className="btn btn-primary w-100">
							Save
						</button>
					</div>
				</div>
			</form>

			<CustomModal
				icon={IconDelete}
				title="Are you sure you want to delete this room?"
				btnClass="btn-danger"
				textPrimary={
					<>
						<img src={IconDeleteGrey} alt="delete" className="icon-btn" /> Delete
					</>
				}
				textSecondary="Cancel"
				onClickPrimary={handleConfirmDelete}
				onClickSecondary={closeCustomModal}
			/>
		</section>
	);
};

export default withRouter(ExhibitorRoomDetailsContainer);
