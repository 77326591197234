import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "app/states/event";
import IconClose from "app/assets/icon-close.png";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import ExhibitorAPI from "app/apis/exhibitor";
import { withRouter, useParams } from "react-router-dom";
import { commonActions } from "app/states/common";
import CustomModal from "app/views/components/CustomModal/CustomModal";
import IconDelete from "app/assets/icon-delete-red.png";
import IconDeleteGrey from "app/assets/icon-delete-grey.png";

const ViewExhibitorCategoryModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeViewExhibitorCategoryModal = () => dispatch(eventActions.closeViewExhibitorCategoryModal());
	const refreshExhibitorCategoryListing = (refresh) =>
		dispatch(eventActions.refreshExhibitorCategoryListing(refresh));
	const openCustomModal = () => dispatch(commonActions.openCustomModal());
	const closeCustomModal = () => dispatch(commonActions.closeCustomModal());
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showViewExhibitorCategoryModal = useSelector(({ event }) => event.showViewExhibitorCategoryModal);
	const viewExhibitorCategoryId = useSelector(({ event }) => event.viewExhibitorCategoryId);
	const { eventId } = useParams();

	const logoRef = useRef();

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [uploadedLogo, setUploadedLogo] = useState(null);
	const [logoUrl, setLogoUrl] = useState(null);

	useEffect(() => {
		if (!viewExhibitorCategoryId) {
			closeViewExhibitorCategoryModal();
			return;
		}

		ExhibitorAPI.getExhibitorCategoryDetail(viewExhibitorCategoryId).then((response) => {
			const { name, description, thumbnail_url } = response.data;
			setName(name);
			setDescription(description);
			setLogoUrl(thumbnail_url);
		});
	}, [viewExhibitorCategoryId]);

	const handleChangeLogo = (event) => {
		setUploadedLogo(event.target.files[0]);
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleUpdateExhibitorCategory = (event) => {
		event.preventDefault();
		showLoading();

		let formData = new FormData();
		formData.append("name", name);
		formData.append("description", description);

		if (uploadedLogo) {
			formData.append("thumbnail", uploadedLogo);
		}

		ExhibitorAPI.postUpdateExhibitorCategory(viewExhibitorCategoryId, formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					refreshExhibitorCategoryListing(true);
					closeViewExhibitorCategoryModal();
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleDelete = () => {
		openCustomModal();
	};

	const handleConfirmDelete = () => {
		showLoading();

		ExhibitorAPI.deleteExhibitorCategory(viewExhibitorCategoryId)
			.then((response) => {
				hideLoading();
				const { code } = response;

				if (code === 200) {
					if (response.data.deleted) {
						refreshExhibitorCategoryListing(true);
						closeCustomModal();
						closeViewExhibitorCategoryModal();
						return;
					}
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				hideLoading();
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	return (
		<Modal
			show={showViewExhibitorCategoryModal}
			onHide={closeViewExhibitorCategoryModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="view-exhibitor-category-modal"
			centered>
			<Modal.Body>
				<div className="view-exhibitor-category-modal-body">
					<div className="d-flex justify-content-end">
						<img
							src={IconClose}
							alt="close"
							className="icon-close"
							onClick={closeViewExhibitorCategoryModal}
						/>
					</div>

					<h3>View Category</h3>

					<form method="post" onSubmit={handleUpdateExhibitorCategory}>
						<div className="form-group">
							<label htmlFor="name">Category Name</label>
							<input
								id="name"
								type="text"
								className="form-control"
								aria-describedby="name"
								name="name"
								value={name}
								onChange={(event) => setName(event.target.value)}
								required
							/>
						</div>

						<div className="form-group">
							<label htmlFor="description">Description</label>
							<textarea
								id="description"
								className="form-control"
								aria-describedby="description"
								name="description"
								value={description}
								onChange={(event) => setDescription(event.target.value)}
								required
								rows={3}
							/>
						</div>

						<div className="form-group">
							<label htmlFor="image">Select Category Image</label>
							<img
								src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : logoUrl ? logoUrl : IconUpload}
								alt="logo"
								className="img-fluid img-logo"
								onClick={browsePicture}
							/>
							<input
								id="image"
								type="file"
								name="logo"
								onChange={handleChangeLogo}
								ref={logoRef}
								className="d-none"
								accept="image/*"
							/>
						</div>

						<div className="row mt-5">
							<div className="col-6">
								<button type="button" className="btn btn-outline-danger w-100" onClick={handleDelete}>
									Delete
								</button>
							</div>
							<div className="col-6">
								<button type="submit" className="btn btn-primary w-100">
									Save
								</button>
							</div>
						</div>
					</form>

					<CustomModal
						icon={IconDelete}
						title="Are you sure you want to delete this category?"
						btnClass="btn-danger"
						textPrimary={
							<>
								<img src={IconDeleteGrey} alt="delete" className="icon-btn" /> Delete
							</>
						}
						textSecondary="Cancel"
						onClickPrimary={handleConfirmDelete}
						onClickSecondary={closeCustomModal}
					/>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(ViewExhibitorCategoryModal);
