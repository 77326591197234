import React, { useState } from "react";
import { withRouter } from "react-router";
import ImageLogin from "app/assets/bg-login-page.png";
import "./style.scss";
import AuthenticationAPI from "app/apis/authentication";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";
import useQueryParams from "app/views/hooks/useQueryParams";

const ResetPassword = ({ history }) => {
	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const queryParams = useQueryParams();

	const [inputData, setInputData] = useState({
		new_password: "",
		confirm_new_password: "",
	});

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleSubmitResetPassword = (event) => {
		event.preventDefault();

		// Check if new password and confirm password is same
		if (inputData.new_password !== inputData.confirm_new_password) {
			openAlertSnackbar("New password and confirm new password must be same!", "error");
			return;
		}

		const email = queryParams.get("email");
		const token = queryParams.get("token");

		AuthenticationAPI.postResetPassword(email, token, inputData.new_password)
			.then((response) => {
				if (response.code === 200 && response.data.updated) {
					openAlertSnackbar("Password reset successfully. Please login again.");
					history.replace("/login");
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	return (
		<div className="auth-page container-fluid vh-100">
			<div className="row vh-100">
				<div className="col-6 auth-left px-5">
					<section className="my-5">
						<h5 className="font-weight-bold">Welcome to boom!</h5>
						<h5>Your global host for online and hybrid events.</h5>
					</section>
					<img src={ImageLogin} alt="Login" className="img-fluid" />
				</div>
				<div className="col-6 auth-right">
					<div className="d-flex flex-column align-items-center">
						<section className="mb-4 text-center">
							<h3>Password Reset</h3>
							<h6>Almost there! One more step to reset your password.</h6>
						</section>

						<form
							id="form-reset-password"
							className="w-100"
							method="post"
							onSubmit={handleSubmitResetPassword}>
							<div className="row">
								<div className="col-12">
									<div className="form-group text-center">
										<label htmlFor="new_password">New Password</label>
										<input
											id="new_password"
											type="password"
											className="form-control text-center"
											aria-describedby="new_password"
											name="new_password"
											value={inputData.new_password}
											onChange={handleInputOnChange}
											required
											minLength={6}
											placeholder="Enter password"
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group text-center">
										<label htmlFor="confirm_new_password">Confirm New Password</label>
										<input
											id="confirm_new_password"
											type="password"
											className="form-control text-center"
											aria-describedby="confirm_new_password"
											name="confirm_new_password"
											value={inputData.confirm_new_password}
											onChange={handleInputOnChange}
											required
											minLength={6}
											placeholder="Confirm password"
										/>
									</div>
								</div>
							</div>

							<div className="mt-4 text-center mb-1">
								<button type="submit" className="btn btn-primary btn-auth px-5">
									Confirm
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(ResetPassword);
