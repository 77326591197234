/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { billingActions } from "app/states/billings";
import IconClose from "app/assets/icon-close.png";
import { withRouter } from "react-router-dom";
import { commonActions } from "app/states/common";
import ReactLoading from "react-loading";
import BillingAPI from "app/apis/billings";
import IconWarningTriangle from "app/assets/icon-alert-triangle-yellow.png";
import moment from "moment";

const DowngradeConfirmationModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeDowngradeConfirmationModal = () => dispatch(billingActions.closeDowngradeConfirmationModal());
	const openAlertSnackbar = (message, variant, duration) =>
		dispatch(commonActions.openAlertSnackbar(message, variant, duration));
	const refreshCurrentSubscriptionPlan = () => dispatch(billingActions.refreshCurrentSubscriptionPlan());
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());

	const showDowngradeConfirmationModal = useSelector(({ billing }) => billing.showDowngradeConfirmationModal);
	const chosenPlan = useSelector(({ billing }) => billing.chosenPlan);
	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);
	const currentSubscriptionPlan = useSelector(({ billing }) => billing.currentSubscriptionPlan);

	const [quotation, setQuotation] = useState(null);

	useEffect(() => {
		if (showDowngradeConfirmationModal) {
			const data = {
				subscription_plan_id: chosenPlan.id,
				term: 1,
			};

			BillingAPI.getQuotation(selectedOrganizationAccount.id, data)
				.then((response) => {
					if (response.code === 400) {
						handleCloseModal();
						openAlertSnackbar(response.errors.join("\n"), "error");
					} else {
						setQuotation(response);
					}
				})
				.catch((error) => {
					console.error(error);
					openAlertSnackbar(error.data.errors.join("\n"), "error");
				});
		}
	}, [showDowngradeConfirmationModal]);

	const handleConfirm = () => {
		const data = {
			subscription_plan_id: chosenPlan.id,
			term: 1,
		};

		showLoading();

		BillingAPI.postChoosePlan(selectedOrganizationAccount.id, data)
			.then((response) => {
				refreshCurrentSubscriptionPlan();

				if (response.code === 200) {
					openAlertSnackbar(
						`Downgrade successful.\n Your plan will be automatically downgraded to ${
							chosenPlan.name
						} effective from ${moment(currentSubscriptionPlan.end_at).format("DD MMMM YYYY")}.`,
						"success",
						10000
					);

					handleCloseModal();
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleCloseModal = () => {
		closeDowngradeConfirmationModal();
	};

	return (
		<Modal
			show={showDowngradeConfirmationModal}
			onHide={handleCloseModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="downgrade-confirmation-modal"
			centered>
			<Modal.Body>
				{chosenPlan ? (
					<div className="downgrade-confirmation-modal-body">
						<div className="icon-close-wrapper">
							<img src={IconClose} alt="close" className="icon-close" onClick={handleCloseModal} />
						</div>

						<div className="content-wrapper">
							<h1>Downgrade Confirmation</h1>

							<div className="content-container">
								<div className="d-flex justify-content-between align-items-center mb-3">
									<div>
										<div className="text-16">{chosenPlan.name}</div>
										<div className="text-12">Billed Monthly</div>
									</div>
									<div className="plan-pricing">
										<span className="price mr-2">${chosenPlan.monthly_price}</span>
										<span>/ month</span>
									</div>
								</div>

								<hr className={`mt-4 mb-3`} />

								<div className="row align-items-center">
									<div className="col-6 text-12">
										Payable Amount Effective from{" "}
										{moment(currentSubscriptionPlan.end_at).format("DD MMMM YYYY")}
									</div>
									<div className="col-6 text-right price">
										${quotation ? quotation.price : chosenPlan.monthly_price}
									</div>
								</div>
							</div>

							<p className="description">
								This is a monthly subscription plan. You can choose to opt out at any point of time at
								the billing panel.
							</p>

							<p className="warning-description">
								<img src={IconWarningTriangle} alt="warning" className="icon-warning" />
								Please be informed that downgrade will only be applied effective from the next billing
								cycle, {moment(currentSubscriptionPlan.end_at).format("DD MMMM YYYY")}.
							</p>

							<div className="d-flex justify-content-center align-items-center">
								<button className="btn btn-cancel" onClick={handleCloseModal}>
									Cancel
								</button>
								<button className="btn btn-check-out" onClick={handleConfirm}>
									Confirm
								</button>
							</div>
						</div>
					</div>
				) : (
					<div className="d-flex justify-content-center align-items-center mt-5">
						<ReactLoading type={"spinningBubbles"} color="lightgrey" />
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(DowngradeConfirmationModal);
