import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import { ALPHABETS_LIST } from "app/config/alphabetList";
import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import ExhibitorAPI from "app/apis/exhibitor";
import { eventActions } from "app/states/event";
import CreateExhibitorCategoryModal from "app/views/containers/Exhibitor/CreateExhibitorCategoryModal/CreateExhibitorCategoryModal";
import ViewExhibitorCategoryModal from "app/views/containers/Exhibitor/ViewExhibitorCategoryModal copy/ViewExhibitorCategoryModal";
import EventInfo from "app/views/components/EventInfo/EventInfo";

const ExhibitorCategoryListing = ({ history }) => {
	const dispatch = useDispatch();
	const openCreateExhibitorCategoryModal = () => dispatch(eventActions.openCreateExhibitorCategoryModal());
	const refreshExhibitorCategoryListing = (refresh) =>
		dispatch(eventActions.refreshExhibitorCategoryListing(refresh));
	const openViewExhibitorCategoryModal = (exhibitorCategoryId) =>
		dispatch(eventActions.openViewExhibitorCategoryModal(exhibitorCategoryId));

	const { eventId } = useParams();
	const isRefreshExhibitorCategoryListing = useSelector(({ event }) => event.refreshExhibitorCategoryListing);

	const [searchText, setSearchText] = useState("");
	const [exhibitorCategoryListing, setExhibitorCategoryListing] = useState([]);
	const [alphabets, setAlphabets] = useState([]);

	useEffect(() => {
		let params = {
			limit: 750,
			event_id: eventId,
			sort_by: "name",
			sort_order: "asc",
		};

		if (searchText) {
			params["search_string"] = searchText;
		}

		getExhibitorCategoryListing(params);
	}, [searchText]);

	useEffect(() => {
		if (!isRefreshExhibitorCategoryListing) return;

		let params = {
			limit: 750,
			event_id: eventId,
			sort_by: "name",
			sort_order: "asc",
		};

		if (searchText) {
			params["name"] = searchText;
		}

		getExhibitorCategoryListing(params);
		refreshExhibitorCategoryListing(false);
	}, [isRefreshExhibitorCategoryListing]);

	const getExhibitorCategoryListing = (params) => {
		ExhibitorAPI.getExhibitorCategoryListing(params)
			.then((response) => {
				const { exhibitor_categories } = response.data;

				// Set alphabets
				let alphabetsArray = [];
				exhibitor_categories.forEach((channel) => {
					const { name } = channel;
					const firstLetter = name.charAt(0).toUpperCase();

					if (!alphabetsArray.includes(firstLetter)) {
						alphabetsArray.push(firstLetter);
					}
				});

				alphabetsArray.sort();
				setAlphabets(alphabetsArray);
				setExhibitorCategoryListing(exhibitor_categories);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const goToAlphabetSection = () => {
		history.push(`/events/view/${eventId}/exhibitor/categories`);
	};

	// Alphabet list component
	const AlphabetList = () => {
		return (
			<div className="alphabet-list">
				{ALPHABETS_LIST.map((alphabet) => {
					return (
						<HashLink
							key={alphabet}
							to={`#section-${alphabet}`}
							className="alphabet-letter"
							onClick={goToAlphabetSection}>
							{alphabet}
						</HashLink>
					);
				})}
			</div>
		);
	};

	return (
		<EventLayoutContainer>
			<div id="exhibitor-category-listing-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-5">
					<div className="d-flex align-items-center">
						<div>
							<h2 className="title">Exhibitor Category Listing</h2>
							<span className="subtitle">Exhibitors &gt; Category</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="p-3">
					<div className="row">
						<div className="col-10">
							<div className="form-group mb-0">
								<input
									type="text"
									className="form-control"
									name="search"
									placeholder="Search"
									value={searchText}
									onChange={(event) => setSearchText(event.target.value)}
								/>
							</div>
						</div>
						<div className="col-2">
							<button
								type="button"
								className="btn btn-primary h-100"
								onClick={openCreateExhibitorCategoryModal}>
								Add Category
							</button>
						</div>
					</div>
				</section>

				<section id="listing-container" className="p-3">
					<AlphabetList />

					{alphabets.map((alphabet) => {
						return (
							<section key={alphabet} id={`section-${alphabet}`} className="mb-3">
								<label className="alphabet-section-title">{alphabet}</label>
								<div id={`section-${alphabet}-exhibitorCategoryListing`} className="row">
									{exhibitorCategoryListing.map((exhibitorCategory) => {
										const { id, name, description, thumbnail_url } = exhibitorCategory;
										const firstLetter = name.charAt(0).toUpperCase();

										if (alphabet === firstLetter) {
											return (
												<div key={`${id}-${name}`} className="col-4 mb-3">
													<ListingCard
														id={id}
														image={thumbnail_url}
														title={name}
														description={description}
														onClick={() => openViewExhibitorCategoryModal(id)}
													/>
												</div>
											);
										}

										return null;
									})}
								</div>
							</section>
						);
					})}
				</section>

				<CreateExhibitorCategoryModal />
				<ViewExhibitorCategoryModal />
			</div>
		</EventLayoutContainer>
	);
};

// Listing Card component
const ListingCard = ({ image, title, description, onClick }) => {
	return (
		<div className="listing-card" onClick={onClick}>
			<div className="row align-items-center">
				<div className="col-3 pr-0">
					<img src={image} alt="channel" className="img-fluid" />
				</div>
				<div className="col-9">
					<div className="title">{title}</div>
					<div className="desc">{description}</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(ExhibitorCategoryListing);
