import * as types from "./types"

export const setOrganizationId = (organizationId) => ({
    type: types.SET_ORGANIZATION_ID,
    params: {
        organizationId
    }
});

export const setSelectedOrganizationAccount = (organizationAccount) => ({
    type: types.SET_SELECTED_ORGANIZATION_ACCOUNT,
    params: {
        organizationAccount
    }
});

export const refreshOrganizationListing = (refresh) => ({
    type: types.REFRESH_ORGANIZATION_LISTING,
    params: {
        refresh
    }
});