import apiAction from "../services/apiAction";

const BillingAPI = {
	/**
	 * Get subscription plan summary
	 * @param {object} queryParams
	 * {type}
	 */
	getSubscriptionPlanSummaries: (queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/subscription_plans/summaries`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get current subscription plan
	 * @param {int} organization_id
	 */
	getCurrentSubscription: (organization_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/organization/${organization_id}/subscriptions/current`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Choose plan
	 * @param {int} organization_id
	 * @param {object} data
	 */
	postChoosePlan: (organization_id, data) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/organization/${organization_id}/subscriptions`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Terminate subscription
	 * @param {int} organization_id
	 */
	postTerminateSubscription: (organization_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/organization/${organization_id}/subscriptions/current`;

			apiAction
				.delete(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get invoices
	 * @param {int} organization_id
	 */
	getInvoices: (organization_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/organization/${organization_id}/invoices`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get invoices
	 * @param {int} organization_id
	 * @param {object} data
	 */
	getQuotation: (organization_id, data) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/organization/${organization_id}/subscriptions/quotation`;

			apiAction
				.post(url, data)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get pending subscription
	 * @param {int} organization_id
	 */
	getPendingSubscription: (organization_id, data) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/organization/${organization_id}/subscriptions/pending`;

			apiAction
				.get(url, data)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Reactivate subscription
	 * @param {int} organization_id
	 */
	postReactivateSubscription: (organization_id) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/organization/${organization_id}/subscriptions/current/reactive`;

			apiAction
				.put(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},
};

export default BillingAPI;
