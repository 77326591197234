/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import AdsManagerAPI from "app/apis/adsManager";
import "./style.scss";
import { withRouter, useParams } from "react-router-dom";
import IconBack from "app/assets/icon-back-grey.png";
import LocalStorageService from "app/services/localStorageService";
import { ADS_TYPE_SIZE } from "app/config/settings";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import { numberWithCommas } from "app/helper/common";
import SessionStorageService from "app/services/sessionStorageService";
import IconDefaultUser from "app/assets/icon-default-user.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import EventInfo from "app/views/components/EventInfo/EventInfo";
import { ORGANIZATION_ACCOUNT_TYPE } from "app/config/constants";

const MAX_TABLE_ROWS = 10;

const ViewAd = ({ history }) => {
	const { eventId, adsTypeId, adsId } = useParams();
	const viewingAdsType = SessionStorageService.getViewingAdsType();

	const [adDetails, setAdDetails] = useState({
		ad_name: "",
		ad_description: "",
		ad_banner_url: "",
		ad_url: "",
		ad_status: "",
		ad_type_name: "",
		total_clicks: 0,
		unique_clicks: 0,
		total_impressions: 0,
		unique_impressions: 0,
	});

	const [organizationDetails, setOrganizationDetails] = useState({
		name: "",
		thumbnail: "",
		type: null,
	});

	const [adPerformances, setAdPerformances] = useState([]);
	const [page, setPage] = useState(1);
	const [totalPageCount, setTotalPageCount] = useState(0);

	const [sortBy, setSortBy] = useState("");
	const [sortOrdering, setSortOrdering] = useState({
		created_date: "asc",
		unique_impressions: "asc",
		total_impressions: "asc",
		unique_clicks: "asc",
		total_clicks: "asc",
	});

	// Get ad details
	useEffect(() => {
		AdsManagerAPI.getAdDetails(adsId)
			.then((response) => {
				const {
					name,
					description,
					banner_url,
					ad_url,
					ad_type_name,
					status,
					total_clicks,
					total_impressions,
					org_thumbnail_url,
					org_name,
					org_acct_name,
					org_acct_type,
					unique_impressions,
					unique_clicks,
				} = response.data;

				setAdDetails({
					...adDetails,
					ad_name: name,
					ad_description: description,
					ad_url: ad_url,
					ad_banner_url: banner_url,
					ad_status: status,
					ad_type_name: ad_type_name,
					total_clicks: total_clicks,
					total_impressions: total_impressions,
					unique_impressions: unique_impressions,
					unique_clicks: unique_clicks,
				});

				setOrganizationDetails({
					name: org_acct_name,
					thumbnail: org_thumbnail_url,
					type: org_acct_type,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	// Get ad performance breakdown
	useEffect(() => {
		let params = {
			page: page,
			limit: MAX_TABLE_ROWS,
		};

		if (sortBy) {
			params["sort_by"] = sortBy;
			params["sort_order"] = sortOrdering[sortBy];
		}

		AdsManagerAPI.getAdDetailsPeformanceBreakdown(adsId, params)
			.then((response) => {
				const { ad_performance_breakdown, paging } = response.data;
				const { last_page } = paging;

				setAdPerformances(ad_performance_breakdown);
				setTotalPageCount(last_page);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [page]);

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	const handleExport = () => {
		AdsManagerAPI.getAdDetailsPeformanceBreakdownExport(adsId)
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "ad_performance_breakdown.csv");
				document.body.appendChild(link);
				link.click();
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleSorting = (sortByColumn) => {
		let sortOrder = "asc";

		sortOrder = sortOrdering[sortByColumn] === "asc" ? "desc" : "asc";

		setSortOrdering({
			...sortOrdering,
			[sortByColumn]: sortOrder,
		});

		setSortBy(sortByColumn);

		let params = {
			page: 1,
			limit: MAX_TABLE_ROWS,
			sort_by: sortByColumn,
			sort_order: sortOrder,
		};

		AdsManagerAPI.getAdDetailsPeformanceBreakdown(adsId, params)
			.then((response) => {
				const { ad_performance_breakdown, paging } = response.data;
				const { last_page } = paging;

				setAdPerformances(ad_performance_breakdown);
				setTotalPageCount(last_page);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<EventLayoutContainer>
			<section className="page-header d-flex justify-content-between align-items-center mb-4">
				<div className="d-flex align-items-center">
					<div className="btn-back" onClick={() => history.goBack()}>
						<img src={IconBack} alt="back" className="img-fluid" />
					</div>
					<div>
						<h2 className="title">View Ad</h2>
						<span className="subtitle">Ads Manager &gt; View/Manage Ads &gt; View Ad</span>
					</div>
				</div>
				<EventInfo />
			</section>

			{adDetails && (
				<section className="ad-details-container my-3">
					<div className="row mb-4">
						<div className="col-6">
							<img src={adDetails.ad_banner_url} alt="ad banner" className="img-fluid mb-3" />
							<div className="ad-name mb-2">{adDetails.ad_name}</div>
							<div className="ad-desc">{adDetails.ad_description}</div>
						</div>
						<div className="col-6">
							<div className="row">
								<div className="col-6">
									<div className="stat-card">
										<div className="title">Unique Impressions</div>
										<div className="desc">Impressions By Unique Users</div>
										<div className="value">{numberWithCommas(adDetails.unique_impressions)}</div>
									</div>
								</div>
								<div className="col-6">
									<div className="stat-card">
										<div className="title">Total Impressions</div>
										<div className="desc">Accumulated Impressions</div>
										<div className="value">{numberWithCommas(adDetails.total_impressions)}</div>
									</div>
								</div>
								<div className="col-6">
									<div className="stat-card">
										<div className="title">Unique Clicks</div>
										<div className="desc">Ad Clicks By Unique Users</div>
										<div className="value">{numberWithCommas(adDetails.unique_clicks)}</div>
									</div>
								</div>
								<div className="col-6">
									<div className="stat-card">
										<div className="title">Total Clicks</div>
										<div className="desc">Accumulated Ad Clicks</div>
										<div className="value">{numberWithCommas(adDetails.total_clicks)}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="ad-owner-wrapper">
						<h3 className="ad-owner-title">Ad Owner:</h3>
						<div className="d-flex">
							<div className="organization-wrapper">
								<img
									src={organizationDetails.thumbnail || IconDefaultUser}
									alt="thumbnail"
									className="org-thumbnail"
								/>
								<div>
									<div className="org-name">{organizationDetails.name}</div>
									<div className="org-desc">
										{organizationDetails.type
											? ORGANIZATION_ACCOUNT_TYPE[organizationDetails.type]
											: ""}
									</div>
								</div>
							</div>
							<div className="other-details-wrapper">
								<div className="row">
									<div className="col-3">
										<span className="label">Ad Type/Status: </span>
									</div>
									<div className="col-9">
										{adDetails.ad_type_name && (
											<>
												<span className="ad-type">
													{adDetails.ad_type_name} (
													{ADS_TYPE_SIZE[viewingAdsType.code]["display"]})
												</span>
												<span
													className={`ad-status ${
														adDetails.ad_status === 1
															? "text-active-ads"
															: "text-inactive-ads"
													}`}>
													{adDetails.ad_status === 1 ? "Active" : "Inactive"}
												</span>
											</>
										)}
									</div>
								</div>
								<div className="row">
									<div className="col-3">
										<span className="label">Ad hyperlink: </span>
									</div>
									<div className="col-9">
										<a
											href={adDetails.ad_url}
											target="_blank"
											rel="noreferrer"
											className="ad-hyperlink">
											{adDetails.ad_url}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row mt-4">
						<div className="col-9"></div>
						<div className="col-3">
							<button
								type="button"
								className="btn btn-primary w-100"
								onClick={() =>
									history.push(`/events/view/${eventId}/ads/type/${adsTypeId}/edit/${adsId}`)
								}>
								Edit
							</button>
						</div>
					</div>
				</section>
			)}

			<section className="ad-performance-section my-3">
				<h3>Ad Performance Breakdown</h3>
				<div className="ad-performance-container">
					<table className="table table-hover">
						<thead>
							<tr>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("created_date")}>
									Date{" "}
									<FontAwesomeIcon
										icon={sortOrdering["created_date"] === "desc" ? faChevronDown : faChevronUp}
									/>
								</th>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("unique_impressions")}>
									Unique Impressions{" "}
									<FontAwesomeIcon
										icon={
											sortOrdering["unique_impressions"] === "desc" ? faChevronDown : faChevronUp
										}
									/>
								</th>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("total_impressions")}>
									Total Impressions{" "}
									<FontAwesomeIcon
										icon={
											sortOrdering["total_impressions"] === "desc" ? faChevronDown : faChevronUp
										}
									/>
								</th>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("unique_clicks")}>
									Unique Clicks{" "}
									<FontAwesomeIcon
										icon={sortOrdering["unique_clicks"] === "desc" ? faChevronDown : faChevronUp}
									/>
								</th>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("total_clicks")}>
									Total Clicks{" "}
									<FontAwesomeIcon
										icon={sortOrdering["total_clicks"] === "desc" ? faChevronDown : faChevronUp}
									/>
								</th>
							</tr>
						</thead>
						<tbody>
							{adPerformances.map((adPerformance, index) => {
								const {
									created_date,
									unique_impressions,
									total_impressions,
									total_clicks,
									unique_clicks,
								} = adPerformance;

								return (
									<tr key={index}>
										<td className="table-cell">{created_date}</td>
										<td className="table-cell">{unique_impressions || 0}</td>
										<td className="table-cell">{total_impressions || 0}</td>
										<td className="table-cell">{unique_clicks || 0}</td>
										<td className="table-cell">{total_clicks || 0}</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					<div className="d-flex align-items-center justify-content-center w-100">
						<AppPagination pageCount={totalPageCount} handlePageChange={handlePageChange} />
					</div>

					<span className="text-export-link" onClick={handleExport}>
						Export
					</span>
				</div>
			</section>
		</EventLayoutContainer>
	);
};

export default withRouter(ViewAd);
