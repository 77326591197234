import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import ExhibitorAPI from "app/apis/exhibitor";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";

const StoreContainer = ({ history, storeDetails }) => {
	let { roomId } = useParams();

	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const [inputData, setInputData] = useState({
		store_url: "",
	});
	const [deliveryMethods, setDeliveryMethods] = useState([]);
	const [paymentMethods, setPaymentMethods] = useState([]);

	// Get store settings
	useEffect(() => {
		if (!storeDetails) return;

		const { store_url, supported_delivery_methods, supported_payment_methods } = storeDetails;

		setInputData({
			store_url: store_url,
		});

		setDeliveryMethods(supported_delivery_methods);
		setPaymentMethods(supported_payment_methods);
	}, [storeDetails]);

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleSaveStoreSettings = async (event) => {
		event.preventDefault();

		let storeId = storeDetails.id;
		let formData = new FormData();
		formData.append("store_url", inputData.store_url);
		
		deliveryMethods.forEach((deliveryMethod) => {
			if (deliveryMethod.selected === 1) {
				formData.append("supported_delivery_methods[]", JSON.stringify(deliveryMethod));
			}
		});

		paymentMethods.forEach((paymentMethod) => {
			if (paymentMethod.selected === 1) {
				formData.append("supported_payment_methods[]", JSON.stringify(paymentMethod));
			}
		})

		ExhibitorAPI.postUpdateEventRoomStore(storeId, formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					openAlertSnackbar("Store Details Updated", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handlePaymentMethodOnChange = (id) => {
		let paymentMethodsCopy = [...paymentMethods];
		paymentMethodsCopy.forEach((paymentMethod) => {
			if (paymentMethod.id === id) {
				paymentMethod.selected = !paymentMethod.selected ? 1 : 0;
			}
		});
		
		setPaymentMethods(paymentMethodsCopy);
	};

	const handleDeliveryMethodOnChange = (id) => {
		let deliveryMethodsCopy = [...deliveryMethods];
		deliveryMethodsCopy.forEach((deliveryMethod) => {
			if (deliveryMethod.id === id) {
				deliveryMethod.selected = !deliveryMethod.selected ? 1 : 0;
			}
		});
		
		setDeliveryMethods(deliveryMethodsCopy);
	}

	return (
		<section className="tab-panel-container store-container mb-4">
			<form method="post" onSubmit={handleSaveStoreSettings}>
				<div className="row mb-3">
					<div className="col-12">
						<div className="form-group">
							<label htmlFor="store_url">Store URL</label>
							<input
								id="store_url"
								type="text"
								className="form-control"
								aria-describedby="store_url"
								name="store_url"
								value={inputData.store_url}
								onChange={handleInputOnChange}
								required
							/>
						</div>
					</div>

					<div className="col-12">
						<div className="form-group mb-0">
							<label>Supported Payment Method</label>
							<div className="row">
								{paymentMethods.map((paymentMethod) => {
									const { id, code, name, selected } = paymentMethod;

									return (
										<div key={`payment-method-${id}`} className="col-6 mb-3">
											<div className="form-check form-group checkbox-wrapper">
												<input
													className="form-check-input custom-outline-checkbox"
													type="checkbox"
													checked={selected === 1 ? true : false}
													id={code}
													onChange={() => handlePaymentMethodOnChange(id)}
												/>
												<label
													className="form-check-label custom-checkbox-label ml-3 mb-0"
													htmlFor={code}>
													{name}
												</label>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>

					<div className="col-12">
						<div className="form-group mb-0">
							<label>Supported Delivery Method</label>
							<div className="row">
								{deliveryMethods.map((deliveryMethod) => {
									const { id, code, name, selected } = deliveryMethod;

									return (
										<div key={`delivery-method-${id}`} className="col-6 mb-3">
											<div className="form-check form-group checkbox-wrapper">
												<input
													className="form-check-input custom-outline-checkbox"
													type="checkbox"
													checked={selected === 1 ? true : false}
													id={code}
													onChange={() => handleDeliveryMethodOnChange(id)}
												/>
												<label
													className="form-check-label custom-checkbox-label ml-3 mb-0"
													htmlFor={code}>
													{name}
												</label>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-10"></div>
					<div className="col-2">
						<button type="submit" className="btn btn-primary w-100">
							Save
						</button>
					</div>
				</div>
			</form>
		</section>
	);
};

export default withRouter(StoreContainer);
