import * as types from "./types"

// Custom modal
export const openCustomModal = () => ({
    type: types.OPEN_CUSTOM_MODAL,
});

export const closeCustomModal = () => ({
    type: types.CLOSE_CUSTOM_MODAL,
});

// Live toggle modal
export const openLiveToggleModal = () => ({
    type: types.OPEN_LIVE_TOGGLE_MODAL,
});

export const closeLiveToggleModal = () => ({
    type: types.CLOSE_LIVE_TOGGLE_MODAL,
});

// Loading
export const showLoading = () => ({
    type: types.SHOW_LOADING,
});

export const hideLoading = () => ({
    type: types.HIDE_LOADING,
});

// Profile modal
export const openProfileModal = () => ({
    type: types.OPEN_PROFILE_MODAL,
});

export const closeProfileModal = () => ({
    type: types.CLOSE_PROFILE_MODAL,
});

// Forgot password modal
export const openForgotPasswordModal = () => ({
    type: types.OPEN_FORGOT_PASSWORD_MODAL,
});

export const closeForgotPasswordModal = () => ({
    type: types.CLOSE_FORGOT_PASSWORD_MODAL,
});

/**
 * Alert snackbar
 */
export const openAlertSnackbar = (message, variant, duration = 3000) => ({
    type: types.OPEN_ALERT_SNACKBAR,
    params: {
        message, variant, duration
    }
});

export const closeAlertSnackbar = () => ({
    type: types.CLOSE_ALERT_SNACKBAR
});

/**
 * User logout
 */
export const logoutUserAndResetStates = () => ({
    type: "USER_LOGOUT"
});