/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams, Redirect } from "react-router-dom";
import EventAPI from "app/apis/event";
import ChannelSessionAPI from "app/apis/channelSession";
import moment from "moment";

const SessionControlRoom = ({ history }) => {
	const { eventId } = useParams();

	const todayDate = moment().format("YYYY-MM-DD");

	const [firstChannelId, setFirstChannelId] = useState(null);
	const [firstChannelSessionId, setFirstChannelSessionId] = useState(null);
	const [doneLoaded, setDoneLoaded] = useState(false);
	const [hasNoChannel, setHasNoChannel] = useState(false);

	useEffect(() => {
		let params = {
			limit: 1,
			event_id: eventId,
			sort_by: "name",
			sort_order: "asc",
		};

		EventAPI.getEventChannelListing(params)
			.then((response) => {
				const { event_channels } = response.data;

				if (event_channels.length > 0) {
					setFirstChannelId(event_channels[0].id);
				}
				else {
					setHasNoChannel(true);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, [eventId]);

	// Get session listing
	useEffect(() => {
		if (!firstChannelId) return;

		let params = {
			channel_id: firstChannelId,
			limit: 1,
			search_string: todayDate,
		};

		ChannelSessionAPI.getChannelSessionListing(params)
			.then((response) => {
				const { event_channel_sessions } = response.data;

				if (event_channel_sessions.length > 0) {
					setFirstChannelSessionId(event_channel_sessions[0].id);
				}

				setDoneLoaded(true);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [firstChannelId]);

	return (
		<>
			{doneLoaded && firstChannelId && firstChannelSessionId && (
				<Redirect to={`/events/view/${eventId}/channels/${firstChannelId}/session/${firstChannelSessionId}`} />
			)}

			{doneLoaded && !firstChannelSessionId && (
				<Redirect
					to={`/events/view/${eventId}/channelSessionControlRoom/channels/${firstChannelId}/session/add`}
				/>
			)}

			{hasNoChannel &&
				<Redirect
					to={`/events/view/${eventId}/channelSessionControlRoom/channels/${firstChannelId}/session/add`}
				/>
			}
		</>
	);
};

export default withRouter(SessionControlRoom);
