export const OPEN_CREATE_CHANNEL_MODAL = "event/OPEN_CREATE_CHANNEL_MODAL";
export const CLOSE_CREATE_CHANNEL_MODAL = "event/CLOSE_CREATE_CHANNEL_MODAL";

export const OPEN_CREATE_QNA_MODAL = "event/OPEN_CREATE_QNA_MODAL";
export const CLOSE_CREATE_QNA_MODAL = "event/CLOSE_CREATE_QNA_MODAL";
export const REFRESH_QNA_LISTING = "event/REFRESH_QNA_LISTING";

export const OPEN_EDIT_QNA_MODAL = "event/OPEN_EDIT_QNA_MODAL";
export const CLOSE_EDIT_QNA_MODAL = "event/CLOSE_EDIT_QNA_MODAL";

export const OPEN_CREATE_POLL_MODAL = "event/OPEN_CREATE_POLL_MODAL";
export const CLOSE_CREATE_POLL_MODAL = "event/CLOSE_CREATE_POLL_MODAL";
export const REFRESH_POLL_LISTING = "event/REFRESH_POLL_LISTING";

export const OPEN_EDIT_POLL_MODAL = "event/OPEN_EDIT_POLL_MODAL";
export const CLOSE_EDIT_POLL_MODAL = "event/CLOSE_EDIT_POLL_MODAL";

export const OPEN_CREATE_EXHIBITOR_CATEGORY_MODAL = "event/OPEN_CREATE_EXHIBITOR_CATEGORY_MODAL";
export const CLOSE_CREATE_EXHIBITOR_CATEGORY_MODAL = "event/CLOSE_CREATE_EXHIBITOR_CATEGORY_MODAL";
export const REFRESH_EXHIBITOR_CATEGORY_LISTING = "event/REFRESH_EXHIBITOR_CATEGORY_LISTING";

export const OPEN_VIEW_EXHIBITOR_CATEGORY_MODAL = "event/OPEN_VIEW_EXHIBITOR_CATEGORY_MODAL";
export const CLOSE_VIEW_EXHIBITOR_CATEGORY_MODAL = "event/CLOSE_VIEW_EXHIBITOR_CATEGORY_MODAL";

export const REFRESH_EXHIBITOR_ROOM_DETAILS = "event/REFRESH_EXHIBITOR_ROOM_DETAILS";

export const SET_SELECTED_CHANNEL_FOR_SESSION_SIDEBAR = "event/SET_SELECTED_CHANNEL_FOR_SESSION_SIDEBAR";
export const SET_SELECTED_DATE_FOR_SESSION_SIDEBAR = "event/SET_SELECTED_DATE_FOR_SESSION_SIDEBAR";

// Event Details
export const SET_EVENT_DETAILS = "event/SET_EVENT_DETAILS";
export const REFRESH_EVENT_DETAILS = "event/REFRESH_EVENT_DETAILS";

// Landing page editor - speaker modal
export const OPEN_ADD_SPEAKER_MODAL = "event/OPEN_ADD_SPEAKER_MODAL";
export const CLOSE_ADD_SPEAKER_MODAL = "event/CLOSE_ADD_SPEAKER_MODAL";
export const OPEN_EDIT_SPEAKER_MODAL = "event/OPEN_EDIT_SPEAKER_MODAL";
export const CLOSE_EDIT_SPEAKER_MODAL = "event/CLOSE_EDIT_SPEAKER_MODAL";
export const SET_VIEWING_SPEAKER = "event/SET_VIEWING_SPEAKER";
export const REFRESH_SPEAKER_LIST = "event/REFRESH_SPEAKER_LIST";

// Landing page editor - event highlights modal
export const OPEN_ADD_EVENT_HIGHLIGHTS_MODAL = "event/OPEN_ADD_EVENT_HIGHLIGHTS_MODAL";
export const CLOSE_ADD_EVENT_HIGHLIGHTS_MODAL = "event/CLOSE_ADD_EVENT_HIGHLIGHTS_MODAL";
export const OPEN_EDIT_EVENT_HIGHLIGHTS_MODAL = "event/OPEN_EDIT_EVENT_HIGHLIGHTS_MODAL";
export const CLOSE_EDIT_EVENT_HIGHLIGHTS_MODAL = "event/CLOSE_EDIT_EVENT_HIGHLIGHTS_MODAL";
export const SET_VIEWING_EVENT_HIGHLIGHTS = "event/SET_VIEWING_EVENT_HIGHLIGHTS";
export const REFRESH_EVENT_HIGHLIGHTS_LIST = "event/REFRESH_EVENT_HIGHLIGHTS_LIST";

// Landing page editor - event partner modal
export const OPEN_ADD_EVENT_PARTNER_MODAL = "event/OPEN_ADD_EVENT_PARTNER_MODAL";
export const CLOSE_ADD_EVENT_PARTNER_MODAL = "event/CLOSE_ADD_EVENT_PARTNER_MODAL";
export const OPEN_EDIT_EVENT_PARTNER_MODAL = "event/OPEN_EDIT_EVENT_PARTNER_MODAL";
export const CLOSE_EDIT_EVENT_PARTNER_MODAL = "event/CLOSE_EDIT_EVENT_PARTNER_MODAL";
export const SET_VIEWING_EVENT_PARTNER = "event/SET_VIEWING_EVENT_PARTNER";
export const SET_CURRENT_EVENT_PARTNER_KEY = "event/SET_CURRENT_EVENT_PARTNER_KEY";
export const REFRESH_EVENT_PARTNERS_LIST = "event/REFRESH_EVENT_PARTNERS_LIST";

// Publish event modal
export const SET_IS_EVENT_PUBLISHED = "event/SET_IS_EVENT_PUBLISHED";
export const SET_EVENT_PUBLISH_ACTION = "event/SET_EVENT_PUBLISH_ACTION";
export const SET_CURRENT_EVENT_STATUS = "event/SET_CURRENT_EVENT_STATUS";

export const OPEN_PUBLISH_EVENT_MODAL = "event/OPEN_PUBLISH_EVENT_MODAL";
export const CLOSE_PUBLISH_EVENT_MODAL = "event/CLOSE_PUBLISH_EVENT_MODAL";

export const OPEN_PAUSE_END_EVENT_MODAL = "event/OPEN_PAUSE_END_EVENT_MODAL";
export const CLOSE_PAUSE_END_EVENT_MODAL = "event/CLOSE_PAUSE_END_EVENT_MODAL";

export const OPEN_REPUBLISH_EVENT_MODAL = "event/OPEN_REPUBLISH_EVENT_MODAL";
export const CLOSE_REPUBLISH_EVENT_MODAL = "event/CLOSE_REPUBLISH_EVENT_MODAL";

// Session control create channel modal
export const OPEN_SESSION_CONTROL_CREATE_CHANNEL_MODAL = "event/OPEN_SESSION_CONTROL_CREATE_CHANNEL_MODAL";
export const CLOSE_SESSION_CONTROL_CREATE_CHANNEL_MODAL = "event/CLOSE_SESSION_CONTROL_CREATE_CHANNEL_MODAL";

// Refresh session listing in sidebar
export const REFRESH_SESSION_CONTROL_LISTING_COUNT = "event/REFRESH_SESSION_CONTROL_LISTING_COUNT";