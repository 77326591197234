import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const EventInfo = () => {
	const eventDetails = useSelector(({ event }) => event.eventDetails);

	return (
		<>
			{eventDetails && (
				<div>
					<h4 className="event-title">{eventDetails.name}</h4>
					<span className="event-datetime">
						{moment(eventDetails.start_at).format("DD MMMM")} - {moment(eventDetails.end_at).format("DD MMMM")} |{" "}
						{moment(eventDetails.start_at).format("h:mmA")} - {moment(eventDetails.end_at).format("h:mmA")}
					</span>
				</div>
			)}
		</>
	);
};

export default EventInfo;
