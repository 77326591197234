import React from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { commonActions } from "app/states/common";
import { billingActions } from "app/states/billings";
import IconClose from "app/assets/icon-close.png";
import moment from "moment";
import BillingAPI from "app/apis/billings";

const ReactivatePlanModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeReactivatePlanModal = () => dispatch(billingActions.closeReactivatePlanModal());
	const openAlertSnackbar = (message, variant, duration) =>
		dispatch(commonActions.openAlertSnackbar(message, variant, duration));
	const refreshCurrentSubscriptionPlan = () => dispatch(billingActions.refreshCurrentSubscriptionPlan());
	const refreshBillingDashboard = () => dispatch(billingActions.refreshBillingDashboard());
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());

	const showReactivatePlanModal = useSelector(({ billing }) => billing.showReactivatePlanModal);
	const currentSubscriptionPlan = useSelector(({ billing }) => billing.currentSubscriptionPlan);
	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);

	const handleCancel = () => {
		closeReactivatePlanModal();
	};

	const handleConfirm = () => {
		showLoading();

		BillingAPI.postReactivateSubscription(selectedOrganizationAccount.id)
			.then((response) => {
				if (response.code === 200) {
					// Refresh data
					refreshCurrentSubscriptionPlan();
					refreshBillingDashboard();

					openAlertSnackbar("Your subscription has been successfully reactivated.", "success", 10000);
					closeReactivatePlanModal();
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	return (
		<Modal
			show={showReactivatePlanModal}
			onHide={closeReactivatePlanModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="reactivate-plan-modal"
			dialogClassName="pause-end-event-dialog"
			centered>
			<Modal.Body>
				<div className="reactivate-plan-modal-body">
					<div className="icon-close-wrapper">
						<img src={IconClose} alt="close" className="icon-close" onClick={closeReactivatePlanModal} />
					</div>

					<div className="w-50">
						<h3 className="mb-5">Reactivation Confirmation</h3>

						<p>
							Your subscription has been terminated. <br /> The plan remains active until{" "}
							{moment(currentSubscriptionPlan.end_at).format("DD MMMM YYYY")}.
						</p>

						<p className="mb-5">Would you like to reactivate your subscription ?</p>

						<div className="d-flex justify-content-around align-items-center">
							<button className="btn btn-outline-primary" onClick={handleCancel}>
								Cancel
							</button>
							<button className="btn btn-primary" onClick={handleConfirm}>
								Confirm
							</button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(ReactivatePlanModal);
