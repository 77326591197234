import apiAction from "../services/apiAction"

const VisitorAPI = {

    /**
     * Get visitor listing
     * @param {object} queryParams 
     * {organization_id, page, limit, search_string}
     */
    getVisitorListing: (queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/visitors`;
            
            apiAction.get(url, queryParams)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get visitor detail
     * @param {int} visitor_id 
     */
    getVisitorDetail: (visitor_id) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/visitors/${visitor_id}`;
            
            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

};

export default VisitorAPI;
