import apiAction from "../services/apiAction";

const AuthenticationAPI = {
	postLogin: (email, password) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/users/login`;

			const data = {
				email: email,
				password: password,
			};

			apiAction
				.post(url, data)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	postRegister: (data) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = "/users";

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	postForgotPassword: (email) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/users/forgotpassword`;

			const data = {
				email: email,
			};

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	postResetPassword: (email, token, newPassword) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/users/resetpassword`;

			const data = {
				email: email,
				token: token,
				new_password: newPassword,
			};

			apiAction
				.patch(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	getIsRegistered: (email) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/users/isRegistered`;

			const data = {
				email: email,
			}

			apiAction
				.get(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	}
};

export default AuthenticationAPI;
