import React, { useState, useEffect } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "app/states/event";
import IconClose from "app/assets/icon-close.png";
import ChannelSessionAPI from "app/apis/channelSession";
import { withRouter } from "react-router-dom";
import { commonActions } from "app/states/common";

const EditQnaModal = ({ history }) => {

	const dispatch = useDispatch();
	const closeEditQnaModal = () => dispatch(eventActions.closeEditQnaModal());
	const refreshQnaListing = (refresh) => dispatch(eventActions.refreshQnaListing(refresh));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showEditQnaModal = useSelector(({ event }) => event.showEditQnaModal);
	const viewQuestionId = useSelector(({ event }) => event.viewQuestionId);

	const [question, setQuestion] = useState("");
	const [answer, setAnswer] = useState("");
	const [inputAnswer, setInputAnswer] = useState("");
	const [answerId, setAnswerId] = useState(null);
	const [updatedQuestion, setUpdatedQuestion] = useState(false);
	const [updatedAnswer, setUpdatedAnswer] = useState(false);

	useEffect(() => {
		if (viewQuestionId) {
			ChannelSessionAPI.getQnaDetails(viewQuestionId)
				.then((response) => {
					setQuestion(response.data.question);
					setAnswer(response.data.answer);
					setInputAnswer(response.data.answer);
					setAnswerId(response.data.answer_id);
					setUpdatedAnswer(false);
					setUpdatedQuestion(false);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, [viewQuestionId]);

	useEffect(() => {
		// If the qna has no answer, it should updated question only
		if (!answer && updatedQuestion) {
			refreshQnaListing(true);
			closeEditQnaModal();
		}
		// If the qna has answer, it should have updated question and answer together
		else if (answer && updatedQuestion && updatedAnswer) {
			refreshQnaListing(true);
			closeEditQnaModal();
		}

	}, [updatedQuestion, updatedAnswer]);

	const handleUpdateQna = (event) => {
		event.preventDefault();

		updateQuestion();

		if (answer) {
			updateAnswer();
		}
	};

	const updateQuestion = () => {
		const data = {
			question: question,
		};

		ChannelSessionAPI.postUpdateQuestion(viewQuestionId, data)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					setUpdatedQuestion(true);
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	}

	const updateAnswer = () => {
		const data = {
			answer: inputAnswer,
		};

		ChannelSessionAPI.postUpdateAnswer(viewQuestionId, answerId, data)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					setUpdatedAnswer(true);
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	}

	return (
		<Modal
			show={showEditQnaModal}
			onHide={closeEditQnaModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="edit-qna-modal"
			centered>
			<Modal.Body>
				<div className="edit-qna-modal-body">
					<div className="d-flex justify-content-end">
						<img src={IconClose} alt="close" className="icon-close" onClick={closeEditQnaModal} />
					</div>

					<h3>Edit Question</h3>

					<form method="post" onSubmit={handleUpdateQna}>
						<div className="form-group">
							<label htmlFor="question">Question</label>
							<textarea
								id="question"
								className="form-control"
								aria-describedby="question"
								name="question"
								value={question}
								onChange={(event) => setQuestion(event.target.value)}
								required
								rows={3}
							/>
						</div>

						{answer && (
							<div className="form-group">
								<label htmlFor="answer">Answer</label>
								<textarea
									id="answer"
									className="form-control"
									aria-describedby="answer"
									name="answer"
									value={inputAnswer}
									onChange={(event) => setInputAnswer(event.target.value)}
									required
									rows={5}
								/>
							</div>
						)}

						<button type="submit" className="btn btn-primary w-100 mt-3">
							Save Changes
						</button>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(EditQnaModal);
