/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { billingActions } from "app/states/billings";
import IconSuccess from "app/assets/icon-success-image.png";
import { withRouter } from "react-router-dom";

const PaymentSuccessModal = ({ history }) => {
	const dispatch = useDispatch();
	const closePaymentSuccessModal = () => dispatch(billingActions.closePaymentSuccessModal());
	const setChosenPlan = (chosenPlan) => dispatch(billingActions.setChosenPlan(chosenPlan));

	const showPaymentSuccessModal = useSelector(({ billing }) => billing.showPaymentSuccessModal);
	const chosenPlan = useSelector(({ billing }) => billing.chosenPlan);
	const selectedPricingMode = useSelector(({ billing }) => billing.selectedPricingMode);

	const handleClose = () => {
		setChosenPlan(null);
		closePaymentSuccessModal();
	}

	return (
		<Modal
			show={showPaymentSuccessModal}
			onHide={handleClose}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="payment-success-modal"
			centered>
			<Modal.Body>
				{chosenPlan ? (
					<div className="payment-success-modal-body">
						<div className="content-wrapper">
							<h1>Payment Successful</h1>

							<img src={IconSuccess} alt="success" className="img-success" />

							<div className="text-success-subscribed">You have successfully subscribed to:</div>

							<div className="plan-container">
								<div className="text-16 mb-3">{chosenPlan.name}</div>
								<div className="plan-price">
									<span className="price mr-2">
										$
										{selectedPricingMode === "yearly"
											? chosenPlan.discounted_monthly_price
											: chosenPlan.monthly_price}
									</span>
									<span>/ month</span>
								</div>
							</div>

							<button className="btn btn-close" onClick={handleClose}>
								Close
							</button>
						</div>
					</div>
				) : (
					handleClose()
				)}
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(PaymentSuccessModal);
