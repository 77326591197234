/* eslint-disable react-hooks/exhaustive-deps */
import OrganizationAPI from "app/apis/organization";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import LayoutContainer from "../../containers/LayoutContainer/LayoutContainer";
import "./style.scss";
import IconRemove from "app/assets/icon-close.png";
import { withRouter } from "react-router-dom";
import CustomModal from "app/views/components/CustomModal/CustomModal";
import { commonActions } from "app/states/common";
import { ACCOUNT_TYPES, ACCOUNT_INVITATION_STATUS } from "app/config/settings";
import InviteMemberRow from "app/views/components/InviteMemberRow/InviteMemberRow";
import IconEditWhite from "app/assets/icon-edit-white.png";
import IconDeleteGrey from "app/assets/icon-delete-grey.png";
import IconDelete from "app/assets/icon-delete-red.png";
import LocalStorageService from "app/services/localStorageService";
import { organizationActions } from "app/states/organization";
import IconDefaultUser from "app/assets/icon-default-user.png";
import { validateEmail } from "app/helper/validation";

const ProfileSettings = () => {
	const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();

	const dispatch = useDispatch();
	const openCustomModal = () => dispatch(commonActions.openCustomModal());
	const closeCustomModal = () => dispatch(commonActions.closeCustomModal());
	const refreshOrganizationListing = (refresh) => dispatch(organizationActions.refreshOrganizationListing(refresh));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setSelectedOrganizationAccount = (organizationAccount) =>
		dispatch(organizationActions.setSelectedOrganizationAccount(organizationAccount));

	const [organizationDetail, setOrganizationDetail] = useState({
		name: "",
		email: "",
		logo_url: "",
		pic_name: "",
		pic_email: "",
		pic_phone_no: "",
	});

	const [uploadedLogo, setUploadedLogo] = useState(null);

	const [emailInvite, setEmailInvite] = useState("");
	const [teamMembers, setTeamMembers] = useState([]);
	const [newTeamMembers, setNewTeamMembers] = useState([]);
	const [openModalName, setOpenModalName] = useState(null);
	const [emailToBeRemoved, setEmailToBeRemoved] = useState(null);
	const [emailIndexToBeRemoved, setEmailIndexToBeRemoved] = useState(null);
	const [updateCachedSelectedOrganization, setUpdateCachedSelectedOrganization] = useState(0);

	const organizationId = useSelector(({ organization }) => organization.organizationId);

	const logoRef = useRef();
	const inviteFormRef = useRef();

	useEffect(() => {
		if (organizationId || (selectedOrganizationAccount && selectedOrganizationAccount.id)) {
			getOrganizationDetail();
		}
	}, [organizationId]);

	// Get latest organizationDetail and update cached selected organization details
	useEffect(() => {
		if (updateCachedSelectedOrganization > 0) {
			getOrganizationDetail();
		}
	}, [updateCachedSelectedOrganization]);

	const getOrganizationDetail = () => {
		OrganizationAPI.getOrganizationDetail(selectedOrganizationAccount.id)
			.then((response) => {
				const { name, email, logo_url, pic_name, pic_email, pic_phone_no, invited_list } = response.data;

				const detail = {
					name: name,
					email: email,
					logo_url: logo_url,
					pic_name: pic_name,
					pic_email: pic_email,
					pic_phone_no: pic_phone_no,
				};

				setOrganizationDetail(detail);
				setTeamMembers(invited_list);

				// Update local storage data
				if (updateCachedSelectedOrganization > 0) {
					const updatedInfo = {
						...selectedOrganizationAccount,
						name: name,
						logo_url: logo_url,
					};
					LocalStorageService.setSelectedOrganizationAccount(updatedInfo);
					setSelectedOrganizationAccount(updatedInfo);
					window.dispatchEvent(new Event("storage"));
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleInputOnChange = (event) => {
		setOrganizationDetail({ ...organizationDetail, [event.target.name]: event.target.value });
	};

	const handleChangeLogo = (event) => {
		setUploadedLogo(event.target.files[0]);
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleDeleteLogo = () => {
		setUploadedLogo(null);
	};

	const handleSaveProfileSettings = (event) => {
		event.preventDefault();

		let formData = new FormData();

		if (uploadedLogo) {
			formData.append("logo", uploadedLogo);
		}

		formData.append("name", organizationDetail.name);
		formData.append("email", organizationDetail.email);
		formData.append("pic_name", organizationDetail.pic_name);
		formData.append("pic_phone_no", organizationDetail.pic_phone_no);
		formData.append("pic_email", organizationDetail.pic_email);

		OrganizationAPI.postUpdateOrganization(selectedOrganizationAccount.id, formData)
			.then((response) => {
				console.log(response);
				const { code } = response;

				if (code === 200) {
					refreshOrganizationListing(true);
					setUpdateCachedSelectedOrganization((prevState) => prevState + 1);
					openAlertSnackbar("Saved", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const postInviteTeamMembers = (data) => {
		OrganizationAPI.postInviteTeamMembers(selectedOrganizationAccount.id, data)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					// Reset state
					setEmailInvite("");
					setNewTeamMembers([]);

					// Get latest data
					getOrganizationDetail();

					openAlertSnackbar("Invitation Sent.", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	}

	const handleSendInvite = (event) => {
		event.preventDefault();

		let emails = [...newTeamMembers];

		// Include the email to be added even user didn't press enter
		if (emailInvite) {
			emails = [...newTeamMembers, emailInvite];
		}

		let data = {
			emails: JSON.stringify(emails),
		};

		postInviteTeamMembers(data);
	};

	const handleSubmitInvite = (event) => {
		// When hit enter, add to the array
		if (event.key === "Enter") {
			// Prevent default submit form
			event.preventDefault();

			// Check email valid
			if (!validateEmail(emailInvite)) {
				openAlertSnackbar("Email is invalid.", "error");
				return;
			}

			const emails = [emailInvite]
			const data = {
				emails: JSON.stringify(emails),
			};
			postInviteTeamMembers(data);

			// setNewTeamMembers([...newTeamMembers, emailInvite]);
			setEmailInvite("");
		}
	};

	// Handle remove existing team member
	const handleRemoveTeamMember = (index) => {
		const deleteTeamMemberDetails = teamMembers[index];
		setEmailToBeRemoved(deleteTeamMemberDetails.email);
		setEmailIndexToBeRemoved(index);
		setOpenModalName("remove_team_member");
		openCustomModal();
	};

	// Handle remove new team member
	const handleRemoveNewTeamMember = (index) => {
		let newTeamMembersCopy = [...newTeamMembers];
		newTeamMembersCopy.splice(index, 1);
		setNewTeamMembers(newTeamMembersCopy);
	};

	const handleConfirmDeleteTeamMember = () => {
		if (!emailToBeRemoved) {
			openAlertSnackbar("Fail to remove", "error");
			return;
		}

		const data = {
			email: emailToBeRemoved,
		};

		OrganizationAPI.deleteTeamMembers(selectedOrganizationAccount.id, data)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					let teamMembersCopy = [...teamMembers];
					teamMembersCopy.splice(emailIndexToBeRemoved, 1);
					setTeamMembers(teamMembersCopy);

					setEmailToBeRemoved(null);
					closeCustomModal();
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	return (
		<LayoutContainer>
			<section className="page-header">
				<h2 className="title">Profile & Settings</h2>
				<span className="subtitle">Edit organization's profile and settings</span>
			</section>

			<section className="profile-and-settings p-4">
				<form id="form-profile-settings" method="post" onSubmit={handleSaveProfileSettings}>
					<div className="row">
						<div className="col-10">
							<div className="form-group">
								<label>Organization Logo</label>
								<div className="row">
									<div className="col-2 pr-0">
										<img
											src={
												uploadedLogo
													? URL.createObjectURL(uploadedLogo)
													: organizationDetail.logo_url || IconDefaultUser
											}
											alt="org-logo"
											className="img-fluid img-logo"
										/>
									</div>
									<div className="col-8">
										<button type="button" className="btn btn-primary" onClick={browsePicture}>
											Replace <img src={IconEditWhite} alt="edit" className="icon-action" />
										</button>
										<button
											type="button"
											className="btn btn-outline-secondary mx-3"
											onClick={handleDeleteLogo}>
											Delete <img src={IconDeleteGrey} alt="edit" className="icon-action" />
										</button>
										<div>
											<small>Suggested size: 100 x 100px, 2MB and below only</small>
										</div>
									</div>
									<input
										type="file"
										name="logo"
										onChange={handleChangeLogo}
										ref={logoRef}
										className="d-none"
										accept="image/*"
										value=""
									/>
								</div>
							</div>
						</div>
						<div className="col-5">
							<div className="form-group">
								<label htmlFor="organization-name">Organization Name</label>
								<input
									id="organization-name"
									type="text"
									className="form-control"
									aria-describedby="organization-name"
									name="name"
									value={organizationDetail.name}
									onChange={handleInputOnChange}
								/>
							</div>
						</div>
						<div className="col-5">
							<div className="form-group">
								<label htmlFor="organization-email">Organization Email Address</label>
								<input
									id="organization-email"
									type="email"
									className="form-control"
									aria-describedby="organization-email"
									name="email"
									value={organizationDetail.email}
									onChange={handleInputOnChange}
								/>
							</div>
						</div>
						<div className="col-10">
							<div className="form-group">
								<label htmlFor="pic_name">
									PIC Name <span className="text-danger">*</span>
								</label>
								<input
									id="pic_name"
									type="text"
									className="form-control"
									aria-describedby="pic_name"
									name="pic_name"
									value={organizationDetail.pic_name}
									onChange={handleInputOnChange}
									required
								/>
							</div>
						</div>
						<div className="col-10">
							<div className="form-group">
								<label htmlFor="pic_contact_number">
									PIC Contact Number <span className="text-danger">*</span>
								</label>
								<input
									id="pic_contact_number"
									type="text"
									className="form-control"
									aria-describedby="pic_contact_number"
									name="pic_phone_no"
									value={organizationDetail.pic_phone_no}
									onChange={handleInputOnChange}
									required
								/>
							</div>
						</div>
						<div className="col-10">
							<div className="form-group">
								<label htmlFor="pic_email">
									PIC Email Address <span className="text-danger">*</span>
								</label>
								<input
									id="pic_email"
									type="email"
									className="form-control"
									aria-describedby="pic_email"
									name="pic_email"
									value={organizationDetail.pic_email}
									onChange={handleInputOnChange}
									required
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-8"></div>
						<div className="col-2">
							<button type="submit" className="btn btn-primary w-100">
								Save
							</button>
						</div>
					</div>
				</form>
			</section>

			<section className="invite-team-members p-4">
				<form id="form-invite-member" method="post" onSubmit={handleSendInvite} ref={inviteFormRef}>
					<div className="row">
						<div className="invite-team-members col-12 form-group">
							<label>Invite Team Members</label>
							{teamMembers.map((teamMember, index) => {
								const { email, status, type } = teamMember;
								return (
									<InviteMemberRow
										key={`${email}-${index}`}
										value={email}
										allowRemove
										onRemove={() => handleRemoveTeamMember(index)}
										readOnly
										confirmed={status === ACCOUNT_INVITATION_STATUS["accepted"] ? true : false}
										invited={
											status === ACCOUNT_INVITATION_STATUS["pending_invitation"] ? true : false
										}
										teamMemberType={parseInt(type)}
									/>
								);
							})}

							{newTeamMembers.map((newTeamMember, index) => {
								return (
									<InviteMemberRow
										key={`${newTeamMember}-${index}`}
										value={newTeamMember}
										allowRemove
										onRemove={() => handleRemoveNewTeamMember(index)}
										readOnly
									/>
								);
							})}

							<InviteMemberRow
								value={emailInvite}
								onChange={(event) => setEmailInvite(event.target.value)}
								onKeyDown={handleSubmitInvite}
							/>
						</div>
					</div>

					<div className="row">
						<div className="col-9"></div>
						<div className="col-3">
							<button type="submit" className="btn btn-primary w-100">
								Send Invite
							</button>
						</div>
					</div>
				</form>
			</section>

			{openModalName === "remove_team_member" && (
				<CustomModal
					icon={IconDelete}
					title="Are you sure you want to delete this team member?"
					btnClass="btn-danger"
					textPrimary={
						<>
							<img src={IconDeleteGrey} alt="delete" className="icon-btn" /> Delete
						</>
					}
					textSecondary="Cancel"
					onClickPrimary={handleConfirmDeleteTeamMember}
					onClickSecondary={closeCustomModal}
				/>
			)}
		</LayoutContainer>
	);
};

export default withRouter(ProfileSettings);
