import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import GamificationAPI from "app/apis/gamification";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";
import EventInfo from "app/views/components/EventInfo/EventInfo";

const GamificationConfiguration = ({ history }) => {
	const { eventId } = useParams();

	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const [configuration, setConfiguration] = useState({
		attend_exhibitor_room: {
			id: null,
			value: 0,
		},
		qna_channel_session: {
			id: null,
			value: 0,
		},
		poll_vote_channel_session: {
			id: null,
			value: 0,
		},
		ads_click: {
			id: null,
			value: 0,
		},
		watch_live_stream: {
			id: null,
			value: 0,
		},
		watch_exhibitor_recorded_video: {
			id: null,
			value: 0,
		},
		attend_channel_session: {
			id: null,
			value: 0,
		},
		watch_channel_session: {
			id: null,
			value: 0,
		},
	});

	// Get gamification configurations
	useEffect(() => {
		GamificationAPI.getGamificationConfigurations(eventId)
			.then((response) => {
				let attend_exhibitor_room = {
						id: null,
						value: 0,
					},
					qna_channel_session = {
						id: null,
						value: 0,
					},
					poll_vote_channel_session = {
						id: null,
						value: 0,
					},
					ads_click = {
						id: null,
						value: 0,
					},
					watch_live_stream = {
						id: null,
						value: 0,
					},
					watch_exhibitor_recorded_video = {
						id: null,
						value: 0,
					},
					attend_channel_session = {
						id: null,
						value: 0,
					},
					watch_channel_session = {
						id: null,
						value: 0,
					};

				response.data.forEach((config) => {
					switch (config.code) {
						case 101:
							ads_click = {
								id: config.id,
								value: config.value,
							};
							break;
						case 200:
							attend_channel_session = {
								id: config.id,
								value: config.value,
							};
							break;
						case 201:
							watch_channel_session = {
								id: config.id,
								value: config.value,
							};
							break;
						case 202:
							watch_exhibitor_recorded_video = {
								id: config.id,
								value: config.value,
							};
							break;
						case 203:
							poll_vote_channel_session = {
								id: config.id,
								value: config.value,
							};
							break;
						case 204:
							qna_channel_session = {
								id: config.id,
								value: config.value,
							};
							break;
						case 300:
							attend_exhibitor_room = {
								id: config.id,
								value: config.value,
							};
							break;
						case 301:
							watch_live_stream = {
								id: config.id,
								value: config.value,
							};
							break;
						default:
					}
				});

				setConfiguration({
					attend_exhibitor_room: attend_exhibitor_room,
					qna_channel_session: qna_channel_session,
					poll_vote_channel_session: poll_vote_channel_session,
					ads_click: ads_click,
					watch_live_stream: watch_live_stream,
					watch_exhibitor_recorded_video: watch_exhibitor_recorded_video,
					attend_channel_session: attend_channel_session,
					watch_channel_session: watch_channel_session,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	const handleInputOnChange = (event) => {
		setConfiguration({
			...configuration,
			[event.target.name]: {
				...configuration[event.target.name],
				value: event.target.value,
			},
		});
	};

	const handleSaveConfiguration = (event) => {
		event.preventDefault();
		let formData = new FormData();

		Object.keys(configuration).forEach((config, index) => {
			// Only update if those config ids are not null
			if (configuration[config]["id"]) {
				formData.append("configs[]", JSON.stringify(configuration[config]));
			}
		});
		
		GamificationAPI.postUpdateGamificationConfigurations(eventId, formData)
			.then((response) => {
				if (response.code === 200) {
					openAlertSnackbar("Updated successfully", "success");
				}
				else {
					if (response.errors) {
						openAlertSnackbar(response.errors.join("\n"), "error");
					}
					else {
						openAlertSnackbar("Fail to update", "error");
					}
				}
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	return (
		<EventLayoutContainer>
			<div id="gamification-configuration-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-5">
					<div className="d-flex align-items-center">
						<div>
							<h2 className="title">Gamification Points Configuration</h2>
							<span className="subtitle">Gamification &gt; Configuration</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="gamification-configuration-section">
					<h3>Decide gamification points to be awarded to visitors for each action performed</h3>

					<form id="form-gamification-configuration" method="post" onSubmit={handleSaveConfiguration}>
						<div className="d-flex justify-content-between align-items-start mb-4">
							<div className="pr-3">
								<label htmlFor="attend_exhibitor_room">Per exhibitor room visited</label>
								<div className="label-desc">
									Awarded each time user visits an exhibitor room (one time per room)
								</div>
							</div>
							<div>
								<input
									id="attend_exhibitor_room"
									type="number"
									className="input-configuration mb-3"
									aria-describedby="attend_exhibitor_room"
									name="attend_exhibitor_room"
									value={configuration.attend_exhibitor_room.value}
									onChange={handleInputOnChange}
									min={0}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-between align-items-start mb-4">
							<div className="pr-3">
								<label htmlFor="qna_channel_session">Per Q&A submitted</label>
								<div className="label-desc">
									Awarded each time user submits a Q&A in a channel session (one time per session)
								</div>
							</div>
							<div>
								<input
									id="qna_channel_session"
									type="number"
									className="input-configuration mb-3"
									aria-describedby="qna_channel_session"
									name="qna_channel_session"
									value={configuration.qna_channel_session.value}
									onChange={handleInputOnChange}
									min={0}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-between align-items-start mb-4">
							<div className="pr-3">
								<label htmlFor="poll_vote_channel_session">Per poll & vote submitted</label>
								<div className="label-desc">
									Awarded each time user submits a vote in a channel session (one time per session)
								</div>
							</div>
							<div>
								<input
									id="poll_vote_channel_session"
									type="number"
									className="input-configuration mb-3"
									aria-describedby="poll_vote_channel_session"
									name="poll_vote_channel_session"
									value={configuration.poll_vote_channel_session.value}
									onChange={handleInputOnChange}
									min={0}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-between align-items-start mb-4">
							<div className="pr-3">
								<label htmlFor="ads_click">Per ads click</label>
								<div className="label-desc">
									Awarded each time user click on an ad (one time per ad)
								</div>
							</div>
							<div>
								<input
									id="ads_click"
									type="number"
									className="input-configuration mb-3"
									aria-describedby="ads_click"
									name="ads_click"
									value={configuration.ads_click.value}
									onChange={handleInputOnChange}
									min={0}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-between align-items-start mb-4">
							<div className="pr-3">
								<label htmlFor="watch_live_stream">Per live stream watched</label>
								<div className="label-desc">
									Awarded each time user watch a live stream in either Channels or Exhibitors (one
									time per live stream session)
								</div>
							</div>
							<div>
								<input
									id="watch_live_stream"
									type="number"
									className="input-configuration mb-3"
									aria-describedby="watch_live_stream"
									name="watch_live_stream"
									value={configuration.watch_live_stream.value}
									onChange={handleInputOnChange}
									min={0}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-between align-items-start">
							<div className="pr-3">
								<label htmlFor="watch_exhibitor_recorded_video">Per recorded video watched</label>
								<div className="label-desc">
									Awarded each time user watch a video in either Channels or Exhibitors (one time per
									video)
								</div>
							</div>
							<div>
								<input
									id="watch_exhibitor_recorded_video"
									type="number"
									className="input-configuration mb-3"
									aria-describedby="watch_exhibitor_recorded_video"
									name="watch_exhibitor_recorded_video"
									value={configuration.watch_exhibitor_recorded_video.value}
									onChange={handleInputOnChange}
									min={0}
								/>
							</div>
						</div>
					</form>
				</section>

				<div className="row mt-4">
					<div className="col-9"></div>
					<div className="col-3">
						<button type="submit" form="form-gamification-configuration" className="btn btn-primary w-100">
							Save
						</button>
					</div>
				</div>
			</div>
		</EventLayoutContainer>
	);
};

export default withRouter(GamificationConfiguration);
