import React, { useState, useRef } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "app/states/event";
import IconClose from "app/assets/icon-close.png";
import { withRouter, useParams } from "react-router-dom";
import { commonActions } from "app/states/common";
import IconAddGrey from "app/assets/icon-add-grey.png";
import LandingPageAPI from "app/apis/landingPage";

const AddSpeakerModal = ({ history }) => {
	const { eventId } = useParams();

	const dispatch = useDispatch();
	const closeAddSpeakerModal = () => dispatch(eventActions.closeAddSpeakerModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const refreshSpeakerList = () => dispatch(eventActions.refreshSpeakerList());

	const showAddSpeakerModal = useSelector(({ event }) => event.showAddSpeakerModal);

	const [uploadedPhoto, setUploadedPhoto] = useState(null);
	const photoRef = useRef();

	const [inputData, setInputData] = useState({
		photo: "",
		name: "",
		company_name: "",
		designation: "",
	});

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleCloseAddSpeakerModal = () => {
		// Clear inputs
		setInputData({
			photo: "",
			name: "",
			company_name: "",
			designation: "",
		});
		setUploadedPhoto(null);

		closeAddSpeakerModal();
	};

	const handleAddSpeaker = (event) => {
		event.preventDefault();

		// Validation
		if (!uploadedPhoto) {
			openAlertSnackbar("Photo is required.", "error");
			return;
		}

		// Form data
		let formData = new FormData();
		formData.append("name", inputData.name);
		formData.append("company_name", inputData.company_name);
		formData.append("speaker_logo", uploadedPhoto);

		if (inputData.designation) {
			formData.append("designation", inputData.designation);
		}

		LandingPageAPI.postCreateUpdateSpeaker(eventId, "", formData)
			.then((response) => {
				refreshSpeakerList();
				openAlertSnackbar("Speaker added successfully.", "success");
				handleCloseAddSpeakerModal();
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleChangePhoto = (event) => {
		setUploadedPhoto(event.target.files[0]);
	};

	const browsePhoto = () => {
		photoRef.current.click();
	};

	return (
		<Modal
			show={showAddSpeakerModal}
			onHide={handleCloseAddSpeakerModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="add-speaker-modal"
			centered>
			<Modal.Body>
				<div className="add-speaker-modal-body">
					<div className="d-flex justify-content-end">
						<img src={IconClose} alt="close" className="icon-close" onClick={handleCloseAddSpeakerModal} />
					</div>

					<h3>Add Speakers</h3>

					<form method="post" onSubmit={handleAddSpeaker}>
						<div className="form-group">
							<label htmlFor="photo">Upload Photo *</label>
							{uploadedPhoto || inputData.photo ? (
								<img
									src={uploadedPhoto ? URL.createObjectURL(uploadedPhoto) : inputData.photo}
									alt="cover"
									className="img-fluid img-photo"
									onClick={browsePhoto}
								/>
							) : (
								<div className="add-photo-wrapper" onClick={browsePhoto}>
									<img src={IconAddGrey} alt="add" className="img-add-photo" />
								</div>
							)}
							<input
								type="file"
								name="photo"
								value={""}
								onChange={handleChangePhoto}
								ref={photoRef}
								className="d-none"
								accept="image/*"
							/>
						</div>
						<div className="form-group">
							<label htmlFor="name">Name *</label>
							<input
								id="name"
								type="text"
								className="form-control"
								aria-describedby="name"
								name="name"
								value={inputData.name}
								onChange={handleInputOnChange}
								required
							/>
						</div>
						<div className="form-group">
							<label htmlFor="company_name">Company Name *</label>
							<input
								id="company_name"
								type="text"
								className="form-control"
								aria-describedby="company_name"
								name="company_name"
								value={inputData.company_name}
								onChange={handleInputOnChange}
								required
							/>
						</div>
						<div className="form-group">
							<label htmlFor="name">Designation</label>
							<input
								id="designation"
								type="text"
								className="form-control"
								aria-describedby="designation"
								name="designation"
								value={inputData.designation}
								onChange={handleInputOnChange}
							/>
						</div>

						<button type="submit" className="btn btn-primary w-100 mt-4">
							Add
						</button>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(AddSpeakerModal);
