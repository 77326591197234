import React, { useState } from "react";
import { withRouter } from "react-router";
import ImageLogin from "app/assets/bg-login-page.png";
import "./style.scss";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";

const Register = ({ history }) => {
	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const [inputData, setInputData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
		confirm_password: "",
		agree_privacy: false,
		agree_tnc: false,
	});

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleGoNext = (event) => {
		event.preventDefault();

		// Check password and confirm password is same
		if (inputData.password !== inputData.confirm_password) {
			openAlertSnackbar("Password does not match.", "error");
			return;
		}

		history.push("/register/part2", {
			registerInputData: inputData,
		});
	};

	return (
		<div className="auth-page container-fluid vh-100">
			<div className="row vh-100">
				<div className="col-6 auth-left px-5">
					<section className="my-5">
						<h5 className="font-weight-bold">Welcome to boom!</h5>
						<h5>Your global host for online and hybrid events.</h5>
					</section>
					<img src={ImageLogin} alt="Login" className="img-fluid" />
				</div>
				<div className="col-6 p-5 auth-right">
					<div className="d-flex flex-column align-items-center">
						<section className="mb-4 text-center">
							<h3>Create New Account</h3>
						</section>

						<form id="form-register" className="w-100" onSubmit={handleGoNext}>
							<div className="row">
								<div className="col-6">
									<div className="form-group">
										<label htmlFor="first_name">
											First Name <span className="text-danger">*</span>
										</label>
										<input
											id="first_name"
											type="text"
											className="form-control"
											aria-describedby="first_name"
											name="first_name"
											value={inputData.first_name}
											onChange={handleInputOnChange}
											required
										/>
									</div>
								</div>
								<div className="col-6">
									<div className="form-group">
										<label htmlFor="last_name">
											Last Name <span className="text-danger">*</span>
										</label>
										<input
											id="last_name"
											type="text"
											className="form-control"
											aria-describedby="last_name"
											name="last_name"
											value={inputData.last_name}
											onChange={handleInputOnChange}
											required
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label htmlFor="email">
											Email Address <span className="text-danger">*</span>
										</label>
										<input
											id="email"
											type="email"
											className="form-control"
											aria-describedby="email"
											name="email"
											value={inputData.email}
											onChange={handleInputOnChange}
											required
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label htmlFor="password">
											Password <span className="text-danger">*</span>
										</label>
										<input
											id="password"
											type="password"
											className="form-control"
											aria-describedby="password"
											name="password"
											value={inputData.password}
											onChange={handleInputOnChange}
											required
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label htmlFor="confirm_password">
											Confirm Password <span className="text-danger">*</span>
										</label>
										<input
											id="confirm_password"
											type="password"
											className="form-control"
											aria-describedby="confirm_password"
											name="confirm_password"
											value={inputData.confirm_password}
											onChange={handleInputOnChange}
											required
										/>
									</div>
								</div>
							</div>

							<div className="px-3 mt-3">
								<div className="form-check form-group">
									<input
										className="form-check-input custom-outline-checkbox"
										type="checkbox"
										id="agree_privacy"
										onChange={() =>
											setInputData({ ...inputData, agree_privacy: !inputData.agree_privacy })
										}
										checked={inputData.agree_privacy}
										required
									/>
									<label className="form-check-label custom-checkbox-label" htmlFor="agree_privacy">
										I have read and agree to the{" "}
										<a href="/" target="_blank" className="text-link">
											Privacy Policy
										</a>
									</label>
								</div>
								<div className="form-check form-group">
									<input
										className="form-check-input custom-outline-checkbox"
										type="checkbox"
										id="agree_tnc"
										onChange={() => setInputData({ ...inputData, agree_tnc: !inputData.agree_tnc })}
										checked={inputData.agree_tnc}
										required
									/>
									<label className="form-check-label custom-checkbox-label" htmlFor="agree_tnc">
										I have read and agree to the{" "}
										<a href="/" target="_blank" className="text-link">
											Terms of Use
										</a>
									</label>
								</div>
							</div>

							<div className="mt-4 text-center mb-1">
								<button type="submit" className="btn btn-primary btn-next px-5">
									Next
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(Register);
