import AddOrganization from "app/views/pages/AddOrganization/AddOrganization";
import ProfileSettings from "app/views/pages/ProfileSettings/ProfileSettings";
import Events from "app/views/pages/Event/Events/Events";
import AddEvent from "app/views/pages/Event/AddEvent/AddEvent";
import ViewEventDetails from "app/views/pages/Event/ViewEventDetails/ViewEventDetails";
import EventVisitors from "app/views/pages/Event/EventVisitors/EventVisitors";
import ChannelListing from "app/views/pages/Channel/ChannelListing/ChannelListing";
import ViewChannel from "app/views/pages/Channel/ViewChannel/ViewChannel";
import AddChannelSession from "app/views/pages/Channel/AddChannelSession/AddChannelSession";
import ViewChannelSession from "app/views/pages/Channel/ViewChannelSession/ViewChannelSession";
import ExhibitorCategoryListing from "app/views/pages/Exhibitor/ExhibitorCategoryListing/ExhibitorCategoryListing";
import ExhibitorRoomListing from "app/views/pages/Exhibitor/ExhibitorRoomListing/ExhibitorRoomListing";
import AddExhibitorRoom from "app/views/pages/Exhibitor/AddExhibitorRoom/AddExhibitorRoom";
import ViewExhibitorRoom from "app/views/pages/Exhibitor/ViewExhibitorRoom/ViewExhibitorRoom";
import AddExhibitorStoreProduct from "app/views/pages/Exhibitor/AddExhibitorStoreProduct/AddExhibitorStoreProduct";
import ViewExhibitorStoreProduct from "app/views/pages/Exhibitor/ViewExhibitorStoreProduct/ViewExhibitorStoreProduct";
import Accounts from "app/views/pages/Account/Accounts/Accounts";
import AddAccount from "app/views/pages/Account/AddAccount/AddAccount";
import ViewAccount from "app/views/pages/Account/ViewAccount/ViewAccount";
import VisitorList from "app/views/pages/VisitorDatabase/VisitorList/VisitorList";
import ViewVisitorDetail from "app/views/pages/VisitorDatabase/ViewVisitorDetail/ViewVisitorDetail";
import AdsManager from "app/views/pages/AdsManager/AdsManager/AdsManager";
import AdsSummary from "app/views/pages/AdsManager/AdsSummary/AdsSummary";
import CreateAd from "app/views/pages/AdsManager/CreateAd/CreateAd";
import EditAd from "app/views/pages/AdsManager/EditAd/EditAd";
import ViewAd from "app/views/pages/AdsManager/ViewAd/ViewAd";
import GamificationLeaderboard from "app/views/pages/Gamification/GamificationLeaderboard/GamificationLeaderboard";
import GamificationLeaderboardDetail from "app/views/pages/Gamification/GamificationLeaderboardDetail/GamificationLeaderboardDetail";
import GamificationConfiguration from "app/views/pages/Gamification/GamificationConfiguration/GamificationConfiguration";
import SessionControlRoom from "app/views/pages/Channel/SessionControlRoom/SessionControlRoom";
import SessionControlRoomAddChannelSession from "app/views/pages/Channel/SessionControlRoomAddChannelSession/SessionControlRoomAddChannelSession";
import StoreTransactionListing from "app/views/pages/StoreTransactions/StoreTransactionListing/StoreTransactionListing";
import StoreTransactionDetail from "app/views/pages/StoreTransactions/StoreTransactionDetail/StoreTransactionDetail";
import Dashboard from "app/views/pages/Dashboard/Dashboard/Dashboard";
import BillingsDashboard from "app/views/pages/Billings/BillingsDashboard/BillingsDashboard";
import ChoosePlan from "app/views/pages/Billings/ChoosePlan/ChoosePlan";
import LandingPageEditor from "app/views/pages/Event/LandingPageEditor/LandingPageEditor";
import TicketPriceSettings from "app/views/pages/Event/TicketPriceSettings/TicketPriceSettings";
import StatisticSummary from "app/views/pages/Statistic/Summary/Summary";

const PrivateRoutes = [
	{
		path: "/dashboard",
		exact: true,
		label: "Dashboard Page",
		component: Dashboard,
	},
	{
		path: "/organization/profile",
		exact: true,
		label: "Profile Settings Page",
		component: ProfileSettings,
	},
	{
		path: "/organization/add",
		exact: true,
		label: "Add Organization Page",
		component: AddOrganization,
	},
	{
		path: "/events",
		exact: true,
		label: "Events Page",
		component: Events,
	},
	{
		path: "/events/add",
		exact: true,
		label: "Add Event Page",
		component: AddEvent,
	},
	{
		path: "/events/view/:eventId",
		exact: true,
		label: "View Event Page",
		component: ViewEventDetails,
	},
	{
		path: "/events/view/:eventId/ticket",
		exact: true,
		label: "Event ticket price setting page",
		component: TicketPriceSettings,
	},
	{
		path: "/events/view/:eventId/visitors",
		exact: true,
		label: "Event Visitor Page",
		component: EventVisitors,
	},
	{
		path: "/events/view/:eventId/landingPageEditor",
		exact: true,
		label: "Landing Page Editor Page",
		component: LandingPageEditor,
	},
	{
		path: "/events/view/:eventId/channels",
		exact: true,
		label: "Channel Page",
		component: ChannelListing,
	},
	{
		path: "/events/view/:eventId/channels/:channelId",
		exact: true,
		label: "View Channel Page",
		component: ViewChannel,
	},
	{
		path: "/events/view/:eventId/channels/:channelId/session/add",
		exact: true,
		label: "Add New Channel Session Page",
		component: AddChannelSession,
	},
	{
		path: "/events/view/:eventId/channelSessionControlRoom",
		exact: true,
		label: "Channel Session Control Room Page",
		component: SessionControlRoom,
	},
	{
		path: "/events/view/:eventId/channelSessionControlRoom/channels/:channelId/session/add",
		exact: true,
		label: "Add New Channel Session Page",
		component: SessionControlRoomAddChannelSession,
	},
	{
		path: "/events/view/:eventId/channels/:channelId/session/:sessionId",
		exact: true,
		label: "View Channel Session Page",
		component: ViewChannelSession,
	},
	{
		path: "/events/view/:eventId/exhibitor/categories",
		exact: true,
		label: "Exhibitor Category Listing Page",
		component: ExhibitorCategoryListing,
	},
	{
		path: "/events/view/:eventId/exhibitor/rooms",
		exact: true,
		label: "Exhibitor Room Listing Page",
		component: ExhibitorRoomListing,
	},
	{
		path: "/events/view/:eventId/exhibitor/rooms/add",
		exact: true,
		label: "Add New Exhibitor Room Page",
		component: AddExhibitorRoom,
	},
	{
		path: "/events/view/:eventId/exhibitor/rooms/:roomId",
		exact: true,
		label: "View Exhibitor Room Page",
		component: ViewExhibitorRoom,
	},
	{
		path: "/events/view/:eventId/exhibitor/rooms/:roomId/product/add",
		exact: true,
		label: "Exhibitor - Add New Store Product",
		component: AddExhibitorStoreProduct,
	},
	{
		path: "/events/view/:eventId/exhibitor/rooms/:roomId/product/:productId",
		exact: true,
		label: "Exhibitor - Add New Store Product",
		component: ViewExhibitorStoreProduct,
	},
	{
		path: "/accounts",
		exact: true,
		label: "Accounts Page",
		component: Accounts,
	},
	{
		path: "/accounts/add",
		exact: true,
		label: "Add Account Page",
		component: AddAccount,
	},
	{
		path: "/accounts/view/:accountId",
		exact: true,
		label: "View Account Page",
		component: ViewAccount,
	},
	{
		path: "/visitors",
		exact: true,
		label: "Visitor Listing Page",
		component: VisitorList,
	},
	{
		path: "/visitors/view/:visitorId",
		exact: true,
		label: "View Visitor Page",
		component: ViewVisitorDetail,
	},
	{
		path: "/events/view/:eventId/ads",
		exact: true,
		label: "Ads Manager",
		component: AdsManager,
	},
	{
		path: "/events/view/:eventId/ads/type/:adsTypeId",
		exact: true,
		label: "Ads Summary",
		component: AdsSummary,
	},
	{
		path: "/events/view/:eventId/ads/type/:adsTypeId/create",
		exact: true,
		label: "Create Ads",
		component: CreateAd,
	},
	{
		path: "/events/view/:eventId/ads/type/:adsTypeId/view/:adsId",
		exact: true,
		label: "View Ads",
		component: ViewAd,
	},
	{
		path: "/events/view/:eventId/ads/type/:adsTypeId/edit/:adsId",
		exact: true,
		label: "Edit Ads",
		component: EditAd,
	},
	{
		path: "/events/view/:eventId/gamification/leaderboard",
		exact: true,
		label: "Gamification Leaderboard",
		component: GamificationLeaderboard,
	},
	{
		path: "/events/view/:eventId/gamification/leaderboard/view/:visitorId",
		exact: true,
		label: "Gamification Leaderboard Detail",
		component: GamificationLeaderboardDetail,
	},
	{
		path: "/events/view/:eventId/gamification/configuration",
		exact: true,
		label: "Gamification Configuration",
		component: GamificationConfiguration,
	},
	{
		path: "/events/view/:eventId/statistics",
		exact: true,
		label: "Statistics & Reporting",
		component: StatisticSummary,
	},
	{
		path: "/events/view/:eventId/store-transactions",
		exact: true,
		label: "Store Transactions",
		component: StoreTransactionListing,
	},
	{
		path: "/events/view/:eventId/store-transactions/view/:transactionId",
		exact: true,
		label: "Store Transaction Detail",
		component: StoreTransactionDetail,
	},
	{
		path: "/billings",
		exact: true,
		label: "Billing Dashboard Page",
		component: BillingsDashboard,
	},
	{
		path: "/billings/plans",
		exact: true,
		label: "Billings Plans Page",
		component: ChoosePlan,
	},
];

export default PrivateRoutes;
