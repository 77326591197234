export const DROPDOWN_RECORDS_PER_PAGE = 20;

export const PAYMENT_SETTINGS = {
	ecommerce: 1,
	payment_gateway: 2,
	credit: 3,
	place_booking: 4,
};

export const EVENT_TYPE = {
	Exhibition: 1,
	Event: 2,
	Conference: 3,
	Seminar: 4,
	Workshop: 5,
};

export const PLATFORM_TYPES = {
	local_upload: {
		value: 1,
		display: "Others",
	},
	youtube: {
		value: 2,
		display: "YouTube",
	},
	facebook: {
		value: 3,
		display: "Facebook",
	},
	// "zoom": {
	//     "value": 4,
	//     "display": "Zoom"
	// }
};

export const ACCOUNT_TYPES = {
	company: {
		value: 1,
		display: "Company",
	},
	individual: {
		value: 2,
		display: "Individual",
	},
};

export const ACCOUNT_INVITATION_STATUS = {
	pending_invitation: 0,
	accepted: 1,
	rejected: 2,
};

export const AGENT_TYPE = {
	admin: 1,
	agent: 2,
};

export const ADS_TYPE_SIZE = {
	global_leaderboard: {
		width: 728,
		height: 90,
		display: "728 x 90px or similar ratio",
	},
	global_customised_leaderboard: {
		width: 325,
		height: 90,
		display: "325 x 90px or similar ratio",
	},
};

export const PRODUCT_VARIANT_COLORS = ["#34A853", "#C53929", "#4285F4"];

export const TEAM_MEMBER_TYPES = {
	owner: 1,
	collaborator: 2,
};
