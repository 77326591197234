import React, { useState } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "app/states/event";
import { withRouter } from "react-router-dom";
import { commonActions } from "app/states/common";
import IconPause from "app/assets/icon-pause-event@2x.png";
import IconEnd from "app/assets/icon-end-event@2x.png";
import IconResume from "app/assets/icon-resume-event@2x.png";
import { EVENT_ACTION, EVENT_STATUS } from "app/config/constants";

const PauseEndEventModal = ({ history }) => {
	const dispatch = useDispatch();
	const closePauseEndEventModal = () => dispatch(eventActions.closePauseEndEventModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setIsEventPublished = (isEventPublished) => dispatch(eventActions.setIsEventPublished(isEventPublished));
	const openPublishEventModal = () => dispatch(eventActions.openPublishEventModal());
	const setEventPublishAction = (action) => dispatch(eventActions.setEventPublishAction(action));

	const showPauseEndEventModal = useSelector(({ event }) => event.showPauseEndEventModal);
	const eventPublishAction = useSelector(({ event }) => event.eventPublishAction);
	const currentEventStatus = useSelector(({ event }) => event.currentEventStatus);

	const handleCancel = () => {
		setEventPublishAction(null);
		closePauseEndEventModal();
	};

	const handleProceed = () => {
		closePauseEndEventModal();
		openPublishEventModal();
	};

	const handleSelectAction = (action) => {
		setEventPublishAction(action);
	};

	return (
		<Modal
			show={showPauseEndEventModal}
			onHide={closePauseEndEventModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="pause-end-event-modal"
			dialogClassName="pause-end-event-dialog"
			centered>
			<Modal.Body>
				<div className="pause-end-event-modal-body">
					<h3>Please select an action:</h3>

					<section className="d-flex justify-content-center align-items-center my-5">
						{currentEventStatus === EVENT_STATUS["pause"]["value"] ? (
							<div
								className={`action-wrapper ${
									eventPublishAction === EVENT_ACTION["resume"] ? "resume-action-wrapper" : ""
								}`}
								onClick={() => handleSelectAction(EVENT_ACTION["resume"])}>
								<img src={IconResume} alt={EVENT_ACTION["resume"]} className="img-action mb-4" />
								<div className="action-title mb-3">Resume Event</div>
								<div className="action-desc">
									Allow attendees to register and access to your event during the event period.
								</div>
							</div>
						) : (
							<div
								className={`action-wrapper ${
									eventPublishAction === EVENT_ACTION["pause"] ? "pause-action-wrapper" : ""
								}`}
								onClick={() => handleSelectAction(EVENT_ACTION["pause"])}>
								<img src={IconPause} alt={EVENT_ACTION["pause"]} className="img-action mb-4" />
								<div className="action-title mb-3">Pause Event</div>
								<div className="action-desc">
									Temporary restrict attendees from accessing to your event.
								</div>
							</div>
						)}

						<div
							className={`action-wrapper ${
								eventPublishAction === EVENT_ACTION["end"] ? "end-action-wrapper" : ""
							}`}
							onClick={() => handleSelectAction(EVENT_ACTION["end"])}>
							<img src={IconEnd} alt={EVENT_ACTION["end"]} className="img-action mb-4" />
							<div className="action-title mb-3">End Event</div>
							<div className="action-desc">
								Permanently restrict access to your event. Use this to unpublish an event to free up
								monthly quota on the next billing cycle.
							</div>
						</div>
					</section>

					<section className="d-flex justify-content-center align-items-center">
						<button className="btn btn-outline-primary mx-2" type="button" onClick={handleCancel}>
							Cancel
						</button>
						<button
							className="btn btn-primary mx-2"
							type="button"
							disabled={eventPublishAction ? false : true}
							onClick={handleProceed}>
							Proceed
						</button>
					</section>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(PauseEndEventModal);
