import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import GamificationAPI from "app/apis/gamification";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import { numberWithCommas, ordinal_suffix_of } from "app/helper/common";
import IconDefaultUser from "app/assets/icon-default-user.png";
import BgFirstPlace from "app/assets/bg-leaderboard-first-place-effect.png";
import IconCrown from "app/assets/icon-leaderboard-crown.png";
import IconBack from "app/assets/icon-back-grey.png";
import EventInfo from "app/views/components/EventInfo/EventInfo";

const GamificationLeaderboardDetail = ({ history, location }) => {
	const { eventId, visitorId } = useParams();

	const [gamificationDetail, setGamificationDetail] = useState({
		first_name: "",
		last_name: "",
		profile_image_url: "",
		email: "",
		total_points: 0,
		ad_view: 0,
		ad_click: 0,
		attend_channel_session: 0,
		watch_channel_recorded: 0,
		qna_channel_session: 0,
		poll_vote_channel_session: 0,
		attend_exhibitor_room: 0,
		watch_live_stream: 0,
	});
	const [rankingNum, setRankingNum] = useState(1);

	// Get gamification detail
	useEffect(() => {
		const { ranking } = location.state; 
		setRankingNum(ranking);

		GamificationAPI.getGamificationDetails(eventId, visitorId)
			.then((response) => {
				let details = {
					first_name: response.data.first_name,
					last_name: response.data.last_name,
					profile_image_url: response.data.profile_image_url,
					email: response.data.email,
					total_points: response.data.total_points,
					ad_view: 0,
					ad_click: 0,
					attend_channel_session: 0,
					watch_channel_recorded: 0,
					qna_channel_session: 0,
					poll_vote_channel_session: 0,
					attend_exhibitor_room: 0,
					watch_live_stream: 0,
				}

				response.data.gamification.forEach((gamification) => {
					switch (gamification.code) {
						case 100: // Ad view
							details.ad_view += gamification.points;
							break;
						case 101: // Ad click
							details.ad_click += gamification.points;
							break;
						case 200: // Attend channel session
							details.attend_channel_session += gamification.points;
							break;
						case 201: // Watch live channel session video
							details.watch_live_stream += gamification.points;
							break;
						case 202: // Watch recorded channel session video
							details.watch_channel_recorded += gamification.points;
							break;
						case 203: // Vote in channel session
							details.poll_vote_channel_session += gamification.points;
							break;
						case 204: // Ask question in channel session
							details.qna_channel_session += gamification.points;
							break;
						case 300: // Attend exhibitor room
							details.attend_exhibitor_room += gamification.points;
							break;
						case 301: // Watch exhibitor room live stream
							details.watch_live_stream += gamification.points;
							break;
						default:
					}
				});

				setGamificationDetail(details);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	return (
		<EventLayoutContainer>
			<div id="gamification-leaderboard-detail-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-5">
					<div className="d-flex align-items-center">
						<div className="btn-back" onClick={() => history.goBack()}>
							<img src={IconBack} alt="back" className="img-fluid" />
						</div>
						<div>
							<h2 className="title">View Gamification Statistic</h2>
							<span className="subtitle">Gamification &gt; Leaderboard &gt; View Statistic</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="gamification-leaderboard-detail-section">
					<h3>Gamificiation Statistics</h3>

					<div className="ranking-container">
						<div className="ranking-position-wrapper">
							<div className="ranking-num">{ordinal_suffix_of(rankingNum)}</div>
							<div className="text-position">POSITION</div>
						</div>

						<div className="ranking-leaderboard-wrapper">
							{rankingNum === 2 && gamificationDetail && (
								<div className="top-rank">
									<img src={gamificationDetail.profile_image_url || IconDefaultUser} alt="second" className="thumbnail-rank-2" />
									<div className="name">
										{gamificationDetail.first_name} {gamificationDetail.last_name}
									</div>
									<div className="email">{gamificationDetail.email}</div>
									<div className="points">
										{numberWithCommas(gamificationDetail.total_points)} points
									</div>
								</div>
							)}

							{rankingNum === 1 && gamificationDetail && (
								<div className="top-rank">
									<img src={IconCrown} alt="crown" className="icon-crown" />
									<div className="position-relative">
										<img src={BgFirstPlace} alt="bg-first-place" className="bg-first-place" />
										<img src={gamificationDetail.profile_image_url || IconDefaultUser} alt="first" className="thumbnail-rank-1" />
									</div>
									<div className="name">
										{gamificationDetail.first_name} {gamificationDetail.last_name}
									</div>
									<div className="email">{gamificationDetail.email}</div>
									<div className="points">
										{numberWithCommas(gamificationDetail.total_points)} points
									</div>
								</div>
							)}

							{rankingNum >= 3 && gamificationDetail && (
								<div className="top-rank">
									<img src={gamificationDetail.profile_image_url || IconDefaultUser} alt="third" className="thumbnail-rank-3" />
									<div className="name">
										{gamificationDetail.first_name} {gamificationDetail.last_name}
									</div>
									<div className="email">{gamificationDetail.email}</div>
									<div className="points">
										{numberWithCommas(gamificationDetail.total_points)} points
									</div>
								</div>
							)}
						</div>

						<div className="points-wrapper">
							<div className="points-count">{numberWithCommas(gamificationDetail.total_points)}</div>
							<div className="text-total-points">TOTAL POINTS</div>
						</div>
					</div>

					<div className="stats-container">
						<div className="row">
							<div className="col-4 mb-4">
								<div className="stats-card">
									<label>Per exhibitor room visited</label>
									<div className="count">
										{numberWithCommas(gamificationDetail.attend_exhibitor_room)}
									</div>
								</div>
							</div>
							<div className="col-4 mb-4">
								<div className="stats-card">
									<label>Per Q&A submitted</label>
									<div className="count">
										{numberWithCommas(gamificationDetail.qna_channel_session)}
									</div>
								</div>
							</div>
							<div className="col-4 mb-4">
								<div className="stats-card">
									<label>Per poll & vote submitted</label>
									<div className="count">
										{numberWithCommas(gamificationDetail.poll_vote_channel_session)}
									</div>
								</div>
							</div>
							<div className="col-4 mb-4">
								<div className="stats-card">
									<label>Per ads click</label>
									<div className="count">{numberWithCommas(gamificationDetail.ad_click)}</div>
								</div>
							</div>
							<div className="col-4 mb-4">
								<div className="stats-card">
									<label>Per live stream watched</label>
									<div className="count">
										{numberWithCommas(gamificationDetail.watch_live_stream)}
									</div>
								</div>
							</div>
							<div className="col-4 mb-4">
								<div className="stats-card">
									<label>Per recorded video watched</label>
									<div className="count">
										{numberWithCommas(gamificationDetail.watch_channel_recorded)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</EventLayoutContainer>
	);
};

export default withRouter(GamificationLeaderboardDetail);
