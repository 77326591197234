/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./style.scss";
import { withRouter, useLocation, useParams, Link } from "react-router-dom";
import ImageLogo from "app/assets/boom-logo-v2-blue.png";
import IconEvent from "app/assets/icon-menu-event-details.png";
import IconChannel from "app/assets/icon-menu-event-channels.png";
import IconExhibitor from "app/assets/icon-menu-event-exhibitors.png";
import IconStoreTransactions from "app/assets/icon-module-ecommerce.png";
import IconAdsManager from "app/assets/icon-menu-event-ad-manager.png";
import IconGamification from "app/assets/icon-menu-event-gamification.png";
import IconStatistic from "app/assets/icon-menu-event-statistics.png";
import SessionStorageService from "app/services/sessionStorageService";
import LocalStorageService from "app/services/localStorageService";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/states/common";
import IconWarningTriangle from "app/assets/icon-alert-triangle-yellow.png";
import PublishEventModal from "../PublishEventModal/PublishEventModal";
import { eventActions } from "app/states/event";
import PauseEndEventModal from "../PauseEndEventModal/PauseEndEventModal";
import RepublishEventModal from "../RepublishEventModal/RepublishEventModal";
import { EVENT_STATUS, EVENT_ACTION } from "app/config/constants";
import EventAPI from "app/apis/event";
import moment from "moment";

const NavLink = ({ icon, title, link, activeRouteKeyword, activeRouteKeywordGroup, rightIcon }) => {
	const location = useLocation();

	const isActiveRoute = () => {
		if (activeRouteKeywordGroup && activeRouteKeywordGroup.includes(location.pathname)) {
			return true;
		} else if (location.pathname.includes(activeRouteKeyword)) {
			return true;
		}

		return false;
	};

	const handleLinkOnClick = () => {
		SessionStorageService.clearLastViewingPage();
		SessionStorageService.clearGamificationLeaderboardTop3();
	};

	return (
		<li className="nav-item main-nav-item mb-2">
			<Link
				className={`nav-link main-nav-link ${isActiveRoute() && "active"}`}
				to={link}
				onClick={handleLinkOnClick}>
				<img src={icon} alt={title} className="nav-icon" /> {title}{" "}
				{rightIcon && <img src={rightIcon} alt="warning" className="nav-warning-icon" />}
			</Link>
		</li>
	);
};

const SubNavLink = ({ icon, title, link, activeRouteKeyword, rightIcon }) => {
	const location = useLocation();

	const isActiveRoute = () => {
		if (location.pathname === link || location.pathname.includes(activeRouteKeyword)) {
			return true;
		}

		return false;
	};

	const handleLinkOnClick = () => {
		SessionStorageService.clearLastViewingPage();
		SessionStorageService.clearGamificationLeaderboardTop3();
	};

	return (
		<li className="nav-item sub-nav-item mb-2">
			<Link
				className={`nav-link sub-nav-link ${isActiveRoute() && "active"}`}
				to={link}
				onClick={handleLinkOnClick}>
				{title} {rightIcon && <img src={rightIcon} alt="warning" className="nav-warning-icon" />}
			</Link>
		</li>
	);
};

const EventSidebar = ({ history }) => {
	const { eventId, channelId, sessionId, roomId, productId, adsTypeId, adsId, visitorId } = useParams();

	const dispatch = useDispatch();
	const openPublishEventModal = () => dispatch(eventActions.openPublishEventModal());
	const openPauseEndEventModal = () => dispatch(eventActions.openPauseEndEventModal());
	const openRepublishEventModal = () => dispatch(eventActions.openRepublishEventModal());
	const setEventPublishAction = (action) => dispatch(eventActions.setEventPublishAction(action));

	const currentSubscriptionPlan = useSelector(({ billing }) => billing.currentSubscriptionPlan);
	const refreshEventDetailsCount = useSelector(({ event }) => event.refreshEventDetailsCount);

	const setIsEventPublished = (isEventPublished) => dispatch(eventActions.setIsEventPublished(isEventPublished));
	const isEventPublished = useSelector(({ event }) => event.isEventPublished);

	const setEventDetails = (eventDetails) => dispatch(eventActions.setEventDetails(eventDetails));
	const eventDetails = useSelector(({ event }) => event.eventDetails);

	const setCurrentEventStatus = (status) => dispatch(eventActions.setCurrentEventStatus(status));
	const currentEventStatus = useSelector(({ event }) => event.currentEventStatus);

	const eventLinkGroup = [
		`/events/view/${eventId}`,
		`/events/view/${eventId}/ticket`,
		`/events/view/${eventId}/visitors`,
		`/events/view/${eventId}/landingPageEditor`,
	];

	const exhibitorLinkGroup = [
		`/events/view/${eventId}/exhibitor/categories`,
		`/events/view/${eventId}/exhibitor/rooms`,
		`/events/view/${eventId}/exhibitor/rooms/add`,
		`/events/view/${eventId}/exhibitor/rooms/${roomId}`,
		`/events/view/${eventId}/exhibitor/rooms/${roomId}/product/add`,
		`/events/view/${eventId}/exhibitor/rooms/${roomId}/product/${productId}`,
	];

	const adsManagerLinkGroup = [
		`/events/view/${eventId}/ads`,
		`/events/view/${eventId}/ads/type/${adsTypeId}`,
		`/events/view/${eventId}/ads/type/${adsTypeId}/create`,
		`/events/view/${eventId}/ads/type/${adsTypeId}/view/${adsId}`,
		`/events/view/${eventId}/ads/type/${adsTypeId}/edit/${adsId}`,
	];

	const gamificationLinkGroup = [
		`/events/view/${eventId}/gamification/leaderboard`,
		`/events/view/${eventId}/gamification/leaderboard/view/${visitorId}`,
		`/events/view/${eventId}/gamification/configuration`,
	];

	// Get event details
	useEffect(() => {
		EventAPI.getEventDetails(eventId)
			.then((response) => {
				if (response.code === 200) {
					setEventDetails(response.data);
					LocalStorageService.setEventDetails(response.data);

					// Set event status
					setCurrentEventStatus(response.data.event_status_code);

					switch (response.data.event_status_code) {
						case EVENT_STATUS["publish"]["value"]:
						case EVENT_STATUS["resume"]["value"]:
						case EVENT_STATUS["republish"]["value"]:
							setIsEventPublished(true);
							break;
						default:
							setIsEventPublished(false);
					}
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [refreshEventDetailsCount]);

	// Scroll to top on change route
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleTogglePublishEvent = (checked) => {
		// To publish event
		if (checked) {
			switch (currentEventStatus) {
				case EVENT_STATUS["end"]["value"]:
					// If event ended, do republish action
					openRepublishEventModal();
					break;
				case EVENT_STATUS["pause"]["value"]:
					// If event paused, do resume action
					setEventPublishAction(EVENT_ACTION["resume"]);
					openPublishEventModal();
					break;
				default:
					openPublishEventModal();
			}
		} else {
			openPauseEndEventModal();
		}
	};

	return (
		<div className="pt-3 event-sidebar pb-5">
			<section className="px-3 mb-4">
				<img src={ImageLogo} alt="logo" className="img-fluid img-logo" />
			</section>

			{eventDetails && (
				<section className="event-publisher-container mx-3 mb-4">
					<div>
						<h4 className="event-title">{eventDetails.name}</h4>
						<span className="event-datetime">
							{moment(eventDetails.start_at).format("DD MMMM")} -{" "}
							{moment(eventDetails.end_at).format("DD MMMM")} |{" "}
							{moment(eventDetails.start_at).format("h:mmA")} -{" "}
							{moment(eventDetails.end_at).format("h:mmA")}
						</span>
					</div>

					<hr className="my-2" />
					<div className="d-flex justify-content-between align-items-center">
						<div>
							<div className="event-status-label mb-1">Event Status</div>
							<div className={`event-status ${isEventPublished ? "status-published" : ""}`}>
								{eventDetails.event_status_code
									? EVENT_STATUS[eventDetails.event_status_code]["display"]
									: EVENT_STATUS["unpublish"]["display"]}
							</div>
						</div>
						<div>
							<Switch
								checkedIcon={false}
								uncheckedIcon={false}
								checked={isEventPublished}
								onChange={handleTogglePublishEvent}
								width={68}
								height={30}
								handleDiameter={24}
								offColor="#D1D1D1"
								onColor="#34A853"
								className="mt-1"
							/>
						</div>
					</div>
				</section>
			)}

			<nav className="pr-3">
				<ul className="nav flex-column">
					<NavLink
						icon={IconEvent}
						title="Event Details"
						link={`/events/view/${eventId}`}
						activeRouteKeywordGroup={eventLinkGroup}
					/>
					<SubNavLink title="Details" link={`/events/view/${eventId}`} />
					<SubNavLink title="Ticket Price" link={`/events/view/${eventId}/ticket`} />
					<SubNavLink
						title="Visitors"
						link={`/events/view/${eventId}/visitors`}
						rightIcon={
							currentSubscriptionPlan &&
							currentSubscriptionPlan.show_warning &&
							currentSubscriptionPlan.show_warning.visitors
								? IconWarningTriangle
								: null
						}
					/>
					<SubNavLink title="Landing Page Editor" link={`/events/view/${eventId}/landingPageEditor`} />

					<NavLink
						icon={IconChannel}
						title="Channels"
						link={`/events/view/${eventId}/channels`}
						activeRouteKeyword="/channel"
						rightIcon={
							currentSubscriptionPlan &&
							currentSubscriptionPlan.show_warning &&
							currentSubscriptionPlan.show_warning.sessions
								? IconWarningTriangle
								: null
						}
					/>
					<SubNavLink
						title="Channel Room"
						link={`/events/view/${eventId}/channels`}
						activeRouteKeyword="/channels"
					/>
					<SubNavLink
						title="Session Control Room"
						link={`/events/view/${eventId}/channelSessionControlRoom`}
						activeRouteKeyword={`/channelSessionControlRoom`}
					/>

					<NavLink
						icon={IconExhibitor}
						title="Exhibitors"
						link={`/events/view/${eventId}/exhibitor/categories`}
						activeRouteKeywordGroup={exhibitorLinkGroup}
					/>
					<SubNavLink
						title="Category"
						link={`/events/view/${eventId}/exhibitor/categories`}
						activeRouteKeyword="/exhibitor/categories"
					/>
					<SubNavLink
						title="Exhibitor Rooms"
						link={`/events/view/${eventId}/exhibitor/rooms`}
						activeRouteKeyword="/exhibitor/rooms"
						rightIcon={
							currentSubscriptionPlan &&
							currentSubscriptionPlan.show_warning &&
							currentSubscriptionPlan.show_warning.exhibitors
								? IconWarningTriangle
								: null
						}
					/>

					{eventDetails && eventDetails.event_settings.ecommerce_enabled === 1 && (
						<NavLink
							icon={IconStoreTransactions}
							title="Store Transactions"
							link={`/events/view/${eventId}/store-transactions`}
							activeRouteKeyword={`/events/view/${eventId}/store-transactions`}
						/>
					)}

					<NavLink
						icon={IconAdsManager}
						title="Ads Manager"
						link={`/events/view/${eventId}/ads`}
						activeRouteKeywordGroup={adsManagerLinkGroup}
						rightIcon={
							currentSubscriptionPlan &&
							currentSubscriptionPlan.show_warning &&
							currentSubscriptionPlan.show_warning.advertisements
								? IconWarningTriangle
								: null
						}
					/>
					<NavLink
						icon={IconGamification}
						title="Gamification"
						link={`/events/view/${eventId}/gamification/leaderboard`}
						activeRouteKeywordGroup={gamificationLinkGroup}
					/>
					<SubNavLink
						title="Leaderboard"
						link={`/events/view/${eventId}/gamification/leaderboard`}
						activeRouteKeyword={`/events/view/${eventId}/gamification/leaderboard`}
					/>
					<SubNavLink title="Configuration" link={`/events/view/${eventId}/gamification/configuration`} />

					<NavLink
						icon={IconStatistic}
						title="Statistics & Reporting"
						link={`/events/view/${eventId}/statistics`}
					/>

					<li className="nav-item btn-back" onClick={() => history.replace("/events")}>
						Back to Organiser Dashboard
					</li>
				</ul>
			</nav>

			<PublishEventModal />
			<PauseEndEventModal />
			<RepublishEventModal />
		</div>
	);
};

export default withRouter(EventSidebar);
