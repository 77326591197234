import * as types from "./types"

const initialState = {
    showCustomModal: false,
    showLiveToggleModal: false,
    showLoading: false,
    showProfileModal: false,
    showForgotPasswordModal: false,
    showAlertSnackbar: false,
    alertSnackbarMessage: "",
    alertSnackbarVariant: "success",
    alertSnackbarDuration: 3000,
}


const CommonReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.OPEN_CUSTOM_MODAL: 
            return {
                ...state, 
                showCustomModal: true
            }
        case types.CLOSE_CUSTOM_MODAL: 
            return {
                ...state, 
                showCustomModal: false
            }

        case types.OPEN_LIVE_TOGGLE_MODAL: 
            return {
                ...state, 
                showLiveToggleModal: true
            }
        case types.CLOSE_LIVE_TOGGLE_MODAL: 
            return {
                ...state, 
                showLiveToggleModal: false
            }

        // Loading
        case types.SHOW_LOADING: 
            return {
                ...state, 
                showLoading: true
            }
        case types.HIDE_LOADING: 
            return {
                ...state, 
                showLoading: false
            }

        // Profile modal
        case types.OPEN_PROFILE_MODAL: 
            return {
                ...state, 
                showProfileModal: true
            }
        case types.CLOSE_PROFILE_MODAL: 
            return {
                ...state, 
                showProfileModal: false
            }

        // Forgot password modal
        case types.OPEN_FORGOT_PASSWORD_MODAL: 
            return {
                ...state, 
                showForgotPasswordModal: true
            }
        case types.CLOSE_FORGOT_PASSWORD_MODAL: 
            return {
                ...state, 
                showForgotPasswordModal: false
            }

        // SHOW ALERT SNACKBAR
        case types.OPEN_ALERT_SNACKBAR: 
            return {
                ...state, 
                showAlertSnackbar: true,
                alertSnackbarMessage: action.params.message,
                alertSnackbarVariant: action.params.variant,
                alertSnackbarDuration: action.params.duration,
            }
        case types.CLOSE_ALERT_SNACKBAR: 
            return {
                ...state, 
                showAlertSnackbar: false
            }
        default:
            return state
    }
}

export default CommonReducer;