import React from "react";
import EventSidebar from "../EventSidebar/EventSidebar";
import "./style.scss";

const EventLayoutContainer = ({ children }) => {
	return (
		<div className="container-fluid layout-container px-0">
            <div className="d-flex layout-content">
                <div className="sidebar-wrapper">
                    <EventSidebar />
                </div>
                <div className="content-wrapper p-4">
                    {children}
                </div>
            </div>
		</div>
	);
};

export default EventLayoutContainer;
