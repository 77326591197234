import LocalStorageService from "app/services/localStorageService";
import apiAction from "../services/apiAction";

const LandingPageAPI = {
	/**
	 * Get event landing page theme listing
	 */
	 getEventLandingPageThemeListing: (event_id) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/event_themes`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get event landing page detail
	 */
	getEventLandingPageDetail: (event_id) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/detail`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

    /**
	 * Update event landing page detail
     * @param {FormData} data
	 */
	postUpdateEventLandingPageDetail: (event_id, data) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/detail`;

			apiAction
				.post(url, data)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get speaker listing
	 * @param {object} queryParams
	 */
	getSpeakerListing: (event_id, queryParams = {}) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/speakers`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get event highlights listing
	 * @param {object} queryParams
	 */
	getEventHighlightsListing: (event_id, queryParams = {}) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/event_highlights`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get featured partners listing
	 * @param {int} type
	 * @param {object} queryParams
	 */
	getFeaturedPartnerListing: (event_id, type, queryParams = {}) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/event_partners/${type}`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Create/Update speaker\
	 * @param {int} speaker_id
	 * @param {FormData} data
	 */
	postCreateUpdateSpeaker: (event_id, speaker_id = "", data) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/speakers/${speaker_id}`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get speaker detail
	 * @param {int} speaker_id
	 */
	getSpeakerDetail: (event_id, speaker_id) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/speakers/${speaker_id}`;

			apiAction
				.get(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Delete speaker
	 * @param {int} speaker_id
	 */
	deleteSpeaker: (event_id, speaker_id) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/speakers/${speaker_id}`;

			apiAction
				.delete(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

    /**
	 * Create/Update event highlights
	 * @param {int} highlight_id
	 * @param {FormData} data
	 */
	postCreateUpdateEventHighlights: (event_id, highlight_id = "", data) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/event_highlights/${highlight_id}`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get event highlights detail
	 * @param {int} highlight_id
	 */
	getEventHighlightsDetail: (event_id, highlight_id) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/event_highlights/${highlight_id}`;

			apiAction
				.get(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Delete event highlights
	 * @param {int} highlight_id
	 */
	deleteEventHighlights: (event_id, highlight_id) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/event_highlights/${highlight_id}`;

			apiAction
				.delete(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

    /**
	 * Create/Update featured partners
     * @param {int} type
	 * @param {int} partner_id
	 * @param {FormData} data
	 */
	postCreateUpdateFeaturedPartners: (event_id, type, partner_id = "", data) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/event_partners/${type}/${partner_id}`;

			apiAction
				.post(url, data)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Get featured partners detail
	 * @param {int} type
     * @param {int} partner_id
	 */
	getFeaturedPartnersDetail: (event_id, type, partner_id) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/event_partners/${type}/${partner_id}`;

			apiAction
				.get(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},

	/**
	 * Delete featured partners
	 * @param {int} type
     * @param {int} partner_id
	 */
	deleteFeaturedPartners: (event_id, type, partner_id) => {
		return new Promise((resolve, reject) => {
            const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
            const organization_id = selectedOrganizationAccount.id;

			/** Define API Url Path */
			const url = `/organization/${organization_id}/event/${event_id}/landing_page/event_partners/${type}/${partner_id}`;

			apiAction
				.delete(url)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err.response));
		});
	},
};

export default LandingPageAPI;
