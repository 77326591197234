import React, { useState } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "app/states/event";
import IconClose from "app/assets/icon-close.png";
import ChannelSessionAPI from "app/apis/channelSession";
import { withRouter, useParams } from "react-router-dom";
import LocalStorageService from "app/services/localStorageService";
import IconAdd from "app/assets/icon-add-grey.png";
import IconDelete from "app/assets/icon-delete-grey.png";
import { commonActions } from "app/states/common";

const CreatePollModal = ({ history }) => {
	const userProfile = LocalStorageService.getUserProfile();

	const dispatch = useDispatch();
	const closeCreatePollModal = () => dispatch(eventActions.closeCreatePollModal());
	const refreshPollListing = (refresh) => dispatch(eventActions.refreshPollListing(refresh));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showCreatePollModal = useSelector(({ event }) => event.showCreatePollModal);
	const { sessionId } = useParams();

	const [poll_title, setPollTitle] = useState("");
	const [pollChoices, setPollChoices] = useState(["", ""]);

	const handleCloseCreatePollModal = () => {
		setPollTitle("");
		setPollChoices(["", ""]);
		closeCreatePollModal();
	}

	const handleCreatePoll = (event) => {
		event.preventDefault();

		const data = {
			session_id: sessionId,
			title: poll_title,
			poll_choices: JSON.stringify(pollChoices),
			created_by: userProfile.id,
		};

		ChannelSessionAPI.postAddPoll(data)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					refreshPollListing(true);
					handleCloseCreatePollModal();
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleAddOptions = () => {
		let pollChoicesCopy = [...pollChoices, ""];
		setPollChoices(pollChoicesCopy);
	};

	const handleDeleteNewOption = (index) => {
		let pollChoicesCopy = [...pollChoices];
		pollChoicesCopy.splice(index, 1);
		setPollChoices(pollChoicesCopy);
	};

	return (
		<Modal
			show={showCreatePollModal}
			onHide={handleCloseCreatePollModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="create-poll-modal"
			centered>
			<Modal.Body>
				<div className="create-poll-modal-body">
					<div className="d-flex justify-content-end">
						<img src={IconClose} alt="close" className="icon-close" onClick={handleCloseCreatePollModal} />
					</div>

					<h3>Add Poll</h3>

					<form method="post" onSubmit={handleCreatePoll}>
						<div className="form-group">
							<label htmlFor="question">Poll</label>
							<input
								id="poll_title"
								type="text"
								className="form-control"
								aria-describedby="poll_title"
								name="poll_title"
								value={poll_title}
								onChange={(event) => setPollTitle(event.target.value)}
								required
							/>
						</div>

						{pollChoices.map((num, index) => {
							const indexNum = index + 1;

							return (
								<div key={indexNum} className="form-group">
									<label htmlFor={`option-${indexNum}`}>Option {indexNum}</label>
									<div className="d-flex align-items-center">
										<input
											id={`option-${indexNum}`}
											type="text"
											className="form-control"
											aria-describedby={`option-${indexNum}`}
											name={`option-${indexNum}`}
											value={pollChoices[index]}
											onChange={(event) => {
												let pollChoicesCopy = [...pollChoices];
												pollChoicesCopy[index] = event.target.value;

												setPollChoices(pollChoicesCopy);
											}}
											required
										/>

										{/* Minimum shall have at least 2 options, so only allow additional options */}
										{index > 1 && (
											<img
												src={IconDelete}
												alt="Delete"
												className="icon-delete"
												onClick={() => handleDeleteNewOption(index)}
											/>
										)}
									</div>
								</div>
							);
						})}

						<button type="button" className="btn btn-add-options mt-4" onClick={handleAddOptions}>
							<img src={IconAdd} alt="add" /> Add Options
						</button>

						<button type="submit" className="btn btn-primary w-100 mt-5">
							Create
						</button>
					</form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(CreatePollModal);
