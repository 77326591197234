import React, { useState, useEffect, useRef } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import moment from "moment";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import ExhibitorAPI from "app/apis/exhibitor";
import { PLATFORM_TYPES } from "app/config/settings";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import { useDispatch } from "react-redux";
import { eventActions } from "app/states/event";
import { commonActions } from "app/states/common";

const StreamingContainer = ({ history, streamingDetails }) => {
	let { roomId } = useParams();

	const dispatch = useDispatch();
	const refreshExhibitorRoomDetails = (refresh) => dispatch(eventActions.refreshExhibitorRoomDetails(refresh));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const [roomSessionId, setRoomSessionId] = useState(null);
	const [inputData, setInputData] = useState({
		streaming_url: "",
		platform_type: "",
		logo: "",
		live: false,
		start_date: new Date(),
		start_time: new Date(),
		end_date: new Date(),
		end_time: new Date(),
	});

	const [uploadedLogo, setUploadedLogo] = useState(null);

	const logoRef = useRef();

	// Get exhibitor room details
	useEffect(() => {
		if (!streamingDetails) return;

		const { id, content_url, thumbnail_url, live, start_at, end_at, type } = streamingDetails;

		// Process start/end datetime
		let isStartAtValid = moment(start_at, moment.ISO_8601, true).isValid();
		let isEndAtValid = moment(end_at, moment.ISO_8601, true).isValid();

		let startDateTime = isStartAtValid ? new Date(start_at) : new Date();
		let endDateTime = isEndAtValid ? new Date(end_at) : new Date();

		setRoomSessionId(id);
		setInputData({
			streaming_url: content_url,
			platform_type: type === 0 ? 1 : type,
			logo: thumbnail_url,
			live: live ? true : false,
			start_date: startDateTime,
			start_time: startDateTime,
			end_date: endDateTime,
			end_time: endDateTime,
		});
	}, [streamingDetails]);

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleChangeLogo = (event) => {
		setUploadedLogo(event.target.files[0]);
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleSaveStreamingSettings = async (event) => {
		event.preventDefault();

		let formData = new FormData();

		// Process start/end time
		let formattedStartDate = moment(inputData.start_date).format("DD MMMM YYYY");
		let formattedStartTime = moment(inputData.start_time).format("h:mma");
		let startDateTime = moment(formattedStartDate + " " + formattedStartTime, "DD MMMM YYYY h:mma");
		let startAt = startDateTime.format("YYYY-MM-DD HH:mm:ss");

		let formattedEndDate = moment(inputData.end_date).format("DD MMMM YYYY");
		let formattedEndTime = moment(inputData.end_time).format("h:mma");
		let endDateTime = moment(formattedEndDate + " " + formattedEndTime, "DD MMMM YYYY h:mma");
		let endAt = endDateTime.format("YYYY-MM-DD HH:mm:ss");

		// Check end datetime and start datetime
		if (!endDateTime.isAfter(startDateTime)) {
			openAlertSnackbar("Start date must be earlier than end date.", "error");
			return;
		}

		formData.append("room_id", roomId);
		formData.append("content_url", inputData.streaming_url);
		formData.append("type", inputData.platform_type);
		formData.append("start_at", startAt);
		formData.append("end_at", endAt);

		if (uploadedLogo) {
			formData.append("thumbnail", uploadedLogo);
		}

		ExhibitorAPI.postUpdateEventRoomStreaming(roomSessionId, formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					refreshExhibitorRoomDetails(true);
					openAlertSnackbar("Streaming details updated", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	return (
		<section className="tab-panel-container streaming-container mb-4">
			<form method="post" onSubmit={handleSaveStreamingSettings}>
				<div className="row mb-3">
					<div className="col-9">
						<div className="form-group">
							<label htmlFor="streaming_url">Streaming URL</label>
							<input
								id="streaming_url"
								type="text"
								className="form-control"
								aria-describedby="streaming_url"
								name="streaming_url"
								value={inputData.streaming_url}
								onChange={handleInputOnChange}
								required
							/>
						</div>
					</div>
					<div className="col-3">
						<div className="form-group">
							<label htmlFor="platform_type">Platform Type</label>
							<div className="select-wrapper">
								<select
									id="platform_type"
									className="form-control"
									aria-describedby="platform_type"
									name="platform_type"
									value={inputData.platform_type}
									onChange={handleInputOnChange}>
									{Object.keys(PLATFORM_TYPES).map((platform) => {
										return (
											<option key={platform} value={PLATFORM_TYPES[platform]["value"]}>
												{PLATFORM_TYPES[platform]["display"]}
											</option>
										);
									})}
								</select>
							</div>
						</div>
					</div>

					<div className="col-6">
						<div className="form-group">
							<label htmlFor="start_date">Start Date</label>
							<DatePicker
								selected={inputData.start_date}
								onChange={(date) => setInputData({ ...inputData, start_date: date })}
								wrapperClassName="d-block"
								className="form-control input-datetime"
								dateFormat="dd MMMM yyyy"
							/>
						</div>
					</div>
					<div className="col-6">
						<div className="form-group">
							<label htmlFor="start_time">Start Time</label>
							<DatePicker
								selected={inputData.start_time}
								onChange={(date) => setInputData({ ...inputData, start_time: date })}
								wrapperClassName="d-block"
								className="form-control input-datetime"
								showTimeInput
								showTimeSelectOnly
								timeCaption="Time"
								dateFormat="h:mm aa"
							/>
						</div>
					</div>
					<div className="col-6">
						<div className="form-group">
							<label htmlFor="end_date">End Date</label>
							<DatePicker
								selected={inputData.end_date}
								onChange={(date) => setInputData({ ...inputData, end_date: date })}
								wrapperClassName="d-block"
								className="form-control input-datetime"
								dateFormat="dd MMMM yyyy"
								// minDate={inputData.start_date}
							/>
						</div>
					</div>
					<div className="col-6">
						<div className="form-group">
							<label htmlFor="end_time">End Time</label>
							<DatePicker
								selected={inputData.end_time}
								onChange={(date) => setInputData({ ...inputData, end_time: date })}
								wrapperClassName="d-block"
								className="form-control input-datetime"
								showTimeInput
								showTimeSelectOnly
								timeCaption="Time"
								dateFormat="h:mm aa"
							/>
						</div>
					</div>

					<div className="col-12">
						<div className="row">
							<div className="col-5">
								<div className="form-group">
									<label>Banner Image</label>
									<div className="d-flex align-items-start">
										{uploadedLogo || inputData.logo ? (
											<img
												src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : inputData.logo}
												alt="banner"
												className="img-logo"
												onClick={browsePicture}
											/>
										) : (
											<img
												src={IconUpload}
												alt="upload banner"
												className="img-upload"
												onClick={browsePicture}
											/>
										)}
										<input
											type="file"
											name="logo"
											onChange={handleChangeLogo}
											ref={logoRef}
											className="d-none"
											accept="image/*"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-10"></div>
					<div className="col-2">
						<button type="submit" className="btn btn-primary w-100">
							Save
						</button>
					</div>
				</div>
			</form>
		</section>
	);
};

export default withRouter(StreamingContainer);
