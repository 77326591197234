/* eslint-disable react-hooks/exhaustive-deps */
import AuthenticationAPI from "app/apis/authentication";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import ImageLogin from "app/assets/bg-login-page.png";
import ImageLogo from "app/assets/boom-logo@2x.png";
import "./style.scss";
import TokenService from "app/services/tokenService";
import LocalStorageService from "app/services/localStorageService";
import { useDispatch } from "react-redux";
import { commonActions } from "app/states/common";
import ForgotPasswordModal from "app/views/components/ForgotPasswordModal/ForgotPasswordModal";
import useQueryParams from "app/views/hooks/useQueryParams";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SessionStorageService from "app/services/sessionStorageService";

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`auth-tabpanel-${index}`}
			aria-labelledby={`auth-tab-${index}`}
			{...other}>
			{value === index && <Box py={3}>{children}</Box>}
		</div>
	);
};

function switchTab(index) {
	return {
		id: `auth-tab-${index}`,
		"aria-controls": `auth-tabpanel-${index}`,
	};
}

const Login = ({ history }) => {
	const queryParams = useQueryParams();

	const dispatch = useDispatch();
	const openForgotPasswordModal = () => dispatch(commonActions.openForgotPasswordModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const tokenExpired = SessionStorageService.getTokenExpired();

	const [tabIndex, setTabIndex] = useState(0);
	const [isPrefillEmail, setIsPrefillEmail] = useState(false);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [registerInputData, setRegisterInputData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
		confirm_password: "",
		agree_privacy: false,
		agree_tnc: false,
	});

	// When token expired, show alert
	useEffect(() => {
		if (tokenExpired) {
			SessionStorageService.clearTokenExpired();
			alert("Your login session has expired. Please login again.");
		}
	}, []);

	// Ensure cached data is removed
	useEffect(() => {
		// If has access token, redirect to dashboard
		const accessToken = TokenService.getAccessToken();
		if (accessToken) {
			history.push("/dashboard");
		} else {
			LocalStorageService.clearAll();
		}
	}, []);

	// Handle query params
	useEffect(() => {
		// Prefill email (if any)
		const email = queryParams.get("email");
		if (email) {
			setEmail(email);
			setRegisterInputData({ ...registerInputData, email: email });
			setIsPrefillEmail(true);
		}

		// Set the tab index based on action (if any)
		const action = queryParams.get("action");
		if (action && action === "register") {
			setTabIndex(1);
		}
	}, []);

	const handleTabChange = (event, newTabIndex) => {
		setTabIndex(newTabIndex);
	};

	/**
	 * Handle submit login
	 * @param {*} event
	 */
	const handleSubmitLogin = (event) => {
		event.preventDefault();

		AuthenticationAPI.postLogin(email, password)
			.then((res) => {
				const { code } = res;

				if (code === 200) {
					const { token, user } = res.data;

					// Clear all before cache the data
					LocalStorageService.clearAll();
					SessionStorageService.clearTokenExpired();

					// Cache the access token and user profile
					TokenService.setAccessToken(token);
					LocalStorageService.setUserProfile(user);

					history.push("/dashboard");
				} else {
					console.error(res);
					openAlertSnackbar(res.errors.join("\n"), "error");
					return;
				}
			})
			.catch((error) => {
				if (error && error.data && error.data.errors) {
					openAlertSnackbar(error.data.errors.join("\n"), "error");
				}
			});
	};

	/**
	 * States and functions for register part
	 */

	const handleInputOnChange = (event) => {
		setRegisterInputData({ ...registerInputData, [event.target.name]: event.target.value });
	};

	const handleGoNext = (event) => {
		event.preventDefault();

		// Check if user email is registered
		AuthenticationAPI.getIsRegistered(registerInputData.email)
			.then((response) => {
				const { isRegistered } = response;

				if (isRegistered) {
					openAlertSnackbar("This email had been used.", "error");
					return;
				} else {
					// Check password and confirm password is same
					if (registerInputData.password !== registerInputData.confirm_password) {
						openAlertSnackbar("Password does not match.", "error");
						return;
					}

					history.push("/register/part2", {
						registerInputData: registerInputData,
					});
				}
			})
			.catch((error) => {
				if (error && error.data && error.data.errors) {
					openAlertSnackbar(error.data.errors.join("\n"), "error");
				}
			});
	};

	return (
		<div id="login-page" className="container-fluid vh-100">
			<div className="row vh-100">
				<div className="col-6 login-left px-5">
					<section className="my-5">
						<div>
							<img src={ImageLogo} alt="logo" className="img-logo" />
						</div>
						<h5>Your global host for online and hybrid events.</h5>
					</section>
					<img src={ImageLogin} alt="Login" className="img-fluid" />
				</div>
				<div className="col-6 login-right">
					<section className="right-heading mb-5">
						<div className="title mb-2">
							{tabIndex === 0 ? "Welcome back to boom!" : "Welcome to boom!"}
						</div>
						<div className="subtitle">
							{tabIndex === 0
								? "Please login with your organiser account to manage your events."
								: "Please sign up for an organiser account to manage your events."}
						</div>
					</section>

					<Tabs
						value={tabIndex}
						onChange={handleTabChange}
						aria-label="Login Register Tabs"
						indicatorColor="primary"
						textColor="primary"
						variant="fullWidth"
						className="mb-4">
						<Tab label="Login" {...switchTab(0)} className="tab" />
						<Tab label="Sign Up" {...switchTab(1)} className="tab" />
					</Tabs>

					<TabPanel value={tabIndex} index={0}>
						<div className="d-flex flex-column align-items-center">
							<form id="form-login" className="w-100" onSubmit={handleSubmitLogin}>
								<div className="form-group text-center">
									<label htmlFor="email">Email Address</label>
									<input
										id="email"
										type="text"
										className="form-control text-center"
										aria-describedby="email"
										name="email"
										value={email}
										onChange={(event) => setEmail(event.target.value)}
										required
										disabled={isPrefillEmail}
									/>
								</div>
								<div className="form-group text-center mb-2">
									<label htmlFor="password">Password</label>
									<input
										id="password"
										type="password"
										className="form-control text-center"
										aria-describedby="password"
										name="password"
										value={password}
										onChange={(event) => setPassword(event.target.value)}
										required
									/>
								</div>
								<div className="text-center">
									<span className="text-forgot-password" onClick={openForgotPasswordModal}>
										Forgot Password
									</span>
								</div>
								<div className="mt-5 text-center mb-1">
									<button type="submit" className="btn btn-primary btn-sign-in px-5">
										Sign In
									</button>
								</div>
							</form>
						</div>
					</TabPanel>

					<TabPanel value={tabIndex} index={1}>
						<form id="form-register" className="w-100" onSubmit={handleGoNext}>
							<div className="row">
								<div className="col-6">
									<div className="form-group">
										<label htmlFor="first_name">
											First Name <span className="text-danger">*</span>
										</label>
										<input
											id="first_name"
											type="text"
											className="form-control"
											aria-describedby="first_name"
											name="first_name"
											value={registerInputData.first_name}
											onChange={handleInputOnChange}
											required
										/>
									</div>
								</div>
								<div className="col-6">
									<div className="form-group">
										<label htmlFor="last_name">
											Last Name <span className="text-danger">*</span>
										</label>
										<input
											id="last_name"
											type="text"
											className="form-control"
											aria-describedby="last_name"
											name="last_name"
											value={registerInputData.last_name}
											onChange={handleInputOnChange}
											required
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label htmlFor="email">
											Email Address <span className="text-danger">*</span>
										</label>
										<input
											id="email"
											type="email"
											className="form-control"
											aria-describedby="email"
											name="email"
											value={registerInputData.email}
											onChange={handleInputOnChange}
											required
											disabled={isPrefillEmail}
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label htmlFor="password">
											Password <span className="text-danger">*</span>
										</label>
										<input
											id="password"
											type="password"
											className="form-control"
											aria-describedby="password"
											name="password"
											value={registerInputData.password}
											onChange={handleInputOnChange}
											required
										/>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label htmlFor="confirm_password">
											Confirm Password <span className="text-danger">*</span>
										</label>
										<input
											id="confirm_password"
											type="password"
											className="form-control"
											aria-describedby="confirm_password"
											name="confirm_password"
											value={registerInputData.confirm_password}
											onChange={handleInputOnChange}
											required
										/>
									</div>
								</div>
							</div>

							<div className="px-3 mt-3">
								<div className="form-check form-group mb-1">
									<input
										className="form-check-input custom-outline-checkbox"
										type="checkbox"
										id="agree_privacy"
										onChange={() =>
											setRegisterInputData({
												...registerInputData,
												agree_privacy: !registerInputData.agree_privacy,
											})
										}
										checked={registerInputData.agree_privacy}
										required
									/>
									<label className="form-check-label custom-checkbox-label" htmlFor="agree_privacy">
										I have read and agree to the{" "}
										<a href="/" target="_blank" className="text-link">
											Privacy Policy
										</a>
									</label>
								</div>
								<div className="form-check form-group">
									<input
										className="form-check-input custom-outline-checkbox"
										type="checkbox"
										id="agree_tnc"
										onChange={() =>
											setRegisterInputData({
												...registerInputData,
												agree_tnc: !registerInputData.agree_tnc,
											})
										}
										checked={registerInputData.agree_tnc}
										required
									/>
									<label className="form-check-label custom-checkbox-label" htmlFor="agree_tnc">
										I have read and agree to the{" "}
										<a href="/" target="_blank" className="text-link">
											Terms of Use
										</a>
									</label>
								</div>
							</div>

							<div className="mt-4 text-center mb-1">
								<button type="submit" className="btn btn-primary btn-next px-5">
									Next
								</button>
							</div>
						</form>
					</TabPanel>
				</div>
			</div>

			<ForgotPasswordModal />
		</div>
	);
};

export default withRouter(Login);
