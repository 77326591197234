/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams, Link } from "react-router-dom";
import "./style.scss";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import { commonActions } from "app/states/common";
import { useDispatch } from "react-redux";
import moment from "moment";
import { formatCurrencyNumbers } from "app/helper/common";
import IconBack from "app/assets/icon-back-grey.png";
import EventInfo from "app/views/components/EventInfo/EventInfo";
import OrdersAPI from "app/apis/orders";
import IconDefaultUser from "app/assets/icon-default-user.png";
import { DELIVERY_METHODS, ORDER_STATUS } from "app/config/constants";

const StoreTransactionDetail = ({ history }) => {
	const { eventId, transactionId } = useParams();

	const dispatch = useDispatch();
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());

	const [orderDetails, setOrderDetails] = useState(null);
	const [buyerDetails, setBuyerDetails] = useState(null);
	const [exhibitorDetails, setExhibitorDetails] = useState(null);
	const [orderSubtotal, setOrderSubtotal] = useState(0);

	const handleViewBuyer = () => {
		let visitorId = 73;
		const win = window.open(`/visitors/view/${visitorId}`, "_blank");
		win.focus();
	};

	const handleViewProduct = () => {
		let roomId = 48;
		let productId = 70;
		const win = window.open(`/events/view/${eventId}/exhibitor/rooms/${roomId}/product/${productId}`, "_blank");
		win.focus();
	};

	// Get order details
	useEffect(() => {
		showLoading();
		OrdersAPI.getOrderDetails(transactionId)
			.then((response) => {
				const { visitor, exhibitor, items } = response.data;

				setOrderDetails(response.data);
				setBuyerDetails(visitor);
				setExhibitorDetails(exhibitor);

				const subtotalPrice = items
					.map((item) => item.product_sub_total)
					.reduce((subtotal, productSubTotal) => {
						return subtotal + productSubTotal;
					});
				setOrderSubtotal(subtotalPrice);
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				hideLoading();
			});
	}, []);

	return (
		<EventLayoutContainer>
			<div id="store-transaction-detail-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-5">
					<div className="d-flex align-items-center">
						<div className="btn-back" onClick={() => history.goBack()}>
							<img src={IconBack} alt="back" className="img-fluid" />
						</div>
						<div>
							<h2 className="title">Store Transaction Details</h2>
							<span className="subtitle">Store Transactions &gt; View Order</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="buyer-details-section mb-5">
					<h3>Buyer's Details</h3>
					<div className="row">
						<div className="col-8">
							{buyerDetails && orderDetails && (
								<div className="buyer-details-container">
									<div
										className="d-inline-flex align-items-center mb-4 cursor-pointer"
										onClick={handleViewBuyer}>
										<img
											src={buyerDetails.image || IconDefaultUser}
											alt="profile"
											className="img-profile"
										/>
										<div>
											<div className="buyer-name mb-1">{buyerDetails.name ?? "N/A"}</div>
											<div className="buyer-email">{buyerDetails.email ?? "N/A"}</div>
										</div>
									</div>
									<div className="details">
										<div className="row mb-1">
											<div className="col-3">
												<label className="detail-label">Recipient Name:</label>
											</div>
											<div className="col-6 pl-0">
												<span className="detail-values">{buyerDetails.name ?? "N/A"}</span>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-3">
												<label className="detail-label">Contact Number:</label>
											</div>
											<div className="col-6 pl-0">
												<span className="detail-values">{buyerDetails.phone_no ?? "N/A"}</span>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-3">
												<label className="detail-label">Delivery Address:</label>
											</div>
											<div className="col-6 pl-0">
												<span className="detail-values">
													{orderDetails.delivery_address ?? "N/A"}
												</span>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-3">
												<label className="detail-label">Delivery Method:</label>
											</div>
											<div className="col-6 pl-0">
												<span className="detail-values text-courier">
													{DELIVERY_METHODS[orderDetails.delivery_method] ?? "N/A"}
												</span>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-3">
												<label className="detail-label">Payment Details:</label>
											</div>
											<div className="col-6 pl-0">
												<span className="detail-values">{orderDetails.card_no}</span>
											</div>
										</div>
										<div className="row mb-1">
											<div className="col-3">
												<label className="detail-label">Date of Purchase:</label>
											</div>
											<div className="col-6 pl-0">
												<span className="detail-values">
													{moment(orderDetails.created_at).format("Do MMMM YYYY, hh:mma")}
												</span>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="col-1"></div>
						<div className="col-3">
							{orderDetails && (
								<div className="order-details-container">
									<div className="title">Order Number</div>
									<div className="order-number">#{orderDetails.order_no}</div>
									<hr className="my-4 w-100" />
									<div className="title mb-0">Order Status</div>
									<div className="last-update mb-3">
										Last Updated on {moment(orderDetails.updated_at).format("Do MMMM YYYY, hh:mma")}
									</div>
									<div className="status">{ORDER_STATUS[orderDetails.status] ?? "N/A"}</div>
								</div>
							)}
						</div>
					</div>
				</section>

				<section className="purchased-items-section mb-5">
					<h3>Purchased Items</h3>
					<table className="table table-hover mb-1">
						<thead>
							<tr>
								<th scope="col" className="table-header text-left" style={{ width: "13%" }}>
									Item Image
								</th>
								<th scope="col" className="table-header text-left" style={{ width: "61%" }}>
									Item Name
								</th>
								<th scope="col" className="table-header" style={{ width: "13%" }}>
									Quantity
								</th>
								<th scope="col" className="table-header" style={{ width: "13%" }}>
									Price
								</th>
							</tr>
						</thead>
						<tbody>
							{orderDetails &&
								orderDetails.items.map((product, index) => {
									const {
										product_image,
										product_name,
										product_quantity,
										product_unit_price,
										product_currency,
										product_size,
										product_color,
										product_option,
									} = product;

									return (
										<tr
											key={`product-${index}`}
											className="cursor-pointer"
											onClick={handleViewProduct}>
											<td className="text-left">
												<img src={product_image} alt="item" className="item-thumbnail" />
											</td>
											<td>
												<div className="item-name">{product_name}</div>
												<div className="d-flex align-items-center flex-wrap item-variant">
													{product_size && (
														<div className="variant-option">
															Size: <span className="size-value">{product_size}</span>
														</div>
													)}
													{product_color && (
														<div className="variant-option">
															Color: <span className="color-value">{product_color}</span>
														</div>
													)}
													{product_option && (
														<div className="variant-option">
															Option:{" "}
															<span className="option-value">{product_option}</span>
														</div>
													)}
												</div>
											</td>
											<td className="item-quantity">{product_quantity}</td>
											<td className="item-price">
												{product_currency}
												{formatCurrencyNumbers(product_unit_price)}
											</td>
										</tr>
									);
								})}
						</tbody>
						{orderDetails && (
							<tfoot>
								<tr>
									<td colSpan={3} className="tfoot-label">
										<div className="my-3">Subtotal ({orderDetails.items.length} items):</div>
										<div className="mb-3">Delivery Fees:</div>
									</td>
									<td className="item-price">
										<div className="my-3">
											{orderDetails.currency}
											{formatCurrencyNumbers(orderSubtotal)}
										</div>
										<div className="mb-3">
											{orderDetails.currency}
											{formatCurrencyNumbers(orderDetails.delivery_fee)}
										</div>
									</td>
								</tr>
								<tr>
									<td colSpan={3} className="tfoot-label">
										<div className="my-3">Total:</div>
									</td>
									<td className="item-price">
										<div className="my-3">
											{orderDetails.currency}
											{formatCurrencyNumbers(orderDetails.total)}
										</div>
									</td>
								</tr>
							</tfoot>
						)}
					</table>
				</section>

				{exhibitorDetails && (
					<section className="seller-section">
						<h3>Seller Name</h3>
						<Link
							to={`/events/view/${eventId}/exhibitor/rooms/${exhibitorDetails.id}`}
							target="_blank"
							rel="noopener noreferrer">
							<div className="seller-thumbnail mt-3">
								<div className="thumbnail-content">
									<img
										src={exhibitorDetails.logo || IconDefaultUser}
										alt="seller"
										className="img-fluid"
									/>
									<div className="seller-name">{exhibitorDetails.name}</div>
								</div>
							</div>
						</Link>
					</section>
				)}
			</div>
		</EventLayoutContainer>
	);
};

export default withRouter(StoreTransactionDetail);
