import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router";
import { InitAxiosInterceptor } from "./app/services/axiosInterceptor";
import AlertSnackbar from "app/views/components/Snackbar/AlertSnackbar";

import Routes from "./app/routes";
import history from "./app/routes/history";
import reduxStore from "./app/services/redux/reduxStore";

InitAxiosInterceptor();

function App() {
	return (
		<Provider store={reduxStore}>
			<Router history={history}>
				<Routes />
			</Router>

			<AlertSnackbar />
		</Provider>
	);
}

export default App;
