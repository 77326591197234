/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import IconEditGrey from "app/assets/icon-edit-grey.png";
import IconDeleteGrey from "app/assets/icon-delete-grey.png";
import IconAddGrey from "app/assets/icon-add-grey.png";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import { useDispatch, useSelector } from "react-redux";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import moment from "moment";
import { commonActions } from "app/states/common";
import { PLATFORM_TYPES } from "app/config/settings";
import { FEATURED_PARTNER_TYPES, LANDING_PAGE_BG_THEME_COLORS } from "app/config/constants";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AddSpeakerModal from "app/views/containers/EventLandingPage/AddSpeakerModal/AddSpeakerModal";
import { eventActions } from "app/states/event";
import EditSpeakerModal from "app/views/containers/EventLandingPage/EditSpeakerModal/EditSpeakerModal";
import AddEventPartnerModal from "app/views/containers/EventLandingPage/AddEventPartnerModal/AddEventPartnerModal";
import EditEventPartnerModal from "app/views/containers/EventLandingPage/EditEventPartnerModal/EditEventPartnerModal";
import AddEventHighlightsModal from "app/views/containers/EventLandingPage/AddEventHighlightsModal/AddEventHighlightsModal";
import EditEventHighlightsModal from "app/views/containers/EventLandingPage/EditEventHighlightsModal/EditEventHighlightsModal";
import LandingPageAPI from "app/apis/landingPage";
import IconDefaultUser from "app/assets/icon-default-user.png";
import CustomModal from "app/views/components/CustomModal/CustomModal";
import IconDelete from "app/assets/icon-delete-red.png";
import EventInfo from "app/views/components/EventInfo/EventInfo";

const LandingPageEditor = ({ history }) => {
	const { eventId } = useParams();

	const dispatch = useDispatch();
	const openCustomModal = () => dispatch(commonActions.openCustomModal());
	const closeCustomModal = () => dispatch(commonActions.closeCustomModal());
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const openAddSpeakerModal = () => dispatch(eventActions.openAddSpeakerModal());
	const openEditSpeakerModal = () => dispatch(eventActions.openEditSpeakerModal());
	const setViewingSpeaker = (speaker) => dispatch(eventActions.setViewingSpeaker(speaker));
	const refreshSpeakerList = () => dispatch(eventActions.refreshSpeakerList());
	const refreshSpeakerListCount = useSelector(({ event }) => event.refreshSpeakerListCount);

	const openAddEventHighlightsModal = () => dispatch(eventActions.openAddEventHighlightsModal());
	const openEditEventHighlightsModal = () => dispatch(eventActions.openEditEventHighlightsModal());
	const setViewingEventHighlights = (eventHighlights) =>
		dispatch(eventActions.setViewingEventHighlights(eventHighlights));
	const refreshEventHighlightsList = () => dispatch(eventActions.refreshEventHighlightsList());
	const refreshEventHighlightsListCount = useSelector(({ event }) => event.refreshEventHighlightsListCount);

	const openAddEventPartnerModal = () => dispatch(eventActions.openAddEventPartnerModal());
	const openEditEventPartnerModal = () => dispatch(eventActions.openEditEventPartnerModal());
	const setViewingEventPartner = (eventPartner) => dispatch(eventActions.setViewingEventPartner(eventPartner));
	const setCurrentEventPartnerKey = (eventPartnerKey) =>
		dispatch(eventActions.setCurrentEventPartnerKey(eventPartnerKey));
	const refreshEventPartnerList = () => dispatch(eventActions.refreshEventPartnerList());
	const currentEventPartnerKey = useSelector(({ event }) => event.currentEventPartnerKey);
	const refreshEventPartnerListCount = useSelector(({ event }) => event.refreshEventPartnerListCount);

	const [eventUrl, setEventUrl] = useState(null);
	const [selectedTheme, setSelectedTheme] = useState(null);
	const [bgThemeColor, setBgThemeColor] = useState(LANDING_PAGE_BG_THEME_COLORS[0]);
	const [inputData, setInputData] = useState({
		cover_image: "",
		event_video: "",
		platform_type: PLATFORM_TYPES["local_upload"]["value"],
		event_description: "",
		website: "",
		linkedin: "",
		facebook: "",
		instagram: "",
		agenda_enabled: 0,
		preview_token: "",
	});

	const [organizerInput, setOrganizerInput] = useState({
		company_photo: "",
		company_name: "",
		company_description: "",
		organizer_website: "",
		organizer_linkedin: "",
		organizer_facebook: "",
		organizer_instagram: "",
	});

	const [uploadedCoverImage, setUploadedCoverImage] = useState(null);
	const coverImageRef = useRef();

	const [uploadedCompanyPhoto, setUploadedCompanyPhoto] = useState(null);
	const companyPhotoRef = useRef();

	const [speakerListing, setSpeakerListing] = useState([]);
	const [eventHighlightListing, setEventHighlightsListing] = useState([]);
	const [featuredPartnerListing, setFeaturedPartnerListing] = useState([]);

	const [deleteModalKey, setDeleteModalKey] = useState(null);
	const [deleteModalText, setDeleteModalText] = useState(null);
	const [deleteId, setDeleteId] = useState(null);

	// Get event landing page details
	useEffect(() => {
		LandingPageAPI.getEventLandingPageThemeListing(eventId)
			.then((response) => {
				const { data } = response;

				if (data.length > 0) {
					setSelectedTheme(data[0]);
					setBgThemeColor(`#${data[0].background_color}`);
				}
			})
			.catch((error) => {
				console.error(error);
			});

		LandingPageAPI.getEventLandingPageDetail(eventId)
			.then((response) => {
				const { event_detail, organizer, event_url } = response.data;

				// Set preview event url
				let previewEventUrl = event_url;
				const previewToken = event_detail.find((detail) => detail.key === "preview_token");
				if (previewToken && previewToken.value) {
					previewEventUrl = event_url + "?preview_token=" + previewToken.value;
				}
				setEventUrl(previewEventUrl);

				// Get agenda enabled flag
				let agendaEnabledFlag = event_detail.find((detail) => detail.key === "publish_sessions_event_agenda");

				// Set input
				setInputData({
					...inputData,
					cover_image: event_detail.find((detail) => detail.key === "event_cover_image_url")?.value || "",
					event_video: event_detail.find((detail) => detail.key === "event_video_url")?.value || "",
					platform_type:
						event_detail.find((detail) => detail.key === "event_video_platform_type")?.value ||
						PLATFORM_TYPES["local_upload"]["value"],
					event_description: event_detail.find((detail) => detail.key === "event_description")?.value || "",
					website: event_detail.find((detail) => detail.key === "website_url")?.value || "",
					linkedin: event_detail.find((detail) => detail.key === "linkedin_url")?.value || "",
					facebook: event_detail.find((detail) => detail.key === "facebook_url")?.value || "",
					instagram: event_detail.find((detail) => detail.key === "instagram_url")?.value || "",
					agenda_enabled:
						agendaEnabledFlag && agendaEnabledFlag.value ? parseInt(agendaEnabledFlag.value) : 0,
				});

				setOrganizerInput({
					company_photo: organizer.company_logo_url || "",
					company_name: organizer.company_name || "",
					company_description: organizer.description || "",
					organizer_website: organizer.website_url || "",
					organizer_linkedin: organizer.linkedin_url || "",
					organizer_facebook: organizer.facebook_url || "",
					organizer_instagram: organizer.instagram_url || "",
				});
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	// Get speaker listing
	useEffect(() => {
		LandingPageAPI.getSpeakerListing(eventId)
			.then((response) => {
				setSpeakerListing(response.data.speakers);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [refreshSpeakerListCount]);

	// Get event highlights listing
	useEffect(() => {
		LandingPageAPI.getEventHighlightsListing(eventId)
			.then((response) => {
				setEventHighlightsListing(response.data.event_highlights);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [refreshEventHighlightsListCount]);

	// Get featured partner listing
	useEffect(() => {
		LandingPageAPI.getFeaturedPartnerListing(eventId, FEATURED_PARTNER_TYPES[currentEventPartnerKey]["type"])
			.then((response) => {
				setFeaturedPartnerListing(response.data[currentEventPartnerKey]);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [refreshEventPartnerListCount, currentEventPartnerKey]);

	const handleInputOnChange = (event) => {
		if (event.target.name === "agenda_enabled") {
			setInputData({ ...inputData, [event.target.name]: !inputData.agenda_enabled ? 1 : 0 });
		} else {
			setInputData({ ...inputData, [event.target.name]: event.target.value });
		}
	};

	const handleOrganizerInputOnChange = (event) => {
		setOrganizerInput({ ...organizerInput, [event.target.name]: event.target.value });
	};

	const handleChangeCoverImage = (event) => {
		setUploadedCoverImage(event.target.files[0]);
	};

	const browsePicture = () => {
		coverImageRef.current.click();
	};

	const handleChangeCompanyPhoto = (event) => {
		setUploadedCompanyPhoto(event.target.files[0]);
	};

	const browseCompanyPhoto = () => {
		companyPhotoRef.current.click();
	};

	const handleUpdateLandingPage = (event) => {
		event.preventDefault();

		// Validation
		if (!inputData.cover_image && !uploadedCoverImage) {
			openAlertSnackbar("Cover Image is required.", "error");
			return;
		}

		if (!organizerInput.company_photo && !uploadedCompanyPhoto) {
			openAlertSnackbar("Company Photo is required.", "error");
			return;
		}

		// Form Data
		showLoading();
		let formData = new FormData();

		// General event details
		if (uploadedCoverImage) {
			formData.append("event_cover_image", uploadedCoverImage);
		}
		if (selectedTheme) {
			formData.append("event_detail[theme_id]", selectedTheme.theme_id);
		}

		formData.append("event_detail[event_video_url]", inputData.event_video);
		formData.append("event_detail[event_video_platform_type]", inputData.platform_type);
		formData.append("event_detail[website_url]", inputData.website);
		formData.append("event_detail[linkedin_url]", inputData.linkedin);
		formData.append("event_detail[facebook_url]", inputData.facebook);
		formData.append("event_detail[instagram_url]", inputData.instagram);
		formData.append("event_detail[event_description]", inputData.event_description);
		formData.append("event_detail[publish_sessions_event_agenda]", inputData.agenda_enabled);

		// Organizer details
		if (uploadedCompanyPhoto) {
			formData.append("company_logo", uploadedCompanyPhoto);
		}

		formData.append("organizer[company_name]", organizerInput.company_name);
		formData.append("organizer[description]", organizerInput.company_description);
		formData.append("organizer[website_url]", organizerInput.organizer_website);
		formData.append("organizer[linkedin_url]", organizerInput.organizer_linkedin);
		formData.append("organizer[facebook_url]", organizerInput.organizer_facebook);
		formData.append("organizer[instagram_url]", organizerInput.organizer_instagram);

		LandingPageAPI.postUpdateEventLandingPageDetail(eventId, formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					openAlertSnackbar("Landing Page Detail Updated.", "success");
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleSelectBgThemeColor = (color) => {
		setBgThemeColor(color);
	};

	const handlePreview = () => {
		if (eventUrl) {
			window.open(eventUrl, "_blank");
		}
	};

	/**
	 * Speaker handlers
	 */
	const handleAddSpeaker = () => {
		openAddSpeakerModal();
	};

	const handleEditSpeaker = (speaker) => {
		setViewingSpeaker(speaker);
		openEditSpeakerModal();
	};

	const handleDeleteSpeaker = (speaker) => {
		setDeleteModalKey("delete_speaker");
		setDeleteModalText("Are you sure you want to delete this speaker?");
		setDeleteId(speaker.speaker_id);
		openCustomModal();
	};

	const handleConfirmDeleteSpeaker = () => {
		LandingPageAPI.deleteSpeaker(eventId, deleteId)
			.then((response) => {
				if (response.code === 200) {
					refreshSpeakerList();
					closeCustomModal();
					openAlertSnackbar("Speaker deleted.", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleAddEventHighlights = () => {
		openAddEventHighlightsModal();
	};

	const handleEditEventHighlights = (eventHighlights) => {
		setViewingEventHighlights(eventHighlights);
		openEditEventHighlightsModal();
	};

	const handleDeleteEventHighlights = (eventHighlights) => {
		setDeleteModalKey("delete_event_highlights");
		setDeleteModalText("Are you sure you want to delete this event highlight?");
		setDeleteId(eventHighlights.id);
		openCustomModal();
	};

	const handleConfirmDeleteEventHighlights = () => {
		LandingPageAPI.deleteEventHighlights(eventId, deleteId)
			.then((response) => {
				if (response.code === 200) {
					refreshEventHighlightsList();
					closeCustomModal();
					openAlertSnackbar("Event highlights deleted.", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleAddEventPartner = () => {
		openAddEventPartnerModal();
	};

	const handleEditEventPartner = (eventPartner) => {
		setViewingEventPartner(eventPartner);
		openEditEventPartnerModal();
	};

	const handleDeleteEventPartner = (eventPartner) => {
		setDeleteModalKey("delete_featured_partner");
		setDeleteModalText(
			`Are you sure you want to delete this ${FEATURED_PARTNER_TYPES[currentEventPartnerKey]["name_lc"]}?`
		);
		setDeleteId(eventPartner.id);
		openCustomModal();
	};

	const handleConfirmDeleteFeaturedPartner = () => {
		LandingPageAPI.deleteFeaturedPartners(eventId, FEATURED_PARTNER_TYPES[currentEventPartnerKey]["type"], deleteId)
			.then((response) => {
				if (response.code === 200) {
					refreshEventPartnerList();
					closeCustomModal();
					openAlertSnackbar(`${FEATURED_PARTNER_TYPES[currentEventPartnerKey]["name"]} deleted.`, "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const FeaturedPartnerTabContainer = ({ listing }) => {
		return (
			<div className="tab-panel-container">
				<table className="table ">
					<thead>
						<tr>
							<th className="table-header">Logo</th>
							<th className="table-header">Company Name</th>
							<th className="table-header">Company URL Link</th>
							<th className="table-header col-actions">Actions</th>
						</tr>
					</thead>
					<tbody>
						{listing.map((partner) => {
							const { id, logo_url, name, url_link } = partner;

							return (
								<tr key={`partner-${id}`}>
									<td className="table-cell">
										<img src={logo_url} alt="thumbnail" className="partner-thumbnail" />
									</td>
									<td className="table-cell">{name || "-"}</td>
									<td className="table-cell text-url">{url_link || "-"}</td>
									<td className="table-cell">
										<span>
											<img
												src={IconEditGrey}
												alt="edit"
												className="btn-icon-edit"
												onClick={() => handleEditEventPartner(partner)}
											/>
										</span>
										<span>
											<img
												src={IconDeleteGrey}
												alt="delete"
												className="btn-icon-delete"
												onClick={() => handleDeleteEventPartner(partner)}
											/>
										</span>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	};

	const DeleteModal = () => {
		let primaryActionHandler = null;

		switch (deleteModalKey) {
			case "delete_speaker":
				primaryActionHandler = handleConfirmDeleteSpeaker;
				break;
			case "delete_event_highlights":
				primaryActionHandler = handleConfirmDeleteEventHighlights;
				break;
			case "delete_featured_partner":
				primaryActionHandler = handleConfirmDeleteFeaturedPartner;
				break;
			default:
		}

		return (
			<CustomModal
				icon={IconDelete}
				title={deleteModalText}
				btnClass="btn-danger"
				textPrimary={
					<>
						<img src={IconDeleteGrey} alt="delete" className="icon-btn" /> Delete
					</>
				}
				textSecondary="Cancel"
				onClickPrimary={primaryActionHandler}
				onClickSecondary={closeCustomModal}
			/>
		);
	};

	return (
		<EventLayoutContainer>
			<div id="landing-editor-page">
				<section className="page-header d-flex justify-content-between align-items-center">
					<div>
						<h2 className="title">Landing Page Editor</h2>
						<span className="subtitle">Event Details &gt; Landing Page Editor</span>
					</div>
					<EventInfo />
				</section>

				<section className="py-5 px-4">
					<form id="form-landing-page" method="post" onSubmit={handleUpdateLandingPage}>
						<div className="row mb-3">
							<div className="col-12 mb-5">
								<h3 className="container-title">General Event Details</h3>
								<div className="general-event-details-container">
									<div className="row">
										<div className="col-5">
											<div className="form-group">
												<label htmlFor="cover_image">Upload Event Cover Image *</label>
												{uploadedCoverImage || inputData.cover_image ? (
													<img
														src={
															uploadedCoverImage
																? URL.createObjectURL(uploadedCoverImage)
																: inputData.cover_image
														}
														alt="cover"
														className="img-fluid img-cover"
														onClick={browsePicture}
													/>
												) : (
													<img
														src={IconUpload}
														alt="upload cover"
														className="img-upload-cover"
														onClick={browsePicture}
													/>
												)}
												<input
													type="file"
													name="cover_image"
													value={""}
													onChange={handleChangeCoverImage}
													ref={coverImageRef}
													className="d-none"
													accept="image/*"
												/>
											</div>
										</div>
										{/* TODO: reopen in next code drop */}
										{/* <div className="col-6">
											<div className="form-group">
												<label htmlFor="bg_theme_color">Backgroud Theme Color *</label>
												<div className="d-flex">
													{LANDING_PAGE_BG_THEME_COLORS.filter((color, index) => {
														return index < 5;
													}).map((color) => {
														return (
															<div
																key={color}
																className={`theme-color-option ${
																	color === bgThemeColor
																		? "theme-color-option-active"
																		: ""
																}`}
																style={{ backgroundColor: color }}
																onClick={() => handleSelectBgThemeColor(color)}></div>
														);
													})}
												</div>
												<div className="d-flex">
													{LANDING_PAGE_BG_THEME_COLORS.filter((color, index) => {
														return index >= 5 && index < 10;
													}).map((color) => {
														return (
															<div
																key={color}
																className={`theme-color-option ${
																	color === bgThemeColor
																		? "theme-color-option-active"
																		: ""
																}`}
																style={{ backgroundColor: color }}
																onClick={() => handleSelectBgThemeColor(color)}></div>
														);
													})}
												</div>
											</div>
										</div> */}
									</div>
									<div className="row">
										<div className="col-9">
											<div className="form-group">
												<label htmlFor="event_video">Event Video</label>
												<input
													id="event_video"
													type="url"
													className="form-control"
													aria-describedby="event_video"
													name="event_video"
													value={inputData.event_video}
													onChange={handleInputOnChange}
												/>
											</div>
										</div>
										<div className="col-3">
											<div className="form-group">
												<label htmlFor="platform_type">Platform Type</label>
												<div className="select-wrapper">
													<select
														id="platform_type"
														className="form-control"
														aria-describedby="platform_type"
														name="platform_type"
														value={inputData.platform_type}
														onChange={handleInputOnChange}>
														{Object.keys(PLATFORM_TYPES).map((platform) => {
															return (
																<option
																	key={platform}
																	value={PLATFORM_TYPES[platform]["value"]}>
																	{PLATFORM_TYPES[platform]["display"]}
																</option>
															);
														})}
													</select>
												</div>
											</div>
										</div>
									</div>
									<div className="form-group">
										<label htmlFor="event_description">Event Description *</label>
										<textarea
											id="event_description"
											type="text"
											className="form-control"
											aria-describedby="event_description"
											name="event_description"
											value={inputData.event_description}
											onChange={handleInputOnChange}
											required
											rows={5}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="website">Social Links</label>
										<div className="row">
											<div className="col-12 mb-3">
												<input
													id="website"
													type="url"
													className="form-control"
													aria-describedby="website"
													name="website"
													value={inputData.website}
													onChange={handleInputOnChange}
													placeholder="Website link"
												/>
											</div>
											<div className="col-12 mb-3">
												<input
													id="linkedin"
													type="url"
													className="form-control"
													aria-describedby="linkedin"
													name="linkedin"
													value={inputData.linkedin}
													onChange={handleInputOnChange}
													placeholder="Linkedin link"
												/>
											</div>
											<div className="col-12 mb-3">
												<input
													id="facebook"
													type="url"
													className="form-control"
													aria-describedby="facebook"
													name="facebook"
													value={inputData.facebook}
													onChange={handleInputOnChange}
													placeholder="Facebook link"
												/>
											</div>
											<div className="col-12 mb-3">
												<input
													id="instagram"
													type="url"
													className="form-control"
													aria-describedby="instagram"
													name="instagram"
													value={inputData.instagram}
													onChange={handleInputOnChange}
													placeholder="Instagram link"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 mb-5">
								<h3 className="container-title">Agenda</h3>
								<div className="agenda-container p-3">
									<div className="d-flex justify-content-between align-items-center">
										<label>Publish channel sessions as Event Agenda</label>
										<div className="custom-control custom-switch">
											<input
												type="checkbox"
												className="custom-control-input"
												id="agenda_enabled"
												name="agenda_enabled"
												value={1}
												checked={inputData.agenda_enabled}
												onChange={handleInputOnChange}
											/>
											<label className="custom-control-label" htmlFor="agenda_enabled"></label>
										</div>
									</div>
									<p className="px-4">
										By enabling this, all event sessions that you’ve created will be published under
										the Agenda session on your landing page.
									</p>
								</div>
							</div>

							<div className="col-12 mb-4">
								<h3 className="container-title">Organizer</h3>
								<div className="organizer-container">
									<div className="form-group">
										<label htmlFor="company_photo">Company Photo *</label>
										{uploadedCompanyPhoto || organizerInput.company_photo ? (
											<img
												src={
													uploadedCompanyPhoto
														? URL.createObjectURL(uploadedCompanyPhoto)
														: organizerInput.company_photo
												}
												alt="cover"
												className="img-fluid img-company"
												onClick={browseCompanyPhoto}
											/>
										) : (
											<div className="add-company-photo-wrapper" onClick={browseCompanyPhoto}>
												<img src={IconAddGrey} alt="add" className="img-add-company-photo" />
											</div>
										)}
										<input
											type="file"
											name="company_photo"
											value={""}
											onChange={handleChangeCompanyPhoto}
											ref={companyPhotoRef}
											className="d-none"
											accept="image/*"
										/>
									</div>
									<div className="form-group">
										<label htmlFor="company_name">Company Name *</label>
										<input
											id="company_name"
											type="text"
											className="form-control"
											aria-describedby="company_name"
											name="company_name"
											value={organizerInput.company_name}
											onChange={handleOrganizerInputOnChange}
											required
										/>
									</div>
									<div className="form-group">
										<label htmlFor="company_description">Description *</label>
										<textarea
											id="company_description"
											className="form-control"
											aria-describedby="company_description"
											name="company_description"
											value={organizerInput.company_description}
											onChange={handleOrganizerInputOnChange}
											required
											rows={5}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="organizer_website">Social Links</label>
										<input
											id="organizer_website"
											type="url"
											className="form-control mb-3"
											aria-describedby="organizer_website"
											name="organizer_website"
											value={organizerInput.organizer_website}
											onChange={handleOrganizerInputOnChange}
											placeholder="Website link"
										/>
										<input
											id="organizer_linkedin"
											type="url"
											className="form-control mb-3"
											aria-describedby="organizer_linkedin"
											name="organizer_linkedin"
											value={organizerInput.organizer_linkedin}
											onChange={handleOrganizerInputOnChange}
											placeholder="Linkedin link"
										/>
										<input
											id="organizer_facebook"
											type="url"
											className="form-control mb-3"
											aria-describedby="organizer_facebook"
											name="organizer_facebook"
											value={organizerInput.organizer_facebook}
											onChange={handleOrganizerInputOnChange}
											placeholder="Facebook link"
										/>
										<input
											id="organizer_instagram"
											type="url"
											className="form-control mb-3"
											aria-describedby="organizer_instagram"
											name="organizer_instagram"
											value={organizerInput.organizer_instagram}
											onChange={handleOrganizerInputOnChange}
											placeholder="Instagram link"
										/>
									</div>
								</div>
							</div>

							<div className="col-12 mb-4">
								<div className="row">
									<div className="col-6"></div>
									<div className="col-3">
										<button
											type="button"
											className="btn btn-outline-primary w-100"
											onClick={handlePreview}>
											Preview
										</button>
									</div>
									<div className="col-3">
										<button type="submit" className="btn btn-primary w-100">
											Save
										</button>
									</div>
								</div>
							</div>

							<div className="col-12 my-5">
								<hr />
							</div>

							<div className="col-12 mb-5">
								<div className="d-flex justify-content-between align-items-center mb-2">
									<h3 className="container-title">Speakers</h3>
									<button
										type="button"
										className="btn btn-primary btn-container-header"
										onClick={handleAddSpeaker}>
										Add Speakers
									</button>
								</div>
								<div className="speaker-container">
									<table className="table">
										<thead>
											<tr>
												<th className="table-header">Photo</th>
												<th className="table-header">Name</th>
												<th className="table-header">Company Name</th>
												<th className="table-header">Designation</th>
												<th className="table-header col-actions">Actions</th>
											</tr>
										</thead>
										<tbody>
											{speakerListing.map((speaker) => {
												const { speaker_id, name, thumbnail_url, company_name, designation } =
													speaker;

												return (
													<tr key={speaker_id}>
														<td className="table-cell">
															<img
																src={thumbnail_url || IconDefaultUser}
																alt="thumbnail"
																className="speaker-thumbnail"
															/>
														</td>
														<td className="table-cell">{name || "-"}</td>
														<td className="table-cell">{company_name || "-"}</td>
														<td className="table-cell">{designation || "-"}</td>
														<td className="table-cell">
															<span>
																<img
																	src={IconEditGrey}
																	alt="edit"
																	className="btn-icon-edit"
																	onClick={() => handleEditSpeaker(speaker)}
																/>
															</span>
															<span>
																<img
																	src={IconDeleteGrey}
																	alt="delete"
																	className="btn-icon-delete"
																	onClick={() => handleDeleteSpeaker(speaker)}
																/>
															</span>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>

							<div className="col-12 mb-5">
								<div className="d-flex justify-content-between align-items-center mb-2">
									<h3 className="container-title">Event Highlights</h3>
									<button
										type="button"
										className="btn btn-primary btn-container-header"
										onClick={handleAddEventHighlights}>
										Add Event Highlights
									</button>
								</div>
								<div className="event-highlights-container">
									<table className="table ">
										<thead>
											<tr>
												<th className="table-header">Image/Video</th>
												<th className="table-header">Title</th>
												<th className="table-header">Start Date & Time</th>
												<th className="table-header">End Date & Time</th>
												<th className="table-header col-actions">Actions</th>
											</tr>
										</thead>
										<tbody>
											{eventHighlightListing.map((eventHighlights) => {
												const { id, logo_url, title, start_at, end_at } = eventHighlights;

												return (
													<tr key={`eventHighlights-${id}`}>
														<td className="table-cell">
															<img
																src={logo_url}
																alt="thumbnail"
																className="event-highlights-thumbnail"
															/>
														</td>
														<td className="table-cell">{title || "-"}</td>
														<td className="table-cell">
															{start_at
																? moment(start_at).format("D MMMM YYYY, hA")
																: "-"}
														</td>
														<td className="table-cell">
															{end_at ? moment(end_at).format("D MMMM YYYY, hA") : "-"}
														</td>
														<td className="table-cell">
															<span>
																<img
																	src={IconEditGrey}
																	alt="edit"
																	className="btn-icon-edit"
																	onClick={() =>
																		handleEditEventHighlights(eventHighlights)
																	}
																/>
															</span>
															<span>
																<img
																	src={IconDeleteGrey}
																	alt="delete"
																	className="btn-icon-delete"
																	onClick={() =>
																		handleDeleteEventHighlights(eventHighlights)
																	}
																/>
															</span>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>

							<div className="col-12 mb-5">
								<div className="d-flex justify-content-between align-items-center mb-2">
									<h3 className="container-title">Featured Partners</h3>
								</div>
								<div className="featured-partners-container">
									<button
										type="button"
										className="btn btn-primary btn-container-header"
										onClick={handleAddEventPartner}>
										Add {FEATURED_PARTNER_TYPES[currentEventPartnerKey]["name"]}
									</button>
									<Tabs
										activeKey={currentEventPartnerKey}
										onSelect={(selectedKey) => setCurrentEventPartnerKey(selectedKey)}
										className="custom-tabs">
										<Tab
											eventKey="event_partners"
											title={FEATURED_PARTNER_TYPES["event_partners"]["name"]}>
											<FeaturedPartnerTabContainer listing={featuredPartnerListing} />
										</Tab>
										<Tab eventKey="sponsors" title={FEATURED_PARTNER_TYPES["sponsors"]["name"]}>
											<FeaturedPartnerTabContainer listing={featuredPartnerListing} />
										</Tab>
										<Tab
											eventKey="featured_exhibitor"
											title={FEATURED_PARTNER_TYPES["featured_exhibitor"]["name"]}>
											<FeaturedPartnerTabContainer listing={featuredPartnerListing} />
										</Tab>
									</Tabs>
								</div>
							</div>
						</div>
					</form>
				</section>
			</div>

			<AddSpeakerModal />
			<EditSpeakerModal />

			<AddEventHighlightsModal />
			<EditEventHighlightsModal />

			<AddEventPartnerModal />
			<EditEventPartnerModal />

			<DeleteModal />
		</EventLayoutContainer>
	);
};

export default withRouter(LandingPageEditor);
