/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./style.scss";
import IconSubscriptionPlan from "app/assets/icon-subscription-plan.png";
import IconWarningTriangle from "app/assets/icon-alert-triangle-yellow.png";
import { withRouter } from "react-router-dom";
import ReactLoading from "react-loading";
import BillingAPI from "app/apis/billings";
import { useDispatch, useSelector } from "react-redux";
import { billingActions } from "app/states/billings";
import LocalStorageService from "app/services/localStorageService";
import moment from "moment";
import UpgradeEnterprisePlanConfirmationModal from "app/views/containers/Billings/UpgradeEnterprisePlanConfirmationModal/UpgradeEnterprisePlanConfirmationModal";
import ReactivatePlanModal from "app/views/containers/Billings/ReactivatePlanModal/ReactivatePlanModal";

const UsagePlanContainer = ({ history }) => {
	const dispatch = useDispatch();
	const setCurrentPlan = (currentPlan) => dispatch(billingActions.setCurrentPlan(currentPlan));
	const setPendingSubscription = (pendingSubscription) =>
		dispatch(billingActions.setPendingSubscription(pendingSubscription));
	const openUpgradeEnterprisePlanConfirmationModal = () =>
		dispatch(billingActions.openUpgradeEnterprisePlanConfirmationModal());
	const openReactivatePlanModal = () => dispatch(billingActions.openReactivatePlanModal());

	const currentSubscriptionPlan = useSelector(({ billing }) => billing.currentSubscriptionPlan);
	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);
	const refreshBillingDashboardCount = useSelector(({ billing }) => billing.refreshBillingDashboardCount);

	const [pendingSubscriptionPlan, setPendingSubsriptionPlan] = useState(null);

	// Get current subscription plan
	useEffect(() => {
		if (!selectedOrganizationAccount) return;

		BillingAPI.getCurrentSubscription(selectedOrganizationAccount.id)
			.then((response) => {
				let subscriptionPlan = { ...response.data };
				const { usages, license } = subscriptionPlan;

				// Get the percentages for all usages
				subscriptionPlan["percentages"] = {};
				subscriptionPlan["show_warning"] = {};

				subscriptionPlan["percentages"]["visitors"] = 0;
				if (license.visitors) {
					if (license.visitors === -1) {
						subscriptionPlan["percentages"]["visitors"] = 100;
					} else if ((usages.visitors / license.visitors) * 100 >= 100) {
						subscriptionPlan["percentages"]["visitors"] = 100;
						subscriptionPlan["show_warning"]["visitors"] = true;
					} else {
						subscriptionPlan["percentages"]["visitors"] = (usages.visitors / license.visitors) * 100;
					}
				}

				subscriptionPlan["percentages"]["sessions"] = 0;
				if (license.sessions) {
					if (license.sessions === -1) {
						subscriptionPlan["percentages"]["sessions"] = 100;
					} else if ((usages.sessions / license.sessions) * 100 >= 100) {
						subscriptionPlan["percentages"]["sessions"] = 100;
						subscriptionPlan["show_warning"]["sessions"] = true;
					} else {
						subscriptionPlan["percentages"]["sessions"] = (usages.sessions / license.sessions) * 100;
					}
				}

				subscriptionPlan["percentages"]["exhibitors"] = 0;
				if (license.exhibitors !== 0) {
					if (license.exhibitors === -1) {
						subscriptionPlan["percentages"]["exhibitors"] = 100;
					} else if ((usages.exhibitors / license.exhibitors) * 100 >= 100) {
						subscriptionPlan["percentages"]["exhibitors"] = 100;
						subscriptionPlan["show_warning"]["exhibitors"] = true;
					} else {
						subscriptionPlan["percentages"]["exhibitors"] = (usages.exhibitors / license.exhibitors) * 100;
					}
				}

				subscriptionPlan["percentages"]["advertisements"] = 0;
				if (license.advertisements !== 0) {
					if (license.advertisements === -1) {
						subscriptionPlan["percentages"]["advertisements"] = 100;
					} else if ((usages.advertisements / license.advertisements) * 100 >= 100) {
						subscriptionPlan["percentages"]["advertisements"] = 100;
						subscriptionPlan["show_warning"]["advertisements"] = true;
					} else {
						subscriptionPlan["percentages"]["advertisements"] =
							(usages.advertisements / license.advertisements) * 100;
					}
				}

				setCurrentPlan(subscriptionPlan);
				LocalStorageService.setCurrentSubscriptionPlan(subscriptionPlan);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [selectedOrganizationAccount, refreshBillingDashboardCount]);

	// Get pending subscription plan
	useEffect(() => {
		if (!selectedOrganizationAccount) return;

		BillingAPI.getPendingSubscription(selectedOrganizationAccount.id)
			.then((response) => {
				if (response.data) {
					setPendingSubsriptionPlan(response.data);
					setPendingSubscription(response.data);
				} else {
					setPendingSubsriptionPlan(null);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [selectedOrganizationAccount, refreshBillingDashboardCount]);

	const goToChoosePlanPage = () => {
		history.push("/billings/plans");
	};

	const handlePayPendingActivation = () => {
		openUpgradeEnterprisePlanConfirmationModal();
	};

	const renderCtaButton = () => {
		if (currentSubscriptionPlan.status === 4) {
			return (
				<button className="btn btn-primary" onClick={openReactivatePlanModal}>
					Reactivate Plan
				</button>
			);
		} else if (
			pendingSubscriptionPlan &&
			currentSubscriptionPlan.subscription_plan.order < pendingSubscriptionPlan.subscription.subscription_plan.order &&
			currentSubscriptionPlan.subscription_plan.id !== 4
		) {
			return (
				<div className="text-right">
					<div className="text-pending-activation mb-1">
						{pendingSubscriptionPlan.subscription.subscription_plan.name} Plan pending for activation!
					</div>
					<button className="btn btn-primary" onClick={handlePayPendingActivation}>
						Pay To Activate
					</button>
				</div>
			);
		} else {
			return (
				<div>
					<button className="btn btn-primary" onClick={goToChoosePlanPage}>
						Upgrade Plan
					</button>
				</div>
			);
		}
	};

	if (!currentSubscriptionPlan) {
		return (
			<div className="d-flex justify-content-center align-items-center mt-5">
				<ReactLoading type={"spinningBubbles"} color="lightgrey" />
			</div>
		);
	}

	return (
		<>
			<div className="section-container usage-plan-container">
				<h3 className="section-title">Your Plan</h3>
				<div className="current-plan-wrapper pt-4 pl-4 mb-4">
					<div className="d-flex align-items-start">
						<img src={IconSubscriptionPlan} alt="subscription plan" className="icon-subscription-plan" />
						<div>
							<div className="plan-label">CURRENT PLAN</div>
							<div className="plan-value">{currentSubscriptionPlan.subscription_plan.name}</div>
							{currentSubscriptionPlan.status === 4 && (
								<div className="plan-terminated-status">
									Subscription terminated. Expires on{" "}
									{moment(currentSubscriptionPlan.end_at).format("DD MMMM YYYY")}.
								</div>
							)}
							{pendingSubscriptionPlan &&
								currentSubscriptionPlan.subscription_plan.order >
									pendingSubscriptionPlan.subscription.subscription_plan.order &&
								currentSubscriptionPlan.subscription_plan.id !== 4 && (
									<div className="plan-downgraded-status">
										Your plan will be automatically downgraded to{" "}
										<strong>{pendingSubscriptionPlan.subscription.subscription_plan.name}</strong>{" "}
										effective from {moment(currentSubscriptionPlan.end_at).format("DD MMMM YYYY")}.
									</div>
								)}
						</div>
					</div>
					{renderCtaButton()}
				</div>

				{currentSubscriptionPlan.term > 0 && (
					<div className="d-flex align-items-start flex-wrap pl-4">
						<div className="plan-info-wrapper">
							<div className="info-label">SUBSCRIPTION PRICE</div>
							<div className="info-value">${currentSubscriptionPlan.amount} / month</div>
						</div>
						<div className="plan-info-wrapper">
							<div className="info-label">LAST RENEWAL DATE</div>
							<div className="info-value">
								{currentSubscriptionPlan.last_renewal_date
									? moment(currentSubscriptionPlan.last_renewal_date).format("DD MMMM YYYY")
									: "-"}
							</div>
						</div>
						<div className="plan-info-wrapper">
							<div className="info-label">NEXT RENEWAL DATE</div>
							<div className="info-value">
								{currentSubscriptionPlan.next_renewal_date
									? moment(currentSubscriptionPlan.next_renewal_date).format("DD MMMM YYYY")
									: "-"}
							</div>
						</div>
					</div>
				)}

				<hr className="my-5" />

				<h3 className="section-title">Usage Summary</h3>
				<div className="usage-summary-wrapper pt-4 pl-4 mb-5">
					<div className="row mb-4">
						<div className="col-3 usage-summary-item">
							<div className="progress-container">
								<div
									className="progress"
									style={{
										backgroundColor: `${
											currentSubscriptionPlan.show_warning &&
											currentSubscriptionPlan.show_warning.visitors
												? "#E8B006"
												: "#34A853"
										}`,
										width: `${currentSubscriptionPlan.percentages.visitors ?? 0}%`,
									}}></div>
							</div>
							<div className="usage-label">REGISTERED VISITORS</div>
							<div className="usage-value">
								<span
									className={`${
										currentSubscriptionPlan.show_warning &&
										currentSubscriptionPlan.show_warning.visitors
											? "text-color-full"
											: ""
									}`}>
									{currentSubscriptionPlan.usages.visitors}
								</span>{" "}
								<span className="slash">/</span>{" "}
								{currentSubscriptionPlan.license.visitors === -1
									? "Unlimited"
									: currentSubscriptionPlan.license.visitors}
							</div>
						</div>

						<div className="col-3 usage-summary-item">
							<div className="progress-container">
								<div
									className="progress"
									style={{
										backgroundColor: `${
											currentSubscriptionPlan.show_warning &&
											currentSubscriptionPlan.show_warning.sessions
												? "#E8B006"
												: "#34A853"
										}`,
										width: `${currentSubscriptionPlan.percentages.sessions}%`,
									}}></div>
							</div>
							<div className="usage-label">SESSIONS</div>
							<div className="usage-value">
								<span
									className={`${
										currentSubscriptionPlan.show_warning &&
										currentSubscriptionPlan.show_warning.sessions
											? "text-color-full"
											: ""
									}`}>
									{currentSubscriptionPlan.usages.sessions}
								</span>{" "}
								<span className="slash">/</span>{" "}
								{currentSubscriptionPlan.license.sessions === -1
									? "Unlimited"
									: currentSubscriptionPlan.license.sessions}
							</div>
						</div>

						<div className="col-3 usage-summary-item">
							<div className="progress-container">
								<div
									className="progress"
									style={{
										backgroundColor: `${
											currentSubscriptionPlan.show_warning &&
											currentSubscriptionPlan.show_warning.exhibitors
												? "#E8B006"
												: "#34A853"
										}`,
										width: `${currentSubscriptionPlan.percentages.exhibitors}%`,
									}}></div>
							</div>
							<div className="usage-label">EXHIBITORS</div>
							<div className="usage-value">
								<span
									className={`${
										currentSubscriptionPlan.show_warning &&
										currentSubscriptionPlan.show_warning.exhibitors
											? "text-color-full"
											: ""
									}`}>
									{currentSubscriptionPlan.usages.exhibitors}
								</span>{" "}
								<span className="slash">/</span>{" "}
								{currentSubscriptionPlan.license.exhibitors === -1
									? "Unlimited"
									: currentSubscriptionPlan.license.exhibitors}
							</div>
						</div>

						<div className="col-3 usage-summary-item">
							<div className="progress-container">
								<div
									className="progress"
									style={{
										backgroundColor: `${
											currentSubscriptionPlan.show_warning &&
											currentSubscriptionPlan.show_warning.advertisements
												? "#E8B006"
												: "#34A853"
										}`,
										width: `${currentSubscriptionPlan.percentages.advertisements}%`,
									}}></div>
							</div>
							<div className="usage-label">ADVERTISEMENT SLOTS</div>
							<div className="usage-value">
								<span
									className={`${
										currentSubscriptionPlan.show_warning &&
										currentSubscriptionPlan.show_warning.advertisements
											? "text-color-full"
											: ""
									}`}>
									{currentSubscriptionPlan.usages.advertisements}
								</span>{" "}
								<span className="slash">/</span>{" "}
								{currentSubscriptionPlan.license.advertisements === -1
									? "Unlimited"
									: currentSubscriptionPlan.license.advertisements}
							</div>
						</div>
					</div>

					{currentSubscriptionPlan.license.visitors !== -1 &&
						currentSubscriptionPlan.percentages.visitors >= 100 && (
							<div className="d-flex align-items-center mb-2">
								<img src={IconWarningTriangle} alt="warning" className="icon-warning" />
								<div className="text-warning">
									Registered visitors limit reached! New visitors can no longer sign-up to your event.
								</div>
							</div>
						)}
					{currentSubscriptionPlan.license.sessions !== -1 &&
						currentSubscriptionPlan.percentages.sessions >= 100 && (
							<div className="d-flex align-items-center mb-2">
								<img src={IconWarningTriangle} alt="warning" className="icon-warning" />
								<div className="text-warning">
									Sessions limit reached! You can no longer create new event sessions.
								</div>
							</div>
						)}
					<div className="text-notice mt-3">
						Note:
						<ol className="pl-4">
							<li>This are shared quotas you have available across all your published events.</li>
							<li>
								Any quota from events you have “Ended” will be available in your next billing month.
							</li>
						</ol>
					</div>
				</div>
			</div>

			<UpgradeEnterprisePlanConfirmationModal />
			<ReactivatePlanModal />
		</>
	);
};

export default withRouter(UsagePlanContainer);
