/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import LayoutContainer from "app/views/containers/LayoutContainer/LayoutContainer";
import UsagePlanContainer from "app/views/containers/Dashboard/UsagePlanContainer/UsagePlanContainer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import LocalStorageService from "app/services/localStorageService";
import { commonActions } from "app/states/common";
import BillingAPI from "app/apis/billings";
import InvoiceListContainer from "app/views/containers/Billings/InvoiceListContainer/InvoiceListContainer";
import { billingActions } from "app/states/billings";
import CustomModal from "app/views/components/CustomModal/CustomModal";
import IconWarningTriangleRed from "app/assets/icon-alert-triangle-red.png";

const BillingsDashboard = ({ history }) => {
	const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();
	const currentSubscriptionPlan = useSelector(({ billing }) => billing.currentSubscriptionPlan);

	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const refreshBillingDashboard = () => dispatch(billingActions.refreshBillingDashboard());

	const openCustomModal = () => dispatch(commonActions.openCustomModal());
	const closeCustomModal = () => dispatch(commonActions.closeCustomModal());

	const handleTerminateSubscription = () => {
		BillingAPI.postTerminateSubscription(selectedOrganizationAccount.id)
			.then((response) => {
				if (response.code === 200) {
					refreshBillingDashboard();
					closeCustomModal();
					openAlertSnackbar("Subscription terminated successfully.", "success");
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	return (
		<LayoutContainer>
			<div id="billings-dashboard-page">
				<section className="page-header d-flex justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						<div>
							<h2 className="title">Billings</h2>
							<span className="subtitle">Overview of your subscription</span>
						</div>
					</div>
				</section>

				<section className="dashboard-content p-4">
					<div className="mb-5">
						<UsagePlanContainer />
					</div>

					<div className="mb-5">
						<InvoiceListContainer />
					</div>

					{currentSubscriptionPlan && currentSubscriptionPlan.term !== -1 && (
						<div className="termination-container">
							<h3 className="section-title">Termination</h3>
							<p>
								By terminating your current subscription, you will only be able to host your event up to{" "}
								<strong>
									{currentSubscriptionPlan
										? moment(currentSubscriptionPlan.end_at).format("DD MMMM YYYY")
										: ""}
								</strong>
								. Once your subscription has ended, your visitors will no longer be able to access to
								your event. All event details will be removed after 7 days.
							</p>
							{currentSubscriptionPlan.status === 4 ? (
								<div className="plan-terminated-status">
									You have terminated your subscription on{" "}
									{moment(currentSubscriptionPlan.terminated_at).format("DD MMMM YYYY")}.
								</div>
							) : (
								<button
									className={`btn btn-terminate ${
										currentSubscriptionPlan.status === 4 ? "btn-disable-terminate" : ""
									}`}
									onClick={openCustomModal}
									disabled={currentSubscriptionPlan.status === 4}>
									Terminate Subscription
								</button>
							)}
						</div>
					)}
				</section>
			</div>

			<CustomModal
				icon={IconWarningTriangleRed}
				title="Are you sure you want to terminate your subscription?"
				desc={`By terminating your current subscription, you will only be able to host your event up to ${moment(currentSubscriptionPlan.end_at).format("DD MMMM YYYY")}. Once your subscription has ended, your visitors will no longer be able to access to your event. All event details will be removed after 7 days.`}
				btnClass="btn-danger"
				textPrimary="Terminate"
				textSecondary="Cancel"
				onClickPrimary={handleTerminateSubscription}
				onClickSecondary={closeCustomModal}
			/>
		</LayoutContainer>
	);
};

export default withRouter(BillingsDashboard);
