import React, { useState } from "react";
import "./style.scss";
import IconRemove from "app/assets/icon-close.png";
import IconGreeTick from "app/assets/icon-tick-no-background.png";
import { TEAM_MEMBER_TYPES } from "app/config/settings";

const InviteMemberRow = ({
	value,
	allowRemove,
	onRemove,
	onChange,
	onKeyDown,
	readOnly,
	confirmed,
	invited,
	teamMemberType,
}) => {
	const renderStatus = () => {
		if (teamMemberType === TEAM_MEMBER_TYPES["owner"]) {
			return <span className="text-owner">Owner</span>;
		} else if (confirmed) {
			return <img src={IconGreeTick} alt="confirmed" className="icon-tick" />;
		} else if (invited) {
			return <span className="text-invited">Invited</span>;
		}
	};

	return (
		<div className="invite-member-row row align-items-center mb-3">
			<div className="col-11">
				<div className="input-wrapper">
					<input
						type="email"
						className={`form-control pr-5 ${confirmed ? "text-confirmed" : ""}`}
						aria-describedby="invite_member_email"
						name="invite_member_email"
						readOnly={readOnly}
						value={value}
						onChange={onChange}
						onKeyDown={onKeyDown}
						placeholder="name@email.com"
					/>

					{renderStatus()}
				</div>
			</div>
			{allowRemove && teamMemberType !== TEAM_MEMBER_TYPES["owner"] && (
				<div className="col-1" onClick={onRemove}>
					<img src={IconRemove} alt="remove" className="icon-remove" />
				</div>
			)}
		</div>
	);
};

export default InviteMemberRow;
