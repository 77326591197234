/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import ChannelSessionAPI from "app/apis/channelSession";
import IconEdit from "app/assets/icon-edit-grey.png";
import IconDeleteGrey from "app/assets/icon-delete-grey.png";
import IconDelete from "app/assets/icon-delete-red.png";
import moment from "moment";
import LocalStorageService from "app/services/localStorageService";
import { commonActions } from "app/states/common";
import { eventActions } from "app/states/event";
import { useSelector, useDispatch } from "react-redux";
import CustomModal from "app/views/components/CustomModal/CustomModal";
import CreatePollModal from "../CreatePollModal/CreatePollModal";
import EditPollModal from "../EditPollModal/EditPollModal";

const MAX_RECORDS_PER_PAGE = 3;

const PollContainer = ({ history }) => {
	const { sessionId } = useParams();

	const dispatch = useDispatch();
	const openCreatePollModal = () => dispatch(eventActions.openCreatePollModal());
	const refreshPollListing = (refresh) => dispatch(eventActions.refreshPollListing(refresh));
	const openCustomModal = () => dispatch(commonActions.openCustomModal());
	const closeCustomModal = () => dispatch(commonActions.closeCustomModal());
	const openEditPollModal = (pollId) => dispatch(eventActions.openEditPollModal(pollId));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const isRefreshPollListing = useSelector(({ event }) => event.refreshPollListing);
	const showCreatePollModal = useSelector(({ event }) => event.showCreatePollModal);

	const [page, setPage] = useState(1);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [polls, setPolls] = useState([]);
	const [pollId, setPollId] = useState(null);
	const [openModalName, setOpenModalName] = useState(null);

	useEffect(() => {
		getPollListing();
	}, [sessionId, page]);

	useEffect(() => {
		if (isRefreshPollListing) {
			refreshPollListing(false);
			getPollListing();
		}
	}, [isRefreshPollListing]);

	const getPollListing = () => {
		let params = {
			page: page,
			limit: MAX_RECORDS_PER_PAGE,
			session_id: sessionId,
		};

		ChannelSessionAPI.getPollListing(params)
			.then((response) => {
				const { polls, paging } = response.data;
				setPolls(polls);

				// Set paging
				const { last_page } = paging;
				setTotalPageCount(last_page);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	const handleConfirmDelete = () => {
		ChannelSessionAPI.deletePoll(pollId)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					setPage(1);
					setPollId(null);
					setOpenModalName(null);
					
					closeCustomModal();
					refreshPollListing(true);
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const PollRow = ({ pollObject, index }) => {
		const { id, title, created_at, updated_at, created_by_name } = pollObject;

		let pollUpdatedAt = moment(created_at).format("DD MMMM YYYY, hh:mmA");
		if (updated_at) {
			pollUpdatedAt = moment(updated_at).format("DD MMMM YYYY, hh:mmA");
		}
		
		const hasChoices = pollObject.hasOwnProperty("choices_detail") ? true : false;

		let totalVotes = 0;
		if (hasChoices) {
			const { choices_detail } = pollObject;

			totalVotes = choices_detail
				.map((choice) => {
					return choice.votes_count;
				})
				.reduce((total, votesCount) => {
					return total + votesCount;
				});
		}

		const handleOnDelete = () => {
			setPollId(id);
			setOpenModalName("delete_poll");
			openCustomModal();
		};

		return (
			<div className="poll-row mb-3">
				<h6 className="poll-num">Poll {index + 1}</h6>
				<div className="poll-card">
					<div className="row mb-3">
						<div className="col-7 poll-title">{title}</div>
						<div className="col-3 px-0">
							<span className="meta-created-by">
								{created_by_name} | {pollUpdatedAt}
							</span>
						</div>
						<div className="col-2 text-right">
							<img src={IconEdit} alt="edit" className="icon-action mr-4" onClick={() => openEditPollModal(id)} />
							<img src={IconDeleteGrey} alt="edit" className="icon-action" onClick={handleOnDelete} />
						</div>
					</div>

					{hasChoices &&
						pollObject.choices_detail.map((choice_detail) => {
							const { choice_id, choices, votes_count } = choice_detail;

							let percentage = 0;
							if (totalVotes > 0) {
								percentage = (votes_count / totalVotes) * 100;
							}

							// Progress bar styling and color
							let progressBarColor = "#42D879"; // green
							if (percentage >= 80) {
								progressBarColor = "#D52937"; // red
							} else if (percentage >= 50) {
								progressBarColor = "#FF6C2B"; // orange
							}
							
							let displayPercentage = Number.isInteger(percentage) ? percentage : parseFloat(percentage).toFixed(2);

							const progressFillerStyles = {
								position: "absolute",
								top: 0,
								left: 0,
								height: "100%",
								width: `${percentage}%`,
								backgroundColor: progressBarColor,
								borderRadius: "inherit",
								borderTopRightRadius: 0,
								borderBottomRightRadius: 0,
							};

							return (
								<div key={choice_id} className="poll-option mb-3">
									<div className="row align-items-center">
										<div className="col-11">
											<div className="d-flex justify-content-between align-items-center mb-2">
												<span className="option-title">{choices}</span>
												<span className="option-total-votes">{votes_count} votes</span>
											</div>
											<div className="polls-progress-container">
												<div className="polls-progress" style={progressFillerStyles}></div>
											</div>
										</div>
										<div className="col-1">
											<span className="percentage">{displayPercentage}%</span>
										</div>
									</div>
								</div>
							);
						})}

					<div className="total-votes">Total Votes: {totalVotes}</div>
				</div>
			</div>
		);
	};

	return (
		<section className="tab-panel-container poll-container">
			<div className="row mb-3">
				<div className="col-3">
					<h4 className="tab-content-title mb-3">Manage Polls</h4>
				</div>
				<div className="col-7"></div>
				<div className="col-2">
					<button type="button" className="btn btn-primary w-100" onClick={openCreatePollModal}>
						Add Poll
					</button>
				</div>
			</div>

			<div className="poll-contents-container">
				{polls.map((poll, index) => {
					return <PollRow key={index} pollObject={poll} index={index} />;
				})}
			</div>

			{page && <AppPagination pageCount={totalPageCount} handlePageChange={handlePageChange} />}

			{openModalName === "delete_poll" && (
				<CustomModal
					icon={IconDelete}
					title="Are you sure you want to delete this poll?"
					btnClass="btn-danger"
					textPrimary={
						<>
							<img src={IconDeleteGrey} alt="delete" className="icon-btn" /> Delete
						</>
					}
					textSecondary="Cancel"
					onClickPrimary={handleConfirmDelete}
					onClickSecondary={closeCustomModal}
				/>
			)}

			{showCreatePollModal && <CreatePollModal />}
			<EditPollModal />
		</section>
	);
};

export default withRouter(PollContainer);
