import apiAction from "../services/apiAction";

const DashboardAPI = {
	/**
	 * Get dashboard data
	 * @param {object} queryParams
	 * {type}
	 */
	getDashboardData: (queryParams = {}) => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/events/dashboard`;

			apiAction
				.get(url, queryParams)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},
};

export default DashboardAPI;
