import React, { useState, useRef } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "app/states/event";
import IconClose from "app/assets/icon-close.png";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import EventAPI from "app/apis/event";
import { withRouter, useParams } from "react-router-dom";
import { commonActions } from "app/states/common";

const CreateChannelModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeCreateChannelModal = () => dispatch(eventActions.closeCreateChannelModal());
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const showCreateChannelModal = useSelector(({ event }) => event.showCreateChannelModal);
	const { eventId } = useParams();

	const logoRef = useRef();

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [uploadedLogo, setUploadedLogo] = useState(null);

	const handleChangeLogo = (event) => {
		setUploadedLogo(event.target.files[0]);
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleSubmitCreateChannel = (event) => {
		event.preventDefault();
		showLoading();

		let formData = new FormData();
		formData.append("event_id", eventId);
		formData.append("name", name);
		formData.append("description", description);
		formData.append("thumbnail", uploadedLogo);

		EventAPI.postCreateEventChannel(formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					const { id } = response.data;
					history.push(`/events/view/${eventId}/channels/${id}`);
					closeCreateChannelModal();
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	return (
		<Modal
			show={showCreateChannelModal}
			onHide={closeCreateChannelModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="create-channel-modal"
			centered>
			<Modal.Body>
				<div className="create-channel-modal-body">
					<div className="d-flex justify-content-end">
						<img src={IconClose} alt="close" className="icon-close" onClick={closeCreateChannelModal} />
					</div>

					<div className="p-3">
						<h3>Add Channel</h3>

						<form method="post" onSubmit={handleSubmitCreateChannel}>
							<div className="form-group">
								<label htmlFor="name">Name</label>
								<input
									id="name"
									type="text"
									className="form-control"
									aria-describedby="name"
									name="name"
									value={name}
									onChange={(event) => setName(event.target.value)}
									required
								/>
							</div>

							<div className="form-group">
								<label htmlFor="description">Description</label>
								<textarea
									id="description"
									className="form-control"
									aria-describedby="description"
									name="description"
									value={description}
									onChange={(event) => setDescription(event.target.value)}
									required
									rows={3}
								/>
							</div>

							<div className="form-group">
								<label htmlFor="image">Logo Image</label>
								<img
									src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : IconUpload}
									alt="logo"
									className="img-fluid img-logo"
									onClick={browsePicture}
								/>
								<input
									id="image"
									type="file"
									name="logo"
									onChange={handleChangeLogo}
									ref={logoRef}
									className="d-none"
									accept="image/*"
								/>
							</div>

							<button type="submit" className="btn btn-primary w-100 mt-3">
								Create
							</button>
						</form>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(CreateChannelModal);
