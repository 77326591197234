/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import LayoutContainer from "app/views/containers/LayoutContainer/LayoutContainer";
import UsagePlanContainer from "app/views/containers/Dashboard/UsagePlanContainer/UsagePlanContainer";
import CurrentLiveEventContainer from "app/views/containers/Dashboard/Dashboard/CurrentLiveEventContainer";
import PaymentSuccessModal from "app/views/containers/Billings/PaymentSuccessModal/PaymentSuccessModal";
import { useSelector, useDispatch } from "react-redux";
import useQueryParams from "app/views/hooks/useQueryParams";
import { billingActions } from "app/states/billings";

const Dashboard = ({ history }) => {
	const dispatch = useDispatch();
	const openPaymentSuccessModal = () => dispatch(billingActions.openPaymentSuccessModal());

	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);
	const queryParams = useQueryParams();

	// Handle payment success when payment gateway redirect to dashboard
	useEffect(() => {
		const paymentStatus = queryParams.get("payment_status");

		if (paymentStatus === "success") {
			// remove query string to indicate it is processed
			window.history.replaceState(null, "Dashboard", `/dashboard`);

			// Open payment success modal
			openPaymentSuccessModal();
		}
	}, []);

	return (
		<LayoutContainer>
			<div id="dashboard-page">
				<section className="page-header d-flex justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						<div>
							<h2 className="title">Dashboard</h2>
							<span className="subtitle">Overview of your account</span>
						</div>
					</div>
				</section>

				<section className="dashboard-content p-4">
					<h1>Welcome, {selectedOrganizationAccount ? selectedOrganizationAccount?.name : ""}!</h1>

					<div className="mb-5">
						<UsagePlanContainer />
					</div>

					<div className="mb-4">
						<CurrentLiveEventContainer />
					</div>
				</section>
			</div>

			<PaymentSuccessModal />
		</LayoutContainer>
	);
};

export default withRouter(Dashboard);
