import React, { useState, useEffect } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "app/states/event";
import { withRouter } from "react-router-dom";
import { commonActions } from "app/states/common";
import moment from "moment";
import { EVENT_STATUS } from "app/config/constants";
import EventAPI from "app/apis/event";
import ReactLoading from "react-loading";

const RepublishEventModal = ({ history }) => {
	const dispatch = useDispatch();
	const closeRepublishEventModal = () => dispatch(eventActions.closeRepublishEventModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const setIsEventPublished = (isEventPublished) => dispatch(eventActions.setIsEventPublished(isEventPublished));
	const setCurrentEventStatus = (status) => dispatch(eventActions.setCurrentEventStatus(status));
	const refreshEventDetails = () => dispatch(eventActions.refreshEventDetails());

	const showRepublishEventModal = useSelector(({ event }) => event.showRepublishEventModal);
	const eventDetails = useSelector(({ event }) => event.eventDetails);

	const [allowRepublish, setAllowRepublish] = useState(false);
	const [quotaBalance, setQuotaBalance] = useState(null);
	const [subscriptionLicense, setSubscriptionLicense] = useState(null);
	const [eventLogo, setEventLogo] = useState(null);

	// Get the event logo
	useEffect(() => {
		if (eventDetails) {
			const logoObject = eventDetails.event_web_configs.find((config) => config.key === "logo_url");
			if (logoObject) {
				setEventLogo(logoObject.value);
			}
		}
	}, [eventDetails]);

	// Get republish event quotation details
	useEffect(() => {
		if (eventDetails) {
			EventAPI.getRepublishEventQuotaDetails(eventDetails.id)
				.then((response) => {
					if (response.code === 200) {
						const { publish_available, quota_balance, subscription_license } = response.data;

						setAllowRepublish(publish_available);
						setQuotaBalance(quota_balance);
						setSubscriptionLicense(subscription_license);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, [eventDetails]);

	const handleRepublishEvent = () => {
		EventAPI.postUpdateEventPublishStatus(EVENT_STATUS["republish"]["value"])
			.then((response) => {
				if (response.code === 200) {
					refreshEventDetails();
					setIsEventPublished(true);
					setCurrentEventStatus(EVENT_STATUS["republish"]["value"]);
					closeRepublishEventModal();
					openAlertSnackbar("Republished event successfully.", "success");
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleManageSubscription = () => {
		closeRepublishEventModal();
		history.push("/dashboard");
	};

	return (
		<Modal
			show={showRepublishEventModal}
			onHide={closeRepublishEventModal}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="republish-event-modal"
			dialogClassName="republish-event-dialog"
			centered>
			<Modal.Body>
				<div className="republish-event-modal-body">
					<section className="mb-5 row w-100">
						{eventDetails && (
							<div className="col-6">
								<div>
									<img src={eventLogo} alt="event" className="img-event mb-4" />
								</div>
								<div className="event-details mb-5">
									<h4 className="event-title">{eventDetails.event_name}</h4>
									<span className="event-datetime">
										{moment(eventDetails.start_at).format("DD MMMM YYYY, hh:mmA")}
									</span>
								</div>
							</div>
						)}
						<div className="col-6">
							<div className="text-ended mb-3">This event has ended.</div>
							<div className="text-summary mb-5">
								By republishing this event, your quota balance will be <strong>utilised</strong>. Here’s
								the summary:
							</div>
							{quotaBalance && subscriptionLicense ? (
								<div className="usage-table">
									<div className="row header-row">
										<div className="col-3 cell header-left"></div>
										<div className="col-3 cell header-top">This Event</div>
										<div className="col-3 cell header-top">Current Usage</div>
										<div className="col-3 cell header-top">Republish Event?</div>
									</div>
									<div className="row">
										<div className="col-3 cell header-left">Registered Visitors</div>
										<div className="col-3 cell usage-value">
											+{quotaBalance.current_event_usage.visitors}
										</div>
										<div className="col-3 cell usage-value">
											{quotaBalance.current_subscription_usage.visitors} /{" "}
											{subscriptionLicense.visitors}
										</div>
										<div className="col-3 cell usage-value">
											<span
												className={`${
													quotaBalance.total_usage_quota.visitors >=
													subscriptionLicense.visitors
														? "insufficient"
														: "sufficient"
												}`}>
												{quotaBalance.total_usage_quota.visitors}
											</span>{" "}
											/ {subscriptionLicense.visitors}
										</div>
									</div>
									<div className="row">
										<div className="col-3 cell header-left">Sessions</div>
										<div className="col-3 cell usage-value">
											+{quotaBalance.current_event_usage.sessions}
										</div>
										<div className="col-3 cell usage-value">
											{quotaBalance.current_subscription_usage.sessions} /{" "}
											{subscriptionLicense.sessions}
										</div>
										<div className="col-3 cell usage-value">
											<span
												className={`${
													quotaBalance.total_usage_quota.sessions >=
													subscriptionLicense.sessions
														? "insufficient"
														: "sufficient"
												}`}>
												{quotaBalance.total_usage_quota.sessions}
											</span>{" "}
											/ {subscriptionLicense.sessions}
										</div>
									</div>
									<div className="row">
										<div className="col-3 cell header-left">Exhibitors</div>
										<div className="col-3 cell usage-value">
											+{quotaBalance.current_event_usage.exhibitors}
										</div>
										<div className="col-3 cell usage-value">
											{quotaBalance.current_subscription_usage.exhibitors} /{" "}
											{subscriptionLicense.exhibitors}
										</div>
										<div className="col-3 cell usage-value">
											<span
												className={`${
													quotaBalance.total_usage_quota.exhibitors >=
													subscriptionLicense.exhibitors
														? "insufficient"
														: "sufficient"
												}`}>
												{quotaBalance.total_usage_quota.exhibitors}
											</span>{" "}
											/ {subscriptionLicense.exhibitors}
										</div>
									</div>
									<div className="row">
										<div className="col-3 cell header-left">Ad Slots</div>
										<div className="col-3 cell usage-value">
											+{quotaBalance.current_event_usage.advertisements}
										</div>
										<div className="col-3 cell usage-value">
											{quotaBalance.current_subscription_usage.advertisements} /{" "}
											{subscriptionLicense.advertisements}
										</div>
										<div className="col-3 cell usage-value">
											<span
												className={`${
													quotaBalance.total_usage_quota.advertisements >=
													subscriptionLicense.advertisements
														? "insufficient"
														: "sufficient"
												}`}>
												{quotaBalance.total_usage_quota.advertisements}
											</span>{" "}
											/ {subscriptionLicense.advertisements}
										</div>
									</div>
								</div>
							) : (
								<div className="d-flex justify-content-center align-items-center mt-5">
									<ReactLoading type={"spinningBubbles"} color="lightgrey" />
								</div>
							)}
						</div>
					</section>

					{allowRepublish ? (
						<section>
							<div className="text-confirmation mb-3">Are you sure you want to republish this event?</div>
							<div className="text-confirmation-description mb-3">
								By republishing this event, previous and new visitors will have access to the event.
							</div>
							<div className="text-note mb-5">
								Note: Your subscription balance will be utilised to republish an event that has ended.
							</div>
							<div className="d-flex justify-content-center align-items-center">
								<button
									className="btn btn-outline-primary mx-2"
									type="button"
									onClick={closeRepublishEventModal}>
									No
								</button>
								<button className="btn btn-republish mx-2" type="button" onClick={handleRepublishEvent}>
									Republish Event
								</button>
							</div>
						</section>
					) : (
						<section>
							<div className="text-insufficient-balance mb-4">
								Oops! You do not have sufficient balance to proceed.
							</div>
							<div className="d-flex justify-content-center align-items-center">
								<button
									className="btn btn-outline-primary mx-2"
									type="button"
									onClick={closeRepublishEventModal}>
									Close
								</button>
								<button
									className="btn btn-manage-subscription mx-2"
									type="button"
									onClick={handleManageSubscription}>
									Manage Subscription
								</button>
							</div>
						</section>
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(RepublishEventModal);
