import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import ExhibitorAPI from "app/apis/exhibitor";
const MAX_TABLE_ROWS = 20;

const AudienceContainer = ({ history }) => {
	const { roomId } = useParams();

	const [page, setPage] = useState(1);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [audiences, setAudiences] = useState([]);
	const [audienceListingType, setAudienceListingType] = useState("active_audience_listing");

	useEffect(() => {
		let params = {
			page: page,
			limit: MAX_TABLE_ROWS,
		};

		if (audienceListingType === "active_audience_listing") {
			getActiveAudienceListing(params);
		} else {
			getHistoryAudienceListing(params);
		}
	}, [page, audienceListingType]);

	const getActiveAudienceListing = (params) => {
		ExhibitorAPI.getRoomAttendanceCurrentListing(roomId, params)
			.then((response) => {
				const { event_exhibitor_rooms, paging } = response.data;

				setAudiences(event_exhibitor_rooms);

				// Set paging
				const { last_page } = paging;
				setTotalPageCount(last_page);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getHistoryAudienceListing = (params) => {
		ExhibitorAPI.getRoomAttendanceHistoryListing(roomId, params)
			.then((response) => {
				const { event_exhibitor_rooms, paging } = response.data;

				setAudiences(event_exhibitor_rooms);

				// Set paging
				const { last_page } = paging;
				setTotalPageCount(last_page);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	return (
		<section className="tab-panel-container exhibitor-audience-container">
			<h4 className="tab-content-title mb-3">View Audience</h4>
			<div className="row">
				<div className="col-3">
					<div className="form-group">
						<div className="select-wrapper">
							<select
								className="form-control"
								name="audience_listing_type"
								value={audienceListingType}
								onChange={(event) => setAudienceListingType(event.target.value)}>
								<option value="active_audience_listing">Current Watching Listing</option>
								<option value="history_audience_listing">All Listing</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<table className="table">
				<thead>
					<tr>
						<th scope="col" className="table-header">
							Name
						</th>
						<th scope="col" className="table-header">
							Email Address
						</th>
						<th scope="col" className="table-header">
							Designation
						</th>
						<th scope="col" className="table-header">
							Company
						</th>
						<th scope="col" className="table-header">
							Industry
						</th>
					</tr>
				</thead>
				<tbody>
					{audiences.map((visitor) => {
						const { room_id, first_name, last_name, email, extra_infos } = visitor;

						const jobTitle = extra_infos.find((info) => {
							return info.key === "jobTitle";
						});

						const company = extra_infos.find((info) => {
							return info.key === "companyName";
						});

						const industry = extra_infos.find((info) => {
							return info.key === "industry";
						});

						return (
							<tr key={room_id}>
								<td className="table-cell">
									{last_name} {first_name}
								</td>
								<td className="table-cell">{email}</td>
								<td className="table-cell">{jobTitle ? jobTitle.value : "-"}</td>
								<td className="table-cell">{company ? company.value : "-"}</td>
								<td className="table-cell">{industry ? industry.value : "-"}</td>
							</tr>
						);
					})}
				</tbody>
			</table>

			<AppPagination pageCount={totalPageCount} handlePageChange={handlePageChange} />
		</section>
	);
};

export default withRouter(AudienceContainer);
