import Login from 'app/views/pages/Authentication/Login/Login';
import Register from 'app/views/pages/Authentication/Register/Register';
import RegisterPart2 from 'app/views/pages/Authentication/RegisterPart2/RegisterPart2';
import ResetPassword from 'app/views/pages/Authentication/ResetPassword/ResetPassword';
import MobileWarning from 'app/views/pages/MobileWarning/MobileWarning';

const PublicRoutes = [{
    path: '/',
    exact: true,
    label: 'Root Page',
    component: Login
}, {
    path: '/login',
    exact: true,
    label: 'Login Page',
    component: Login
}, {
    path: '/register',
    exact: true,
    label: 'Register Page',
    component: Register
}, {
    path: '/register/part2',
    exact: true,
    label: 'Register Page Part 2',
    component: RegisterPart2
}, {
    path: '/resetpassword',
    exact: true,
    label: 'Password Reset Page',
    component: ResetPassword
}, {
    path: '/mobile-warning',
    exact: true,
    label: 'Mobile Warning Page',
    component: MobileWarning
}];

export default PublicRoutes;