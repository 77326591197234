/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import ExhibitorAPI from "app/apis/exhibitor";
import "./style.scss";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import LocalStorageService from "app/services/localStorageService";

const MAX_RECORDS = 10;

const StoreProductsContainer = ({ history, maxStoreProductSlots }) => {
	let { eventId, roomId } = useParams();

	const exhibitorRoomDetails = LocalStorageService.getExhibitorRoomDetails();
	const storeId = exhibitorRoomDetails.event_exhibitor_room_stores[0].id;

	const [searchText, setSearchText] = useState("");

	const [page, setPage] = useState(1);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [productListing, setProductListing] = useState([]);
	const [loadedFirstTime, setLoadedFirstTime] = useState(false);
	const [totalProductListing, setTotalProductListing] = useState(0);

	useEffect(() => {
		let params = {
			page: page,
			store_id: storeId,
			limit: MAX_RECORDS,
		};

		if (searchText !== "") {
			params["search_string"] = searchText;
		}

		getEventRoomStoreProductListing(params);
	}, [page]);

	useEffect(() => {
		if (!loadedFirstTime) return;

		const timeout = setTimeout(() => {
			let params = {
				page: 1,
				store_id: storeId,
				limit: 10,
			};

			if (searchText !== "") {
				params["search_string"] = searchText;
			}

			getEventRoomStoreProductListing(params);
		}, 500);

		return () => clearTimeout(timeout);
	}, [searchText]);

	const getEventRoomStoreProductListing = (params) => {
		ExhibitorAPI.getEventRoomStoreProductListing(params)
			.then((response) => {
				const { event_exhibitor_room_store_products, paging } = response.data;
				const { last_page, records_total } = paging;

				setProductListing(event_exhibitor_room_store_products);
				setTotalPageCount(last_page);
				setLoadedFirstTime(true);

				if (totalProductListing <= 0) {
					setTotalProductListing(records_total);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	const handleViewProductDetails = (productId) => {
		history.push(`/events/view/${eventId}/exhibitor/rooms/${roomId}/product/${productId}`);
	}

	const ProductCard = ({ id, thumbnail, name, price }) => {
		return (
			<div className="product-card" onClick={() => handleViewProductDetails(id)}>
				<div className="product-image">
					<img src={thumbnail} alt="thumbnail" className="product-thumbnail" />
				</div>
				<div className="p-2">
					<div className="product-name">{name}</div>
					<div className="product-price">{price}</div>
				</div>
			</div>
		);
	};

	return (
		<section className="tab-panel-container store-products-container mb-4">
			<div className="row align-items-center mb-3">
				<div className="col-2">
					<h4 className="tab-content-title">Manage Store</h4>
				</div>
				<div className="col-5"></div>
				<div className="col-5 text-right">
					{maxStoreProductSlots && (
						<span className="product-slots-utilized">
							{totalProductListing} of {maxStoreProductSlots} product slots utilised
						</span>
					)}
				</div>
				{/* Disabled: do not allow add product */}
				{/* <div className="col-2">
					<button
						type="button"
						className="btn btn-primary w-100"
						disabled={totalProductListing >= maxStoreProductSlots}
						onClick={() => history.push(`/events/view/${eventId}/exhibitor/rooms/${roomId}/product/add`)}>
						Add Product
					</button>
				</div> */}
			</div>

			<div className="row mb-3">
				<div className="col-12">
					<div className="form-group">
						<input
							id="search"
							type="text"
							className="form-control"
							aria-describedby="search"
							name="search"
							value={searchText}
							onChange={(event) => setSearchText(event.target.value)}
							placeholder="Search"
						/>
					</div>
				</div>
			</div>

			<div className="row row-cols-5">
				{productListing.map((product) => {
					const { id, name, currency, price, content_url } = product;

					return (
						<div key={id} className="col mb-3">
							<ProductCard id={id} thumbnail={content_url} name={name} price={`${currency}${price}`} />
						</div>
					);
				})}
			</div>

			<AppPagination pageCount={totalPageCount} handlePageChange={handlePageChange} />
		</section>
	);
};

export default withRouter(StoreProductsContainer);
