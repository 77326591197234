import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./style.scss";

const LayoutContainer = ({ children }) => {
	return (
		<div className="container-fluid layout-container px-0">
            <div className="d-flex layout-content">
                <div className="sidebar-wrapper">
                    <Sidebar />
                </div>
                <div className="content-wrapper p-4">
                    {children}
                </div>
            </div>
		</div>
	);
};

export default LayoutContainer;
