import apiAction from "../services/apiAction";

const CommonAPI = {
	/**
	 * Get currencies
	 */
	getCurrencies: () => {
		return new Promise((resolve, reject) => {
			/** Define API Url Path */
			const url = `/currencies`;

			apiAction
				.get(url)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err.response));
		});
	},
};

export default CommonAPI;
