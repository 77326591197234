/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import ChannelSessionAPI from "app/apis/channelSession";
import IconEdit from "app/assets/icon-edit-grey.png";
import IconDeleteGrey from "app/assets/icon-delete-grey.png";
import IconDelete from "app/assets/icon-delete-red.png";
import moment from "moment";
import LocalStorageService from "app/services/localStorageService";
import { commonActions } from "app/states/common";
import { eventActions } from "app/states/event";
import { useSelector, useDispatch } from "react-redux";
import CustomModal from "app/views/components/CustomModal/CustomModal";
import CreateQnaModal from "../CreateQnaModal/CreateQnaModal";
import EditQnaModal from "../EditQnaModal/EditQnaModal";

const MAX_RECORDS_PER_PAGE = 5;

const QnAContainer = ({ history }) => {
	const userProfile = LocalStorageService.getUserProfile();
	const { sessionId } = useParams();

	const dispatch = useDispatch();
	const openCreateQnaModal = () => dispatch(eventActions.openCreateQnaModal());
	const refreshQnaListing = (refresh) => dispatch(eventActions.refreshQnaListing(refresh));
	const openCustomModal = () => dispatch(commonActions.openCustomModal());
	const closeCustomModal = () => dispatch(commonActions.closeCustomModal());
	const openEditQnaModal = (questionId) => dispatch(eventActions.openEditQnaModal(questionId));
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const isRefreshQnaListing = useSelector(({ event }) => event.refreshQnaListing);

	const [page, setPage] = useState(1);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [qnas, setQnas] = useState([]);
	const [questionId, setQuestionId] = useState(null);
	const [openModalName, setOpenModalName] = useState(null);

	useEffect(() => {
		getQnaListing();
	}, [sessionId, page]);

	useEffect(() => {
		if (isRefreshQnaListing) {
			refreshQnaListing(false);
			getQnaListing();
		}
	}, [isRefreshQnaListing]);

	const getQnaListing = () => {
		let params = {
			page: page,
			limit: MAX_RECORDS_PER_PAGE,
			session_id: sessionId,
		};

		ChannelSessionAPI.getQnaListing(params)
			.then((response) => {
				const { questionnaires, paging } = response.data;
				setQnas(questionnaires);

				// Set paging
				const { last_page } = paging;
				setTotalPageCount(last_page);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	const handleConfirmDelete = () => {
		ChannelSessionAPI.deleteQna(questionId)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					if (response.data.deleted) {
						setQuestionId(null);
						setOpenModalName(null);
						closeCustomModal();

						// Remove the deleted from array
						let deletedElementIndex = qnas
							.map((qna) => {
								return qna.question_id;
							})
							.indexOf(questionId);

						let qnasCopy = [...qnas];
						qnasCopy.splice(deletedElementIndex, 1);
						setQnas(qnasCopy);
					}

					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const QuestionRow = ({
		question_id,
		question,
		question_created_name,
		question_created_at,
		answer,
		answer_created_name,
		answer_created_at,
	}) => {
		const [inputAnswer, setInputAnswer] = useState(answer);
		const [submittedAnswer, setSubmittedAnswer] = useState(answer ? true : false);

		const handleSubmitAnswer = (event) => {
			// submit answer
			event.preventDefault();

			const data = {
				answer: inputAnswer,
				answer_created_by: userProfile.id,
			};

			ChannelSessionAPI.postAddAnswer(question_id, data)
				.then((response) => {
					setSubmittedAnswer(true);
					getQnaListing();
				})
				.catch((error) => {
					openAlertSnackbar(error.data.errors.join("\n"), "error");
				});
		};

		const handleOnDelete = () => {
			setQuestionId(question_id);
			setOpenModalName("delete_qna");
			openCustomModal();
		};

		return (
			<div className="question-row mb-3">
				<div className="row mb-3">
					<div className="col-7 title">{question}</div>
					<div className="col-3 px-0">
						<span className="meta-question-by">
							{question_created_name} | {question_created_at}
						</span>
					</div>
					<div className="col-2 text-right">
						<img
							src={IconEdit}
							alt="edit"
							className="icon-action mr-4"
							onClick={() => openEditQnaModal(question_id)}
						/>
						<img src={IconDeleteGrey} alt="edit" className="icon-action" onClick={handleOnDelete} />
					</div>
				</div>
				{submittedAnswer ? (
					<>
						<div className="answer mb-2 px-3">{inputAnswer}</div>
						<div className="meta-answer-by px-3">
							Answered by {answer_created_name} at {answer_created_at}.
						</div>
					</>
				) : (
					<form>
						<div className="form-group answer-input-wrapper">
							<textarea
								className="form-control"
								value={inputAnswer}
								placeholder="Answer here..."
								rows={3}
								onChange={(event) => setInputAnswer(event.target.value)}
								required
							/>

							{inputAnswer && (
								<button type="button" className="btn btn-primary btn-submit-answer" onClick={handleSubmitAnswer}>
									Submit
								</button>
							)}
						</div>
					</form>
				)}
			</div>
		);
	};

	return (
		<section className="tab-panel-container qna-container">
			<div className="row mb-3">
				<div className="col-3">
					<h4 className="tab-content-title mb-3">Manage Q&A</h4>
				</div>
				<div className="col-7"></div>
				<div className="col-2">
					<button type="button" className="btn btn-primary w-100" onClick={openCreateQnaModal}>
						Add QnA
					</button>
				</div>
			</div>

			<div className="qna-contents-container">
				{qnas.map((qna) => {
					const {
						question_id,
						question,
						question_created_name,
						question_created_at,
						answer,
						answer_created_name,
						answer_created_at,
					} = qna;

					const questionDatetime = moment(question_created_at).format("DD MMMM YYYY, hh:mmA");
					const answerDatetime = moment(answer_created_at).format("DD MMMM YYYY, hh:mmA");

					return (
						<QuestionRow
							key={question_id}
							question_id={question_id}
							question={question}
							question_created_name={question_created_name}
							question_created_at={questionDatetime}
							answer={answer}
							answer_created_name={answer_created_name}
							answer_created_at={answerDatetime}
						/>
					);
				})}
			</div>

			<AppPagination pageCount={totalPageCount} handlePageChange={handlePageChange} />

			{openModalName === "delete_qna" && (
				<CustomModal
					icon={IconDelete}
					title="Are you sure you want to delete this question?"
					btnClass="btn-danger"
					textPrimary={
						<>
							<img src={IconDeleteGrey} alt="delete" className="icon-btn" /> Delete
						</>
					}
					textSecondary="Cancel"
					onClickPrimary={handleConfirmDelete}
					onClickSecondary={closeCustomModal}
				/>
			)}

			<CreateQnaModal />
			<EditQnaModal />
		</section>
	);
};

export default withRouter(QnAContainer);
