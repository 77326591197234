import LocalStorageService from "app/services/localStorageService";
import * as types from "./types";

const initialState = {
	showCreateChannelModal: false,
	showCreateQnaModal: false,
	refreshQnaListing: false,
	showEditQnaModal: false,
	viewQuestionId: null,
	showCreatePollModal: false,
	refreshPollListing: false,
	showEditPollModal: false,
	viewPollId: null,
	showCreateExhibitorCategoryModal: false,
	refreshExhibitorCategoryListing: false,
	showViewExhibitorCategoryModal: false,
	viewExhibitorCategoryId: null,
	isRefreshExhibitorRoomDetails: false,
	selectedChannelForSessionSidebar: null,
	selectedDateForSessionSidebar: new Date(),

	showAddSpeakerModal: false,
	showEditSpeakerModal: false,
	viewingSpeaker: null,
	refreshSpeakerListCount: 0,

	showAddEventHighlightsModal: false,
	showEditEventHighlightsModal: false,
	viewingEventHighlights: null,
	refreshEventHighlightsListCount: 0,

	showAddEventPartnerModal: false,
	showEditEventPartnerModal: false,
	viewingEventPartner: null,
	currentEventPartnerKey: "event_partners",
	refreshEventPartnerListCount: 0,

	isEventPublished: false,
	eventPublishAction: null,
	currentEventStatus: null,
	showPublishEventModal: false,
	showPauseEndEventModal: false,
	showRepublishEventModal: false,

	eventDetails: LocalStorageService.getEventDetails() || null,
	refreshEventDetailsCount: 0,

	showSessionControlCreateChannelModal: false,
	refreshSessionControlListingCount: 0,
};

const EventReducer = (state = initialState, action) => {
	switch (action.type) {
		/**
		 * Create channel modal
		 */
		case types.OPEN_CREATE_CHANNEL_MODAL:
			return {
				...state,
				showCreateChannelModal: true,
			};
		case types.CLOSE_CREATE_CHANNEL_MODAL:
			return {
				...state,
				showCreateChannelModal: false,
			};

		/**
		 * QnA
		 */
		case types.OPEN_CREATE_QNA_MODAL:
			return {
				...state,
				showCreateQnaModal: true,
			};
		case types.CLOSE_CREATE_QNA_MODAL:
			return {
				...state,
				showCreateQnaModal: false,
			};

		case types.REFRESH_QNA_LISTING:
			return {
				...state,
				refreshQnaListing: action.params.refresh,
			};

		case types.OPEN_EDIT_QNA_MODAL:
			return {
				...state,
				showEditQnaModal: true,
				viewQuestionId: action.params.questionId,
			};
		case types.CLOSE_EDIT_QNA_MODAL:
			return {
				...state,
				showEditQnaModal: false,
				viewQuestionId: null,
			};

		/**
		 * Poll
		 */
		case types.OPEN_CREATE_POLL_MODAL:
			return {
				...state,
				showCreatePollModal: true,
			};
		case types.CLOSE_CREATE_POLL_MODAL:
			return {
				...state,
				showCreatePollModal: false,
			};

		case types.REFRESH_POLL_LISTING:
			return {
				...state,
				refreshPollListing: action.params.refresh,
			};

		case types.OPEN_EDIT_POLL_MODAL:
			return {
				...state,
				showEditPollModal: true,
				viewPollId: action.params.pollId,
			};
		case types.CLOSE_EDIT_POLL_MODAL:
			return {
				...state,
				showEditPollModal: false,
				viewPollId: null,
			};

		/**
		 * Create channel modal
		 */
		case types.OPEN_CREATE_EXHIBITOR_CATEGORY_MODAL:
			return {
				...state,
				showCreateExhibitorCategoryModal: true,
			};
		case types.CLOSE_CREATE_EXHIBITOR_CATEGORY_MODAL:
			return {
				...state,
				showCreateExhibitorCategoryModal: false,
			};
		case types.REFRESH_EXHIBITOR_CATEGORY_LISTING:
			return {
				...state,
				refreshExhibitorCategoryListing: action.params.refresh,
			};
		case types.OPEN_VIEW_EXHIBITOR_CATEGORY_MODAL:
			return {
				...state,
				showViewExhibitorCategoryModal: true,
				viewExhibitorCategoryId: action.params.exhibitorCategoryId,
			};
		case types.CLOSE_VIEW_EXHIBITOR_CATEGORY_MODAL:
			return {
				...state,
				showViewExhibitorCategoryModal: false,
				viewExhibitorCategoryId: null,
			};

		case types.REFRESH_EXHIBITOR_ROOM_DETAILS:
			return {
				...state,
				isRefreshExhibitorRoomDetails: action.params.refresh,
			};

		/**
		 * SELECTED CHANNEL FOR SESSION SIDEBAR
		 */
		case types.SET_SELECTED_CHANNEL_FOR_SESSION_SIDEBAR:
			return {
				...state,
				selectedChannelForSessionSidebar: action.params.selectedChannel,
			};
		case types.SET_SELECTED_DATE_FOR_SESSION_SIDEBAR:
			return {
				...state,
				selectedDateForSessionSidebar: action.params.selectedDate,
			};

		/**
		 * EVENT DETAILS
		 */
		case types.SET_EVENT_DETAILS:
			return {
				...state,
				eventDetails: action.params.eventDetails,
			};
		case types.REFRESH_EVENT_DETAILS:
			return {
				...state,
				refreshEventDetailsCount: state.refreshEventDetailsCount + 1,
			};

		/**
		 * Landing page editor - speaker modal
		 */
		case types.OPEN_ADD_SPEAKER_MODAL:
			return {
				...state,
				showAddSpeakerModal: true,
			};
		case types.CLOSE_ADD_SPEAKER_MODAL:
			return {
				...state,
				showAddSpeakerModal: false,
			};
		case types.OPEN_EDIT_SPEAKER_MODAL:
			return {
				...state,
				showEditSpeakerModal: true,
			};
		case types.CLOSE_EDIT_SPEAKER_MODAL:
			return {
				...state,
				showEditSpeakerModal: false,
			};
		case types.SET_VIEWING_SPEAKER:
			return {
				...state,
				viewingSpeaker: action.params.speaker,
			};
		case types.REFRESH_SPEAKER_LIST:
			return {
				...state,
				refreshSpeakerListCount: state.refreshSpeakerListCount + 1,
			};

		/**
		 * Landing page editor - event highlights modal
		 */
		case types.OPEN_ADD_EVENT_HIGHLIGHTS_MODAL:
			return {
				...state,
				showAddEventHighlightsModal: true,
			};
		case types.CLOSE_ADD_EVENT_HIGHLIGHTS_MODAL:
			return {
				...state,
				showAddEventHighlightsModal: false,
			};
		case types.OPEN_EDIT_EVENT_HIGHLIGHTS_MODAL:
			return {
				...state,
				showEditEventHighlightsModal: true,
			};
		case types.CLOSE_EDIT_EVENT_HIGHLIGHTS_MODAL:
			return {
				...state,
				showEditEventHighlightsModal: false,
			};
		case types.SET_VIEWING_EVENT_HIGHLIGHTS:
			return {
				...state,
				viewingEventHighlights: action.params.eventHighlights,
			};
		case types.REFRESH_EVENT_HIGHLIGHTS_LIST:
			return {
				...state,
				refreshEventHighlightsListCount: state.refreshEventHighlightsListCount + 1,
			};

		/**
		 * Landing page editor - event partner modal
		 */
		case types.OPEN_ADD_EVENT_PARTNER_MODAL:
			return {
				...state,
				showAddEventPartnerModal: true,
			};
		case types.CLOSE_ADD_EVENT_PARTNER_MODAL:
			return {
				...state,
				showAddEventPartnerModal: false,
			};
		case types.OPEN_EDIT_EVENT_PARTNER_MODAL:
			return {
				...state,
				showEditEventPartnerModal: true,
			};
		case types.CLOSE_EDIT_EVENT_PARTNER_MODAL:
			return {
				...state,
				showEditEventPartnerModal: false,
			};
		case types.SET_VIEWING_EVENT_PARTNER:
			return {
				...state,
				viewingEventPartner: action.params.eventPartner,
			};
		case types.SET_CURRENT_EVENT_PARTNER_KEY:
			return {
				...state,
				currentEventPartnerKey: action.params.eventPartnerKey,
			};
		case types.REFRESH_EVENT_PARTNERS_LIST:
			return {
				...state,
				refreshEventPartnerListCount: state.refreshEventPartnerListCount + 1,
			};

		/**
		 * Publish event modal
		 */
		case types.SET_IS_EVENT_PUBLISHED:
			return {
				...state,
				isEventPublished: action.params.isEventPublished,
			};
		case types.SET_EVENT_PUBLISH_ACTION:
			return {
				...state,
				eventPublishAction: action.params.action,
			};
		case types.SET_CURRENT_EVENT_STATUS:
			return {
				...state,
				currentEventStatus: action.params.status,
			};
		case types.OPEN_PUBLISH_EVENT_MODAL:
			return {
				...state,
				showPublishEventModal: true,
			};
		case types.CLOSE_PUBLISH_EVENT_MODAL:
			return {
				...state,
				showPublishEventModal: false,
			};
		case types.OPEN_PAUSE_END_EVENT_MODAL:
			return {
				...state,
				showPauseEndEventModal: true,
			};
		case types.CLOSE_PAUSE_END_EVENT_MODAL:
			return {
				...state,
				showPauseEndEventModal: false,
			};
		case types.OPEN_REPUBLISH_EVENT_MODAL:
			return {
				...state,
				showRepublishEventModal: true,
			};
		case types.CLOSE_REPUBLISH_EVENT_MODAL:
			return {
				...state,
				showRepublishEventModal: false,
			};

		/**
		 * Session control create channel modal
		 */
		case types.OPEN_SESSION_CONTROL_CREATE_CHANNEL_MODAL:
			return {
				...state,
				showSessionControlCreateChannelModal: true,
			};
		case types.CLOSE_SESSION_CONTROL_CREATE_CHANNEL_MODAL:
			return {
				...state,
				showSessionControlCreateChannelModal: false,
			};

		/**
		 * Refresh session listing in sidebar
		 */
		case types.REFRESH_SESSION_CONTROL_LISTING_COUNT:
			return {
				...state,
				refreshSessionControlListingCount: state.refreshSessionControlListingCount + 1,
			};
		default:
			return state;
	}
};

export default EventReducer;
