import LocalStorageService from "app/services/localStorageService";
import * as types from "./types";

const initialState = {
	showUpgradeMonthlyPlanConfirmationModal: false,
	showUpgradeAnnualPlanConfirmationModal: false,
	showPaymentSuccessModal: false,
	chosenPlan: LocalStorageService.getChosenPlan() || null,
	currentSubscriptionPlan: LocalStorageService.getCurrentSubscriptionPlan() || null,
	selectedPricingMode: "monthly",
	refreshBillingDashboardCount: 0,
	isUpgradingPlan: false,
	confirmPurchasePrice: null,
	showConfirmPurchasePlanModal: false,
	showUpgradeEnterprisePlanConfirmationModal: false,
	showEnterprisePlanReachoutSuccessModal: false,
	pendingSubscription: LocalStorageService.getPendingSubscriptionPlan() || null,
	refreshCurrentSubscriptionPlanCount: 0,
	showReactivatePlanModal: false,
	showDowngradeConfirmationModal: false,
};

const BillingReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.OPEN_UPGRADE_MONTHLY_PLAN_CONFIRMATION_MODAL:
			return {
				...state,
				showUpgradeMonthlyPlanConfirmationModal: true,
			};
		case types.CLOSE_UPGRADE_MONTHLY_PLAN_CONFIRMATION_MODAL:
			return {
				...state,
				showUpgradeMonthlyPlanConfirmationModal: false,
			};

		case types.OPEN_UPGRADE_ANNUAL_PLAN_CONFIRMATION_MODAL:
			return {
				...state,
				showUpgradeAnnualPlanConfirmationModal: true,
			};
		case types.CLOSE_UPGRADE_ANNUAL_PLAN_CONFIRMATION_MODAL:
			return {
				...state,
				showUpgradeAnnualPlanConfirmationModal: false,
			};

		case types.OPEN_PAYMENT_SUCCESS_MODAL:
			return {
				...state,
				showPaymentSuccessModal: true,
			};
		case types.CLOSE_PAYMENT_SUCCESS_MODAL:
			return {
				...state,
				showPaymentSuccessModal: false,
			};

		case types.SET_CHOSEN_PLAN:
			if (action.params.chosenPlan) {
				LocalStorageService.setChosenPlan(action.params.chosenPlan);
			}
			else {
				LocalStorageService.removeChosenPlan();
			}

			return {
				...state,
				chosenPlan: action.params.chosenPlan,
			};

		case types.SET_CURRENT_PLAN:
			return {
				...state,
				currentSubscriptionPlan: action.params.currentPlan,
			};

		case types.SET_SELECTED_PRICING_MODE:
			return {
				...state,
				selectedPricingMode: action.params.pricingMode,
			};

		case types.REFRESH_BILLING_DASHBOARD:
			return {
				...state,
				refreshBillingDashboardCount: state.refreshBillingDashboardCount + 1,
			};

		case types.SET_IS_UPGRADING_PLAN:
			return {
				...state,
				isUpgradingPlan: action.params.isUpgradingPlan,
			};

		// Confirm purchase plan modal
		case types.OPEN_CONFIRM_PURCHASE_PLAN_MODAL:
			return {
				...state,
				showConfirmPurchasePlanModal: true,
			};
		case types.CLOSE_CONFIRM_PURCHASE_PLAN_MODAL:
			return {
				...state,
				showConfirmPurchasePlanModal: false,
			};
		case types.SET_CONFIRM_PURCHASE_PRICE:
			return {
				...state,
				confirmPurchasePrice: action.params.confirmPurchasePrice,
			};

		// Upgrade enterprise plan confirmation modal
		case types.OPEN_UPGRADE_ENTERPRISE_PLAN_CONFIRMATION_MODAL:
			return {
				...state,
				showUpgradeEnterprisePlanConfirmationModal: true,
			};
		case types.CLOSE_UPGRADE_ENTERPRISE_PLAN_CONFIRMATION_MODAL:
			return {
				...state,
				showUpgradeEnterprisePlanConfirmationModal: false,
			};

		// Enterprise plan reachout success modal
		case types.OPEN_ENTERPRISE_PLAN_REACHOUT_SUCCESS_MODAL:
			return {
				...state,
				showEnterprisePlanReachoutSuccessModal: true,
			};
		case types.CLOSE_ENTERPRISE_PLAN_REACHOUT_SUCCESS_MODAL:
			return {
				...state,
				showEnterprisePlanReachoutSuccessModal: false,
			};

		// Pending subscription
		case types.SET_PENDING_SUBSCRIPTION:
			LocalStorageService.setPendingSubscriptionPlan(action.params.pendingSubscription);

			return {
				...state,
				pendingSubscription: action.params.pendingSubscription,
			};

		// refresh current subscription plan count
		case types.REFRESH_CURRENT_SUBSCRIPTION_PLAN:
			return {
				...state,
				refreshCurrentSubscriptionPlanCount: state.refreshCurrentSubscriptionPlanCount + 1,
			};

		// reactivate plan modal
		case types.OPEN_REACTIVATE_PLAN_MODAL:
			return {
				...state,
				showReactivatePlanModal: true,
			};
		case types.CLOSE_REACTIVATE_PLAN_MODAL:
			return {
				...state,
				showReactivatePlanModal: false,
			};

		// Downgrade Confirmation modal
		case types.OPEN_DOWNGRADE_CONFIRMATION_MODAL:
			return {
				...state,
				showDowngradeConfirmationModal: true,
			};
		case types.CLOSE_DOWNGRADE_CONFIRMATION_MODAL:
			return {
				...state,
				showDowngradeConfirmationModal: false,
			};
			
		default:
			return state;
	}
};

export default BillingReducer;
