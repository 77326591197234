/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { billingActions } from "app/states/billings";
import IconClose from "app/assets/icon-close.png";
import { withRouter } from "react-router-dom";
import { commonActions } from "app/states/common";
import ReactLoading from "react-loading";
import BillingAPI from "app/apis/billings";
import LocalStorageService from "app/services/localStorageService";

const UpgradeMonthlyPlanConfirmationModal = ({ history }) => {
	const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();

	const dispatch = useDispatch();
	const closeUpgradeMonthlyPlanConfirmationModal = () =>
		dispatch(billingActions.closeUpgradeMonthlyPlanConfirmationModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const openConfirmPurchasePlanModal = () => dispatch(billingActions.openConfirmPurchasePlanModal());
	const setConfirmPurchasePrice = (price) => dispatch(billingActions.setConfirmPurchasePrice(price));
	const refreshCurrentSubscriptionPlan = () => dispatch(billingActions.refreshCurrentSubscriptionPlan());
	const setIsUpgradingPlan = (isUpgrade) => dispatch(billingActions.isUpgradingPlan(isUpgrade));

	const showUpgradeMonthlyPlanConfirmationModal = useSelector(
		({ billing }) => billing.showUpgradeMonthlyPlanConfirmationModal
	);
	const showConfirmPurchasePlanModal = useSelector(({ billing }) => billing.showConfirmPurchasePlanModal);
	const chosenPlan = useSelector(({ billing }) => billing.chosenPlan);
	const isUpgradingPlan = useSelector(({ billing }) => billing.isUpgradingPlan);

	const [upgradeQuotation, setUpgradeQuotation] = useState(null);

	useEffect(() => {
		if (isUpgradingPlan && showUpgradeMonthlyPlanConfirmationModal) {
			const data = {
				subscription_plan_id: chosenPlan.id,
				term: 1,
			};

			BillingAPI.getQuotation(selectedOrganizationAccount.id, data)
				.then((response) => {
					if (response.code === 400) {
						handleCloseModal();
						openAlertSnackbar(response.errors.join("\n"), "error");
					}
					else {
						setUpgradeQuotation(response);
						setConfirmPurchasePrice(response.total);
					}
				})
				.catch((error) => {
					console.error(error);
					openAlertSnackbar(error.data.errors.join("\n"), "error");
				});
		}
	}, [showUpgradeMonthlyPlanConfirmationModal]);

	const handleCheckout = () => {
		if (isUpgradingPlan) {
			openConfirmPurchasePlanModal();
			return;
		}

		const data = {
			subscription_plan_id: chosenPlan.id,
			term: 1,
		};

		BillingAPI.postChoosePlan(selectedOrganizationAccount.id, data)
			.then((response) => {
				refreshCurrentSubscriptionPlan();

				if (response.code === 200) {
					const { payment_url } = response.data;
					window.location.href = payment_url;

					handleCloseModal();
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleCloseModal = () => {
		setIsUpgradingPlan(false);
		closeUpgradeMonthlyPlanConfirmationModal();
	}

	return (
		<Modal
			show={showUpgradeMonthlyPlanConfirmationModal}
			onHide={handleCloseModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="upgrade-plan-confirmation-modal"
			className={showConfirmPurchasePlanModal ? "upgrade-plan-modal" : ""}
			centered>
			<Modal.Body>
				{chosenPlan ? (
					<div className="upgrade-plan-confirmation-modal-body">
						<div className="icon-close-wrapper">
							<img
								src={IconClose}
								alt="close"
								className="icon-close"
								onClick={handleCloseModal}
							/>
						</div>

						<div className="content-wrapper">
							<h1>Upgrade Confirmation</h1>

							<div className="content-container">
								<div className="d-flex justify-content-between align-items-center mb-3">
									<div>
										<div className="text-16">{chosenPlan.name}</div>
										<div className="text-12">Billed Monthly</div>
									</div>
									<div className="plan-pricing">
										<span className="price mr-2">${chosenPlan.monthly_price}</span>
										<span>/ month</span>
									</div>
								</div>

								{upgradeQuotation && (
									<section className="upgrade-discount-section">
										<div className="d-flex justify-content-between align-items-center mb-3">
											<div>
												<div className="text-16 discount-text">{upgradeQuotation.existing_product}</div>
												<div className="text-12 discount-text">
													Unused time, Pro-Rated Rebate
												</div>
											</div>
											<div className="discount-price">- ${Math.abs(upgradeQuotation.prorated)}</div>
										</div>
										<div className="d-flex justify-content-between align-items-center">
											<div>
												<div className="text-12 discount-text">
													Discount {chosenPlan.monthly_discount_percentage}%
												</div>
											</div>
											<div className="discount-price">- ${Math.abs(upgradeQuotation.discount)}</div>
										</div>
									</section>
								)}

								<hr className={`${isUpgradingPlan ? "mt-3" : "mt-5"} mb-3`} />

								<div className="d-flex justify-content-between align-items-center">
									<div className="text-12">Payable Amount</div>
									<div className="price">${upgradeQuotation ? upgradeQuotation.total : chosenPlan.monthly_price}</div>
								</div>
							</div>

							<p className="description">
								This is a monthly subscription plan. You can choose to opt out at any point of time at
								the billing panel.
							</p>

							<div className="d-flex justify-content-center align-items-center">
								<button className="btn btn-cancel" onClick={handleCloseModal}>
									Cancel
								</button>
								<button className="btn btn-check-out" onClick={handleCheckout}>
									Check Out
								</button>
							</div>
						</div>
					</div>
				) : (
					<div className="d-flex justify-content-center align-items-center mt-5">
						<ReactLoading type={"spinningBubbles"} color="lightgrey" />
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(UpgradeMonthlyPlanConfirmationModal);
