import LocalStorageService from "app/services/localStorageService"
import * as types from "./types"


const initialState = {
    organizationId: null,
    selectedOrganizationAccount: LocalStorageService.getSelectedOrganizationAccount() || null,
    isRefreshOrganizationListing: false,
}


const OrganizationReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.SET_ORGANIZATION_ID: 
            return {
                ...state, 
                organizationId: action.params.organizationId
            }
        case types.SET_SELECTED_ORGANIZATION_ACCOUNT: 
            return {
                ...state, 
                selectedOrganizationAccount: action.params.organizationAccount
            }
        case types.REFRESH_ORGANIZATION_LISTING: 
            return {
                ...state, 
                isRefreshOrganizationListing: action.params.refresh
            }

        default:
            return state
    }
}

export default OrganizationReducer;