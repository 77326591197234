import React, { Fragment, useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import TokenService from "../services/tokenService";
import axios from "axios";
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive'

import PublicRoutes from "./public";
import PrivateRoutes from "./private";

const Routes = () => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

	const showLoading = useSelector(({ common }) => common.showLoading);

	const [publicRoutes, setPublicRoutes] = useState([]);
	const [privateRoutes, setPrivateRoutes] = useState([]);

	const location = useLocation();

	// Render routes
	useEffect(() => {
		renderPublicRoutes();
		renderPrivateRoutes();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// // Redirect to mobile warning page
	// useEffect(() => {
	// 	if (isTabletOrMobile && window.location.pathname !== "/mobile-warning") {
	// 		window.location.href = "/mobile-warning";
	// 	}
	// }, []);

	const checkIsAuthenticated = (RouteComponent) => {
		const accessToken = TokenService.getAccessToken();

		// Add authorization bearer token to axios
		axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

		if (!accessToken) {
			return <Redirect to="/login" />;
		}

		return <RouteComponent />;
	};

	const renderPublicRoutes = () => {
		let rootRoutes = Array.prototype.concat(PublicRoutes);

		const publicRoutes = rootRoutes.map((route, index) => (
			<Route key={index} exact={route.exact} path={route.path} component={route.component} />
		));

		setPublicRoutes(publicRoutes);
	};

	const renderPrivateRoutes = () => {
		let rootRoutes = Array.prototype.concat(PrivateRoutes);

		const privateRoutes = rootRoutes.map((route, index) => (
			<Route
				key={index}
				exact={route.exact}
				path={route.path}
				render={() => checkIsAuthenticated(route.component)}
			/>
		));

		setPrivateRoutes(privateRoutes);
	};

	const redirectToLogin = () => {
		if (location.pathname === "/") {
			return <Redirect to="/login" />;
		}
	};

	return (
		<Fragment>
			<Switch>
				{publicRoutes}
				{privateRoutes}

				{redirectToLogin()}
			</Switch>

			{showLoading && (
				<div className="app-loading-overlay">
					<ReactLoading type={"spinningBubbles"} color="lightgrey" />
				</div>
			)}
		</Fragment>
	);
};

export default withRouter(Routes);
