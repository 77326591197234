import apiAction from "../services/apiAction"

const AccountAPI = {

    /**
     * Get organization account listing
     * @param {int} organizationId
     * @param {object} queryParams 
     * {page, limit, name}
     */
    getOrganizationAccountListing: (organizationId, queryParams = {}) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/organization/${organizationId}/accounts`;
            
            apiAction.get(url, queryParams)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Get organization account detail
     * @param {int} organizationId
     * @param {int} account_id 
     */
    getOrganizationDetail: (organizationId, account_id) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/organization/${organizationId}/accounts/${account_id}`;
            
            apiAction.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => reject(err.response))
        })
    },

    /**
     * Create organization account
     * @param {FormData} formData 
     */
    postCreateOrganizationAccount: (formData) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/organization/accounts`;

            apiAction.post(url, formData)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Update organization account
     * @param {int} account_id
     * @param {FormData} formData 
     */
    postUpdateOrganizationAccount: (account_id, formData) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/organization/accounts/${account_id}`;

            apiAction.post(url, formData)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Delete organization account
     * @param {int} account_id
     */
    deleteOrganizationAccount: (account_id) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/organization/accounts/${account_id}`;

            apiAction.delete(url)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Invite team members
     * @param {int} account_id
     * @param {formData} data 
     */
    postInviteTeamMembers: (account_id, data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/organization/account/user/invites/${account_id}`;

            apiAction.post(url, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },

    /**
     * Delete organization account user invite
     * @param {int} account_id
     */
    deleteOrganizationAccountUserInvite: (account_id, data) => {
        return new Promise((resolve, reject) => {
            /** Define API Url Path */
            const url = `/organization/account/user/invites/${account_id}`;

            apiAction.delete(url, {}, data)
                .then(response => resolve(response.data))
                .catch(err => reject(err.response))
        })
    },
};

export default AccountAPI;
