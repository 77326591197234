import React, { useState, useRef, useEffect } from "react";
import IconBack from "app/assets/icon-back-grey.png";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import { useDispatch } from "react-redux";
import IconUpload from "app/assets/icon-add-grey.png";
import IconRemove from "app/assets/icon-close.png";
import ExhibitorAPI from "app/apis/exhibitor";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import LocalStorageService from "app/services/localStorageService";
import { commonActions } from "app/states/common";

const AddExhibitorStoreProduct = ({ history }) => {
	let { eventId, roomId } = useParams();

	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());

	const exhibitorRoomDetails = LocalStorageService.getExhibitorRoomDetails();

	const [inputData, setInputData] = useState({
		name: "",
		description: "",
		currency: "",
		price: "",
	});

	const [uploadedProductImages, setUploadedProductImages] = useState([]);
	const [uploadedProductImagesUrl, setUploadedProductImagesUrl] = useState([]);
	const [productImages, setProductImages] = useState([]);

	const handleInputOnChange = (event) => {
		if (event.target.name === "price") {
			// Restrict 2 decimal places only
			let t = event.target.value;
			event.target.value = t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3) : t;
			setInputData({ ...inputData, [event.target.name]: event.target.value });
		} else {
			setInputData({ ...inputData, [event.target.name]: event.target.value });
		}
	};

	const handleCreateProduct = (event) => {
		event.preventDefault();
		showLoading();

		const storeId = exhibitorRoomDetails.event_exhibitor_room_stores.id;

		let formData = new FormData();
		formData.append("store_id", storeId);
		formData.append("name", inputData.name);
		formData.append("description", inputData.description);
		formData.append("price", inputData.price);

		uploadedProductImages.forEach((uploadedProductImage) => {
			formData.append("photo", uploadedProductImage);
		});

		ExhibitorAPI.postCreateEventRoomStoreProduct(formData)
			.then((response) => {
				if (response.code === 200) {
					const newProductId = response.data.id[0];
					history.push(`/events/view/${eventId}/exhibitor/rooms/${roomId}`, {
						defaultTabKey: "store",
					});
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleProductImageUpload = (event, index) => {
		setUploadedProductImages([...uploadedProductImages, event.target.files[0]]);
		setUploadedProductImagesUrl([...uploadedProductImagesUrl, URL.createObjectURL(event.target.files[0])]);
	};

	const handleProductImageRemove = (index, isUploadImage) => {
		if (isUploadImage) {
			let uploadedProductImagesCopy = [...uploadedProductImages];
			uploadedProductImagesCopy.splice(index, 1);
			setUploadedProductImages(uploadedProductImagesCopy);

			let uploadedProductImagesUrlCopy = [...uploadedProductImagesUrl];
			uploadedProductImagesUrlCopy.splice(index, 1);
			setUploadedProductImagesUrl(uploadedProductImagesUrlCopy);
		} else {
			let productImagesCopy = [...productImages];
			productImagesCopy.splice(index, 1);
			setProductImages(productImagesCopy);
		}
	};

	return (
		<EventLayoutContainer>
			<div id="exhibitor-add-product-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-4">
					<div className="d-flex align-items-center">
						<div className="btn-back" onClick={() => history.goBack()}>
							<img src={IconBack} alt="back" className="img-fluid" />
						</div>
						{exhibitorRoomDetails && (
							<img src={exhibitorRoomDetails.logo_url} alt="room logo" className="img-fluid room-logo" />
						)}
						<div>
							<h2 className="title">Product Details</h2>
							<span className="subtitle">Exhibitors &gt; Exhibitor Rooms &gt; Create Product</span>
						</div>
					</div>
				</section>

				<div className="section-container mb-3">
					<form id="form-add-product" method="post" onSubmit={handleCreateProduct}>
						<div className="row mb-3">
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="name">Product Name</label>
									<input
										id="name"
										type="text"
										className="form-control"
										aria-describedby="name"
										name="name"
										value={inputData.name}
										onChange={handleInputOnChange}
										required
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="form-group">
									<label htmlFor="description">Product Description</label>
									<textarea
										id="description"
										type="text"
										className="form-control"
										aria-describedby="description"
										name="description"
										value={inputData.description}
										onChange={handleInputOnChange}
										rows={3}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<label htmlFor="price">Product Price</label>
									<div className="d-flex align-items-center product-price-input-wrapper">
										<div className="select-wrapper">
											<select className="form-control mr-3">
												<option value="MYR">MYR</option>
											</select>
										</div>
										<input
											id="price"
											type="number"
											className="form-control"
											aria-describedby="price"
											name="price"
											value={inputData.price}
											onChange={handleInputOnChange}
											required
											step="0.01"
											min="0"
										/>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className="form-group">
									<label>Photo Gallery</label>
									<div className="d-flex align-items-center">
										{uploadedProductImagesUrl.map((uploadedProductImage, index) => {
											return (
												<GalleryItem
													key={index}
													thumbnail={uploadedProductImage}
													isUploaded
													index={index}
													onUpload={handleProductImageUpload}
													onRemove={handleProductImageRemove}
												/>
											);
										})}

										<GalleryItem
											onUpload={handleProductImageUpload}
											onRemove={handleProductImageRemove}
											index={uploadedProductImages.length - 1}
										/>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>

				<div className="row">
					<div className="col-10"></div>
					<div className="col-2">
						<button type="submit" className="btn btn-primary w-100" form="form-add-product">
							Create
						</button>
					</div>
				</div>
			</div>
		</EventLayoutContainer>
	);
};

const GalleryItem = ({ thumbnail, isUploaded, onUpload, onRemove, index }) => {
	const [isUploadImage, setIsUploadImage] = useState(isUploaded || false);
	const [imageThumbnail, setImageThumbnail] = useState(thumbnail || null);

	const imageRef = useRef();

	const browsePicture = () => {
		imageRef.current.click();
	};

	const handleImageChange = (event) => {
		if (typeof onUpload === "function") {
			onUpload(event, index);
		}
	};

	const handleRemoveImage = () => {
		if (typeof onRemove === "function") {
			onRemove(index, isUploadImage);
		}
	};

	// Show image thumbnail
	if (imageThumbnail) {
		return (
			<div className="gallery-item mr-3">
				<img src={thumbnail} alt="thumbnail" className="img-fluid img-product" />
				<img src={IconRemove} alt="remove" className="icon-remove" onClick={handleRemoveImage} />
			</div>
		);
	}

	// No image thumbnail, show add icon
	return (
		<div className="gallery-item-add mr-3">
			<img src={IconUpload} alt="upload" className="img-fluid img-upload" onClick={browsePicture} />
			<input
				type="file"
				name="image"
				onChange={handleImageChange}
				value={""}
				ref={imageRef}
				className="d-none"
				accept="image/*"
			/>
		</div>
	);
};

export default withRouter(AddExhibitorStoreProduct);
