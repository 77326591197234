import React, { useState } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { billingActions } from "app/states/billings";
import IconClose from "app/assets/icon-close.png";
import { withRouter } from "react-router-dom";
import { commonActions } from "app/states/common";
import ReactLoading from "react-loading";
import BillingAPI from "app/apis/billings";
import LocalStorageService from "app/services/localStorageService";

const UpgradeAnnualPlanConfirmationModal = ({ history }) => {
	const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();

	const dispatch = useDispatch();
	const closeUpgradeAnnualPlanConfirmationModal = () =>
		dispatch(billingActions.closeUpgradeAnnualPlanConfirmationModal());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));
	const refreshCurrentSubscriptionPlan = () => dispatch(billingActions.refreshCurrentSubscriptionPlan());

	const showUpgradeAnnualPlanConfirmationModal = useSelector(
		({ billing }) => billing.showUpgradeAnnualPlanConfirmationModal
	);
	const chosenPlan = useSelector(({ billing }) => billing.chosenPlan);

	const handleCheckout = () => {
		const data = {
			subscription_plan_id: chosenPlan.id,
			term: 12,
		};

		BillingAPI.postChoosePlan(selectedOrganizationAccount.id, data)
			.then((response) => {
				refreshCurrentSubscriptionPlan();
				
				if (response.code === 200) {
					const { payment_url } = response.data;
					window.location.href = payment_url;
					
					closeUpgradeAnnualPlanConfirmationModal();
				}
				else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	return (
		<Modal
			show={showUpgradeAnnualPlanConfirmationModal}
			onHide={closeUpgradeAnnualPlanConfirmationModal}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			contentClassName="upgrade-plan-confirmation-modal"
			centered>
			<Modal.Body>
				{chosenPlan ? (
					<div className="upgrade-plan-confirmation-modal-body">
						<div className="icon-close-wrapper">
							<img
								src={IconClose}
								alt="close"
								className="icon-close"
								onClick={closeUpgradeAnnualPlanConfirmationModal}
							/>
						</div>

						<div className="content-wrapper">
							<h1>Upgrade Confirmation</h1>

							<div className="content-container">
								<div className="d-flex justify-content-between align-items-center mb-3">
									<div>
										<div className="text-16">{chosenPlan.name}</div>
										<div className="text-12">Billed Annually (${chosenPlan.monthly_price} x 12 months)</div>
									</div>
									<div className="plan-pricing">
										<span className="price mr-2">${chosenPlan.yearly_price}</span>
										<span>/ year</span>
									</div>
								</div>

								<div className="d-flex justify-content-between align-items-center">
									<div className="text-discount">Discount {chosenPlan.yearly_discount_percentage}%</div>
									<div className="discounted-price">- ${chosenPlan.discount_yearly_price}</div>
								</div>

								<hr className="mt-3 mb-3" />

								<div className="d-flex justify-content-between align-items-center">
									<div className="text-12">Payable Amount</div>
									<div className="price">${chosenPlan.discounted_yearly_price}</div>
								</div>
							</div>

							<p className="description">
								This is an annual subscription plan. You can choose to opt out at any point of time at
								the billing panel.
							</p>

							<div className="d-flex justify-content-center align-items-center">
								<button className="btn btn-cancel" onClick={closeUpgradeAnnualPlanConfirmationModal}>
									Cancel
								</button>
								<button className="btn btn-check-out" onClick={handleCheckout}>
									Check Out
								</button>
							</div>
						</div>
					</div>
				) : (
					<div className="d-flex justify-content-center align-items-center mt-5">
						<ReactLoading type={"spinningBubbles"} color="lightgrey" />
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(UpgradeAnnualPlanConfirmationModal);
