import { combineReducers } from "redux";
import CommonReducer from "./common";
import OrganizationReducer from "./organization";
import EventReducer from "./event";
import BillingReducer from "./billings";

const appReducer = combineReducers({
	common: CommonReducer,
	organization: OrganizationReducer,
	event: EventReducer,
	billing: BillingReducer,
});

const rootReducer = (state, action) => {
	if (action.type === "USER_LOGOUT") {
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};

export default rootReducer;
