/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import EventInfo from "app/views/components/EventInfo/EventInfo";
import { useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import EventTicketAPI from "app/apis/eventTicket";
import { numberWithCommas } from "app/helper/common";
import { commonActions } from "app/states/common";
import CommonAPI from "app/apis/common";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import moment from "moment";
import { EVENT_TICKET_TRANSACTION_STATUS } from "app/config/constants";

const MAX_TABLE_ROWS = 10;

const GreenRadio = withStyles({
	root: {
		color: "#D1D1D1",
		"&$checked": {
			color: "#34A853",
		},
	},
	checked: {},
})((props) => <Radio color="default" {...props} />);

const TicketPriceSettings = ({ history }) => {
	const { eventId } = useParams();

	const dispatch = useDispatch();
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const [currencies, setCurrencies] = useState([]);
	const [ticketSummaries, setTicketSummaries] = useState(null);
	const [priceSetting, setPriceSetting] = useState("free_admission");
	const [inputData, setInputData] = useState({
		ticket_name: "",
		currency: "USD",
		price: "",
		description: "",
	});

	const [searchText, setSearchText] = useState("");
	const [page, setPage] = useState(1);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [transactions, setTransactions] = useState([]);
	const [loadedTransactionListing, setLoadedTransactionListing] = useState(false);
	const [hasTransactions, setHasTransactions] = useState(false);

	// Get ticket details
	useEffect(() => {
		EventTicketAPI.getEventTickets(eventId)
			.then((response) => {
				if (response.code === 200) {
					if (response.data.length > 0) {
						const { name, currency, price, description, is_enabled } = response.data[0];
						setInputData({
							...inputData,
							ticket_name: name,
							currency: currency,
							price: price,
							description: description,
						});

						if (is_enabled) {
							setPriceSetting("chargeable");
						}
					}
				}
			})
			.catch((error) => {});
	}, []);

	// Get ticket summaries
	useEffect(() => {
		EventTicketAPI.getEventTicketSummaries(eventId)
			.then((response) => {
				if (response.code === 200) {
					setTicketSummaries(response.data);
				}
			})
			.catch((error) => {});
	}, []);

	// Get currencies
	useEffect(() => {
		CommonAPI.getCurrencies()
			.then((response) => {
				if (response.code === 200) {
					setCurrencies(response.data);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	const handleRadioButtonOnChange = (event) => {
		setPriceSetting(event.target.value);
	};

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();

		const data = {
			name: inputData.ticket_name,
			currency: inputData.currency,
			price: inputData.price,
			description: inputData.description,
			is_enabled: priceSetting === "free_admission" ? 0 : 1,
		};

		EventTicketAPI.postCreateUpdateTicket(eventId, data)
			.then((response) => {
				if (response.code === 200) {
					openAlertSnackbar("Updated successfully", "success");
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	// Get transaction listing
	useEffect(() => {
		let params = {
			page: page,
			limit: MAX_TABLE_ROWS,
		};

		if (searchText) {
			params["search_string"] = searchText;
		}

		EventTicketAPI.getTicketTransactions(eventId, params)
			.then((response) => {
				const { transactions, paging } = response.data;

				// Check whether has transaction data
				if (transactions.length > 0 && !loadedTransactionListing) {
					setHasTransactions(true);
				}

				// Set transactions
				setTransactions(transactions);

				// Set paging
				const { last_page } = paging;
				setTotalPageCount(last_page);

				setLoadedTransactionListing(true);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [page]);

	// Search transaction listing
	useEffect(() => {
		if (!loadedTransactionListing) return;

		const timeout = setTimeout(() => {
			let params = {
				page: page,
				limit: MAX_TABLE_ROWS,
			};

			if (searchText) {
				params["search_string"] = searchText;
			}

			EventTicketAPI.getTicketTransactions(eventId, params)
				.then((response) => {
					const { transactions, paging } = response.data;

					// Set transactions
					setTransactions(transactions);

					// Set paging
					const { last_page } = paging;
					setTotalPageCount(last_page);
				})
				.catch((error) => {
					console.log(error);
				});
		}, 500);

		return () => clearTimeout(timeout);
	}, [searchText]);

	const handleSearchChange = (event) => {
		setSearchText(event.target.value);
		setPage(1);
	};

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	return (
		<EventLayoutContainer>
			<div id="ticket-price-setting-page">
				<section className="page-header d-flex justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						<div>
							<h2 className="title">Ticket Price</h2>
							<span className="subtitle">Event Details &gt; Ticket Price</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="ticket-sales-summary-section mx-5 my-5 mb-5">
					<h3 className="mb-3">Ticket Sales Summary</h3>
					<div className="d-flex">
						<div className="summary-card">
							<h4 className="summary-card-title">Total Tickets Sold</h4>
							<div className="summary-card-subtitle mb-3">Total tickets sold in this event</div>
							<h1 className="summary-card-value color-blue">
								{ticketSummaries ? numberWithCommas(ticketSummaries.total_tickets_sold) : "N/A"}
							</h1>
						</div>
						<div className="summary-card">
							<h4 className="summary-card-title">Total Collection</h4>
							<div className="summary-card-subtitle mb-3">Total collection from ticket sales</div>
							<h1 className="summary-card-value color-green">
								{ticketSummaries
									? `${inputData.currency} ${numberWithCommas(ticketSummaries.total_collections)}`
									: "N/A"}
							</h1>
						</div>
						<div className="summary-card">
							<h4 className="summary-card-title">Total Collection after Commission</h4>
							<div className="summary-card-subtitle mb-3">
								Total collection after deduction of transaction fees
							</div>
							<h1 className="summary-card-value color-orange">
								{ticketSummaries
									? `${inputData.currency} ${numberWithCommas(
											ticketSummaries.total_collection_after_commission
									  )}`
									: "N/A"}
							</h1>
						</div>
					</div>
				</section>

				<section className="ticket-details-section mx-5 my-5 mb-5">
					<h3 className="mb-3">Ticket Details</h3>
					<div className="section-container mb-4">
						<form id="form_ticket_details" onSubmit={handleFormSubmit}>
							<div>
								<RadioGroup
									aria-label="price_settting"
									name="price_settting"
									value={priceSetting}
									onChange={handleRadioButtonOnChange}>
									<FormControlLabel
										value="free_admission"
										control={<GreenRadio />}
										label="Free Admission"
										classes={{ root: "radio-label-root", label: "radio-label" }}
									/>
									<FormControlLabel
										value="chargeable"
										control={<GreenRadio />}
										label="Chargeable"
										classes={{ root: "radio-label-root", label: "radio-label" }}
									/>
								</RadioGroup>
							</div>

							{priceSetting === "chargeable" && (
								<div className="row mb-3 mt-4">
									<div className="col-12">
										<div className="form-group">
											<label htmlFor="ticket_name">Ticket Name / Title</label>
											<input
												id="ticket_name"
												type="text"
												className="form-control"
												aria-describedby="ticket_name"
												name="ticket_name"
												value={inputData.ticket_name}
												onChange={handleInputOnChange}
												required
												maxLength={30}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="form-group">
											<label htmlFor="price">Price ({inputData.currency})</label>
											<div className="row">
												<div className="col-2">
													<div className="select-wrapper">
														<select
															className="form-control"
															name="currency"
															value={inputData.currency}
															onChange={handleInputOnChange}>
															{currencies.map((currencyObject, index) => {
																const { currency } = currencyObject;

																return (
																	<option key={index} value={currency}>
																		{currency}
																	</option>
																);
															})}
														</select>
													</div>
												</div>
												<div className="col-4">
													<input
														id="price"
														type="number"
														className="form-control"
														aria-describedby="price"
														name="price"
														value={inputData.price}
														onChange={handleInputOnChange}
														required
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="col-12">
										<div className="form-group">
											<label htmlFor="description">Ticket Description</label>
											<input
												id="description"
												type="text"
												className="form-control"
												aria-describedby="description"
												name="description"
												value={inputData.description}
												onChange={handleInputOnChange}
												required
												maxLength={150}
											/>
										</div>
									</div>
								</div>
							)}
						</form>
					</div>
					<div className="row">
						<div className="col-9"></div>
						<div className="col-3">
							<button type="submit" form="form_ticket_details" className="btn btn-primary w-100">
								Save
							</button>
						</div>
					</div>
				</section>

				{hasTransactions && (
					<section className="ticket-transaction-history-section mx-5 my-5">
						<h3 className="mb-3">Ticket Sales Transaction</h3>
						<div className="row">
							<div className="col-12">
								<div className="form-group mb-0">
									<input
										type="text"
										className="form-control"
										name="search"
										placeholder="Search"
										value={searchText}
										onChange={handleSearchChange}
									/>
								</div>
							</div>
						</div>

						<table className="table table-hover">
							<thead>
								<tr>
									<th scope="col" className="table-header">
										Date
									</th>
									<th scope="col" className="table-header">
										Purchase Status
									</th>
									<th scope="col" className="table-header">
										Customer Name
									</th>
									<th scope="col" className="table-header">
										Email Address
									</th>
									<th scope="col" className="table-header">
										Ticket Price
									</th>
									<th scope="col" className="table-header">
										Amount Collected
									</th>
								</tr>
							</thead>
							<tbody>
								{transactions.map((transaction, index) => {
									const { date, status, customer_name, email, currency, price, net } = transaction;

									return (
										<tr key={index}>
											<td className="table-cell">
												{date ? moment(date).format("DD MMM YYYY, HH:mm") : "-"}
											</td>
											<td
												className={`table-cell status-${EVENT_TICKET_TRANSACTION_STATUS[status]}`}>
												{EVENT_TICKET_TRANSACTION_STATUS[status]
													? EVENT_TICKET_TRANSACTION_STATUS[status]
													: status}
											</td>
											<td className="table-cell">{customer_name || "-"}</td>
											<td className="table-cell">{email || "-"}</td>
											<td className="table-cell">
												{currency} {price}
											</td>
											<td className="table-cell">
												{currency} {net}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>

						<AppPagination
							pageCount={totalPageCount}
							handlePageChange={handlePageChange}
							initialPage={page - 1}
						/>
					</section>
				)}
			</div>
		</EventLayoutContainer>
	);
};

export default withRouter(TicketPriceSettings);
