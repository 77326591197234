import React, { useState, useRef, useEffect } from "react";
import LayoutContainer from "app/views/containers/LayoutContainer/LayoutContainer";
import AccountAPI from "app/apis/account";
import IconUpload from "app/assets/icon-upload-button@2x.png";
import "./style.scss";
import { withRouter, useParams } from "react-router-dom";
import IconBack from "app/assets/icon-back-grey.png";
import IconEditWhite from "app/assets/icon-edit-white.png";
import IconDeleteGrey from "app/assets/icon-delete-grey.png";
import InviteMemberRow from "app/views/components/InviteMemberRow/InviteMemberRow";
import LocalStorageService from "app/services/localStorageService";
import IconDelete from "app/assets/icon-delete-red.png";
import { useSelector, useDispatch } from "react-redux";
import CustomModal from "app/views/components/CustomModal/CustomModal";
import { commonActions } from "app/states/common";
import { ACCOUNT_TYPES, ACCOUNT_INVITATION_STATUS } from "app/config/settings";
import { validateEmail } from "app/helper/validation";

const ViewAccount = ({ history }) => {
	const { accountId } = useParams();
	const selectedOrganizationAccount = LocalStorageService.getSelectedOrganizationAccount();

	const dispatch = useDispatch();
	const openCustomModal = () => dispatch(commonActions.openCustomModal());
	const closeCustomModal = () => dispatch(commonActions.closeCustomModal());
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const [inputData, setInputData] = useState({
		account_type: ACCOUNT_TYPES["company"]["value"],
		logo: "",
		company_name: "",
		company_email: "",
		company_phone_no: "",
		website: "",
		address: "",
		pic_name: "",
		pic_email: "",
		pic_phone_no: "",
	});

	const [uploadedLogo, setUploadedLogo] = useState(null);

	const [emailInvite, setEmailInvite] = useState("");
	const [teamMembers, setTeamMembers] = useState([]);
	const [newTeamMembers, setNewTeamMembers] = useState([]);
	const [openModalName, setOpenModalName] = useState(null);
	const [emailToBeRemoved, setEmailToBeRemoved] = useState(null);
	const [emailIndexToBeRemoved, setEmailIndexToBeRemoved] = useState(null);

	const logoRef = useRef();

	useEffect(() => {
		getOrganizationDetail();
	}, []);

	const getOrganizationDetail = () => {
		AccountAPI.getOrganizationDetail(selectedOrganizationAccount.id, accountId)
			.then((response) => {
				const {
					name,
					email,
					phone_no,
					website,
					type,
					address,
					organization_account_user_invitations,
					pic_email,
					pic_name,
					pic_phone_no,
					logo_url,
				} = response.data;

				setInputData({
					account_type: type,
					company_name: name || "",
					company_email: email || "",
					company_phone_no: phone_no || "",
					website: website || "",
					address: address || "",
					pic_name: pic_name || "",
					pic_email: pic_email || "",
					pic_phone_no: pic_phone_no || "",
					logo: logo_url,
				});

				setTeamMembers(organization_account_user_invitations);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleInputOnChange = (event) => {
		setInputData({ ...inputData, [event.target.name]: event.target.value });
	};

	const handleChangeLogo = (event) => {
		setUploadedLogo(event.target.files[0]);
	};

	const handleDeleteLogo = () => {
		setUploadedLogo(null);
	};

	const browsePicture = () => {
		logoRef.current.click();
	};

	const handleUpdateOrganization = (event) => {
		event.preventDefault();
		showLoading();

		let formData = new FormData();

		formData.append("name", inputData.company_name);
		formData.append("type", inputData.account_type);
		formData.append("email", inputData.company_email);
		formData.append("phone_no", inputData.company_phone_no);
		formData.append("website", inputData.website);

		if (inputData.account_type === ACCOUNT_TYPES["company"]["value"]) {
			formData.append("pic_name", inputData.pic_name);
			formData.append("pic_phone_no", inputData.pic_phone_no);
			formData.append("pic_email", inputData.pic_email);
		}

		if (inputData.address) {
			formData.append("address", inputData.address);
		}

		if (uploadedLogo) {
			formData.append("thumbnail", uploadedLogo);
		}

		AccountAPI.postUpdateOrganizationAccount(accountId, formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					openAlertSnackbar("Account Updated", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleSubmitInvite = (event) => {
		// When hit enter, add to the array
		if (event.key === "Enter") {
			// Prevent default submit form
			event.preventDefault();

			// Check email valid
			if (!validateEmail(emailInvite)) {
				openAlertSnackbar("Email is invalid.", "error");
				return;
			}

			postInviteTeamMembers();

			// setNewTeamMembers([...newTeamMembers, emailInvite]);
			// setEmailInvite("");
		}
	};

	// Handle remove existing team member
	const handleRemoveTeamMember = (index) => {
		const deleteTeamMemberDetails = teamMembers[index];
		setEmailToBeRemoved(deleteTeamMemberDetails.email);
		setEmailIndexToBeRemoved(index);
		setOpenModalName("remove_team_member");
		openCustomModal();
	};

	// Handle remove new team member
	const handleRemoveNewTeamMember = (index) => {
		let newTeamMembersCopy = [...newTeamMembers];
		newTeamMembersCopy.splice(index, 1);
		setNewTeamMembers(newTeamMembersCopy);
	};

	const postInviteTeamMembers = () => {
		let formData = new FormData();

		// Include the email to be added even user didn't press enter
		if (emailInvite) {
			formData.append("organization_account_user_invitations[]", JSON.stringify({ email: emailInvite }));
		}

		// Pass existing to remain
		teamMembers.forEach((teamMember) => {
			const { id, email } = teamMember;
			formData.append("organization_account_user_invitations[]", JSON.stringify({ id, email }));
		});

		// Pass new member to send invitation
		newTeamMembers.forEach((newTeamMemberEmail) => {
			formData.append("organization_account_user_invitations[]", JSON.stringify({ email: newTeamMemberEmail }));
		});

		AccountAPI.postInviteTeamMembers(accountId, formData)
			.then((response) => {
				const { code } = response;

				if (code === 200) {
					// Reset state
					setEmailInvite("");
					setNewTeamMembers([]);

					// Get latest data
					getOrganizationDetail();

					openAlertSnackbar("Invitation Sent.", "success");
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleSendInvite = (event) => {
		event.preventDefault();
		showLoading();

		postInviteTeamMembers();
	};

	const handleConfirmDeleteTeamMember = () => {
		if (!emailToBeRemoved) {
			openAlertSnackbar("Fail to remove", "error");
			return;
		}

		showLoading();

		const data = {
			email: emailToBeRemoved,
		};

		AccountAPI.deleteOrganizationAccountUserInvite(accountId, data)
			.then((response) => {
				hideLoading();
				const { code } = response;

				if (code === 200) {
					let teamMembersCopy = [...teamMembers];
					teamMembersCopy.splice(emailIndexToBeRemoved, 1);
					setTeamMembers(teamMembersCopy);

					setEmailToBeRemoved(null);
					closeCustomModal();
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				hideLoading();
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	const handleOpenDeleteAccountModal = () => {
		setOpenModalName("remove_account");
		openCustomModal();
	};

	const handleConfirmDeleteAccount = () => {
		showLoading();

		AccountAPI.deleteOrganizationAccount(accountId)
			.then((response) => {
				hideLoading();
				const { code } = response;

				if (code === 200) {
					closeCustomModal();
					history.replace(`/accounts`);
					return;
				}

				openAlertSnackbar(response.errors.join("\n"), "error");
			})
			.catch((error) => {
				hideLoading();
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	};

	return (
		<LayoutContainer>
			<section className="page-header d-flex justify-content-between align-items-center mb-4">
				<div className="d-flex align-items-center">
					<div className="btn-back" onClick={() => history.goBack()}>
						<img src={IconBack} alt="back" className="img-fluid" />
					</div>
					<div>
						<h2 className="title">{inputData.company_name}</h2>
						<span className="subtitle">Accounts &gt; Company &gt; View Account</span>
					</div>
				</div>
			</section>

			<section className="view-account-container p-4">
				<form id="form-view-account" method="post" onSubmit={handleUpdateOrganization}>
					<div className="row">
						<div className="col-6">
							<div className="form-group">
								<label>Account Type</label>
								{/* <select
									className="form-control"
									name="account_type"
									value={inputData.account_type}
									onChange={handleInputOnChange}>
									{Object.keys(ACCOUNT_TYPES).map((accountTypeKey) => {
										return (
											<option value={ACCOUNT_TYPES[accountTypeKey]["value"]}>
												{ACCOUNT_TYPES[accountTypeKey]["display"]}
											</option>
										);
									})}
								</select> */}
								<input
									id="account_type"
									type="text"
									className="form-control"
									aria-describedby="account_type"
									name="account_type"
									value={
										parseInt(inputData.account_type) === ACCOUNT_TYPES["company"]["value"]
											? ACCOUNT_TYPES["company"]["display"]
											: ACCOUNT_TYPES["individual"]["display"]
									}
									onChange={handleInputOnChange}
									readOnly
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label>
									{parseInt(inputData.account_type) === ACCOUNT_TYPES["company"]["value"]
										? "Company Logo"
										: "Account Logo"}
								</label>
								<div className="d-flex align-items-start">
									<img
										src={
											uploadedLogo
												? URL.createObjectURL(uploadedLogo)
												: inputData.logo
												? inputData.logo
												: IconUpload
										}
										alt="event-logo"
										className="img-fluid img-logo"
										onClick={browsePicture}
									/>
									<input
										type="file"
										name="logo"
										onChange={handleChangeLogo}
										value=""
										ref={logoRef}
										className="d-none"
										accept="image/*"
									/>
									<div className="ml-3">
										<div className="d-flex align-items-start mb-1">
											<button
												type="button"
												className="btn btn-primary d-flex align-items-center"
												onClick={browsePicture}>
												Replace <img src={IconEditWhite} alt="edit" className="icon-action" />
											</button>
											<button
												type="button"
												className="btn btn-outline-secondary ml-3 d-flex align-items-center"
												onClick={handleDeleteLogo}
												disabled={uploadedLogo ? false : true}>
												Delete <img src={IconDeleteGrey} alt="edit" className="icon-action" />
											</button>
										</div>
										<div className="text-info">Suggested size: 100x100px, 2MB and below only</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="company_name">
									{parseInt(inputData.account_type) === ACCOUNT_TYPES["company"]["value"]
										? "Company Name"
										: "Name"}
								</label>
								<input
									id="company_name"
									type="text"
									className="form-control"
									aria-describedby="company_name"
									name="company_name"
									value={inputData.company_name}
									onChange={handleInputOnChange}
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="website">Website</label>
								<input
									id="website"
									type="url"
									className="form-control"
									aria-describedby="website"
									name="website"
									value={inputData.website}
									onChange={handleInputOnChange}
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="company_phone_no">Contact Number</label>
								<input
									id="company_phone_no"
									type="text"
									className="form-control"
									aria-describedby="company_phone_no"
									name="company_phone_no"
									value={inputData.company_phone_no}
									onChange={handleInputOnChange}
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="company_email">Email Address</label>
								<input
									id="company_email"
									type="email"
									className="form-control"
									aria-describedby="company_email"
									name="company_email"
									value={inputData.company_email}
									onChange={handleInputOnChange}
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="address">Address</label>
								<input
									id="address"
									type="text"
									className="form-control"
									aria-describedby="address"
									name="address"
									value={inputData.address}
									onChange={handleInputOnChange}
								/>
							</div>
						</div>

						{parseInt(inputData.account_type) === ACCOUNT_TYPES["company"]["value"] && (
							<>
								<div className="col-6">
									<div className="form-group">
										<label htmlFor="pic_name">PIC Name</label>
										<input
											id="pic_name"
											type="text"
											className="form-control"
											aria-describedby="pic_name"
											name="pic_name"
											value={inputData.pic_name}
											onChange={handleInputOnChange}
										/>
									</div>
								</div>
								<div className="col-6">
									<div className="form-group">
										<label htmlFor="pic_phone_no">PIC Contact Number</label>
										<input
											id="pic_phone_no"
											type="text"
											className="form-control"
											aria-describedby="pic_phone_no"
											name="pic_phone_no"
											value={inputData.pic_phone_no}
											onChange={handleInputOnChange}
										/>
									</div>
								</div>
								<div className="col-6">
									<div className="form-group">
										<label htmlFor="pic_email">PIC Email Address</label>
										<input
											id="pic_email"
											type="text"
											className="form-control"
											aria-describedby="pic_email"
											name="pic_email"
											value={inputData.pic_email}
											onChange={handleInputOnChange}
										/>
									</div>
								</div>
							</>
						)}
					</div>
					<div className="row">
						<div className="col-6"></div>
						<div className="col-3">
							<button
								type="button"
								className="btn btn-outline-danger w-100"
								onClick={handleOpenDeleteAccountModal}>
								<img src={IconDelete} alt="delete" className="icon-btn" /> Delete
							</button>
						</div>
						<div className="col-3">
							<button type="submit" className="btn btn-primary w-100">
								Save
							</button>
						</div>
					</div>
				</form>

				<form id="form-invite-member" method="post" onSubmit={handleSendInvite}>
					<div className="row">
						<div className="invite-team-members col-12 form-group">
							<label>Invite Staff/Team Members</label>
							{teamMembers.map((teamMember, index) => {
								const { email, status } = teamMember;
								return (
									<InviteMemberRow
										key={`${email}-${index}`}
										value={email}
										allowRemove
										onRemove={() => handleRemoveTeamMember(index)}
										readOnly
										confirmed={status === ACCOUNT_INVITATION_STATUS["accepted"] ? true : false}
										invited={
											status === ACCOUNT_INVITATION_STATUS["pending_invitation"] ? true : false
										}
									/>
								);
							})}

							{newTeamMembers.map((newTeamMember, index) => {
								return (
									<InviteMemberRow
										key={`${newTeamMember}-${index}`}
										value={newTeamMember}
										allowRemove
										onRemove={() => handleRemoveNewTeamMember(index)}
										readOnly
									/>
								);
							})}

							<InviteMemberRow
								value={emailInvite}
								onChange={(event) => setEmailInvite(event.target.value)}
								onKeyDown={handleSubmitInvite}
							/>
						</div>
					</div>

					<div className="row">
						<div className="col-9"></div>
						<div className="col-3">
							<button type="submit" className="btn btn-primary w-100">
								Send Invite
							</button>
						</div>
					</div>
				</form>
			</section>

			{openModalName === "remove_account" && (
				<CustomModal
					icon={IconDelete}
					title="Are you sure you want to delete this account?"
					btnClass="btn-danger"
					textPrimary={
						<>
							<img src={IconDeleteGrey} alt="delete" className="icon-btn" /> Delete
						</>
					}
					textSecondary="Cancel"
					onClickPrimary={handleConfirmDeleteAccount}
					onClickSecondary={closeCustomModal}
				/>
			)}

			{openModalName === "remove_team_member" && (
				<CustomModal
					icon={IconDelete}
					title="Are you sure you want to delete this team member?"
					btnClass="btn-danger"
					textPrimary={
						<>
							<img src={IconDeleteGrey} alt="delete" className="icon-btn" /> Delete
						</>
					}
					textSecondary="Cancel"
					onClickPrimary={handleConfirmDeleteTeamMember}
					onClickSecondary={closeCustomModal}
				/>
			)}
		</LayoutContainer>
	);
};

export default withRouter(ViewAccount);
