export async function createFileFromUrl(url) {
	let response = await fetch(url);
	let data = await response.blob();
	const objectURL = URL.createObjectURL(data);

	return new File(objectURL, "logo.jpg");
}

export function numberWithCommas(x) {
	if (!x) return 0;
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatCurrencyNumbers(x) {
	return x.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function ordinal_suffix_of(i) {
	var j = i % 10,
		k = i % 100;

	if (j === 1 && k !== 11) {
		return i + "st";
	}
	if (j === 2 && k !== 12) {
		return i + "nd";
	}
	if (j === 3 && k !== 13) {
		return i + "rd";
	}
	return i + "th";
}

export function toFixedIfNecessary(value, dp) {
	if (!value) return 0;
	return +parseFloat(value).toFixed(dp);
}

export function validateEmail(email) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function getRandomColor() {
	var letters = "0123456789ABCDEF";
	var color = "#";
	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
}
