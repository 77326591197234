/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import { getRandomColor, numberWithCommas, toFixedIfNecessary } from "app/helper/common";
import { commonActions } from "app/states/common";
import { useDispatch } from "react-redux";
import SessionStorageService from "app/services/sessionStorageService";
import EventInfo from "app/views/components/EventInfo/EventInfo";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import IconSpeaker from "app/assets/icon-loudspeaker.png";
import IconDownload from "app/assets/icon-download.png";
import IconInfo from "app/assets/icon-info.png";
import IconInfoQuestionMark from "app/assets/icon-info-question-mark.png";
import { Bar } from "react-chartjs-2";
import StatisticAPI from "app/apis/statistic";
import { EVENT_STATUS } from "app/config/constants";
import COUNTRIES from "app/config/country_region.json";
import moment from "moment";

const TABLE_ROWS_LIMIT = 10;

const groupedBarChartOptions = {
	scales: {
		x: {
			grid: {
				drawBorder: false,
				drawOnChartArea: false,
			},
		},
		y: {
			ticks: {
				beginAtZero: true,
			},
		},
	},
	responsive: true,
	maintainAspectRatio: true,
};

const verticalBarChartOptions = {
	plugins: {
		legend: {
			display: false,
		},
	},
	scales: {
		x: {
			grid: {
				drawBorder: false,
				drawOnChartArea: false,
			},
		},
		y: {
			ticks: {
				beginAtZero: true,
			},
		},
	},
	responsive: true,
	maintainAspectRatio: true,
};

const StatisticReportingSummary = ({ history }) => {
	const { eventId } = useParams();
	const lastViewingPage = SessionStorageService.getLastViewingPage();

	const dispatch = useDispatch();
	const showLoading = () => dispatch(commonActions.showLoading());
	const hideLoading = () => dispatch(commonActions.hideLoading());
	const openAlertSnackbar = (message, variant) => dispatch(commonActions.openAlertSnackbar(message, variant));

	const [page, setPage] = useState(lastViewingPage || 1);
	const [totalPageCount, setTotalPageCount] = useState(0);

	const [summaryText, setSummaryText] = useState("");
	const [reportAnalytics, setReportAnalytics] = useState(null);
	const [eventStatusCode, setEventStatusCode] = useState(null);
	const [eventThumbnail, setEventThumbnail] = useState(null);

	const [sessionAnalyticsOverview, setSessionAnalyticsOverview] = useState(null);
	const [sessionViewCountChartData, setSessionViewCountChartData] = useState({});
	const [sessionPerformances, setSessionPerformance] = useState([]);
	const [sessionPerformancePage, setSessionPerformancePage] = useState(1);
	const [sessionPerformanceTotalPage, setSessionPerformanceTotalPage] = useState(0);

	const [exhibitorAnalyticsOverview, setExhibitorAnalyticsOverview] = useState(null);
	const [exhibitorVisitByDateChartData, setExhibitorVisitByDateChartData] = useState({});
	const [exhibitorPerformances, setExhibitorPerformances] = useState([]);
	const [exhibitorPerformancePage, setExhibitorPerformancePage] = useState(1);
	const [exhibitorPerformanceTotalPage, setExhibitorPerformanceTotalPage] = useState(0);

	// Get report analytics
	useEffect(() => {
		showLoading();

		StatisticAPI.getReportAnalytics(eventId)
			.then((response) => {
				if (response.code === 200) {
					const { summary } = response.data;
					const { event_status_code, event_web_configs } = response.data.event;

					// summary text
					setSummaryText(summary);

					// event status code
					let eventStatusKey = EVENT_STATUS[event_status_code] ? event_status_code : "unpublish";
					setEventStatusCode(eventStatusKey);

					// event thumbnail
					const logoConfig = event_web_configs.find((config) => config.key === "logo_url");
					setEventThumbnail(logoConfig.value);

					// all report analytics data
					setReportAnalytics(response.data);
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			})
			.finally(() => {
				hideLoading();
			});
	}, []);

	// Get session analytics
	useEffect(() => {
		StatisticAPI.getReportSessionAnalytics(eventId)
			.then((response) => {
				if (response.code === 200) {
					const { overview, session_view_count_per_channel } = response.data;

					const chartDateLabel = session_view_count_per_channel.map((sessionView) =>
						moment(sessionView.start).format("DD MMMM")
					);

					const chartData = {};
					session_view_count_per_channel.forEach((sessionView) => {
						sessionView.channels.forEach((channel) => {
							const { name, count } = channel;

							if (chartData.hasOwnProperty(name)) {
								chartData[name].push(count);
							} else {
								chartData[name] = [];
								chartData[name].push(count);
							}
						});
					});

					const chartDatasets = Object.keys(chartData).map((labelName) => {
						return {
							label: labelName,
							data: chartData[labelName],
							backgroundColor: getRandomColor(),
						};
					});

					const groupedBarChartData = {
						labels: chartDateLabel,
						datasets: chartDatasets,
					};

					setSessionAnalyticsOverview(overview);
					setSessionViewCountChartData(groupedBarChartData);
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	}, []);

	// Get session performance
	useEffect(() => {
		const params = {
			page: sessionPerformancePage,
			limit: TABLE_ROWS_LIMIT,
		};

		StatisticAPI.getReportSessionPerformance(eventId, params)
			.then((response) => {
				if (response.code === 200) {
					const { sessions, paging } = response.data;
					const { last_page } = paging;

					setSessionPerformance(sessions);
					setSessionPerformanceTotalPage(last_page);
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	}, [sessionPerformancePage]);

	// Get exhibitor analytics
	useEffect(() => {
		StatisticAPI.getReportExhibitorAnalytics(eventId)
			.then((response) => {
				if (response.code === 200) {
					const { overview, exhibitor_visits_per_date } = response.data;

					const chartDateLabel = exhibitor_visits_per_date.map((exhibitorVisit) =>
						moment(exhibitorVisit.start).format("DD MMMM")
					);
					const chartData = exhibitor_visits_per_date.map((exhibitorVisit) => exhibitorVisit.total_visits);
					const verticalBarChartData = {
						labels: chartDateLabel,
						datasets: [
							{
								data: chartData,
								backgroundColor: "#1E76FE",
							},
						],
					};

					setExhibitorAnalyticsOverview(overview);
					setExhibitorVisitByDateChartData(verticalBarChartData);
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	}, []);

	// Get exhibitor performance
	useEffect(() => {
		const params = {
			page: exhibitorPerformancePage,
			limit: TABLE_ROWS_LIMIT,
		};

		StatisticAPI.getReportExhibitorPerformance(eventId, params)
			.then((response) => {
				if (response.code === 200) {
					const { exhibitors, paging } = response.data;
					const { last_page } = paging;

					setExhibitorPerformances(exhibitors);
					setExhibitorPerformanceTotalPage(last_page);
				} else {
					openAlertSnackbar(response.errors.join("\n"), "error");
				}
			})
			.catch((error) => {
				console.error(error);
				openAlertSnackbar(error.data.errors.join("\n"), "error");
			});
	}, [exhibitorPerformancePage]);

	const handleSessionPerformancePageChange = (selectedPage) => {
		setSessionPerformancePage(selectedPage);
	};

	const handleExhibitorPerformancePageChange = (selectedPage) => {
		setExhibitorPerformancePage(selectedPage);
	};

	const handleDownloadSessionPerformanceCsv = () => {
		StatisticAPI.getReportSessionPerformanceCSV(eventId)
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "session_performance.csv");
				document.body.appendChild(link);
				link.click();
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleDownloadExhibitorPerformanceCsv = () => {
		StatisticAPI.getReportExhibitorPerformanceCSV(eventId)
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "exhibitor_performance.csv");
				document.body.appendChild(link);
				link.click();
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const OverviewStatRow = ({ label, value, tooltip }) => {
		return (
			<div className="row mb-3">
				<div className="col-8 stat-label">{label}</div>
				<div className="col-4 stat-value text-right">
					{value}
					{/* TODO: Hide temporary until got the copyrighting */}
					{/* <OverlayTrigger
						placement="bottom"
						delay={{ show: 250, hide: 400 }}
						overlay={(props) => renderTooltip(props, tooltip)}>
						<button className="question-info-tooltip" data-toggle="tooltip" data-placement="bottom">
							<img src={IconInfoQuestionMark} alt="question info" className="question-icon-info" />
						</button>
					</OverlayTrigger> */}
				</div>
			</div>
		);
	};

	const renderTooltip = (props, text) => <Tooltip {...props}>{text}</Tooltip>;

	if (!reportAnalytics) {
		return (
			<EventLayoutContainer>
				<div id="statistic-reporting-summary-page">
					<section className="page-header d-flex justify-content-between align-items-center mb-5">
						<div className="d-flex align-items-center">
							<div>
								<h2 className="title">Statistics & Reporting</h2>
								<span className="subtitle">Statistics & Reporting &gt; Summary</span>
							</div>
						</div>
						<EventInfo />
					</section>
				</div>
			</EventLayoutContainer>
		);
	}

	return (
		<EventLayoutContainer>
			<div id="statistic-reporting-summary-page">
				<section className="page-header d-flex justify-content-between align-items-center mb-5">
					<div className="d-flex align-items-center">
						<div>
							<h2 className="title">Statistics & Reporting</h2>
							<span className="subtitle">Statistics & Reporting &gt; Summary</span>
						</div>
					</div>
					<EventInfo />
				</section>

				<section className="event-analytics-section mb-5">
					<h3>Event Analytics</h3>
					<div className="row">
						<div className="col-7">
							<div className="notice-wrapper mb-3">
								<img src={IconSpeaker} alt="event" className="img-notice" />
								<div className="text-notice">{summaryText}</div>
							</div>
							<div className="status-wrapper mb-3 row">
								<div className="col">
									<div
										className="status-card"
										style={{ backgroundColor: EVENT_STATUS[eventStatusCode]["color"] }}>
										{EVENT_STATUS[eventStatusCode]["display"]}
									</div>
								</div>
								<div className="col">
									<div className="percentage-card">
										<div className="percentage-value">
											{toFixedIfNecessary(reportAnalytics.completed_percentage, 2)}%
										</div>
										<div className="desc">Completed</div>
										<div className="percentage-bar-wrapper">
											<div
												className="percentage-progress"
												style={{
													width: `${toFixedIfNecessary(
														reportAnalytics.completed_percentage,
														2
													)}%`,
												}}></div>
										</div>
										{/* TODO: Hide temporary until got the copyrighting */}
										{/* <OverlayTrigger
											placement="bottom"
											delay={{ show: 250, hide: 400 }}
											overlay={(props) =>
												renderTooltip(
													props,
													"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend ipsum sed varius pretium. Interdum et malesuada fames ac ante ipsum primis in faucibus."
												)
											}>
											<button
												className="info-tooltip"
												data-toggle="tooltip"
												data-placement="bottom">
												<img src={IconInfo} alt="info" className="icon-info" />
											</button>
										</OverlayTrigger> */}
									</div>
								</div>
								<div className="col">
									<div className="percentage-card">
										<div className="percentage-value">
											{toFixedIfNecessary(reportAnalytics.attendance_percentage, 2)}%
										</div>
										<div className="desc">Attendance Rate</div>
										<div className="percentage-bar-wrapper">
											<div
												className="percentage-progress"
												style={{
													width: `${toFixedIfNecessary(
														reportAnalytics.attendance_percentage,
														2
													)}%`,
												}}></div>
										</div>
										{/* TODO: Hide temporary until got the copyrighting */}
										{/* <OverlayTrigger
											placement="bottom"
											delay={{ show: 250, hide: 400 }}
											overlay={(props) =>
												renderTooltip(
													props,
													"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend ipsum sed varius pretium. Interdum et malesuada fames ac ante ipsum primis in faucibus."
												)
											}>
											<button
												className="info-tooltip"
												data-toggle="tooltip"
												data-placement="bottom">
												<img src={IconInfo} alt="info" className="icon-info" />
											</button>
										</OverlayTrigger> */}
									</div>
								</div>
							</div>
							<div className="overview-wrapper mb-3">
								<div className="row">
									<div className="col-7">
										<h6 className="overview-title">Overview</h6>
										<div className="overview-stats-container">
											<OverviewStatRow
												label="Number of event views"
												value={numberWithCommas(reportAnalytics.overview.total_views)}
												tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend ipsum sed varius pretium."
											/>

											<OverviewStatRow
												label="Number of registered visitors"
												value={numberWithCommas(reportAnalytics.overview.total_visitors)}
												tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend ipsum sed varius pretium."
											/>

											<OverviewStatRow
												label="Number of visitors login"
												value={numberWithCommas(reportAnalytics.overview.total_visitors_login)}
												tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend ipsum sed varius pretium."
											/>

											<OverviewStatRow
												label="Number of sessions"
												value={numberWithCommas(reportAnalytics.overview.total_sessions)}
												tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend ipsum sed varius pretium."
											/>

											<OverviewStatRow
												label="Number of exhibitors"
												value={numberWithCommas(reportAnalytics.overview.total_exhibitors)}
												tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend ipsum sed varius pretium."
											/>

											<OverviewStatRow
												label="Number of advertisements"
												value={numberWithCommas(reportAnalytics.overview.total_advertisements)}
												tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend ipsum sed varius pretium."
											/>

											<OverviewStatRow
												label="Total Collection"
												value={numberWithCommas(reportAnalytics.overview.total_collections)}
												tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eleifend ipsum sed varius pretium."
											/>
										</div>
									</div>
									<div className="col-5">
										<h6 className="overview-title">Top visitors by country</h6>
										<div className="top-visitors-container">
											{reportAnalytics.visitors_country.map((visitor_country, index) => {
												const { vistior_count, country } = visitor_country;

												// Get the country name
												const countryObject = COUNTRIES.filter((countryRegion) => {
													return (
														countryRegion.countryShortCode === country ||
														countryRegion.countryName === country
													);
												});

												return (
													<div
														className="row align-items-center mb-2"
														key={`visitor-country-${index}`}>
														<div className="col-9">
															<div className="country-box"></div>
															{countryObject.length > 0
																? countryObject[0].countryName
																: country}
														</div>
														<div className="col-3 text-center">
															{numberWithCommas(vistior_count)}
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-5">
							<img src={eventThumbnail} alt="event" className="img-fluid" />
						</div>
					</div>
				</section>

				<section className="analytics-section session-analytics-section mb-5">
					<h3>Session Analytics</h3>

					<div className="session-analytics-container">
						<div className="row mb-5">
							{sessionAnalyticsOverview && (
								<div className="col-5 overview-container">
									<div className="container-title">Overview</div>
									<div className="overview-stats">
										<div className="stat-item">
											<div className="stat-name">Total Sessions</div>
											<div className="stat-value">
												{numberWithCommas(sessionAnalyticsOverview.total_sessions)}
											</div>
										</div>
										<div className="stat-item">
											<div className="stat-name">Total Speakers</div>
											<div className="stat-value">
												{numberWithCommas(sessionAnalyticsOverview.total_speakers)}
											</div>
										</div>
										<div className="stat-item">
											<div className="stat-name">Total Polls Created</div>
											<div className="stat-value">
												{numberWithCommas(sessionAnalyticsOverview.total_polls)}
											</div>
										</div>
										<div className="stat-item">
											<div className="stat-name">Total Questions</div>
											<div className="stat-value">
												{numberWithCommas(sessionAnalyticsOverview.total_questions)}
											</div>
										</div>
										<div className="stat-item">
											<div className="stat-name">Total Chats</div>
											<div className="stat-value">
												{numberWithCommas(sessionAnalyticsOverview.total_chats)}
											</div>
										</div>
										<div className="stat-item">
											<div className="stat-name">Total Likes</div>
											<div className="stat-value">
												{numberWithCommas(sessionAnalyticsOverview.total_likes)}
											</div>
										</div>
										<div className="stat-item">
											<div className="stat-name">Average Likes Per Session</div>
											<div className="stat-value">
												{toFixedIfNecessary(
													numberWithCommas(
														sessionAnalyticsOverview.average_likes_per_session
													),
													2
												)}
											</div>
										</div>
									</div>
								</div>
							)}
							<div className="col-7 chart-container">
								<div className="container-title ml-3">Session View Count per Channel</div>
								<div className="p-3 position-relative w-100">
									<Bar data={sessionViewCountChartData} options={groupedBarChartOptions} />
								</div>
							</div>
						</div>

						<div className="session-performance-container">
							<div className="container-header">
								<div className="container-title">Session Performance</div>
							</div>
							<div className="table-wrapper">
								<table className="table table-hover">
									<thead>
										<tr>
											<th scope="col" className="table-header">
												Session ID
											</th>
											<th scope="col" className="table-header text-left">
												Session Name
											</th>
											<th scope="col" className="table-header">
												Channel Name
											</th>
											<th scope="col" className="table-header">
												Viewers Count
											</th>
											<th scope="col" className="table-header">
												View Count
											</th>
											<th scope="col" className="table-header">
												Likes
											</th>
											<th scope="col" className="table-header">
												Q&A
											</th>
											<th scope="col" className="table-header">
												Polls
											</th>
											<th scope="col" className="table-header">
												Votes
											</th>
										</tr>
									</thead>
									<tbody>
										{sessionPerformances.map((sessionPerformance) => {
											const {
												session_id,
												session_name,
												channel_name,
												view_count,
												viewer_count,
												likes_count,
												total_questions,
												total_polls,
												total_votes,
											} = sessionPerformance;

											return (
												<tr key={`session-performance-${session_id}`}>
													<td className="table-cell" style={{ minWidth: "100px" }}>
														{session_id}
													</td>
													<td className="table-cell text-left" style={{ minWidth: "250px" }}>
														{session_name}
													</td>
													<td className="table-cell" style={{ minWidth: "200px" }}>
														{channel_name}
													</td>
													<td className="table-cell" style={{ minWidth: "146px" }}>
														{numberWithCommas(viewer_count)}
													</td>
													<td className="table-cell" style={{ minWidth: "146px" }}>
														{numberWithCommas(view_count)}
													</td>
													<td className="table-cell" style={{ minWidth: "146px" }}>
														{numberWithCommas(likes_count)}
													</td>
													<td className="table-cell" style={{ minWidth: "146px" }}>
														{numberWithCommas(total_questions)}
													</td>
													<td className="table-cell" style={{ minWidth: "146px" }}>
														{numberWithCommas(total_polls)}
													</td>
													<td className="table-cell" style={{ minWidth: "146px" }}>
														{numberWithCommas(total_votes)}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>

							<div className="d-flex align-items-center justify-content-between w-100 mt-3">
								<div className="px-4"></div>

								<AppPagination
									pageCount={sessionPerformanceTotalPage}
									handlePageChange={handleSessionPerformancePageChange}
									initialPage={sessionPerformancePage - 1}
									className="mb-0"
								/>

								<span className="btn-download-csv" onClick={handleDownloadSessionPerformanceCsv}>
									<img src={IconDownload} alt="download" /> Download as CSV
								</span>
							</div>
						</div>
					</div>
				</section>

				<section className="analytics-section exhibitor-analytics-section mb-5">
					<h3>Exhibitor Analytics</h3>

					<div className="exhibitor-analytics-container">
						<div className="row mb-5">
							{exhibitorAnalyticsOverview && (
								<div className="col-5 overview-container">
									<div className="container-title">Overview</div>
									<div className="overview-stats">
										<div className="stat-item">
											<div className="stat-name">Total Exhibitors</div>
											<div className="stat-value">
												{numberWithCommas(exhibitorAnalyticsOverview.total_exhibitors)}
											</div>
										</div>
										<div className="stat-item">
											<div className="stat-name">Average Visitor Per Room</div>
											<div className="stat-value">
												{numberWithCommas(
													exhibitorAnalyticsOverview.average_vistiors_per_rooms
												)}
											</div>
										</div>
										<div className="stat-item">
											<div className="stat-name">Total Breakout Rooms</div>
											<div className="stat-value">
												{numberWithCommas(exhibitorAnalyticsOverview.total_breakout_rooms)}
											</div>
										</div>
										<div className="stat-item">
											<div className="stat-name">Total Uploaded Files</div>
											<div className="stat-value">
												{numberWithCommas(exhibitorAnalyticsOverview.total_uploaded_files)}
											</div>
										</div>
										<div className="stat-item">
											<div className="stat-name">Total Uploaded Media</div>
											<div className="stat-value">
												{numberWithCommas(exhibitorAnalyticsOverview.total_uploaded_media)}
											</div>
										</div>
									</div>
								</div>
							)}
							<div className="col-7 chart-container">
								<div className="container-title ml-3">Exhibitor Visits by Date</div>
								<div className="p-3 position-relative w-100">
									<Bar data={exhibitorVisitByDateChartData} options={verticalBarChartOptions} />
								</div>
							</div>
						</div>

						<div className="exhibitor-performance-container">
							<div className="container-header">
								<div className="container-title">Exhibitor Performance</div>
							</div>
							<div className="table-wrapper">
								<table className="table table-hover">
									<thead>
										<tr>
											<th
												scope="col"
												className="table-header text-left"
												style={{ minWidth: "200px" }}>
												Room Name
											</th>
											<th scope="col" className="table-header" style={{ minWidth: "200px" }}>
												Category
											</th>
											<th scope="col" className="table-header" style={{ minWidth: "146px" }}>
												Unique Visitors
											</th>
											<th scope="col" className="table-header" style={{ minWidth: "146px" }}>
												Chats
											</th>
											<th scope="col" className="table-header" style={{ minWidth: "146px" }}>
												Offline Enquiry
											</th>
											<th scope="col" className="table-header" style={{ minWidth: "146px" }}>
												Agents
											</th>
											<th scope="col" className="table-header" style={{ minWidth: "146px" }}>
												Chat Users
											</th>
											<th scope="col" className="table-header" style={{ minWidth: "146px" }}>
												Breakout Rooms
											</th>
										</tr>
									</thead>
									<tbody>
										{exhibitorPerformances.map((exhibitorPerformance, index) => {
											const {
												room_name,
												categories_names,
												total_vistors,
												total_breakouts,
												total_chats,
												total_chat_users,
												total_enquiries,
												total_agents,
											} = exhibitorPerformance;

											return (
												<tr key={`exhibitor-performance-${index}`}>
													<td className="table-cell text-left">{room_name}</td>
													<td className="table-cell">{categories_names}</td>
													<td className="table-cell">{numberWithCommas(total_vistors)}</td>
													<td className="table-cell">{numberWithCommas(total_chats)}</td>
													<td className="table-cell">{numberWithCommas(total_enquiries)}</td>
													<td className="table-cell">{numberWithCommas(total_agents)}</td>
													<td className="table-cell">{numberWithCommas(total_chat_users)}</td>
													<td className="table-cell">{numberWithCommas(total_breakouts)}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>

							<div className="d-flex align-items-center justify-content-between w-100 mt-3">
								<div className="px-4"></div>

								<AppPagination
									pageCount={exhibitorPerformanceTotalPage}
									handlePageChange={handleExhibitorPerformancePageChange}
									initialPage={exhibitorPerformancePage - 1}
									className="mb-0"
								/>

								<span className="btn-download-csv" onClick={handleDownloadExhibitorPerformanceCsv}>
									<img src={IconDownload} alt="download" /> Download as CSV
								</span>
							</div>
						</div>
					</div>
				</section>
			</div>
		</EventLayoutContainer>
	);
};

export default withRouter(StatisticReportingSummary);
